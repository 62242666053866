import React from "react";
import { css } from "styled-components/macro";
import { customToast } from "../../components/customToast";
import { Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { theme } from "../../themes/variables";
import { Column, Row, Spacing } from "../../helpers/layout";
import { InputField } from "../fields/InputField";
import { ImageUploadField } from "../fields/ImageUploadField";
import { Button } from "../../components/Button";

import { UserProfile } from "../../types/UserProfile";
import { Image, ImageType } from "../../types/Image";
import { InputType } from "../../components/InputComponent";
import { uploadImage } from "../../actions/image";
import { serverErrorHandler } from "../../helpers/serverErrorHandler";

export type FormValues = {
  first_name: string;
  last_name: string;
  introduction?: string;
  city: string;
  avatar: Image | null;
  position: string;
  email: string;
};

export function AccountForm(props: {
  onSubmit: (values: FormValues) => Promise<void>;
  data: UserProfile;
}) {
  const { t } = useTranslation();

  const re = new RegExp("[a-zA-Z-]");

  const onSubmit = async (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    try {
      setSubmitting(true);

      await props.onSubmit(values);
    } catch (error: any) {
      customToast.error(
        t("status.error", {
          error: serverErrorHandler(error),
        })
      );
    } finally {
      setSubmitting(false);
    }
  };

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().matches(re).required(),
    last_name: Yup.string().matches(re).required(),
    introduction: Yup.string().max(500),
    city: Yup.string().matches(re).required(),
  });

  const initialValues: FormValues = {
    first_name: props.data.first_name,
    last_name: props.data.last_name,
    introduction: props.data?.introduction,
    city: props.data.city,
    avatar: props.data.avatar || null,
    position: props.data.position || "",
    email: props.data.email,
  };

  return (
    <div
      css={css`
        width: 100%;
        max-width: 500px;
      `}
    >
      <Formik<FormValues>
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, dirty, isValid }) => {
          return (
            <Form noValidate>
              <Column
                gutter={Spacing.xl}
                css={css`
                  display: flex;
                  width: 100%;
                `}
              >
                <ImageUploadField
                  label={`${t("account.avatar")}:`}
                  name={"avatar"}
                  type={ImageType.HIDDEN}
                  disableLibrary
                  uploadFn={uploadImage}
                />

                <Row gutter={Spacing.l}>
                  <InputField
                    name={"first_name"}
                    type={InputType.TEXT}
                    label={t("account.first-name")}
                    css={css`
                      width: 100%;
                    `}
                  />

                  <InputField
                    name={"last_name"}
                    type={InputType.TEXT}
                    label={t("account.last-name")}
                    css={css`
                      width: 100%;
                    `}
                  />
                </Row>

                <InputField
                  name={"email"}
                  type={InputType.TEXT}
                  label={t("account.email")}
                  disabled
                  css={css`
                    width: 100%;
                    input {
                      border: none;
                      background-color: ${theme.colors.gray2};
                    }
                  `}
                />

                {/* <TextareaField
                  label={`${t("account.introduction")}:`}
                  name={"introduction"}
                  maxLength={500}
                  css={css`
                    width: 100%;
                  `}
                /> */}

                <InputField
                  name={"position"}
                  type={InputType.TEXT}
                  label={`${t("account.position")}`}
                />

                <InputField
                  name={"city"}
                  type={InputType.TEXT}
                  label={`${t("account.city")}`}
                  css={css`
                    width: 50%;
                  `}
                />

                <div>
                  <Button
                    isSubmitting={isSubmitting}
                    type="submit"
                    disabled={!dirty || !isValid}
                  >
                    {t("account.update")}
                  </Button>
                </div>
              </Column>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
