import React, { useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { calculateRelativeSize } from "../helpers/renderer";

export function RichTextEditor(props: {
  html: string;
  onChange: (event: any) => void;
  disabled?: boolean;
  style?: React.CSSProperties;
  tagName?: string;
  onInit?: () => void;
  id: string;
}) {
  const editorRef = useRef<Editor>(null);

  const fontSizes = [8, 10, 12, 14, 16, 18, 24, 28, 32, 36, 48, 64, 80, 144];
  const fontString = fontSizes
    .map((size) => `${size}=${calculateRelativeSize(size)}`)
    .join(" ");

  if (props.disabled) {
    return <div dangerouslySetInnerHTML={{ __html: props.html }} />;
  }

  return (
    <Editor
      apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
      onInit={(_, editor) => {
        editorRef.current?.setState(editor);
      }}
      plugins={["lists", "link"]}
      init={{
        menubar: false,
        inline: true,
        height: "100%",
        paste_as_text: true,
        paste_remove_styles_if_webkit: true,
        paste_merge_formats: true,
        paste_remove_styles: true,
        width: "100%",
        toolbar:
          "forecolor | customInsertButton | fontfamily fontsize | bold italic underline bullist | alignleft aligncenter alignright | link",
        fixed_toolbar_container: ".editor-toolbar",
        font_size_formats: fontString,
        forced_root_block: "p",
        setup: function (editor) {
          editor.on("blur", function (e) {
            e.stopImmediatePropagation();
            return false;
          });
          // Select all text when clicking three times
          editor.on("click", function (e) {
            if (e.detail >= 3) {
              e.preventDefault();
              e.stopPropagation();
              editor.selection.select(editor.getBody(), true);
            }
          });
          editor.on("BeforeExecCommand", function (e) {
            if (e.command === "FontSize" || e.command === "FontName") {
              e.preventDefault();
              const val = e.value;
              e.target.dom.setStyle(
                [
                  ...e.target.dom.select("p"),
                  ...e.target.dom.select("h1"),
                  ...e.target.dom.select("h2"),
                  ...e.target.dom.select("h3"),
                  ...e.target.dom.select("h4"),
                  ...e.target.dom.select("h5"),
                  ...e.target.dom.select("h6"),
                  ...e.target.dom.select("ul"),
                  ...e.target.dom.select("ol"),
                  ...e.target.dom.select("li"),
                  ...e.target.dom.select("div"),
                  ...e.target.dom.select("span"),
                ],
                e.command === "FontSize" ? "font-size" : "font-family",
                val
              );
            }
          });
        },
      }}
      disabled={props.disabled}
      value={props.html}
      onEditorChange={props.onChange}
      id={props.id}
      onPaste={(e) => {
        if (!e.clipboardData) return;

        const copiedText = e.clipboardData.getData("text/plain");
        let hasJson = false;

        try {
          const jsonContent = JSON.parse(copiedText);

          if (jsonContent) {
            hasJson = true;
          }
        } catch (e) {
          hasJson = false;
        }

        if (hasJson) {
          e.preventDefault();
          e.stopPropagation();
          return;
        }
      }}
    />
  );
}
