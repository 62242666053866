import React, {
  ChangeEvent,
  FormEvent,
  KeyboardEventHandler,
  useEffect,
  useState,
} from "react";
import { css } from "styled-components/macro";
import { theme } from "../themes/variables";
import { Hint } from "./Hint";
import { InputLabel } from "./InputLabel";

export enum InputStyle {
  DEFAULT = "default",
  CLEAR = "clear",
  UNDERLINE = "underline",
}

export enum InputType {
  TEXT = "text",
  EMAIL = "email",
  SEARCH = "search",
  PASSWORD = "password",
  NUMBER = "number",
  TEL = "tel",
  URL = "url",
  SELECT = "select",
  TIME = "time",
}

export type InputProps = {
  id?: string;
  name?: string;
  type?: InputType;
  style?: InputStyle;
  label?: string;
  hint?: string;
  value?: string;
  placeholder?: string;
  disabled?: boolean;
  readonly?: boolean;
  className?: string;
  append?: React.ReactNode;
  prepend?: React.ReactNode;
  error?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onInput?: (event: FormEvent<HTMLInputElement>) => void;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  onSubmitByEnterKey?: () => void;
  onMouseEnter?: (
    event: React.MouseEvent<HTMLLabelElement, MouseEvent>
  ) => void;
  onMouseLeave?: (
    event: React.MouseEvent<HTMLLabelElement, MouseEvent>
  ) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
};

const getInputStyles = (
  style: InputStyle,
  disabled: boolean | undefined,
  error: boolean | undefined,
  focused: boolean
) => {
  switch (style) {
    case InputStyle.DEFAULT:
      return css`
        padding: 0 12px;
        border-radius: 7px;
        border: 1px solid
          ${disabled
            ? theme.colors.gray2
            : error
            ? theme.colors.redMain
            : focused
            ? theme.colors.primary
            : "#8E8E8E"};
      `;
    case InputStyle.UNDERLINE:
      return css`
        padding: 0 12px;
        border-radius: 7px;
        border: 1px solid
          ${disabled
            ? theme.colors.gray2
            : error
            ? theme.colors.redMain
            : focused
            ? theme.colors.primary
            : "#8E8E8E"};
      `;
    default:
      return;
  }
};

function ContainerOfAdditionElement(props: {
  case: "prepend" | "append";
  focused?: boolean /* There is no way to use css selector for previous siblings, so the only way to set color on focused icons for prepend is this way */;
  children: React.ReactNode;
}) {
  return (
    <div
      className={props.case}
      css={css`
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;
        height: 100%;
        ${props.case === "prepend"
          ? css`
              padding-right: 12px;
            `
          : css`
              padding-left: 12px;
            `}
      `}
    >
      {props.children}
    </div>
  );
}

export function InputElement(props: InputProps) {
  const [focused, setFocused] = useState(false);

  const { style = InputStyle.DEFAULT } = props;

  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      setFocused(true);

      if (props.onSubmitByEnterKey && event.key === "Enter" && focused) {
        props.onSubmitByEnterKey();
        setFocused(false);
      }
    };
    window.addEventListener("keydown", onKeyDown);

    return () => {
      window.removeEventListener("keydown", onKeyDown);
    };
  }, [props, focused]);

  return (
    <label onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave}>
      <InputLabel label={props.label} />

      <div
        css={css`
          position: relative;
          display: flex;
          align-items: center;
          width: 100%;
          background-color: ${theme.colors.white};
          overflow: hidden;
          ${getInputStyles(style, props.disabled, props.error, focused)}
        `}
        className={props.className}
      >
        <div
          className={focused ? "focused" : ""}
          css={css`
            position: relative;
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
            background-color: ${theme.colors.white};
            overflow: hidden;
          `}
        >
          {props.prepend && (
            <ContainerOfAdditionElement case="prepend" focused={focused}>
              {props.prepend}
            </ContainerOfAdditionElement>
          )}

          <input
            id={props.id}
            type={props.type || InputType.TEXT}
            value={props.value}
            placeholder={props.placeholder || ""}
            readOnly={props.readonly || false}
            disabled={props.disabled || false}
            aria-label={props.name || ""}
            css={css`
              flex-grow: 1;
              border: none;
              display: block;
              width: 100%;
              background-color: ${theme.colors.white};
              line-height: 1.5;
              color: ${theme.colors.dark};
              outline: none;
              font-size: 16px;
            `}
            onChange={(e) => {
              if (props.onChange) {
                props.onChange(e);
              }
            }}
            onFocus={(e) => {
              if (props.onFocus) props.onFocus(e);
              setFocused(true);
            }}
            onBlur={(e) => {
              if (props.onBlur) props.onBlur(e);
              setFocused(false);
            }}
            onKeyDown={props.onKeyDown}
            onInput={props.onInput}
          />

          {props.append && (
            <ContainerOfAdditionElement case="append" focused={focused}>
              {props.append}
            </ContainerOfAdditionElement>
          )}
        </div>

        {props.hint && <Hint>{props.hint}</Hint>}
      </div>
    </label>
  );
}
