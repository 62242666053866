import styled, { css } from "styled-components/macro";
import { useEditor } from "../contexts/EditorContext";
import { useClickOutside } from "../../VideoEditor/hooks/useClickOutside";
import { useCallback, useEffect, useRef, useState } from "react";
import { Headers } from "../helpers/headers";
import { ReactComponent as DocumentIcon } from "../assets/icons/Document.svg";
import { ReactComponent as LinkIcon } from "../assets/icons/Link.svg";
import { ReactComponent as PageLinkIcon } from "../assets/icons/PageLink.svg";
import { ReactComponent as FolderIcon } from "../assets/icons/Folder.svg";
import { ReactComponent as TrashIcon } from "../assets/icons/ControlsDelete.svg";
import { useTranslation } from "react-i18next";
import { useStorage } from "../contexts/StorageContext";
import { DropFile } from "./DropFile";
import { debounce } from "lodash";

const SquareButton = styled.button<{
  isActive: boolean;
}>`
  width: 108px;
  height: 98px;
  background: #ffffff;
  /* outline */

  border: 1px solid #8e8e8e;
  border-radius: 7px;
  color: #8e8e8e;
  font-size: 16px;
  cursor: pointer;

  svg {
    fill: #8e8e8e;
  }

  h4 {
    font-size: 14px;
  }

  ${(props) => {
    if (props.isActive) {
      return css`
        border: 1px solid #e95b2e;
        color: #e95b2e;

        svg {
          fill: #e95b2e;
        }
      `;
    }
  }}
`;

const ButtonContainer = styled.div`
  width: 108px;

  p {
    margin-top: 10px;
  }
`;

export function InteractivitySettings() {
  const ref = useRef<HTMLDivElement>(null);
  const [mode, setMode] = useState<"link" | "document">("link");
  const { t } = useTranslation();
  const { api } = useStorage();
  const [url, setUrl] = useState("");

  const { interactivityEditing, setInteractivityEditing, activeElementId } =
    useEditor();

  const { video } = useStorage();

  const activeElement = video?.schema.schema.elements.find(
    (element) => element.id === activeElementId
  );

  useEffect(() => {
    if (
      !activeElement ||
      !(activeElement.type === "text" || activeElement.type === "image")
    )
      return;

    setUrl(activeElement.cta ? activeElement.cta.url : "");

    if (activeElement.cta?.type === "link") {
      setMode("link");
    } else {
      setMode("document");
    }
  }, [activeElement]);

  useClickOutside(ref, () => {
    setInteractivityEditing(false);
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateUrl = useCallback(
    debounce(async (url: string) => {
      if (!activeElementId) return;

      api.updateElement(activeElementId, {
        cta: {
          type: "link",
          url: url,
          file: null,
        },
      });
    }, 1000),
    [activeElementId]
  );

  if (
    !activeElement ||
    !(activeElement.type === "text" || activeElement.type === "image")
  )
    return null;

  return (
    <div
      ref={ref}
      css={css`
        position: absolute;
        width: 290px;
        right: -290px;
        top: 59px;
        height: 100%;
        padding: 24px;
        transition: right 0.2s ease-in-out;
        box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25);
        z-index: 2000;
        background: #fff;

        ${interactivityEditing &&
        css`
          right: 0;
        `};
      `}
    >
      <Headers.H3>{t("interactivity_settings.title")}</Headers.H3>
      <div
        css={css`
          display: flex;
          flex-flow: row wrap;
          justify-content: space-between;
          margin-top: 20px;
          border-bottom: 1px solid #8e8e8e;
          padding-bottom: 30px;

          p {
            text-align: center;
            line-height: 1;
          }
        `}
      >
        <ButtonContainer>
          <SquareButton
            onClick={() => {
              setMode("link");
            }}
            isActive={mode === "link"}
          >
            <PageLinkIcon />
            <h4>{t("interactivity_settings.link_label")}</h4>
          </SquareButton>
          <p>{t("interactivity_settings.link_description")}</p>
        </ButtonContainer>
        <ButtonContainer>
          <SquareButton
            onClick={() => {
              setMode("document");
            }}
            isActive={mode === "document"}
          >
            <DocumentIcon />

            <h4>{t("interactivity_settings.document_label")}</h4>
          </SquareButton>
          <p>{t("interactivity_settings.document_description")}</p>
        </ButtonContainer>
      </div>
      <div
        css={css`
          padding-top: 20px;
        `}
      >
        <Headers.H4
          css={css`
            margin-bottom: 10px;
            font-weight: 500;
          `}
        >
          {t("interactivity_settings.attached_material")}
        </Headers.H4>

        {mode === "link" && (
          <div
            css={css`
              position: relative;
              display: flex;
              flex-flow: row nowrap;
            `}
          >
            <div
              css={css`
                position: relative;
                flex: 1 0 10%;
              `}
            >
              <input
                type="text"
                css={css`
                  height: 40px;
                  width: 100%;
                  padding: 0 20px;
                  padding-left: 50px;
                  border: 1px solid #8e8e8e;
                  border-radius: 7px;

                  &:focus {
                    border-color: #e95b2e;
                    outline: none;

                    & + .icon svg {
                      fill: #e95b2e;
                    }
                  }
                `}
                value={url}
                onChange={(e) => {
                  setUrl(e.target.value);
                  updateUrl(e.target.value);
                }}
              />
              <div
                className="icon"
                css={css`
                  width: 18px;
                  height: 18px;
                  top: 0;
                  bottom: 0;
                  left: 10px;
                  margin: auto;
                  position: absolute;

                  svg {
                    width: 100%;
                    height: 100%;
                  }
                `}
              >
                <LinkIcon />
              </div>
            </div>
            <button
              css={css`
                flex: 0 0 auto;
                min-width: 0;
                background: none;
                border: none;
                cursor: pointer;
                margin-left: 15px;

                svg {
                  width: 16px;
                }
              `}
              onClick={() => {
                if (!activeElementId) return;

                api.updateElement(activeElementId, {
                  cta: {
                    type: "link",
                    url: "",
                    file: null,
                  },
                });
              }}
            >
              <TrashIcon />
            </button>
          </div>
        )}
        {mode === "document" && (
          <div>
            {activeElement.cta?.file ? (
              <div
                css={css`
                  position: relative;
                  display: flex;
                  flex-flow: row wrap;
                  justify-content: space-between;
                  align-items: center;
                `}
              >
                <div
                  data-type={activeElement.cta.file.mime_type}
                  css={css`
                    flex: 0 0 auto;
                    min-width: 0;
                    margin-right: 10px;
                    font-family: Arial, Tahoma, sans-serif;
                    font-weight: 300;
                    display: inline-block;
                    width: 20px;
                    height: 26px;
                    background: #018fef;
                    position: relative;
                    border-radius: 2px;
                    text-align: left;
                    -webkit-font-smoothing: antialiased;

                    &:before {
                      display: block;
                      content: "";
                      position: absolute;
                      top: 0;
                      right: 0;
                      width: 0;
                      height: 0;
                      border-bottom-left-radius: 2px;
                      border-width: 4px;
                      border-style: solid;
                      border-color: #fff #fff rgba(255, 255, 255, 0.35)
                        rgba(255, 255, 255, 0.35);
                    }

                    &[data-type="zip"],
                    &[data-type="rar"] {
                      background: #acacac;
                    }
                    &[data-type^="doc"] {
                      background: #307cf1;
                    }
                    &[data-type^="xls"] {
                      background: #0f9d58;
                    }
                    &[data-type^="ppt"] {
                      background: #d24726;
                    }
                    &[data-type="pdf"] {
                      background: #e13d34;
                    }
                    &[data-type="txt"] {
                      background: #5eb533;
                    }
                    &[data-type="mp3"],
                    &[data-type="wma"],
                    &[data-type="m4a"],
                    &[data-type="flac"] {
                      background: #8e44ad;
                    }
                    &[data-type="mp4"],
                    &[data-type="wmv"],
                    &[data-type="mov"],
                    &[data-type="avi"],
                    &[data-type="mkv"] {
                      background: #7a3ce7;
                    }
                    &[data-type="bmp"],
                    &[data-type="jpg"],
                    &[data-type="jpeg"],
                    &[data-type="gif"],
                    &[data-type="png"] {
                      background: #f4b400;
                    }
                  `}
                ></div>
                <div
                  css={css`
                    flex: 1 0 10%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  `}
                >
                  {activeElement.cta?.file.title}
                </div>
                <button
                  css={css`
                    flex: 0 0 auto;
                    min-width: 0;
                    background: none;
                    border: none;
                    cursor: pointer;
                    margin-left: 15px;

                    svg {
                      width: 16px;
                    }
                  `}
                  onClick={() => {
                    if (!activeElementId) return;

                    api.updateElement(activeElementId, {
                      cta: {
                        type: "file",
                        url: "",
                        file: null,
                      },
                    });
                  }}
                >
                  <TrashIcon />
                </button>
              </div>
            ) : (
              <DropFile
                initialMessage={
                  <div
                    css={css`
                      display: flex;
                      flex-direction: row;
                      align-items: center;
                      padding: 7px 10px;
                    `}
                  >
                    <FolderIcon
                      css={css`
                        align-self: flex-start;
                        width: 42px;
                        margin-top: 3px;
                      `}
                    />
                    <Headers.H5
                      css={css`
                        margin-left: 7px;
                      `}
                    >
                      {t("media.sidebar.dragDrop")}
                    </Headers.H5>
                  </div>
                }
                onUpload={(file) => {
                  if (!activeElementId || !file) return;

                  api.updateElement(activeElementId, {
                    cta: {
                      type: "file",
                      url: "",
                      file,
                    },
                  });
                }}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}
