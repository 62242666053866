import React, { useEffect, useCallback } from "react";
import { debounce, isEqual } from "lodash";
import {
  VideoElement,
  VideoElementResizeDirection,
  VideoElementRotatePosition,
  VideoElementState,
} from "../types/Video";

import { useStorage } from "../contexts/StorageContext";
import { useEditor } from "../contexts/EditorContext";

import {
  calculateProportionalSize,
  calculateReverseProportionalSize,
  getAdjustedState,
  getElementRenderer,
} from "../helpers/renderer";
import { canEdit } from "../helpers/collaborator";
import { ElementControls } from "../components/ElementControls/ElementControls";
import { ElementFrame } from "../components/ElementControls/ElementFrame";

const controlsBlacklist = ["video-preview"];
const promtsTypes = ["record-prompt", "image-prompt"];

function ElementRendererPure(props: {
  element: VideoElement;
  state: VideoElementState;
}) {
  const resizingRef = React.useRef<VideoElementResizeDirection | null>(null);
  const draggingRef = React.useRef<boolean>(false);
  const rotatingRef = React.useRef<VideoElementRotatePosition | null>(null);
  const initialStateRef = React.useRef<VideoElementState>(props.state);
  const offsetRef = React.useRef<{ x: number; y: number }>({ x: 0, y: 0 });

  const [state, setState] = React.useState(props.state);
  const [hovered, setHovered] = React.useState(false);

  const { video, api } = useStorage();
  const {
    setActiveElementId,
    activeElementId,
    setActiveElementsId,
    activeElementsId,
    wrapperRef,
    canvasSize,
    setDraggingPosition,
  } = useEditor();

  const currentElementId = props.element.id;
  const currentElementType = props.element.type;
  const adjustedState = getAdjustedState(state, canvasSize.width);
  const Renderer = getElementRenderer(props.element);
  const isActive = activeElementId === props.element.id;

  useEffect(() => {
    setState(props.state);
  }, [props.state]);

  useEffect(() => {
    if (activeElementId !== currentElementId) return;

    const updateElement = debounce(
      (activeElementId: string, state: VideoElementState) => {
        api.updateElement(activeElementId, {
          states: [state],
        });
      },
      1000
    );

    const onMouseMove = (e: MouseEvent) => {
      const initialWidth = initialStateRef.current.width;
      const initialHeight = initialStateRef.current.height;
      const initialLeft = initialStateRef.current.left;
      const initialTop = initialStateRef.current.top;
      const initialRotation = initialStateRef.current.rotation;
      if (
        !wrapperRef.current ||
        !(resizingRef.current || draggingRef.current || rotatingRef.current)
      )
        return;

      const rect = wrapperRef.current.getBoundingClientRect();

      if (!rect) return;

      const isElementInFocus =
        document.activeElement?.attributes.getNamedItem("contenteditable")
          ?.value === "true";

      // Mouse position
      let x = e.clientX - rect.left;
      let y = e.clientY - rect.top;

      x = Math.max(0, x);
      y = Math.max(0, y);

      x = Math.min(x, canvasSize.width);
      y = Math.min(y, canvasSize.height);

      let width = calculateProportionalSize(initialWidth, canvasSize.width);
      let height = calculateProportionalSize(initialHeight, canvasSize.width);
      let left = calculateProportionalSize(initialLeft, canvasSize.width);
      let top = calculateProportionalSize(initialTop, canvasSize.width);
      let deltaX = 0;
      let deltaY = 0;
      let rotation = initialRotation;
      const enableProportionalResize =
        e.shiftKey ||
        currentElementType === "video" ||
        currentElementType === "image" ||
        currentElementType === "gif";

      if (resizingRef.current) {
        if (resizingRef.current === "nw") {
          if (enableProportionalResize) {
            deltaY = y - top;

            width = width - deltaY;
            height = height - deltaY * (initialHeight / initialWidth);
            left = left + deltaY;
            top = top + deltaY * (initialHeight / initialWidth);
          } else {
            deltaX = x - left;
            deltaY = y - top;

            width = width - deltaX;
            height = height - deltaY;
            left = left + deltaX;
            top = top + deltaY;
          }
        } else if (resizingRef.current === "ne") {
          if (enableProportionalResize) {
            deltaY = y - top;

            width = width - deltaY;
            height = height - deltaY * (initialHeight / initialWidth);
            top = top + deltaY * (initialHeight / initialWidth);
          } else {
            deltaX = x - left - width;
            deltaY = y - top;

            width = width + deltaX;
            height = height - deltaY;
            top = top + deltaY;
          }
        } else if (resizingRef.current === "sw") {
          if (enableProportionalResize) {
            deltaX = x - left;
            deltaY = y - top - height;

            width = width - deltaX;
            height = height - deltaX * (initialHeight / initialWidth);
            left = left + deltaX;
          } else {
            deltaX = x - left;
            deltaY = y - top - height;

            width = width - deltaX;
            height = height + deltaY;
            left = left + deltaX;
          }
        } else if (resizingRef.current === "se") {
          if (enableProportionalResize) {
            deltaX = x - left - width;
            width = width + deltaX;
            height = height + deltaX * (initialHeight / initialWidth);
          } else {
            deltaX = x - left - width;
            deltaY = y - top - height;
            width = width + deltaX;
            height = height + deltaY;
          }
        } else if (resizingRef.current === "n") {
          if (enableProportionalResize) {
            deltaY = y - top;
            height = height - deltaY;
            width = width - deltaY * (initialWidth / initialHeight);
            top = top + deltaY;
            left = left + deltaY * (initialWidth / initialHeight);
          } else {
            deltaY = y - top;
            height = height - deltaY;
            top = top + deltaY;
          }
        } else if (resizingRef.current === "s") {
          if (enableProportionalResize) {
            deltaY = y - top - height;
            height = height + deltaY;
            width = width + deltaY * (initialWidth / initialHeight);
            left = left - deltaY * (initialWidth / initialHeight);
          } else {
            deltaY = y - top - height;
            height = height + deltaY;
          }
        } else if (resizingRef.current === "w") {
          if (enableProportionalResize) {
            deltaX = x - left;
            width = width - deltaX;
            height = height - deltaX * (initialHeight / initialWidth);
            left = left + deltaX;
          } else {
            deltaX = x - left;
            width = width - deltaX;
            left = left + deltaX;
          }
        } else if (resizingRef.current === "e") {
          if (enableProportionalResize) {
            deltaX = x - left - width;
            width = width + deltaX;
            height = height + deltaX * (initialHeight / initialWidth);
          } else {
            deltaX = x - left - width;
            width = width + deltaX;
          }
        }
      } else if (draggingRef.current && !isElementInFocus) {
        left = x - offsetRef.current.x;
        top = y - offsetRef.current.y;
      } else if (rotatingRef.current) {
        const centerX = left + width / 2;
        const centerY = top + height / 2;

        const handlePositionLeft =
          rotatingRef.current === "nw" ? left : left + width;
        const handlePositionTop =
          rotatingRef.current === "nw" ? top : top + height;

        const angle =
          Math.atan2(y - centerY, x - centerX) -
          Math.atan2(handlePositionTop - centerY, handlePositionLeft - centerX);

        const newAngle = angle * (180 / Math.PI);

        rotation = newAngle;
      }

      // Position bigger than 0,0
      left = Math.max(0, left);
      top = Math.max(0, top);

      // Size smaller than canvas
      if (width > canvasSize.width) {
        width = canvasSize.width;
        height = width * (initialHeight / initialWidth);
      }

      if (height > canvasSize.height) {
        height = canvasSize.height;
        width = height * (initialWidth / initialHeight);
      }

      // Doesn't go outside canvas
      if (left + width > canvasSize.width) {
        left = canvasSize.width - width;
      }

      if (top + height > canvasSize.height) {
        top = canvasSize.height - height;
      }

      // Minimum size
      if (width < 30 || height < 30) {
        if (width > height) {
          height = 30;
          width = height * (initialWidth / initialHeight);
        } else {
          width = 30;
          height = width * (initialHeight / initialWidth);
        }
      }

      const newState = {
        ...initialStateRef.current,
        width: calculateReverseProportionalSize(width, canvasSize.width),
        height: calculateReverseProportionalSize(height, canvasSize.width),
        left: calculateReverseProportionalSize(left, canvasSize.width),
        top: calculateReverseProportionalSize(top, canvasSize.width),
        rotation,
      };

      setDraggingPosition({
        elementId: activeElementId,
        boundaries: [
          {
            x: newState.left,
            y: newState.top,
          },
          {
            x: newState.left + newState.width,
            y: newState.top,
          },
          {
            x: newState.left + newState.width,
            y: newState.top + newState.height,
          },
          {
            x: newState.left,
            y: newState.top + newState.height,
          },
        ],
      });

      setState(newState);
      initialStateRef.current = newState;
      updateElement(currentElementId, newState);
    };

    document.addEventListener("mousemove", onMouseMove);

    return () => {
      document.removeEventListener("mousemove", onMouseMove);
    };
  }, [
    wrapperRef,
    activeElementId,
    currentElementId,
    api,
    canvasSize,
    currentElementType,
    setDraggingPosition,
  ]);

  useEffect(() => {
    const onMouseUp = () => {
      resizingRef.current = null;
      draggingRef.current = false;
      rotatingRef.current = null;

      setDraggingPosition(null);
    };

    document.addEventListener("mouseup", onMouseUp);

    return () => {
      document.removeEventListener("mouseup", onMouseUp);
    };
  }, [setDraggingPosition]);

  const resizeHandler = (
    e: React.MouseEvent,
    direction: VideoElementResizeDirection
  ) => {
    e.stopPropagation();
    e.preventDefault();

    initialStateRef.current = state;
    resizingRef.current = direction;
  };

  const rotateHandler = (
    e: React.MouseEvent,
    position: VideoElementRotatePosition
  ) => {
    e.stopPropagation();
    e.preventDefault();

    initialStateRef.current = state;
    rotatingRef.current = position;
  };

  const dragHandler = (e: React.MouseEvent) => {
    if (!wrapperRef.current) return;
    if (controlsBlacklist.includes(props.element.type)) return;

    const rect = wrapperRef.current.getBoundingClientRect();

    if (!rect) return;

    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    initialStateRef.current = state;
    draggingRef.current = true;
    offsetRef.current = {
      x: x - calculateProportionalSize(state.left, canvasSize.width),
      y: y - calculateProportionalSize(state.top, canvasSize.width),
    };
  };

  const onChange = useCallback(
    (heightOfElement: number) => {
      const isBiggerThanCanvas = (h: number) =>
        canvasSize.height < state.top + h;
      const isHeightNeedToBeChanged = (h: number) =>
        state.height < h && !isBiggerThanCanvas(h);

      if (isHeightNeedToBeChanged(heightOfElement)) {
        const height = calculateReverseProportionalSize(
          heightOfElement,
          canvasSize.width
        );

        const updateState = debounce(
          (activeElementId: string, state: VideoElementState) => {
            api.updateElement(activeElementId, {
              states: [state],
            });
          },
          300
        );

        const updatedState = {
          ...state,
          height: height,
        };
        initialStateRef.current = updatedState;
        updateState(currentElementId, updatedState);
        setState(updatedState);
      }
    },
    [api, canvasSize.height, canvasSize.width, currentElementId, state]
  );

  const onElementClick = (event: React.MouseEvent) => {
    event.stopPropagation();

    if (canEdit(video?.current_user_role))
      if (event.shiftKey && (activeElementId || activeElementsId.length > 0)) {
        if (activeElementsId.includes(props.element.id)) {
          setActiveElementsId(
            activeElementsId.filter((id) => id !== props.element.id)
          );
        } else {
          setActiveElementsId([
            ...activeElementsId,
            activeElementId!,
            props.element.id,
          ]);
          setActiveElementId(null);
        }
      } else {
        setActiveElementId(props.element.id);
        setActiveElementsId([]);
      }
  };

  return (
    <div
      style={{
        position: "absolute",
        width: adjustedState.width + 10,
        height: adjustedState.height + 10,
        transform: `translate3d(${adjustedState.left - 5}px, ${
          adjustedState.top - 5
        }px, 0)`,
        zIndex: isActive ? 100 : props.element.order,
      }}
      onClick={(event) => onElementClick(event)}
      onMouseOver={() => {
        setHovered(true);
      }}
      onMouseOut={() => {
        setHovered(false);
      }}
    >
      <div
        style={{
          position: "absolute",
          transform: `rotate(${adjustedState.rotation}deg)`,
          width: "100%",
          height: "100%",
          border: controlsBlacklist.includes(props.element.type)
            ? "1px solid transparent"
            : isActive
            ? "1px solid #000"
            : props.element.touched === false ||
              promtsTypes.includes(props.element.type)
            ? "1px dashed #000"
            : hovered
            ? "1px solid rgba(0, 0, 0, 0.2)"
            : "1px solid transparent",
        }}
      >
        <div
          style={{
            overflow: [
              "reaction",
              "poll-single-choice",
              "poll-multiple-choice",
              "user-tagging",
            ].includes(props.element.type)
              ? "visible"
              : "hidden",
            position: "absolute",
            width: "calc(100% - 8px)",
            height: "calc(100% - 8px)",
            top: 4,
            left: 4,
            cursor:
              isActive && !controlsBlacklist.includes(props.element.type)
                ? "move"
                : "default",
          }}
          onMouseDown={(e) => dragHandler(e)}
        >
          {Renderer && (
            <Renderer
              element={props.element}
              state={adjustedState}
              onChange={onChange}
            />
          )}
        </div>
        {isActive && !controlsBlacklist.includes(props.element.type) && (
          <ElementFrame
            state={adjustedState}
            resizeHandler={resizeHandler}
            rotateHandler={rotateHandler}
          />
        )}
      </div>
      {isActive && !controlsBlacklist.includes(props.element.type) && (
        <ElementControls element={props.element} />
      )}
    </div>
  );
}

export const ElementRenderer = React.memo(
  ElementRendererPure,
  (oldProps, newProps) => {
    const is = isEqual(oldProps, newProps);

    return is;
  }
);

export { calculateProportionalSize };
