import { css } from "styled-components/macro";

export function CircularProgressBar(props: {
  progress: number | any;
  totalDuration: number;
  className?: string;
  playing: boolean;
}) {
  const size = 30;
  const cx = size / 2;
  const cy = size / 2;
  const strokeWidth = 1.5;
  const radius = size / 2 - strokeWidth / 2;
  const circumference = 2 * Math.PI * radius;
  const progressOffset =
    circumference -
    (circumference * ((props.progress / props.totalDuration) * 100)) / 100;

  return (
    <svg
      className={props.className}
      viewBox={"0 0 30 30"}
      css={css`
        position: absolute;
      `}
    >
      <circle
        stroke={"#f2f2f2"}
        cx={cx}
        cy={cy}
        r={radius}
        strokeWidth={1.5}
        css={css`
          fill: none;
        `}
      ></circle>
      <circle
        stroke={props.playing ? "#e95b2e" : "#8E8E8E"}
        cx={cx}
        cy={cy}
        r={radius}
        strokeWidth={strokeWidth}
        strokeDasharray={circumference}
        strokeDashoffset={progressOffset}
        strokeLinecap="round"
        css={css`
          fill: none;
          transform: rotate(-90deg);
          transform-origin: center;
        `}
      />
    </svg>
  );
}
