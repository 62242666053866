import { Modal } from "../components/Modal";
import VideoProductSlide1_EN from "../assets/videos/Product1_en.mp4";
import VideoProductSlide1_DE from "../assets/videos/Product1_de.mp4";
import ImageProductSlide2_EN from "../assets/img/product2_en.jpg";
import ImageProductSlide2_DE from "../assets/img/product2_de.jpg";
import ImageProductSlide3_EN from "../assets/img/product3_en.jpg";
import ImageProductSlide3_DE from "../assets/img/product3_de.jpg";
import ImageProductSlide4 from "../assets/img/product4_en.jpg";
import { useTranslation } from "react-i18next";
import { Carousel } from "../components/Carousel";

export function ScreensTourModal(props: {
  isOpen: boolean;
  onClose: () => void;
}) {
  const { t, i18n } = useTranslation();
  const content: { image: string | any; title: string; description: string }[] =
    [
      {
        image:
          i18n.language === "en"
            ? VideoProductSlide1_EN
            : VideoProductSlide1_DE,
        title: t("dashboard.product-tour.first-screen.title"),
        description: t("dashboard.product-tour.first-screen.description"),
      },
      {
        image:
          i18n.language === "en"
            ? ImageProductSlide2_EN
            : ImageProductSlide2_DE,
        title: t("dashboard.product-tour.second-screen.title"),
        description: t("dashboard.product-tour.second-screen.description"),
      },
      {
        image:
          i18n.language === "en"
            ? ImageProductSlide3_EN
            : ImageProductSlide3_DE,
        title: t("dashboard.product-tour.third-screen.title"),
        description: t("dashboard.product-tour.third-screen.description"),
      },
      {
        image: ImageProductSlide4,
        title: t("dashboard.product-tour.forth-screen.title"),
        description: t("dashboard.product-tour.forth-screen.description"),
      },
    ];
  return (
    <Modal
      modalIsOpen={props.isOpen}
      onClose={props.onClose}
      contentStyles={{
        maxWidth: "600px",
        height: "535px",
        padding: "0",
      }}
    >
      <Carousel content={content} onClose={props.onClose} />
    </Modal>
  );
}
