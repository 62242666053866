import React from "react";
import { css } from "styled-components/macro";
import { Step } from "react-joyride";
import { Headers } from "../helpers/headers";
import { ReactComponent as CloseIcon } from "../assets/icons/TooltipClose.svg";
import { ReactComponent as NextIcon } from "../assets/icons/Next.svg";
import { ReactComponent as BackIcon } from "../assets/icons/Back.svg";
import { Button } from "./Button";
import { useEditor } from "../contexts/EditorContext";
import { useTranslation } from "react-i18next";

export function TooltipBody(props: {
  children?: React.ReactNode | React.ReactNode[] | string | null;
  className?: string;
  continuous: boolean;
  index: number;
  isLastStep: boolean;
  size: number;
  step: Step;
  backProps: {
    "aria-label": string;
    "data-action": string;
    onClick: (e: React.MouseEvent<HTMLElement>) => void;
    role: string;
    title: string;
  };
  closeProps: {
    "aria-label": string;
    "data-action": string;
    onClick: (e: React.MouseEvent<HTMLElement>) => void;
    role: string;
    title: string;
  };
  primaryProps: {
    "aria-label": string;
    "data-action": string;
    onClick: (e: React.MouseEvent<HTMLElement>) => void;
    role: string;
    title: string;
  };
  skipProps: {
    "aria-label": string;
    "data-action": string;
    onClick: (e: React.MouseEvent<HTMLElement>) => void;
    role: string;
    title: string;
  };
  tooltipProps: {
    "aria-modal": boolean;
    ref: React.RefCallback<HTMLElement>;
    role: string;
  };
}) {
  const { hideProductTour } = useEditor();
  const { t } = useTranslation();

  return (
    <div
      className={props.className}
      {...props.tooltipProps}
      css={css`
        width: 320px;
        background-color: #ffffff;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        ${props.isLastStep &&
        css`
          width: 400px;
          height: 190px;
          border: 1px solid #f2f2f2;
          justify-content: center;
          align-items: center;
          text-align: center;
          box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.25);
          border-radius: 10px;
        `}
      `}
    >
      <Button
        onClick={() => {
          hideProductTour();
        }}
        icon={<CloseIcon />}
        css={css`
          min-width: 2em;
          background-color: transparent;
          align-self: flex-end;
          padding: 8px 4px;
          ${props.isLastStep &&
          css`
            margin-top: -18px;
          `}
        `}
      ></Button>
      <Headers.H2
        css={css`
          font-weight: 500;
          font-size: 20px;
          line-height: 24px;
          padding: 0px 10px 7px 12px;
        `}
      >
        {props.step.title}
      </Headers.H2>
      <Headers.H4
        css={css`
          font-weight: 400;
          font-size: 16px;
          line-height: 19.68px;
          color: #7f7f7f;
          padding: 0px 5px 5px 12px;
        `}
      >
        {props.step.content}
      </Headers.H4>
      {props.isLastStep ? (
        <div
          css={css`
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 10px;
            padding-top: 10px;
          `}
        >
          {props.continuous && (
            <button
              {...props.primaryProps}
              css={css`
                background-color: #e95b2e;
                min-width: 2em;
                padding: 9px 24px;
                border: none;
                font-weight: 700;
                font-size: 16px;
                border-radius: 20px;
                color: #fff;
                cursor: pointer;
                :focus {
                  outline: none;
                }
                :hover {
                  background-color: #fe521c;
                }
              `}
            >
              {t("tooltip_screenEditor.start-creating")}
            </button>
          )}
        </div>
      ) : (
        <div
          css={css`
            margin-top: 3px;
            height: 35px;
            width: 100%;
            background-color: #fae7d9;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-radius: 0 0 3px 3px;
          `}
        >
          <div
            css={css`
              display: flex;
            `}
          >
            <Headers.H2
              css={css`
                font-size: 16px;
                color: #e95b2e;
                margin-left: 13px;
              `}
            >
              {t("product-tour.tooltip.step")} {props.index + 1}
            </Headers.H2>
            <div
              css={css`
                align-self: center;
                margin-left: 20px;
                display: flex;
              `}
            >
              {Array(4)
                .fill(0)
                .map((_, index) => {
                  return (
                    <>
                      <div
                        key={index}
                        css={css`
                          width: 6px;
                          height: 6px;
                          border-radius: 50%;
                          border: 1px solid #e95b2e;
                          background-color: ${props.index >= index
                            ? "#E95B2E"
                            : "transparent"};
                          box-shadow: ${props.index >= index
                            ? "0px 0px 4px #E95B2E"
                            : "none"};
                        `}
                      ></div>
                      <div
                        css={css`
                          width: 5px;
                          height: 1px;
                          background: #e95b2e;
                          align-self: center;
                          :last-child {
                            display: none;
                          }
                        `}
                      ></div>
                    </>
                  );
                })}
            </div>
          </div>

          <div>
            {props.index >= 0 && (
              <button
                {...props.backProps}
                css={css`
                  background-color: transparent;
                  min-width: 2em;
                  border: none;
                  cursor: pointer;
                `}
              >
                <BackIcon
                  css={css`
                    color: ${props.index === 0 ? "#CDCDCD" : "#E95B2E"};
                    ${props.index === 0 &&
                    css`
                      pointer-events: none;
                      color: #cdcdcd;
                    `}
                  `}
                />
              </button>
            )}
            {props.continuous && (
              <button
                {...props.primaryProps}
                css={css`
                  background-color: transparent;
                  min-width: 2em;
                  border-radius: 0;
                  padding: 7px 15px;
                  border: none;
                  font-weight: 600;
                  font-size: 16px;
                  border-radius: 0 0 5px 0;
                  cursor: pointer;
                  :hover {
                    background-color: #ffdfc7;
                  }
                  :focus {
                    outline: none;
                  }
                  ${props.index === 3 &&
                  css`
                    color: #fae7d9;
                    background-color: #e95b2e;
                    :hover {
                      background-color: #e95b2e;
                    }
                  `}
                `}
              >
                {props.index === 3 ? t("tooltip_publish.done") : <NextIcon />}
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
