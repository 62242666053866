import { ReactComponent as OnboardingIcon } from "../../assets/icons/Onboarding.svg";
import { ReactComponent as OffboardingIcon } from "../../assets/icons/Offboarding.svg";
import { ReactComponent as ReportingIcon } from "../../assets/icons/Reporting.svg";

export type Template = {
  id: string;
  title: string;
  description: string;
  icon: React.ReactNode;
  iconBackground: string;
  previews: string[];
};

export function getThemisTemplates(t: any) {
  return [
    {
      id: "881ea882-c8be-45a2-a4a6-f2c37e35d6b5",
      title: "Onboarding",
      description: t("dashboard.templateData.description.onBoarding"),
      icon: <OnboardingIcon />,
      iconBackground: "#FAE7D9",
      previews: [
        require("../../assets/templates/onboarding/welcome.jpg"),
        require("../../assets/templates/onboarding/slide2.jpg"),
        require("../../assets/templates/onboarding/slide3.jpg"),
        require("../../assets/templates/onboarding/slide4.jpg"),
        require("../../assets/templates/onboarding/slide5.jpg"),
        require("../../assets/templates/onboarding/slide6.jpg"),
        require("../../assets/templates/onboarding/slide7.jpg"),
      ],
    },
    {
      id: "689c8e95-fb85-47ab-8d40-ad86d2ba4801",
      title: "Offboarding",
      description: t("dashboard.templateData.description.offboarding"),
      icon: <OffboardingIcon />,
      iconBackground: "#E5E1EE",
      previews: [
        require("../../assets/templates/offboarding/welcome.jpg"),
        require("../../assets/templates/offboarding/slide2.jpg"),
        require("../../assets/templates/offboarding/slide3.jpg"),
        require("../../assets/templates/offboarding/slide4.jpg"),
        require("../../assets/templates/offboarding/slide5.jpg"),
      ],
    },
    {
      id: "e73a1dc3-fa5b-4516-a590-3005a797184f",
      title: t("dashboard.templateData.title"),
      description: t("dashboard.templateData.description.meeting-notes"),
      icon: <ReportingIcon />,
      iconBackground: "#F9EFE0",
      previews: [
        require("../../assets/templates/meeting/welcome.jpg"),
        require("../../assets/templates/meeting/slide2.jpg"),
        require("../../assets/templates/meeting/slide3.jpg"),
        require("../../assets/templates/meeting/slide4.jpg"),
        require("../../assets/templates/meeting/slide5.jpg"),
        require("../../assets/templates/meeting/slide6.jpg"),
      ],
    },
  ];
}
