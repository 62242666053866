import React, { useRef, useState } from "react";
import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { usePlayback } from "../../contexts/PlaybackContext";
import { Headers } from "../../helpers/headers";
import { CommentVisibility } from "../../../types/Comment";
import { ToggleSwitch } from "../ToggleSwitch";
import { useComments } from "../../contexts/CommentsContext";
import { ModalDelete } from "../../../modals/ModalDelete";
import { CommentSidebarCard } from ".";

export default function CommentsSidebar() {
  const { activeScene } = usePlayback();
  const ref = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [selectedCommentId, setSelectedCommentId] = useState<number | null>(
    null
  );

  const { comments, commentsOpen, commentMode, setCommentMode, commentApi } =
    useComments();

  const onConfirmDelete = () => {
    if (selectedCommentId) {
      commentApi.deleteEditorComment(selectedCommentId);
      setDeleteModal(false);
      setSelectedCommentId(null);
    }
  };

  if (!activeScene) return null;

  return (
    <div
      ref={ref}
      css={css`
        position: absolute;
        width: 252px;
        right: -252px;
        top: 59px;
        height: calc(100vh - 60px);
        min-height: 0px;
        transition: right 0.2s ease-in-out;
        box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25);
        z-index: 2000;
        background: #fff;

        ${commentsOpen &&
        css`
          right: 0;
        `};
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 24px 25px 12px 14px;
            width: 100%;
            align-items: center;
          `}
        >
          <Headers.H3>{t("comment.comments")}</Headers.H3>
          <ToggleSwitch
            value={commentMode}
            onChange={() => setCommentMode(!commentMode)}
          />
        </div>
      </div>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          margin-right: 6px;
          height: calc(100vh - 160px);
          margin-bottom: 50px;

          overflow-y: scroll;
          min-width: 0;

          /* width */
          ::-webkit-scrollbar {
            height: 100%;
            width: 6px;
            border-radius: 5px;
          }

          /* Track */
          ::-webkit-scrollbar-track {
            background: #f2f2f2;
          }

          /* Handle */
          ::-webkit-scrollbar-thumb {
            background: #8e8e8e;
            border-radius: 5px;
          }
        `}
      >
        {comments &&
          comments.map(
            (comment, key) =>
              comment.visibility === CommentVisibility.CREATOR && (
                <CommentSidebarCard
                  key={key}
                  comment={comment}
                  setDeleteModal={() => setDeleteModal(true)}
                  setSelectedCommentId={(commentId) =>
                    setSelectedCommentId(commentId)
                  }
                />
              )
          )}
      </div>
      <ModalDelete
        onDelete={() => onConfirmDelete()}
        isOpen={deleteModal}
        onClose={() => setDeleteModal(false)}
        title={t("actions.sure")}
      />
    </div>
  );
}
