import {
  VideoElementEllipse,
  VideoElementState,
} from "../../../VideoEditor/types/Video";
import { css } from "styled-components/macro";
import { calculateProportionalSize } from "../../helpers/renderer";
import { usePlayer } from "../../contexts/PlayerContext";

export function ElementEllipseRenderer(props: {
  element: VideoElementEllipse;
  state: VideoElementState;
}) {
  const { config } = props.element;
  const width = props.state.width - 10;
  const height = props.state.height - 10;
  const { canvasSize } = usePlayer();
  const strokeSize = calculateProportionalSize(
    config.strokeSize,
    canvasSize.width
  );

  return (
    <div
      css={css`
        width: 100%;
        height: 100%;
      `}
    >
      <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill={config.fillColor}
        fillOpacity={config.fillOpacity / 100}
        stroke={config.strokeColor}
        strokeWidth={strokeSize}
        strokeOpacity={config.strokeOpacity / 100}
      >
        <ellipse
          cx={width / 2}
          cy={height / 2}
          rx={(width - strokeSize * 2) / 2}
          ry={(height - strokeSize * 2) / 2}
        />
      </svg>
    </div>
  );
}
