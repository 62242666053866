import { useState } from "react";
import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";

import { Button } from "./Button";
import { useStorage } from "../contexts/StorageContext";
import { ModalShare } from "../../modals/ModalShare";

import { useNewFetch } from "../../useAPI";
import { getMe } from "../../actions/account";
import { pageLoading } from "../../helpers/pageLoading";
import { ReactComponent as InviteIcon } from "../assets/icons/Invite.svg";

export function ShareButton() {
  const { t } = useTranslation();
  const [videoShare, setVideoshare] = useState<string | null>(null);
  const { video } = useStorage();
  const { data: profileData, error: profileDataErrors } = useNewFetch(getMe);

  if (!profileData || profileDataErrors) {
    return pageLoading(profileDataErrors);
  }

  return (
    <div>
      <Button
        css={css`
          color: #fff;
          font-weight: 700;
          min-width: 6em;
          padding: 10px 16px;
          :hover {
            background-color: #fe521c;
          }
        `}
        icon={<InviteIcon />}
        onClick={() => {
          if (video) setVideoshare(video.uuid);
        }}
      >
        {t("video-editor.status-bar.share")}
      </Button>

      <ModalShare
        isOpen={!!videoShare}
        onClose={() => setVideoshare(null)}
        userData={profileData}
      />
    </div>
  );
}
