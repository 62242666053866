import React, { ChangeEvent, FormEvent, KeyboardEventHandler } from "react";
import { css } from "styled-components/macro";
import { FieldInputProps } from "formik";

export enum InputStyle {
  DEFAULT = "default",
  CLEAR = "clear",
  UNDERLINE = "underline",
}

export function InputComponent(props: {
  id?: string;
  name?: string;
  type?:
    | "text"
    | "email"
    | "search"
    | "password"
    | "number"
    | "tel"
    | "url"
    | "select"
    | "time";
  style?: InputStyle;
  label?: string;
  hint?: string;
  value?: string;
  placeholder?: string;
  disabled?: boolean;
  readonly?: boolean;
  className?: string;
  field?: FieldInputProps<any>;
  error?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onInput?: (event: FormEvent<HTMLInputElement>) => void;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  onMouseEnter?: (e: React.MouseEvent<HTMLLabelElement, MouseEvent>) => void;
  onMouseLeave?: (e: React.MouseEvent<HTMLLabelElement, MouseEvent>) => void;
}) {
  return (
    <label onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave}>
      <div
        css={css`
          position: relative;
          width: 100%;
        `}
      >
        <input
          id={props.id}
          type={props.type || "text"}
          value={props.value}
          placeholder={props.placeholder || ""}
          readOnly={props.readonly || false}
          disabled={props.disabled || false}
          aria-label={props.name || ""}
          css={css`
            width: 100%;
            height: 51px;
            padding: 0 20px;
            border: 1px solid #000000;
            border-radius: 10px;
            box-sizing: border-box;
            background: transparent;
            font-weight: 400;
            font-size: 16px;

            &:focus {
              outline: none;
              border-color: #e95b2e;
            }
          `}
          {...props.field}
          className={props.className}
          onChange={(e) => {
            props.field && props.field.onChange(e);

            if (props.onChange) {
              props.onChange(e);
            }
          }}
          onKeyDown={props.onKeyDown}
          onInput={props.onInput}
        />
      </div>
    </label>
  );
}
