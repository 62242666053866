import {
  VideoElementAudio,
  VideoElementState,
} from "../../../VideoEditor/types/Video";
import { css } from "styled-components/macro";
import { useEffect, useRef } from "react";
import { usePlayback } from "../../../VideoEditor/contexts/PlaybackContext";

export function ElementVideoRenderer(props: {
  element: VideoElementAudio;
  state: VideoElementState;
}) {
  const ref = useRef<HTMLVideoElement>(null);
  const { activeScene, currentTime, playing } = usePlayback();

  useEffect(() => {
    if (ref.current && activeScene) {
      ref.current.muted = false;

      let videoTime = currentTime - activeScene.start_time;
      const isFinished = ref.current.duration - videoTime / 1000 < 0.1;

      videoTime = Math.min(videoTime, activeScene.duration);
      videoTime = Math.max(videoTime, 0);
      videoTime = videoTime / 1000;

      if (playing && ref.current.paused && !isFinished) {
        ref.current.currentTime = videoTime;
        ref.current.play();
      } else if (!playing && !ref.current.paused) {
        ref.current.pause();
      } else if (ref.current.paused) {
        ref.current.currentTime = videoTime;
      } else if (isFinished) {
        ref.current.pause();
      }
    }
  }, [currentTime, activeScene, playing]);

  return (
    <div
      css={css`
        width: 100%;
        height: 100%;

        video {
          width: 100%;
          height: 100%;
          object-fit: cover;
          user-select: none;
          overflow: hidden;
          border-radius: 50%;
        }
      `}
    >
      <video loop={false} ref={ref}>
        <source src={props.element.file.file} type="video/mp4" />
        Your browser does not support the video element.
      </video>
    </div>
  );
}
