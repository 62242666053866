import { css } from "styled-components/macro";
import { useCheckMobile } from "../../hooks/useCheckMobile";
import { Wrapper } from "./Wrapper";
import { useTranslation } from "react-i18next";

export function ComingSoon() {
  const { isMobile } = useCheckMobile();
  const { t } = useTranslation();

  return (
    <section
      css={css`
        position: relative;
        padding: 100px 0 200px;
        z-index: 2;
        background: #f9efe0;

        ${isMobile &&
        css`
          padding: 40px 0;
          text-align: center;
        `}
      `}
    >
      <Wrapper>
        <div
          css={css`
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            align-items: center;

            h2 {
              font-size: ${isMobile ? "27px" : "47px"};
            }
          `}
        >
          <div
            css={
              isMobile &&
              css`
                flex: 0 0 100%;
              `
            }
          >
            <h2
              css={css`
                font-weight: 400;
              `}
            >
              {t("upcoming-features.title")}
            </h2>
          </div>
          <div
            css={
              isMobile &&
              css`
                flex: 0 0 100%;
              `
            }
          >
            <div
              css={css`
                position: relative;
                width: 100%;
                max-width: 414px;
                padding: 40px 60px;
                box-sizing: border-box;
                background: #ffffff;
                border: 1px solid #000000;
                border-radius: 24px;

                h4 {
                  font-size: 25px;
                  font-weight: 400;
                  line-height: 1.1;
                  letter-spacing: -0.04em;
                  margin: 0;
                  padding: 0;
                }

                h5 {
                  font-size: 18px;
                  letter-spacing: -0.04em;
                  font-weight: 400;
                  margin: 0;
                  padding: 0;
                }

                ${isMobile &&
                css`
                  max-width: none;
                  margin-bottom: 40px;

                  h4 {
                    font-size: 20px;
                  }

                  h5 {
                    font-size: 16px;
                  }
                `}
              `}
            >
              <div
                css={css`
                  position: absolute;
                  width: 220px;
                  height: auto;
                  padding: 0px 0;
                  left: 0;
                  right: 0;
                  top: -14px;
                  margin: auto;
                  background: #a47aff;
                  border: 1px solid #000000;
                  border-radius: 59.5px;
                  text-align: center;
                  color: #fff;

                  ${isMobile &&
                  css`
                    position: relative;
                    width: 100%;
                  `}
                `}
              >
                <h5>✨Upcoming feature ✨</h5>
              </div>
              <h4>Automatically create videos from uploaded documents.</h4>
            </div>
          </div>
        </div>
      </Wrapper>
    </section>
  );
}
