import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { customToast } from "../../components/customToast";
import { css } from "styled-components/macro";
import { Block } from "../../components/Block";
import { SEO } from "../../components/SEO";
import { useNewFetch } from "../../useAPI";
import { Headers } from "../../helpers/layout";
import { Link, useHistory } from "react-router-dom";
import { ReactComponent as ArrowIcon } from "../../assets/icons/Arrow.svg";
import {
  AccountForm,
  FormValues as AccountFormValues,
} from "../../forms/account/AccountForm";
import {
  changePassword,
  deleteMe,
  getMe,
  updateMe,
} from "../../actions/account";
import { pageLoading } from "../../helpers/pageLoading";
import { PageLayout } from "../../components/PageLayout";
import {
  ChangePasswordForm,
  FormValues as ChangePasswordFormValues,
} from "../../forms/account/ChangePasswordForm";
import { DangerZone } from "../../components/DangerZone";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "../../components/Modal";
import { useAuth } from "../../contexts/UserContext";
import { Button } from "../../components/Button";
import { theme } from "../../themes/variables";
import { FeedbackWidget } from "../../components/FeedbackWidget";

export function AccountPage() {
  const { t } = useTranslation();
  const [deleteModal, setDeleteModal] = useState(false);
  const history = useHistory();
  const { deauthenticate, refetch } = useAuth();

  const onConfirmDelete = async () => {
    try {
      await deleteMe();
      setDeleteModal(false);
      deauthenticate();
      history.push(`/`);
    } catch (e) {
      customToast.error(t("status.error"));
    }
  };

  const {
    data: profileData,
    error: profileDataErrors,
    setData,
  } = useNewFetch(getMe);

  if (!profileData || profileDataErrors) {
    return pageLoading(profileDataErrors);
  }

  const onSubmitAccountForm = async (values: AccountFormValues) => {
    const response = await updateMe(values);
    customToast.success(t("status.success"));
    setData(response.data);
    refetch();
  };

  const onSubmitChangePasswordForm = async (
    values: ChangePasswordFormValues
  ) => {
    await changePassword(values);
    customToast.success(t("status.success"));
  };

  return (
    <>
      <PageLayout>
        <SEO>
          <title>{t("account.personal-info")}</title>
        </SEO>
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <Link to="/">
            <ArrowIcon />
          </Link>
          <Link
            to="/"
            css={css`
              font-size: 20px;
              font-weight: 600;
              letter-spacing: -0.05em;
              margin-left: 20px;
            `}
          >
            {t("myThemi.dashboard.button.back")}
          </Link>
        </div>
        <Block
          css={css`
            padding: 10px 0;
            margin-top: 30px;
            margin-bottom: 0;
          `}
        >
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
            `}
          >
            <Headers.H4
              css={css`
                font-size: 20px;
                font-weight: bold;
                color: #e95b2e;
              `}
            >
              Info
            </Headers.H4>
          </div>
        </Block>
        <Block
          css={css`
            padding: 10px 0;
          `}
        >
          <div
            css={css`
              display: flex;
              width: 100%;
            `}
          >
            {profileData && (
              <AccountForm data={profileData} onSubmit={onSubmitAccountForm} />
            )}
          </div>
          <div
            css={css`
              margin-top: 50px;
            `}
          >
            <Headers.H4
              css={css`
                font-size: 20px;
                font-weight: bold;
                color: #e95b2e;
              `}
            >
              {t("account.security")}
            </Headers.H4>
            <div
              css={css`
                display: flex;
                width: 100%;
                margin-top: 30px;
              `}
            >
              {profileData && (
                <ChangePasswordForm
                  data={profileData}
                  onSubmit={onSubmitChangePasswordForm}
                />
              )}
            </div>
          </div>
          <DangerZone
            css={css`
              margin-top: 5rem;
              max-width: 500px;
              width: 100%;
            `}
          >
            <Button onClick={() => setDeleteModal(true)}>
              {t("account.delete")}
            </Button>

            <Modal
              modalIsOpen={deleteModal}
              onClose={() => setDeleteModal(false)}
              contentLabel={t("actions.sure")}
            >
              <ModalHeader closeIcon onClose={() => setDeleteModal(false)}>
                {t("actions.sure")}
              </ModalHeader>

              <ModalBody>{t("account.modal.body")}</ModalBody>

              <ModalFooter>
                <Button
                  background={theme.colors.white}
                  color={theme.colors.dark}
                  border={`1px solid ${theme.colors.dark}`}
                  hoverStyles={`border: 1px solid ${theme.colors.primary}; color: ${theme.colors.white}; background: ${theme.colors.primary};`}
                  onClick={() => setDeleteModal(false)}
                >
                  {t("actions.cancel")}
                </Button>

                <Button onClick={onConfirmDelete}>{t("actions.delete")}</Button>
              </ModalFooter>
            </Modal>
          </DangerZone>
        </Block>
      </PageLayout>
      <FeedbackWidget />
    </>
  );
}
