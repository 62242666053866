import { css } from "styled-components/macro";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { ReactComponent as LeftArrowIcon } from "../assets/icons/LeftArrow.svg";
import { ReactComponent as PreviewPlayIcon } from "../assets/icons/PreviewPlay.svg";
import { ReactComponent as CommentModeIcon } from "../assets/icons/CommentMode.svg";
import { Collaborators } from "./Collaborators";
import { Controls } from "./Controls";
import { HistoryButtons } from "./HistoryButtons";
import { Title } from "./Title";
import { ShareButton } from "./ShareButton";
import { PublishButton } from "./PublishButton";

import { useCollaboration } from "../contexts/CollaborationContext";
import { Button } from "./Button";
import { useComments } from "../contexts/CommentsContext";
import { useStorage } from "../contexts/StorageContext";

export function StatusBar() {
  const { members } = useCollaboration();
  const { toggleComments } = useComments();
  const { t } = useTranslation();
  const history = useHistory();
  const { video } = useStorage();

  return (
    <div
      css={css`
        display: flex;
        height: 100%;
        flex-flow: row wrap;
        justify-content: space-between;
      `}
    >
      <div
        css={css`
          flex: 0 0 295px;
          height: 100%;
          display: flex;
          flex-flow: row wrap;
          justify-content: space-between;
          border-right: 1px solid #d0d0d0;
        `}
      >
        <div
          css={css`
            flex: 0 0 auto;
            display: flex;
            align-items: center;
            padding-left: 10px;
          `}
        >
          <Link
            to="/"
            css={css`
              display: flex;
              align-items: center;
              text-decoration: underline;
              font-weight: 600;
            `}
          >
            <LeftArrowIcon
              css={css`
                margin-right: 8px;
              `}
            />
            {t("video-player.dashboard")}
          </Link>
        </div>
        <div
          css={css`
            flex: 0 0 auto;
            display: flex;
            align-items: center;
            padding: 0 18px;
            display: flex;
            justify-content: flex-end;
          `}
        >
          <HistoryButtons />
        </div>
      </div>
      <div
        css={css`
          display: flex;
          flex: 1 0 10%;
          height: 100%;
          flex-flow: row wrap;
          justify-content: space-between;
          border-right: 1px solid #d0d0d0;
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            margin-left: 15px;
          `}
        >
          <Button
            icon={<CommentModeIcon />}
            css={css`
              min-width: 2em;
              width: 30px;
              background: transparent;
            `}
            onClick={toggleComments}
          />
          <Button
            icon={<PreviewPlayIcon />}
            css={css`
              background: transparent;
              width: 30px;
              min-width: 2em;
            `}
            onClick={() => {
              if (video) {
                history.push(`/video-preview/${video.uuid}`);
              }
            }}
          />
        </div>
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <Title />
        </div>
        <Controls />
      </div>
      <div
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        <div
          css={css`
            flex: 0 0 auto;
            display: flex;
            align-items: center;
            padding: 0 30px;
          `}
        >
          <Collaborators
            users={members}
            maxCollaborators={4}
            isHover={true}
            isValueClickable={true}
          />
        </div>
        <div
          css={css`
            flex: 0 0 auto;
            display: flex;
            justify-content: space-between;
            padding: 0px 20px 0px 0;
          `}
        >
          <div id="themis-collaborators">
            <ShareButton />
          </div>
          <div id="themis-publish">
            <PublishButton />
          </div>
        </div>
      </div>
    </div>
  );
}
