import { css } from "styled-components/macro";
import avatar from "../assets/img/Default_Avatar.png";
import { Button } from "./Button";
import { ReactComponent as PencilSvg } from "../assets/icons/Pencil.svg";
import { useHistory } from "react-router";
import { useAuth } from "../contexts/UserContext";
import { Headers } from "../helpers/layout";

export function ProfileHeaderVertical(props: {
  className?: string;
  isEditable?: boolean;
}) {
  const history = useHistory();
  const { userprofile } = useAuth();

  if (!userprofile) return null;

  return (
    <div
      className={props.className}
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
      `}
    >
      <div
        css={css`
          position: relative;
          display: flex;
          flex-direction: column;
          border-radius: 50%;
          width: 100px;
          height: 100px;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center center;
          cursor: pointer;

          ${userprofile.avatar
            ? css`
                background-image: url(${userprofile.avatar.image});
                border: 2px solid rgba(233, 91, 46, 1);
              `
            : css`
                background-image: url(${avatar});
                border: 2px solid rgba(233, 91, 46, 1);
              `}
        `}
        onClick={() => {
          history.push("/account/me");
        }}
      >
        {props.isEditable && (
          <Button
            css={css`
              display: flex;
              position: absolute;
              top: 0px;
              right: 0;
              padding: 0;
              min-width: 28px;
              height: 28px;
              border-radius: 50%;
              border: 2px solid rgba(233, 91, 46, 1);
              background-color: rgba(250, 231, 217, 1);
              align-items: center;
              justify-content: center;
              color: rgba(233, 91, 46, 1);
            `}
            hoverStyles="none"
            icon={<PencilSvg />}
            onClick={() => {
              history.push("/account/me");
            }}
          />
        )}
      </div>
      <div
        css={css`
          margin-top: 7px;
        `}
      >
        <Headers.H3
          css={css`
            font-weight: 700;
            line-height: 31.2px;
            overflow-wrap: break-word;
            text-align: center;
          `}
        >
          {userprofile.first_name} {userprofile.last_name}
        </Headers.H3>
      </div>
    </div>
  );
}
