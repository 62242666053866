import { VideoElementRecordPrompt, VideoElementState } from "../../types/Video";
import { css } from "styled-components/macro";
import { Headers } from "../../helpers/headers";
import { ReactComponent as PlayIcon } from "../../assets/icons/Play.svg";
import { useEditor } from "../../contexts/EditorContext";
import { calculateProportionalSize } from "../../../VideoPlayer/helpers/renderer";

export function ElementRecordRendererPrompt(props: {
  element: VideoElementRecordPrompt;
  state: VideoElementState;
}) {
  const { canvasSize, setRecordModalVisible } = useEditor();

  return (
    <div
      css={css`
        width: 100%;
        height: 100%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      `}
    >
      <Headers.H3
        style={{
          fontSize: calculateProportionalSize(12, canvasSize.width),
          lineHeight: 1.6,
        }}
      >
        {props.element.instruction}
      </Headers.H3>
      <button
        css={css`
          border: none;
          background: none;
          cursor: pointer;
          width: ${calculateProportionalSize(30, canvasSize.width)}px;
          height: ${calculateProportionalSize(30, canvasSize.width)}px;
          background: #000;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: ${calculateProportionalSize(15, canvasSize.width)}px 0;

          svg {
            fill: #fff;
            stroke: none;
            width: ${calculateProportionalSize(
              15,
              canvasSize.width
            )}px !important;
            height: ${calculateProportionalSize(
              15,
              canvasSize.width
            )}px !important;
          }
        `}
        onClick={(e) => {
          e.stopPropagation();
          setRecordModalVisible(true);
        }}
      >
        <PlayIcon width={calculateProportionalSize(15, canvasSize.width)} />
      </button>
      <Headers.H4
        style={{
          fontSize: calculateProportionalSize(12, canvasSize.width),
          lineHeight: 1.6,
        }}
      >
        Record narrative
      </Headers.H4>
    </div>
  );
}
