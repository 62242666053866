import React from "react";
import { css } from "styled-components/macro";
import { VideoElement } from "../../types/Video";
import { ReactComponent as DeleteIcon } from "../../assets/icons/ElementDelete.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/Edit.svg";
import { ReactComponent as InteractivityIcon } from "../../assets/icons/Interactivity.svg";
import { useTranslation } from "react-i18next";
import { useEditor } from "../../contexts/EditorContext";
import { useStorage } from "../../contexts/StorageContext";
import { ElementDropdown } from "./ElementDropdown";
import { ElementButton } from "./ElementButton";
import tinymce from "tinymce/tinymce";

type ElementControlsProps = {
  element: VideoElement;
};

export function ElementControls(props: ElementControlsProps) {
  const { t } = useTranslation();
  const {
    activeNav,
    setActiveNav,
    interactivityEditing,
    setInteractivityEditing,
  } = useEditor();

  const { api } = useStorage();

  const orderDropDown: { value: string; label: string }[] = [
    {
      value: "bringToFront",
      label: t("canvas.elements.dropdown.option1"),
    },
    {
      value: "bringToBack",
      label: t("canvas.elements.dropdown.option2"),
    },
  ];

  const shapes = [
    "polygon",
    "rectangle",
    "ellipse",
    "star",
    "arrow-1",
    "arrow-2",
    "arrow-3",
  ];

  const isEditing = () => {
    if (props.element.type === "text") {
      return activeNav === "text";
    }
    if (props.element.type === "image") {
      return activeNav === "media";
    }
    if (props.element.type === "drawing") {
      return activeNav === "draw";
    }

    if (shapes.includes(props.element.type)) {
      return activeNav === "shape";
    }

    return false;
  };

  return (
    <>
      <div
        css={css`
          position: absolute;
          top: -50px;
          right: 0;
          display: flex;
          justify-content: center;
        `}
      >
        {props.element.type === "image" && (
          <ElementButton
            onClick={() => {
              setInteractivityEditing(true);
            }}
            css={
              interactivityEditing &&
              css`
                border: 1px solid #e95b2e !important;
                color: #e95b2e !important;
              `
            }
          >
            <InteractivityIcon />
          </ElementButton>
        )}
        {["poll", "video", "image"].includes(props.element.type) ? null : (
          <ElementButton
            onClick={() => {
              if (props.element.type === "text") {
                const editorWrapper = document.getElementById(
                  `text-element-${props.element.id}`
                );

                // @ts-ignore
                if (editorWrapper && editorWrapper.api) {
                  // @ts-ignore
                  editorWrapper.api.setEditable(true);
                }

                setTimeout(() => {
                  const editor = tinymce.get(`editor-${props.element.id}`);

                  if (editor) {
                    editor.fire("focus");
                    editor.selection.select(editor.getBody(), true);
                    editor.selection.collapse(false);
                  }
                }, 100);
              } else if (props.element.type === "drawing") {
                setActiveNav("draw");
              } else if (shapes.includes(props.element.type)) {
                setActiveNav("shape");
              }
            }}
            css={
              isEditing() &&
              css`
                border: 1px solid #e95b2e !important;
                color: #e95b2e !important;
              `
            }
          >
            <EditIcon />
          </ElementButton>
        )}
        <ElementDropdown
          options={orderDropDown}
          css={css`
            margin: 0 3px;
          `}
          onSelect={(option) => {
            if (option === "bringToFront") {
              api.bringToFront(props.element.id);
            } else if (option === "bringToBack") {
              api.bringToBack(props.element.id);
            }
          }}
        >
          {t("canvas.elements.dropdown.title")}
        </ElementDropdown>
        <ElementButton
          onClick={() => {
            api.deleteElement(props.element.id);
          }}
        >
          <DeleteIcon />
        </ElementButton>
      </div>
    </>
  );
}
