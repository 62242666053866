import React, { useState, useEffect, useCallback, useRef } from "react";
import styled, { css } from "styled-components/macro";
import { createPortal } from "react-dom";
import { theme } from "../themes/variables";

export const TooltipText = styled.span`
  visibility: hidden;
  opacity: 0;
  min-width: 220px;
  background-color: #db2136;
  color: ${theme.colors.white};
  text-align: left;
  border-radius: 6px;
  padding: 8px;
  position: absolute;
  z-index: 1;
  bottom: calc(100% + 5px);
  margin-left: -30px;
  font-weight: 500;
  user-select: none;
  :after {
    content: "";
    position: absolute;
    top: 100%;
    left: 21px;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #db2136 transparent transparent transparent;
  }
`;

export type TooltipProps = {
  text?: string;
  children?: JSX.Element | string;
  className?: string;
};

export function Tooltip(props: TooltipProps) {
  return (
    <div
      css={css`
        position: relative;
        display: inline-block;
        margin-right: 9px;
        z-index: 1;
        transition: opacity 0.2s ease-in-out;
        :hover ${TooltipText} {
          opacity: 1;
          visibility: visible;
        }
      `}
      className={props.className}
    >
      {props.children}
      <TooltipText>{props.text}</TooltipText>
    </div>
  );
}

export type LifetimeTooltipProps = {
  show?: boolean;
  isActive?: boolean;
  onDeactivate?: () => void;
  deactivateOnClickTooltip?: boolean;
  activateOnClick?: boolean;
  lifeTime?: number;
  text?: string;
  textColor?: string;
  tooltipColor?: string;
  position?: "left" | "center" | "right";
  verticalPosition?: "bottom" | "top";
  children: JSX.Element | null;
  className?: string;
};

export function TooltipArea(props: {
  show?: boolean;
  text?: string;
  textColor?: string;
  tooltipColor?: string;
  position?: "left" | "center" | "right";
  verticalPosition?: "bottom" | "top";
  children: JSX.Element;
  className?: string;
  offsetX?: number;
  offsetY?: number;
  staticPosition?: boolean;
}) {
  const {
    show = true,
    text,
    textColor = theme.colors.white,
    tooltipColor = theme.colors.black,
    children,
    className,
    offsetX = 0,
    offsetY = 0,
    staticPosition,
  } = props;

  const [active, setActive] = useState(false);
  const [positionX, setPositionX] = useState(0);
  const [positionY, setPositionY] = useState(0);
  const tooltipRef = useRef<HTMLDivElement | null>(null);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const height = tooltipRef.current?.clientHeight;

  const rootElement = document.getElementById("root");
  const rect = wrapperRef.current?.getBoundingClientRect();

  useEffect(() => {
    const wrapper = wrapperRef.current;

    if (!wrapper) return;

    const mouseMove = (e: MouseEvent) => {
      if (!staticPosition) {
        setPositionX(e.x);
        setPositionY(e.y);
      }
      if (rect) {
        if (staticPosition) {
          setPositionX(rect.left + rect.width);
          setPositionY(rect.top + rect.height);
        }
        /* Control width */
        if (e.x > rect.left + rect.width || e.x < rect.left) {
          setActive(false);
        }
        /* Control height */
        if (e.y > rect.top + rect.height || e.y < rect.top) {
          setActive(false);
        }
      }
    };
    wrapper.addEventListener("mousemove", mouseMove);

    return () => {
      wrapper.removeEventListener("mousemove", mouseMove);
    };
  }, [rootElement, rect, staticPosition]);

  if (!show) return children;

  if (!rootElement) return children;

  return (
    <div
      css={css`
        display: block;
      `}
      onClick={() => setActive(true)}
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
      className={className}
      ref={wrapperRef}
    >
      {children}
      {createPortal(
        <TooltipText
          className="tooltip"
          ref={tooltipRef}
          style={{
            position: "absolute",
            transform: `translate3d(
              ${positionX + 6 + offsetX}px,
              ${positionY - (height ? height / 2 : 0) + offsetY}px,
              0
            )`,
          }}
          onMouseEnter={() => setActive(false)}
          onMouseOver={() => setActive(false)}
          onMouseLeave={() => setActive(false)}
          css={css`
            position: absolute;
            z-index: 100;
            top: 0;
            left: 0;
            visibility: ${active ? "visible" : "hidden"};
            opacity: ${active ? 1 : 0};
            color: ${textColor};
            background: ${tooltipColor};
            font-size: 14px;
            text-align: center;
            min-width: 0;
            margin-left: 0;
            bottom: unset;
            transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
            :after {
              border-color: ${tooltipColor} transparent transparent transparent;
              transform: rotate(90deg);
              top: calc(50% - 5px);
              left: -5px;
            }
          `}
        >
          {text}
        </TooltipText>,
        rootElement
      )}
    </div>
  );
}

export function LifetimeTooltip(props: LifetimeTooltipProps) {
  const {
    show = true,
    isActive,
    onDeactivate,
    deactivateOnClickTooltip,
    activateOnClick,
    lifeTime = 3000,
    text,
    textColor = theme.colors.white,
    tooltipColor = theme.colors.black,
    position,
    verticalPosition = "bottom",
    children,
    className,
  } = props;

  const [active, setActive] = useState(isActive);
  const tooltipRef = useRef<HTMLDivElement | null>(null);

  const onDeactivateTooltip = useCallback(() => {
    if (onDeactivate) onDeactivate();
    setActive(false);
  }, [onDeactivate]);

  useEffect(() => {
    setActive(isActive);
  }, [isActive]);

  useEffect(() => {
    if (active) {
      setTimeout(() => {
        onDeactivateTooltip();
      }, lifeTime);
    }
  }, [active, lifeTime, onDeactivateTooltip]);

  const onClickTooltip = useCallback(() => {
    if (deactivateOnClickTooltip) {
      onDeactivateTooltip();
    }
  }, [deactivateOnClickTooltip, onDeactivateTooltip]);

  const onClick = useCallback(() => {
    if (activateOnClick) setActive(true);
  }, [activateOnClick]);

  if (!show) return children;

  return (
    <div
      css={css`
        position: relative;
        display: inline-block;
        z-index: 1;
      `}
      onClick={onClick}
      className={className}
    >
      {children}
      <TooltipText
        ref={tooltipRef}
        onClick={onClickTooltip}
        className="tooltip"
        css={css`
          visibility: ${active ? "visible" : "hidden"};
          opacity: ${active ? 1 : 0};
          color: ${textColor};
          background: ${tooltipColor};
          font-size: 14px;
          text-align: center;
          min-width: 0;
          left: 0;
          right: 0;
          margin-left: 0;
          transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
          ${verticalPosition === "top" &&
          css`
            bottom: calc(-${tooltipRef.current?.clientHeight}px - 7.5px);
            :after {
              bottom: 100%;
              top: unset;
              transform: rotate(180deg);
            }
          `}
          ${position === "right" &&
          css`
            :after {
              left: unset;
            }
          `}
          ${position === "center" &&
          css`
            :after {
              left: 50%;
            }
          `}
          :after {
            border-color: ${tooltipColor} transparent transparent transparent;
          }
        `}
      >
        {text}
      </TooltipText>
    </div>
  );
}
