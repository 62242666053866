import React from "react";
import { css } from "styled-components/macro";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { useAuth } from "../contexts/UserContext";
import { CreateThemiButton } from "./CreateThemiButton";
import { Footer } from "./Footer";
import { ProfileHeaderVertical } from "./ProfileHeaderVertical";
import { Button } from "./Button";
import { Search } from "./Search";
import { Wrapper } from "./Wrapper";
import { ReactComponent as LogOutIcon } from "../assets/icons/LogOut.svg";
import { useTranslation } from "react-i18next";
import { LanguageSelector } from "./LanguageSelector";

type StatusParams = {
  status: "published";
};

export function PageLayout(props: {
  children: React.ReactNode | React.ReactNode[];
}) {
  const { deauthenticate } = useAuth();
  const { status } = useParams<StatusParams>();
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <>
      <Wrapper
        css={css`
          padding: 26px 0 65px;
          min-height: calc(100vh - 36px - 48px);
          box-sizing: border-box;
        `}
      >
        <div
          css={css`
            position: relative;
            display: flex;
            flex-flow: row wrap;
            justify-content: stretch;
          `}
        >
          <aside
            css={css`
              position: relative;
              flex: 0 0 292px;
              border-right: 1px solid #f2f2f2;
              padding-right: 33px;
              padding-left: 27px;
              box-sizing: border-box;
              display: flex;
              flex-direction: column;
              align-items: center;
              min-height: calc(100vh - 200px);
            `}
          >
            <div
              css={css`
                height: 100%;
              `}
            >
              <ProfileHeaderVertical
                isEditable
                css={css`
                  padding-top: 19px;
                `}
              />
              <div
                css={css`
                  margin-top: 23px;
                  display: flex;
                  justify-content: center;
                `}
              >
                <CreateThemiButton />
              </div>
              <button
                css={css`
                  margin-top: 20px;
                  border: none;
                  background-color: transparent;
                  color: #cdcdcd;
                  font-size: 20px;
                  cursor: pointer;
                  width: 100%;

                  ${location.pathname.includes("/themi") &&
                  status !== "published" &&
                  css`
                    border: 2px solid #e95b2e;
                    background-color: #fae7d9;
                    color: #e95b2e;
                    border-radius: 7px;
                    padding: 4px 0;
                  `}
                `}
                onClick={() => {
                  location.pathname.includes("/") && history.push("/themi");
                }}
              >
                {t("pagelayout.myThemi-button")}
              </button>
              <button
                css={css`
                  margin-top: 10px;
                  border: none;
                  background-color: transparent;
                  color: #cdcdcd;
                  font-size: 20px;
                  cursor: pointer;
                  width: 100%;

                  ${location.pathname.includes("/themi") &&
                  status === "published" &&
                  css`
                    border: 2px solid #e95b2e;
                    background-color: #fae7d9;
                    color: #e95b2e;
                    border-radius: 7px;
                    padding: 4px 0;
                  `}
                `}
                onClick={() => {
                  location.pathname.includes("/") &&
                    history.push("/themi/published/");
                }}
              >
                {t("pagelayout.published-button")}
              </button>
            </div>
            <div
              css={css`
                position: sticky;
                bottom: 30px;
                left: 0;
                width: 100%;
              `}
            >
              <div
                css={css`
                  margin-bottom: 20px;
                  text-align: center;
                `}
              >
                <Button
                  onClick={() => {
                    deauthenticate();
                  }}
                  icon={<LogOutIcon />}
                  css={css`
                    background-color: transparent;
                    color: #000;
                    font-size: 20px;
                    font-weight: 400;
                  `}
                  hoverStyles="none"
                >
                  {t("pagelayout.logout")}
                </Button>
              </div>
            </div>
          </aside>
          <main
            css={css`
              flex: 1 1 10%;
              padding-left: 70px;
              box-sizing: border-box;
            `}
          >
            <header
              css={css`
                margin-bottom: 15px;
                display: flex;
                flex-flow: row wrap;
                justify-content: flex-end;
              `}
            >
              <div
                css={css`
                  flex: 0 0 230px;
                `}
              >
                <Search />
              </div>
              <LanguageSelector
                css={css`
                  margin-top: 7px;
                `}
              />
            </header>
            <section>{props.children}</section>
          </main>
        </div>
      </Wrapper>
      <Footer />
    </>
  );
}
