import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { css } from "styled-components/macro";
import { getVideos } from "../actions/themi/getVideos";
import { theme } from "../themes/variables";
import { ReactComponent as SearchSvg } from "../assets/icons/Search.svg";
import { Loader } from "./Loader";
import { useDebounce } from "use-debounce";
import { Video } from "../VideoEditor/types/Video";
import { useClickOutside } from "../VideoEditor/hooks/useClickOutside";
import { useTranslation } from "react-i18next";

export function SearchNoResults(props: {
  show: boolean;
  text: string;
  className?: string;
}) {
  if (!props.show) {
    return null;
  }

  return (
    <div
      css={css`
        top: 30px;
        left: 0;
        position: absolute;
        width: calc(100% - 106px);
        padding: 20px;
        text-align: center;
        background: ${theme.colors.white};
        opacity: 0.9;
      `}
      className={props.className}
    >
      <div
        css={css`
          margin: 20px 0;
        `}
      >
        Sorry, there were no matches for
        <strong>{props.text}</strong>
      </div>

      <div>
        If you still can't get the information you're looking for please{" "}
        <a href="mailto:info@themis.eu" rel="noopener noreferrer">
          contact us
        </a>
        .
      </div>
    </div>
  );
}

export function Search(props: {
  className?: string;
  hasPlaceholder?: boolean;
  hasDropDown?: boolean;
}) {
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [results, setResults] = useState<Video[]>([]);
  const [focused, setFocused] = useState(false);
  const search = useRef(null);
  const { t } = useTranslation();

  const isExpanded = focused && results.length > 0;

  const [debouncedSearchTerm] = useDebounce(searchTerm, 500);

  useEffect(() => {
    setLoading(true);
    if (debouncedSearchTerm) {
      getVideos({
        searchText: debouncedSearchTerm,
        limit: 5,
      }).then((response) => {
        setResults(response.data.results);
        setLoading(false);
      });
    } else {
      setResults([]);

      setLoading(false);
    }
  }, [debouncedSearchTerm]);

  const trimmedSearchArr = searchTerm.trim().toLowerCase().split(" ");

  useClickOutside(search, () => {
    setFocused(false);
  });

  return (
    <div
      className={props.className}
      css={css`
        position: relative;
        width: 100%;
        padding: 0px 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: relative;
        border-radius: 21px;
        border: 1px solid rgba(205, 205, 205, 1);
        transition: all 0.3s ease;

        z-index: 1;

        ${isExpanded &&
        css`
          border-radius: 21px 21px 0px 0px;
        `}
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          position: relative;
          width: 100%;
        `}
      >
        <SearchSvg width={20} height="20" display="flex" color="#CDCDCD" />
        <input
          placeholder={t("pagelayout.search-placeholder")}
          onChange={(e) => setSearchTerm(e.target.value)}
          onFocus={() => setFocused(true)}
          value={searchTerm}
          css={css`
            width: 100%;
            height: 42px;
            box-sizing: border-box;
            background: none;
            border: none;
            font-size: 18px;
            outline: none;
            color: ${theme.colors.dark};
            margin-left: 9px;

            ::placeholder {
              font-size: 14px;
              color: ${theme.colors.gray4};
            }
          `}
        />
      </div>

      <div
        ref={search}
        className={`search-dropdown ${focused ? "focused" : ""}`}
        css={css`
          position: absolute;
          max-height: ${focused ? 300 : 0}px;
          top: 35px;
          width: calc(100% + 2px);
          left: -1px;
          background: ${theme.colors.gray1};
          border: 1px solid rgba(205, 205, 205, 1);
          padding: 0 20px;
          border-bottom-right-radius: 20px;
          border-bottom-left-radius: 20px;
          transition: all 0.3s ease;
          overflow: hidden;
          font-size: 16px;
          opacity: 0;

          ${isExpanded &&
          css`
            opacity: 1;
            top: 42px;
          `}
        `}
      >
        {loading ? (
          <Loader size={20} />
        ) : results.length ? (
          <div
            css={css`
              padding: 20px 0;
            `}
          >
            {results.map((result, idx) => (
              <Link
                css={css`
                  width: 100%;
                  cursor: pointer;
                  display: block;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  margin-bottom: 8px;
                `}
                key={idx}
                to={`/video-editor/${result.uuid}`}
              >
                {result.name.split(" ").map((el, key) => {
                  return !!trimmedSearchArr.length &&
                    trimmedSearchArr.some((trimmedEl) =>
                      el.trim().toLowerCase().includes(trimmedEl)
                    ) ? (
                    <span key={key}>
                      {(() => {
                        return trimmedSearchArr.map((trimmedElem, idx) => {
                          const regexp = new RegExp(`(${trimmedElem})`, "gi");
                          if (!el.match(regexp)) {
                            return null;
                          }
                          const replaced = el.replace(
                            regexp,
                            `<strong>$1</strong>`
                          );

                          return (
                            <span
                              key={idx}
                              dangerouslySetInnerHTML={{
                                __html: replaced + "&nbsp;",
                              }}
                            ></span>
                          );
                        });
                      })()}
                    </span>
                  ) : (
                    <span key={key}>{el}&nbsp;</span>
                  );
                })}
              </Link>
            ))}
          </div>
        ) : debouncedSearchTerm && !loading ? (
          <div
            css={css`
              padding: 20px 0;
              line-height: 22px;
            `}
          >
            Sorry, there were no matches for
            <strong>{searchTerm}</strong>
          </div>
        ) : null}
      </div>
    </div>
  );
}
