import { css } from "styled-components/macro";
import { useRecording } from "../contexts/RecordingContext";
import { ReactComponent as DeleteIcon } from "../assets/icons/Video-Delete.svg";
import { ReactComponent as CircleStopIcon } from "../assets/icons/Stop.svg";
import { ReactComponent as SquareStopIcon } from "../assets/icons/SquareStop.svg";
import { ReactComponent as PauseIcon } from "../assets/icons/Pause.svg";
import React, { useEffect } from "react";
import { Play } from "react-feather";
import { DateTime } from "luxon";

import { calculateProportionalSize } from "../renderers/ElementRenderer";
import { useEditor } from "../contexts/EditorContext";

export function RecordingIndicator() {
  const {
    recording,
    recordingType,
    mediaRecorder,
    countdownTime,
    recordingTime,
    cancelRecording,
    stopRecording,
    pauseRecording,
    resumeRecording,
  } = useRecording();
  const videoPreview = React.useRef<HTMLVideoElement>(null);
  const { canvasSize } = useEditor();

  useEffect(() => {
    const interval = setInterval(() => {
      if (mediaRecorder.current && videoPreview.current) {
        if (
          !videoPreview.current.srcObject &&
          mediaRecorder.current.stream.active
        ) {
          videoPreview.current.srcObject = mediaRecorder.current.stream;
        }
      }
    }, 300);

    return () => clearInterval(interval);
  }, [mediaRecorder, recording]);

  const recordingStarted = countdownTime === 0;

  const elWidth = calculateProportionalSize(65, canvasSize.width);

  if (recordingType === "video") {
    return (
      <div
        css={css`
          position: absolute;
          top: ${calculateProportionalSize(17, canvasSize.width)}px;
          right: ${calculateProportionalSize(20, canvasSize.width)}px;
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
          z-index: 11;
          border-radius: 10px;
          padding: 10px;
        `}
      >
        {recordingStarted ? (
          <div
            css={css`
              position: relative;
            `}
          >
            <video
              css={css`
                width: ${elWidth}px;
                height: ${elWidth}px;
                border-radius: 50%;
                object-fit: cover;
                user-select: none;
                overflow: hidden;
              `}
              autoPlay
              muted
              ref={videoPreview}
            >
              Your browser doesn't support the video tag
            </video>
            <div
              css={css`
                position: absolute;
                width: 100%;
                text-align: center;
                bottom: 15px;
                font-size: 10px;
                font-weight: 400;
              `}
            >
              {DateTime.fromSeconds(recordingTime).toFormat("mm:ss")}
            </div>
          </div>
        ) : (
          <div
            css={css`
              width: ${elWidth}px;
              height: ${elWidth}px;
              border-radius: 50%;
              background: rgb(217, 33, 33);
              border: none;
              display: flex;
              align-items: center;
              justify-content: center;
              color: white;
              font-size: ${calculateProportionalSize(10, canvasSize.width)}px;
            `}
          >
            {countdownTime}
          </div>
        )}
        <div
          css={css`
            display: flex;
            justify-content: center;
          `}
        >
          <button
            onClick={() => {
              cancelRecording();
            }}
            css={css`
              border: none;
              align-self: center;
              margin-top: 6px;
              cursor: pointer;
              background-color: #fff;
              box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
              width: 22px;
              height: 22px;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
            `}
          >
            <DeleteIcon
              css={css`
                width: 13.6px;
                height: 12.6px;
              `}
            />
          </button>
          <button
            onClick={() => {
              stopRecording();
            }}
            css={css`
              border: none;
              background-color: transparent;
              color: #fff;
              border-radius: 20px;
              margin-top: 0;
              padding: 5px;
              cursor: pointer;
              width: 50px;
              align-self: center;
              height: 41px;
              font-size: 20px;
            `}
          >
            {recordingStarted ? (
              <SquareStopIcon
                css={css`
                  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
                  border-radius: 20px;
                  width: 33px;
                  height: 33px;
                `}
              />
            ) : (
              <CircleStopIcon />
            )}
          </button>

          <button
            onClick={() => {
              if (recording === "paused") {
                resumeRecording();
              } else {
                pauseRecording();
              }
            }}
            css={css`
              border: none;
              align-self: center;
              margin-top: 6px;
              cursor: pointer;
              background-color: #fff;
              box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
              width: 22px;
              height: 22px;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
            `}
          >
            {recording === "paused" ? (
              <Play
                css={css`
                  width: 14px;
                  height: 14px;
                `}
              />
            ) : (
              <PauseIcon
                css={css`
                  width: 8.91px;
                  height: 12.34px;
                `}
              />
            )}
          </button>
        </div>
      </div>
    );
  }

  if (recordingType === "audio") {
    return (
      <div
        css={css`
          position: absolute;
          top: 20px;
          right: 20px;
          text-align: center;
          display: flex;
          flex-direction: column;
          z-index: 11;
          background: #fff;
          border-radius: 10px;
          padding: 10px;
        `}
      >
        {!recordingStarted ? (
          <div
            css={css`
              width: 100px;
              height: 100px;
              border-radius: 50%;
              background: rgb(217, 33, 33);
              border: none;
              display: flex;
              align-items: center;
              justify-content: center;
              color: white;
              font-size: 20px;
            `}
          >
            {countdownTime}
          </div>
        ) : (
          <div>{DateTime.fromSeconds(recordingTime).toFormat("mm:ss")}</div>
        )}
        <div
          css={css`
            display: flex;
            justify-content: center;
          `}
        >
          <button
            onClick={() => {
              cancelRecording();
            }}
            css={css`
              border: none;
              align-self: center;
              margin-top: 6px;
              cursor: pointer;
              background-color: transparent;
            `}
          >
            <DeleteIcon />
          </button>
          <button
            onClick={() => {
              stopRecording();
            }}
            css={css`
              border: none;
              background-color: transparent;
              color: #fff;
              border-radius: 20px;
              margin-top: 0;
              padding: 5px;
              cursor: pointer;
              width: 50px;
              align-self: center;
              height: 41px;
              font-size: 20px;
            `}
          >
            <CircleStopIcon />
          </button>

          <button
            onClick={() => {
              if (recording === "paused") {
                resumeRecording();
              } else {
                pauseRecording();
              }
            }}
            css={css`
              border: none;
              align-self: center;
              margin-top: 6px;
              cursor: pointer;
              background-color: transparent;
            `}
          >
            {recording === "paused" ? (
              <Play />
            ) : (
              <PauseIcon
                css={css`
                  width: 17px;
                  height: 16px;
                `}
              />
            )}
          </button>
        </div>
      </div>
    );
  }

  return null;
}
