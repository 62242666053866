import { Button } from "../components/Button";
import { useTranslation } from "react-i18next";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "../components/Modal";
import { css } from "styled-components/macro";

type ModalDeleteProps = {
  onDelete: () => void;
  isOpen: boolean;
  onClose: () => void;
  title: string;
  description?: string;
};

export function ModalDelete(props: ModalDeleteProps) {
  const { t } = useTranslation();

  return (
    <Modal
      modalIsOpen={props.isOpen}
      onClose={props.onClose}
      contentLabel={props.title}
      contentStyles={{ maxWidth: "355px", padding: "18px 14px 23px 21px" }}
    >
      <ModalHeader
        css={css`
          font-size: 20px;
        `}
      >
        {props.title}
      </ModalHeader>
      {props.description && (
        <ModalBody
          css={css`
            padding: 3px 0;
            font-size: 15px;
            font-weight: 300;
          `}
        >
          {props.description}
        </ModalBody>
      )}
      <ModalFooter
        css={css`
          justify-content: center;
        `}
      >
        <Button
          onClick={props.onClose}
          css={css`
            background-color: transparent;
            color: #8e8e8e;
            border: 1px solid #8e8e8e;
            padding: 6px 27px;
          `}
          hoverStyles={`color: #000; border-color:#000`}
        >
          {t("modal.delete.cancel")}
        </Button>
        <Button
          onClick={props.onDelete}
          css={css`
            background-color: #e95b2e;
            color: #fff;
            padding: 6px 27px;
          `}
          hoverStyles={`color:#fff; border:none;background-color:#FE521C`}
        >
          {t("modal.delete.delete")}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
