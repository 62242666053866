import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { css } from "styled-components/macro";
import { customToast } from "../../components/customToast";
import { LifetimeTooltip } from "../../components/Tooltip";
import { serverErrorHandler } from "../../helpers/serverErrorHandler";
import { deleteVideo } from "../actions/deleteVideo";
import { ReactComponent as TrashIcon } from "../assets/icons/ControlsDelete.svg";
import { useStorage } from "../contexts/StorageContext";
import { Button } from "./Button";
import { DisableUI } from "./DisableUI";
import { ModalDelete } from "../../modals/ModalDelete";
import { canEdit } from "../helpers/collaborator";

export function Controls() {
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const { video } = useStorage();
  const history = useHistory();
  const { t } = useTranslation();

  const onConfirmDelete = async () => {
    if (video) {
      try {
        await deleteVideo(video.uuid);
      } catch (error: any) {
        customToast.error(
          t("status.error", {
            error: serverErrorHandler(error),
          })
        );
      } finally {
        history.push("/themi");
      }
    }
  };

  return (
    <div
      css={css`
        display: flex;
      `}
    >
      <LifetimeTooltip
        activateOnClick
        text={t("tooltip.access")}
        lifeTime={5000}
        position="left"
        verticalPosition="top"
        show={!canEdit(video?.current_user_role)}
        css={css`
          display: flex;
          .tooltip {
            min-width: 120px;
          }
        `}
      >
        <DisableUI
          show={!canEdit(video?.current_user_role)}
          css={css`
            display: flex;
          `}
        >
          <Button
            css={css`
              background-color: transparent;
              min-width: 2em;
              :disabled {
                background-color: transparent;
              }
            `}
            onClick={() => {
              if (video) {
                setDeleteModal(true);
              }
            }}
          >
            <TrashIcon />
          </Button>
        </DisableUI>
      </LifetimeTooltip>

      <ModalDelete
        isOpen={deleteModal}
        onClose={() => setDeleteModal(false)}
        onDelete={onConfirmDelete}
        title={t("modal.delete.title")}
        description={t("modal.delete.description")}
      />
    </div>
  );
}
