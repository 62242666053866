import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { css } from "styled-components/macro";
import { Headers } from "../helpers/layout";
import { serverErrorHandler } from "../helpers/serverErrorHandler";
import { Template } from "../helpers/templates/themis";
import { createVideoFromTemplate } from "../VideoEditor/actions/createVideoFromTemplate";
import { Button } from "./Button";
import { customToast } from "./customToast";
import { Loader } from "./Loader";

export function TemplateCard(props: {
  template: Template;
  onShowPreview: () => void;
}) {
  const { template } = props;
  const { t } = useTranslation();
  const [creating, setCreating] = useState(false);
  const history = useHistory();

  return (
    <div
      key={template.id}
      css={css`
        display: flex;
        flex-direction: column;
        border: 1px solid #f2f2f2;
        padding: 16px 20px;
        border-radius: 16px;
        transform: translateY(0);
        transition: all 300ms;

        :hover {
          border: 1px solid #000;
          transform: translateY(-15px);
          box-shadow: 0px 7px 9px rgba(0, 0, 0, 0.14);
          display: block;
          .buttons {
            visibility: visible;
            opacity: 1;
            transform: translateY(0);
          }
        }
      `}
    >
      <div
        css={css`
          display: flex;
        `}
      >
        <div
          className="backgroundColors"
          css={css`
            width: 49px;
            height: 49px;
            border: none;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: ${template.iconBackground};
          `}
        >
          {template.icon}
        </div>

        <Headers.H3
          css={css`
            font-size: 20px;
            font-weight: 600;
            margin-left: 28px;
          `}
        >
          {template.title}
        </Headers.H3>
      </div>
      <Headers.H4
        css={css`
          line-height: 19px;
          margin-top: 15px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
        `}
      >
        {template.description}
      </Headers.H4>
      <div
        className="buttons"
        css={css`
          opacity: 0;
          visibility: hidden;
          transform: translateY(10px);
          transition: all 300ms;
          margin-top: 13px;
          margin-bottom: 3px;
          display: flex;
          justify-content: center;
        `}
      >
        <Button
          css={css`
            padding: 8px 20px;
            font-size: 14px;
            margin-right: 10px;
            border: 1px solid #858585;
            background-color: transparent;
            color: #858585;
          `}
          hoverStyles="none"
          onClick={() => {
            props.onShowPreview();
          }}
        >
          {t("dashboard.templateData.preview")}
        </Button>
        <Button
          css={css`
            padding: 8px 20px;
            font-size: 14px;
            background-color: #e95b2e;
          `}
          hoverStyles="none"
          onClick={async () => {
            setCreating(true);

            try {
              const response = await createVideoFromTemplate({
                template_id: template.id,
              });

              history.push(`/video-editor/${response.data.uuid}`);
            } catch (error: any) {
              customToast.error(
                t("status.error", {
                  error: serverErrorHandler(error),
                })
              );
            } finally {
              setCreating(false);
            }
          }}
        >
          {creating ? <Loader /> : t("dashboard.templateData.use-template")}
        </Button>
      </div>
    </div>
  );
}
