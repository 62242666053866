import React from "react";
import { css } from "styled-components/macro";
import { Loader } from "../../components/Loader";

export function InlineButton(props: {
  type?: "button" | "submit";
  children?: React.ReactNode;
  title?: string;
  name?: string;
  onClick?: () => void;
  className?: string;
  icon?: React.ReactNode;
  iconRight?: boolean;
  isSubmitting?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  isActive?: boolean;
}) {
  return (
    <button
      type={props.type || "button"}
      name={props.name}
      title={props.title}
      css={css`
        border: 1px solid #cdcdcd;
        font-size: 16px;
        padding: 5px 8px;
        background-color: #ffff;
        color: #7f7f7f;
        border-radius: 6px;
        font-weight: 600;
        cursor: pointer;
        min-width: 7em;
        :disabled {
          cursor: default;
          background: #858585;
        }

        :hover {
          background-color: #fff;
          border: 1px solid #e95b2e;
          color: #e95b2e;
        }

        ${props.isActive &&
        css`
          border: 1px solid #e95b2e !important;
          background-color: #fae7d9 !important;
          color: #e95b2e !important;
        `}
      `}
      disabled={props.disabled || props.isSubmitting}
      className={props.className}
      onClick={props.onClick}
    >
      {props.isSubmitting ? (
        <Loader size={15} />
      ) : (
        <div
          css={css`
            display: flex;
          `}
        >
          {props.icon && !props.iconRight && (
            <span
              css={css`
                display: flex;
                justify-content: center;
                ${props.children &&
                css`
                  margin-right: 10px;
                `};
              `}
            >
              {props.icon}
            </span>
          )}

          <span
            css={css`
              display: flex;
              justify-content: center;
              align-items: center;
            `}
          >
            {props.children}
          </span>

          {props.icon && props.iconRight && (
            <span
              css={css`
                display: flex;
                ${props.children &&
                css`
                  margin-left: 10px;
                `}
              `}
            >
              {props.icon}
            </span>
          )}
        </div>
      )}
    </button>
  );
}
