import { css } from "styled-components/macro";
import { useComments } from "../contexts/CommentsContext";
import { usePlayback } from "../contexts/PlaybackContext";
import { VideoElement } from "../types/Video";
import { CommentRenderer } from "./CommentRenderer";
import { Elements } from "./Elements";

export function FrameRenderer(props: { elements: VideoElement[] }) {
  const { activeScene } = usePlayback();
  const { commentMode, commentsOpen } = useComments();
  const { comments } = useComments();

  const sortCommentsByParentId = () => {
    if (!activeScene) return;

    const rootComments = comments.filter(
      (comment) =>
        comment.parent === null && comment.scene_id === activeScene.id
    );
    if (!rootComments.length) return [];

    const result: any[] = [];
    rootComments.forEach((rootComment) => {
      const thread = [rootComment];
      comments.forEach((comment) => {
        if (comment.parent === rootComment.id) {
          thread.push(comment);
        }
      });
      result.push(thread);
    });
    return result;
  };

  const sortedComments = sortCommentsByParentId();

  return (
    <div>
      <div
        css={css`
          ${commentsOpen &&
          commentMode &&
          css`
            pointer-events: none;
          `}
        `}
      >
        <Elements elements={props.elements} />
      </div>
      {commentMode &&
        sortedComments &&
        sortedComments.map((comments, key) => (
          <CommentRenderer comments={comments} key={key} />
        ))}
    </div>
  );
}
