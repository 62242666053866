import { useCallback, useEffect, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router";
import { customToast } from "../../components/customToast";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { Button } from "../../VideoEditor/components/Button";
import { Header } from "../../components/Header";
import { Wrapper } from "../../components/Wrapper";
import { SEO } from "../../components/SEO";
import { Loader } from "../../components/Loader";
import { theme } from "../../themes/variables";
import { Headers } from "../../helpers/layout";
import {
  resendConfirmationEmail,
  USER_CONFIRM_EMAIL,
} from "../../actions/auth/resendConfirmationEmail";
import { confirmEmail } from "../../actions/auth/confirmEmail";
import { serverErrorHandler } from "../../helpers/serverErrorHandler";
import { AuthBackgroundStyle } from "../../themes/variables";
import { FeedbackWidget } from "../../components/FeedbackWidget";

export function ConfirmEmailPage(props: RouteComponentProps) {
  const { t } = useTranslation();

  const history = useHistory();

  const params = new URLSearchParams(props.location.search);

  const token = params.get("token");

  const [isSubmitting, setSubmitting] = useState(false);
  const [isConfirming, setConfirming] = useState(token ? true : false);

  const userEmail = localStorage.getItem(USER_CONFIRM_EMAIL);

  const sendEmailConfirm = useCallback(
    async (token: string) => {
      try {
        setConfirming(true);

        const response = await confirmEmail(token);

        if (response.data.is_verified) {
          history.push("/login");
        } else {
          history.push("/verification-info");
        }
      } catch (error) {
        customToast.error(
          t("status.error", {
            error: serverErrorHandler(error),
          })
        );
      } finally {
        setConfirming(false);
      }
    },
    [history, t]
  );

  useEffect(() => {
    if (!token) return;

    sendEmailConfirm(token);
  }, [token, sendEmailConfirm]);

  return (
    <Wrapper>
      <SEO>
        <title>{t("confirm-email.title")}</title>
      </SEO>
      <AuthBackgroundStyle />
      <Header />
      <div
        css={css`
          width: 100%;
          max-width: 500px;
          margin: 0 auto;
          padding: 20px 0;
          border: 1px solid black;
          box-shadow: 0px 0px 19px rgba(0, 0, 0, 0.11);
          border-radius: 25px;
          background-color: ${theme.colors.white};
          text-align: center;

          p {
            margin: 1em 0;
          }
        `}
      >
        {isConfirming ? (
          <Loader />
        ) : (
          <>
            <div
              css={css`
                margin-bottom: 40px;
                text-align: center;
              `}
            >
              <Headers.H2>{t("confirm-email.title")}</Headers.H2>

              <p>
                {t("confirm-email.email-sent", {
                  email: userEmail,
                })}
                <br />
                {t("confirm-email.click-on-link")}
              </p>
            </div>
            <div>
              <p>
                <b>{t("confirm-email.not-receiving")}</b>
              </p>
              <Button
                disabled={!userEmail}
                isSubmitting={isSubmitting}
                onClick={async () => {
                  if (!userEmail) return;

                  setSubmitting(true);

                  try {
                    await resendConfirmationEmail(userEmail);
                  } catch (error) {
                    customToast.error(
                      t("status.error", {
                        error: serverErrorHandler(error),
                      })
                    );
                  } finally {
                    setSubmitting(false);
                  }
                }}
                css={css`
                  color: #fff;
                `}
              >
                {t("actions.resend-email")}
              </Button>
              <p>{t("confirm-email.check-junk-folder")}</p>
            </div>
          </>
        )}
      </div>
      <FeedbackWidget />
    </Wrapper>
  );
}
