import { useState, useCallback, useEffect } from "react";
import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { ReactComponent as PublishIcon } from "../assets/icons/Publish.svg";
import { Button } from "./Button";
import { ModalPublish } from "../../modals/ModalPublish";
import { useStorage } from "../contexts/StorageContext";
import { db } from "../db";
import { liveQuery } from "dexie";

export function PublishButton() {
  const { t } = useTranslation();
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [enabled, setEnabled] = useState<boolean>(false);
  const {
    video,
    api: { getLastEvent },
  } = useStorage();

  const openModal = useCallback(() => {
    if (video) setModalIsOpen(true);
  }, [video]);
  const closeModal = useCallback(() => setModalIsOpen(false), []);

  useEffect(() => {
    const getIsEnabled = async () => {
      const lastEvent = await getLastEvent();
      const isEnabled = !!lastEvent;

      if (isEnabled) {
        setEnabled(isEnabled);

        if (subscription) {
          subscription.unsubscribe();
        }
      }
    };
    const videoUpdatesObservable = liveQuery(() =>
      db.video_state_updates.where("video_id").equals(video!.uuid).toArray()
    );

    const subscription = videoUpdatesObservable.subscribe(async (updates) => {
      getIsEnabled();
    });

    getIsEnabled();

    return () => {
      subscription.unsubscribe();
    };
  }, [getLastEvent, video]);

  return (
    <div>
      <Button
        css={css`
          background-color: #252424;
          color: #fff;
          margin-left: 18px;
        `}
        icon={<PublishIcon />}
        onClick={openModal}
        disabled={!enabled}
      >
        {t("video-editor.status-bar.publish")}
      </Button>

      <ModalPublish isOpen={modalIsOpen} onClose={closeModal} />
    </div>
  );
}
