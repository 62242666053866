import { notAuthorised } from "../../request";

export async function getWaitlistPosition(ref: string) {
  const API = notAuthorised;

  return await API.request<{
    order: number;
  }>({
    method: "GET",
    url: `/api/studio/join-waitlist/?inviter_code=${ref}`,
  });
}
