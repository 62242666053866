import ReactContentEditable from "react-contenteditable";

export function ContentEditable(props: {
  html: string;
  onChange: (event: any) => void;
  disabled?: boolean;
  style?: React.CSSProperties;
  tagName?: string;
}) {
  return (
    <ReactContentEditable
      style={props.style}
      tagName={props.tagName}
      disabled={props.disabled}
      html={props.html}
      onChange={props.onChange}
      onPaste={(e) => {
        e.preventDefault();
        const text = e.clipboardData.getData("text/plain");
        document.execCommand("insertHTML", false, text);
      }}
    />
  );
}
