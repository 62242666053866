import {
  VideoElementAudio,
  VideoElementState,
} from "../../../VideoEditor/types/Video";
import { css } from "styled-components/macro";
import { useEffect, useRef, useState } from "react";
import { ReactComponent as AudioPlayIcon } from "../../../VideoEditor/assets/icons/AudioPlay.svg";
import { ReactComponent as AudioPauseIcon } from "../../../VideoEditor/assets/icons/AudioPause.svg";
import { CircularProgressBar } from "../../../VideoEditor/components/CircularProgressBar";
import { calculateProportionalSize } from "../../helpers/renderer";
import { usePlayer } from "../../contexts/PlayerContext";
import { usePlayback } from "../../../VideoEditor/contexts/PlaybackContext";
import { sendEvent } from "../../../helpers/tracking";

export function ElementAudioRenderer(props: {
  element: VideoElementAudio;
  state: VideoElementState;
}) {
  const ref = useRef<HTMLAudioElement>(null);
  const animationRef = useRef<any>(null);
  const [playing, setPlaying] = useState(false);
  const [currentProgress, setCurrentProgress] = useState(0);
  const { video, canvasSize } = usePlayer();
  const { currentFrame, playing: playbackPlaying, pause } = usePlayback();
  const audioFiles = currentFrame.filter((elem) => elem.type === "audio");
  const firstAudioId = audioFiles.sort((a, b) => {
    const isCloserToTheEdge =
      a.states[0].left * a.states[0].top > b.states[0].left * b.states[0].top;

    if (isCloserToTheEdge) {
      return 1;
    }
    return -1;
  })[0];

  useEffect(() => {
    const currentRef = ref.current;
    if (currentRef) {
      if (playing) currentRef.play();
      else if (playbackPlaying) {
        if (audioFiles.length > 1 && firstAudioId.id === props.element.id) {
          currentRef.play();
        } else if (audioFiles.length === 1) {
          currentRef.play();
        }
      } else {
        currentRef.pause();
      }
    }

    const updateDuration = () => {
      if (currentRef) {
        if (currentRef.ended) {
          setCurrentProgress(props.element.file.duration);
        } else {
          setCurrentProgress(currentRef.currentTime);
        }
      }

      animationRef.current = window.requestAnimationFrame(updateDuration);
    };

    const onEnded = () => {
      if (audioFiles.length > 1) {
        pause();
      }
      setCurrentProgress(props.element.file.duration);
      setPlaying(false);
    };

    if (playing) {
      animationRef.current = window.requestAnimationFrame(updateDuration);
    }

    if (currentRef) {
      currentRef.addEventListener("ended", onEnded);
    }

    return () => {
      if (currentRef) {
        currentRef.removeEventListener("ended", onEnded);
      }

      window.cancelAnimationFrame(animationRef.current);
    };
  }, [
    playing,
    props.element.file.duration,
    playbackPlaying,
    audioFiles.length,
    firstAudioId.id,
    pause,
    props.element.id,
  ]);

  return (
    <div
      css={css`
        width: 100%;
        height: 100%;
        text-align: center;

        img {
          width: 100%;
          height: auto;
          user-select: none;
        }
      `}
    >
      <audio controls={false} ref={ref}>
        <source src={props.element.file.file} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
      <button
        css={css`
          position: relative;
          width: ${calculateProportionalSize(30, canvasSize.width)}px;
          height: ${calculateProportionalSize(30, canvasSize.width)}px;
          margin: 0 auto;
          border-radius: 50%;
          border: none;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          margin-top: 3px;
          border: 2px solid #ffffff;
          box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.55);
        `}
        onClick={() => {
          setPlaying(!playing);

          sendEvent("Interact with Themi", {
            themiId: video?.uuid,
            feature: props.element.type,
          });
        }}
      >
        <CircularProgressBar
          totalDuration={props.element.file.duration}
          progress={currentProgress}
          playing={playing}
          css={css`
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
          `}
        />
        {playing ? (
          <AudioPauseIcon width="33%" fill={"#e95b2e"} />
        ) : (
          <AudioPlayIcon width="40%" />
        )}
      </button>

      <h4
        style={{
          marginTop: calculateProportionalSize(3, canvasSize.width),
          color: "#FFFFFF",
          WebkitTextStrokeWidth: calculateProportionalSize(
            0.3,
            canvasSize.width
          ),
          WebkitTextStrokeColor: "#858585",
          fontSize: calculateProportionalSize(8, canvasSize.width),
          fontWeight: 700,
        }}
      >
        {props.element.title}
      </h4>
    </div>
  );
}
