import { UserMeta } from "../../types/UserProfile";

export enum CollaboratorRole {
  OWNER = "OWNER",
  EDITOR = "EDITOR",
  COMMENTER = "COMMENTER",
  VIEWER = "VIEWER",
}

export type Collaborator = {
  user: UserMeta;
  role: CollaboratorRole;
};
