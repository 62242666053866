import { css } from "styled-components/macro";
import { ReactComponent as PencilIcon } from "../assets/icons/Pencil.svg";
import { ReactComponent as SmallIcon } from "../assets/icons/Thickness_Small.svg";
import { ReactComponent as MediumIcon } from "../assets/icons/Thickness_Medium.svg";
import { ReactComponent as LargeIcon } from "../assets/icons/Thickness_Large.svg";
import { ColorAndVisibility } from "./ColorAndVisibility";
import { useEditor } from "../contexts/EditorContext";
import { Headers } from "../helpers/headers";
import { Button } from "./Button";
import { InlineButton } from "./InlineButton";
import { useTranslation } from "react-i18next";

export function DrawSidebar() {
  const { drawConfig, drawEnabled, setDrawConfig, setDrawEnabled } =
    useEditor();
  const { t } = useTranslation();
  return (
    <div>
      <div
        css={css`
          display: flex;
        `}
      >
        <PencilIcon
          css={css`
            align-self: center;
            color: #e95b2e;
          `}
        />
        <Headers.H3
          css={css`
            align-self: center;
            padding-left: 15px;
          `}
        >
          {t("draw.sidebar.title.draw")}
        </Headers.H3>
      </div>
      <div
        css={css`
          position: relative;
          display: flex;
          flex-wrap: wrap;
          min-width: 0;
          margin-top: 10px;
        `}
      >
        <InlineButton
          icon={<PencilIcon />}
          css={css`
            margin-bottom: 4px;
          `}
          onClick={() => {
            setDrawConfig("mode", "draw");
            setDrawEnabled(true);
          }}
          isActive={drawEnabled && drawConfig.mode === "draw"}
        >
          {t("draw.sidebar.draw.button.pencil")}
        </InlineButton>
      </div>
      <div
        css={css`
          margin-top: 20px;
          min-width: 0;
        `}
      >
        <Headers.H4>{t("draw.sidebar.title.thickness")}</Headers.H4>
        <div
          css={css`
            margin-top: 10px;
            display: flex;
          `}
        >
          <Button
            icon={<SmallIcon />}
            css={css`
              background-color: transparent;
              color: ${drawConfig.lineWidth === 10 ? "#ff812f" : "#252424"};
              padding: 0;
              min-width: 0;
              margin-right: 20px;
            `}
            onClick={() => {
              setDrawConfig("lineWidth", 10);
            }}
          ></Button>
          <Button
            icon={<MediumIcon />}
            css={css`
              background-color: transparent;
              color: ${drawConfig.lineWidth === 17 ? "#ff812f" : "#252424"};
              padding: 0;
              min-width: 0;
              margin-right: 20px;
            `}
            onClick={() => {
              setDrawConfig("lineWidth", 17);
            }}
          ></Button>
          <Button
            icon={<LargeIcon />}
            css={css`
              background-color: transparent;
              color: ${drawConfig.lineWidth === 28 ? "#ff812f" : "#252424"};
              padding: 0;
              min-width: 0;
            `}
            onClick={() => {
              setDrawConfig("lineWidth", 28);
            }}
          ></Button>
        </div>
        <hr
          css={css`
            background-color: #d9d9d9;
            height: 1px;
            border: 0;
            margin: 25px 0 10px 0 !important;
          `}
        />
        <ColorAndVisibility
          title={t("draw.sidebar.title.color")}
          fillColor={drawConfig.color}
          opacityValue={drawConfig.opacity}
          colorChangeHandler={(value: string) => {
            setDrawConfig("color", value);
          }}
          opacityChangeHandler={(value: string) => {
            setDrawConfig("opacity", Number(value));
          }}
          css={css`
            margin-top: 30px;
          `}
        />
      </div>
    </div>
  );
}
