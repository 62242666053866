import React from "react";
import { Field, FieldProps } from "formik";
import { css } from "styled-components/macro";
import { CheckboxComponent } from "./CheckboxComponent";
import { InputErrors } from "./InputErrors";

export function CheckboxField(props: {
  name: string;
  id?: string;
  label?: string;
  labelRight?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  value?: boolean;
  className?: string;
  validate?: (value: string) => Promise<void | string>;
  children?: React.ReactNode;
}) {
  const { validate, ...other } = props;
  return (
    <Field type="checkbox" name={props.name} validate={validate}>
      {({ field, meta }: FieldProps) => {
        return (
          <CheckboxComponent {...other} field={field}>
            {props.children}
            <InputErrors
              error={meta.error}
              touched={meta.touched}
              css={css`
                top: 0;
                transform: none;
              `}
            />
          </CheckboxComponent>
        );
      }}
    </Field>
  );
}
