import React from "react";
import { css } from "styled-components/macro";
import {
  VideoElementResizeDirection,
  VideoElementRotatePosition,
  VideoElementState,
} from "../../types/Video";

import { ElementRotator } from "./ElementRotator";
import { ElementResizer } from "./ElementResizer";

const Dot = css`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #a47aff;
  border: 1px solid #000;
`;

type ElementFrameProps = {
  state: VideoElementState;
  resizeHandler: (
    e: React.MouseEvent,
    direction: VideoElementResizeDirection
  ) => void;
  rotateHandler: (
    e: React.MouseEvent,
    position: VideoElementRotatePosition
  ) => void;
};

export function ElementFrame(props: ElementFrameProps) {
  return (
    <>
      <ElementResizer
        css={css`
          top: -4px;
          left: -4px;
          ${Dot};
        `}
        initialDirection="nw"
        resizeHandler={props.resizeHandler}
        rotation={props.state.rotation}
      />
      <ElementResizer
        css={css`
          top: -4px;
          right: -4px;
          ${Dot};
        `}
        initialDirection={"ne"}
        resizeHandler={props.resizeHandler}
        rotation={props.state.rotation}
      />

      <ElementResizer
        css={css`
          bottom: -4px;
          left: -4px;
          ${Dot};
        `}
        initialDirection={"sw"}
        resizeHandler={props.resizeHandler}
        rotation={props.state.rotation}
      />
      <ElementResizer
        css={css`
          bottom: -4px;
          right: -4px;
          ${Dot};
        `}
        initialDirection={"se"}
        resizeHandler={props.resizeHandler}
        rotation={props.state.rotation}
      />
      <ElementResizer
        css={css`
          top: -4px;
          left: 4px;
          width: calc(100% - 8px);
          height: 8px;
        `}
        initialDirection="n"
        resizeHandler={props.resizeHandler}
        rotation={props.state.rotation}
      />
      <ElementResizer
        css={css`
          bottom: -4px;
          left: 4px;
          width: calc(100% - 8px);
          height: 8px;
        `}
        initialDirection="s"
        resizeHandler={props.resizeHandler}
        rotation={props.state.rotation}
      />
      <ElementResizer
        css={css`
          bottom: 4px;
          left: -4px;
          height: calc(100% - 8px);
          width: 8px;
        `}
        initialDirection="w"
        resizeHandler={props.resizeHandler}
        rotation={props.state.rotation}
      />
      <ElementResizer
        css={css`
          bottom: 4px;
          right: -4px;
          height: calc(100% - 8px);
          width: 8px;
        `}
        initialDirection="e"
        resizeHandler={props.resizeHandler}
        rotation={props.state.rotation}
      />
      <ElementRotator
        css={css`
          top: -20px;
          left: -20px;
          transform: rotate(180deg);
        `}
        onMouseDown={(e) => props.rotateHandler(e, "nw")}
      />
    </>
  );
}
