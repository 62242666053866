import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "../components/Modal";
import { Button } from "../components/Button";

import { ReactComponent as CheckIcon } from "../VideoEditor/assets/icons/Check.svg";
import { theme } from "../themes/variables";

export function ModalAiSuccess(props: {
  isOpen: boolean;
  onClose: () => void;
}) {
  const { t } = useTranslation();

  return (
    <Modal
      modalIsOpen={props.isOpen}
      onClose={props.onClose}
      contentLabel={t("ai.modal.success.title")}
      contentStyles={{ maxWidth: "416px", padding: "20px 28px 21px 29px" }}
    >
      <ModalHeader
        css={css`
          font-size: 20px;
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <CheckIcon width={22} height={22} />
          <span
            css={css`
              margin-left: 17px;
            `}
          >
            {t("ai.modal.success.title")}
          </span>
        </div>
      </ModalHeader>
      <ModalBody
        css={css`
          padding: 3px 0;
          font-size: 15px;
          font-weight: 300;
          margin-left: 39px;
        `}
      >
        {t("ai.modal.success.description")}
      </ModalBody>
      <ModalFooter
        css={css`
          justify-content: center;
        `}
      >
        <Button
          onClick={props.onClose}
          css={css`
            background-color: ${theme.colors.primary};
            color: ${theme.colors.white};
            padding: 4px 30px;
            :hover {
              background: #ff812fd9
                radial-gradient(circle, transparent 1%, #ff812fd9 1%)
                center/15000%;
            }
            :active {
              background-color: ${theme.colors.primaryLighter};
              background-size: 100%;
              transition: background 0s;
            }
          `}
          hoverStyles={"none"}
        >
          {t("tooltip_publish.done")}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
