import { css } from "styled-components/macro";
import { usePlayer } from "../contexts/PlayerContext";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { ReactComponent as CommentModeIcon } from "../assets/icons/CommentIcon.svg";
import { ReactComponent as LeftIcon } from "../assets/icons/LeftIcon.svg";
import { Button } from "../../VideoEditor/components/Button";
import { useRef } from "react";
import { CommentsBox } from "./Comments";
import { useViewerComments } from "../contexts/ViewerCommentsContext";
import { usePlayback } from "../../VideoEditor/contexts/PlaybackContext";

type PlayerHeaderProps = {
  preview?: boolean;
  disableActions?: boolean;
};

export function PlayerHeader(props: PlayerHeaderProps) {
  const ref = useRef(null);

  const { commentsThreadOpen, toggleCommentsThread } = useViewerComments();
  const { video, showControls } = usePlayer();
  const { pause } = usePlayback();
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div
      css={css`
        position: absolute;
        padding: 40px 60px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        left: 0;
        top: -110px;
        margin: auto;
        transition: all 1s ease-in-out;
        opacity: 0;
        background: linear-gradient(
          180deg,
          rgba(40, 40, 40, 0.3) 7.35%,
          rgba(40, 40, 40, 0) 100%
        );
        z-index: 1000;
        pointer-events: none;

        ${showControls &&
        css`
          top: 0;
          transition: all 0.5s ease-in-out;
          opacity: 1;
        `}
      `}
    >
      <button
        css={css`
          display: flex;
          align-items: center;
          background: none;
          border: none;
          cursor: pointer;
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 28px;
          text-align: center;
          color: #ffffff;
          text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
          pointer-events: all;
        `}
        onClick={() => {
          const isIframe = window.location !== window.parent.location;

          if (props.preview && video) {
            history.push(`/video-editor/${video.uuid}`);
          } else if (isIframe) {
            window.open(
              `${process.env.REACT_APP_FRONTEND_BASE_URL}/`,
              "_blank"
            );
          } else {
            history.push("/");
          }
        }}
      >
        <LeftIcon />
        <span
          css={css`
            text-decoration: underline;
            position: relative;
            margin-left: 5px;
          `}
        >
          {props.preview ? t("video-player.back") : t("video-player.dashboard")}
        </span>
      </button>
      <div
        ref={ref}
        css={css`
          position: relative;
          pointer-events: all;
        `}
      >
        <Button
          icon={<CommentModeIcon />}
          css={css`
            min-width: 2em;
            width: 44px;
            background: transparent;

            ${props.disableActions &&
            css`
              cursor: not-allowed;
            `}
          `}
          onClick={() => {
            toggleCommentsThread();
            pause();
          }}
        />
        {commentsThreadOpen && <CommentsBox />}
      </div>
    </div>
  );
}
