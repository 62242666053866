// import { Button } from "../components/Button";
import {
  Modal,
  ModalBody,
  // ModalFooter,
  ModalHeader,
} from "../components/Modal";
import { css } from "styled-components/macro";
import Themoji from "../assets/img/themoji-noaccess.jpg";
import { Headers } from "../helpers/layout";
import { useTranslation } from "react-i18next";

export function ModalNoAccess(props: { isOpen: boolean; onClose: () => void }) {
  const { t } = useTranslation();
  return (
    <Modal
      modalIsOpen={props.isOpen}
      onClose={props.onClose}
      contentStyles={{
        maxWidth: "422px",
        padding: "22px 15px 15px 30px",
        border: "2px solid black",
      }}
    >
      <ModalHeader
        closeIcon
        onClose={props.onClose}
        css={css`
          justify-content: center;
          align-items: flex-start;
          height: 85px;
          width: 100%;
          button {
            position: absolute;
            top: 14px;
            right: 15px;
          }
        `}
      >
        <img
          src={Themoji}
          alt="no-access"
          css={css`
            width: 100%;
            height: 85px;
            object-fit: cover;
          `}
        />
      </ModalHeader>
      <ModalBody
        css={css`
          justify-content: center;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 5px 0;
        `}
      >
        <Headers.H2
          css={css`
            font-weight: 700;
            font-size: 20px;
            line-height: 28px;
          `}
        >
          {t("no-access.title")}
        </Headers.H2>
        <Headers.H4
          css={css`
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
          `}
        >
          {t("no-access.description")}
        </Headers.H4>
      </ModalBody>
      {/* <ModalFooter
        css={css`
          justify-content: center;
        `}
      >
        <Button
          css={css`
            background-color: #e95b2e;
            font-weight: 700;
            font-size: 16px;
          `}
          hoverStyles={`background:#FE521C`}
          onClick={() => {}}
        >
          {t("no-access.request")}
        </Button>
      </ModalFooter> */}
    </Modal>
  );
}
