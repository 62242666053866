import React, { useCallback, useEffect } from "react";
import { debounce } from "lodash";
import { css } from "styled-components/macro";
import { VideoElementState, VideoElementText } from "../../types/Video";
import { useStorage } from "../../contexts/StorageContext";
import { useEditor } from "../../contexts/EditorContext";
import { useClickOutside } from "../../hooks/useClickOutside";
import { RichTextEditor } from "../../components/RichTextEditor";
import { convertToRichText } from "../../helpers/video";

export function ElementTextRenderer(props: {
  element: VideoElementText;
  state: VideoElementState;
  onChange: (height: number) => void;
}) {
  const { api } = useStorage();
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const passedText = convertToRichText(props.element).text;

  const editableElement = React.useRef<HTMLDivElement | null>(
    (wrapperRef.current?.querySelector(
      "[contenteditable]"
    ) as HTMLDivElement) || null
  );

  const { activeElementId } = useEditor();
  const clickPosition = React.useRef({ x: 0, y: 0 });
  const [editable, setEditable] = React.useState(false);
  const editableRef = React.useRef(editable);
  const [text, setText] = React.useState(passedText);

  useEffect(() => {
    if (wrapperRef.current) {
      // @ts-ignore
      wrapperRef.current.api = {
        setEditable: (value: boolean) => {
          setEditable(value);
        },
      };
    }
  }, []);

  useEffect(() => {
    editableRef.current = editable;
  }, [editable]);

  useEffect(() => {
    if (!editableRef.current) {
      setText(passedText);
    }
  }, [passedText]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateElement = useCallback(
    debounce(async (elementId: string, text: string) => {
      await api.updateElement(elementId, {
        text: text,
        config: {},
      });
    }, 1000),
    [api]
  );

  const handleContentEditable = (content: any) => {
    if (content === text) return;

    setText(content);
    updateElement(props.element.id, content);

    if (!editableElement.current) {
      editableElement.current = wrapperRef.current?.querySelector(
        "[contenteditable]"
      ) as HTMLDivElement;
    }

    const heightOfElement = editableElement.current.clientHeight;
    props.onChange(heightOfElement);
  };

  useClickOutside(wrapperRef, () => {
    setEditable(false);
    editableElement.current = null;

    // clear selection
    const selection = window.getSelection();
    if (selection) {
      selection.removeAllRanges();
    }
  });

  useEffect(() => {
    if (editable) {
      const el = wrapperRef.current?.querySelector(
        "[contenteditable]"
      ) as HTMLDivElement;
      if (el) {
        const range = document.createRange();
        const selection = window.getSelection();

        range.selectNodeContents(el);
        range.collapse(false);

        if (selection) {
          selection.removeAllRanges();
          selection.addRange(range);
        }
      }
    }
  }, [editable]);

  return (
    <div
      ref={wrapperRef}
      id={`text-element-${props.element.id}`}
      onMouseDown={(e) => {
        clickPosition.current = {
          x: e.clientX,
          y: e.clientY,
        };
      }}
      onDoubleClick={(e) => {
        e.stopPropagation();
        setEditable(true);
      }}
      onMouseUp={(e) => {
        if (props.element.id !== activeElementId || editable) return;

        if (
          Math.abs(clickPosition.current.x - e.clientX) < 5 &&
          Math.abs(clickPosition.current.y - e.clientY) < 5
        ) {
          setEditable(true);
        }
      }}
      css={css`
        height: 100%;

        li {
          margin-left: 1.2em;
        }

        ${!editable &&
        css`
          user-select: "none";
          -moz-user-select: "none";
          -ms-user-select: "none";

          a {
            pointer-events: none;
          }
        `}
      `}
    >
      <RichTextEditor
        disabled={!editable}
        html={text}
        onChange={handleContentEditable}
        id={`editor-${props.element.id}`}
      />
    </div>
  );
}
