import React, { Suspense, useEffect } from "react";
import { css } from "styled-components/macro";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { UserProvider } from "./contexts/UserContext";
import { CheckVersionUpdate } from "./components/CheckVersionUpdate";
import { EnterpriseProvider } from "./contexts/EnterpriseContext";
import { ThemiRouter } from "./ThemiRouter";
import { sendEvent } from "./helpers/tracking";

function App() {
  useEffect(() => {
    sendEvent("Session Started");
  }, []);

  return (
    <Suspense fallback="loading">
      <EnterpriseProvider>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={true}
          pauseOnHover={false}
          css={css`
            width: auto;
            svg {
              color: #242424;
            }
          `}
        />

        <UserProvider>
          <ThemiRouter />
        </UserProvider>
        <CheckVersionUpdate />
      </EnterpriseProvider>
    </Suspense>
  );
}

export default App;
