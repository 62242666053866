import React from "react";
import { css } from "styled-components/macro";
import { theme } from "../themes/variables";

export type TabProps = {
  name: string /* need to specify to control tabs outside */;
  label?: string | number | null;
  icon?: React.ReactNode;
};

/***
 * tabs: list of the tabs
 * activeTab: current active tab. Just need to control tabs outside
 * onClick: function to control if tab was clicked
 * align: if set, tab will align at chosen position. If not defined, tabs will take all width possible
 * activeColor: color for tab label
 * indicatorColor: color for tab underline
 * narrowIndicator: if set, underline line will take only content space, but not all available
 * inlineLabel: if set, icon with text would be inline
 * className: css properties
 ***/

export type TabsProps = {
  tabs: TabProps[];
  activeTab: string;
  onClick: (tabName: string) => void;
  align?: "left" | "center" | "right";
  activeColor?: string;
  indicatorColor?: string;
  narrowIndicator?: boolean;
  inlineLabel?: boolean;
  className?: string;
};

export function Tabs(props: TabsProps) {
  const {
    tabs,
    activeTab,
    onClick,
    align = "center",
    activeColor = theme.colors.primary,
    indicatorColor = theme.colors.primary,
    narrowIndicator = false,
    inlineLabel,
    className,
  } = props;

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        justify-content: ${align || "center"};
        flex-wrap: nowrap;
        width: 100%;
        background: ${theme.colors.white};
        max-width: 1280px;
      `}
      className={className}
    >
      {tabs.map((tab, i) => {
        return (
          <div
            key={i}
            onClick={() => onClick(tab.name)}
            css={css`
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;
              ${!props.align &&
              css`
                flex: 1 1 auto;
              `}
              text-align: center;
              font-size: 16px;
              font-weight: 600;
              padding: 0 28px;
              color: ${activeTab === tab.name ? activeColor : "#252424"};
              position: relative;
              cursor: pointer;
              ${!narrowIndicator &&
              css`
                padding-bottom: 6px;
              `}
            `}
          >
            <div
              css={
                narrowIndicator &&
                css`
                  position: relative;
                  display: flex;
                  flex-wrap: wrap;
                  align-items: center;
                  justify-content: center;
                  flex-direction: ${inlineLabel ? "row" : "column"};
                  align-self: stretch;
                  padding-bottom: 6px;
                `
              }
            >
              {tab.label}
              {tab.icon}
              <div
                css={css`
                  user-select: none;
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  right: 0;
                  height: 2px;
                  background: ${indicatorColor};
                  opacity: ${activeTab === tab.name ? 1 : 0};
                  transition: all 400ms ease;
                `}
              ></div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
