import { css } from "styled-components/macro";
import { theme } from "../themes/variables";

export function InputLabel(props: { label?: string; description?: string }) {
  if (!props.label) return null;
  let label = props.label?.trim();

  if (label && label[label.length - 1] === ":") {
    label = label.slice(0, label.length - 1);
  }

  return (
    <div
      css={css`
        margin-bottom: 6px;
      `}
    >
      <div
        css={css`
          font-size: 16px;
          font-weight: 400;
        `}
      >
        {label}
      </div>
      {props.description && (
        <div
          css={css`
            font-size: 16px;
            color: ${theme.colors.gray5};
          `}
        >
          {props.description}
        </div>
      )}
    </div>
  );
}
