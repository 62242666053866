import { authorised } from "../../request";
import {
  Video,
  PublishedVideo,
  VideoPublishStatus,
} from "../../VideoEditor/types/Video";
import { CollaboratorRole } from "../../VideoEditor/types/Collaborator";

export async function getVideo(videoId: string): Promise<Video> {
  const API = authorised();

  const response = await API.request<PublishedVideo>({
    method: "GET",
    url: `/api/studio/themis/${videoId}/published/`,
  });

  return {
    name: response.data.name,
    uuid: videoId,
    schema: {
      schema: response.data.schema,
    },
    publish_date: response.data.publish_date,
    archived_at: null,
    publish_status: VideoPublishStatus.PUBLISHED,
    collaborators: [],
    current_user_role: CollaboratorRole.OWNER,
  };
}
