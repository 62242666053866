import { isEqual } from "lodash";
import React, { useEffect } from "react";
import { calculateProportionalSize } from "../../VideoPlayer/helpers/renderer";
import { useComments } from "../contexts/CommentsContext";
import { useEditor } from "../contexts/EditorContext";
import { Comment } from "../../types/Comment";
import { CommentBox, CommentMarker } from "../components/Comments";

function CommentRendererPure(props: { comments: Comment[] }) {
  const [extended, setExtended] = React.useState<boolean>(false);
  const { canvasSize } = useEditor();
  const { expandedComment } = useComments();
  const firstComment = props.comments[0];

  useEffect(() => {
    if (props.comments.find((comment) => comment.id === expandedComment))
      setExtended(true);
  }, [props.comments, expandedComment]);

  if (!firstComment) {
    return null;
  }

  return (
    <div
      style={{
        position: "absolute",
        top: calculateProportionalSize(firstComment.top, canvasSize.width),
        left: calculateProportionalSize(firstComment.left, canvasSize.width),
      }}
    >
      {extended ? (
        <CommentBox comments={props.comments} setExtended={setExtended} />
      ) : (
        <CommentMarker comments={props.comments} setExtended={setExtended} />
      )}
    </div>
  );
}

export const CommentRenderer = React.memo(
  CommentRendererPure,
  (oldProps, newProps) => {
    const is = isEqual(oldProps, newProps);

    return is;
  }
);
