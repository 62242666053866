import { cloneDeep, debounce } from "lodash";
import React, { useCallback, useEffect } from "react";
import { useAuth } from "../../contexts/UserContext";
import { ChangeActionType, db } from "../db";
import { Image } from "../types/Image";
import { pusher } from "../helpers/pusher";

type UserPosition = {
  user: {
    id: number;
    name: string;
    avatar?: Image | null;
  };
  x: number;
  y: number;
  time: number;
};

type MouseEvent = UserPosition & {
  type: "mouse-position";
};

type ChangeEvent = {
  type: "change";
  video_id: string;
  element_id?: string | null;
  content?: any;
  action: ChangeActionType;
  user_id: number;
  update_group_id?: string | null;
  remote_id: number;
  event_id: string;
};

export type UserMinimum = {
  id: number;
  avatar: Image | null;
  last_name: string;
  first_name: string;
};

type Members = {
  members: {
    [key: string]: UserMinimum;
  };
  count: number;
  me: UserMinimum;
};

type Member = {
  info: UserMinimum;
  id: string;
};

const CollaborationContext = React.createContext<{
  userPositions: Array<UserPosition>;
  setPosition: (currentTime: number, x: number, y: number) => void;
  members: UserMinimum[];
}>({
  userPositions: [],
  setPosition: () => {},
  members: [],
});

export function CollaborationProvider(props: {
  children: React.ReactNode;
  videoId: string;
}) {
  const [userPositions, setUserPositions] = React.useState<UserPosition[]>([]);
  const [members, setMembers] = React.useState<UserMinimum[]>([]);
  const { userprofile } = useAuth();

  useEffect(() => {
    const channel = pusher.subscribe(`presence-video--${props.videoId}`);

    channel.bind("client-mouse-position", function (data: MouseEvent) {
      setUserPositions((userPositions) => {
        const positions = cloneDeep(userPositions);
        const userPositionIndex = positions.findIndex(
          (p) => p.user.id === data.user.id
        );

        if (userPositionIndex !== -1) {
          if (data.x === 0 && data.y === 0 && data.time === 0) {
            positions.splice(userPositionIndex, 1);
          } else {
            positions[userPositionIndex] = data;
          }

          return positions;
        }

        return [...positions, data];
      });
    });

    channel.bind("video-change", async function (data: ChangeEvent) {
      const change = await db.video_state_updates.get({
        event_id: data.event_id,
      });

      if (!change) {
        db.video_state_updates.add({
          event_id: data.event_id,
          update_group_id: data.update_group_id || null,
          video_id: data.video_id,
          timestamp: Date.now(),
          user_id: data.user_id,
          action: data.action,
          element_id: data.element_id,
          content: data.content,
          remote_id: data.remote_id,
        });
      }
    });

    channel.bind("pusher:subscription_succeeded", (members: Members) => {
      setMembers(Object.values(members.members));
    });

    channel.bind("pusher:member_added", (member: Member) => {
      setMembers((members) => {
        if (members.find((m) => m.id === member.info.id)) {
          return members;
        }

        return [...members, member.info];
      });
    });

    channel.bind("pusher:member_removed", (member: Member) => {
      setMembers((members) => {
        return members.filter((m) => m.id !== member.info.id);
      });
      setUserPositions((userPositions) => {
        return userPositions.filter((m) => m.user.id !== member.info.id);
      });
    });
  }, [props.videoId]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setPosition = useCallback(
    debounce((currentTime, x, y) => {
      const channel = pusher.subscribe(`presence-video--${props.videoId}`);

      const state: MouseEvent = {
        type: "mouse-position",
        time: currentTime,
        user: {
          id: userprofile!.id,
          name: `${userprofile!.first_name} ${userprofile!.last_name}`,
          avatar: userprofile?.avatar,
        },
        x,
        y,
      };

      channel.trigger("client-mouse-position", state);
    }, 200),
    []
  );

  return (
    <CollaborationContext.Provider
      value={{
        userPositions,
        setPosition,
        members,
      }}
    >
      {props.children}
    </CollaborationContext.Provider>
  );
}

export function useCollaboration() {
  const context = React.useContext(CollaborationContext);

  if (context === undefined) {
    throw new Error(
      "useCollaboration must be used within a CollaborationProvider"
    );
  }

  return context;
}
