import { VideoElementArrow, VideoElementState } from "../../types/Video";
import { css } from "styled-components/macro";
import { calculateProportionalSize } from "../../../VideoPlayer/helpers/renderer";
import { useEditor } from "../../contexts/EditorContext";

export function ElementArrowRenderer(props: {
  element: VideoElementArrow;
  state: VideoElementState;
}) {
  const { config } = props.element;
  const { canvasSize } = useEditor();
  const strokeSize = calculateProportionalSize(
    config.strokeSize,
    canvasSize.width
  );
  const arrowSize = strokeSize * 2;
  const width = props.state.width;
  const height = props.state.height;

  return (
    <div
      css={css`
        width: 100%;
        height: 100%;

        svg {
          width: 100%;
          height: 100%;
        }
      `}
    >
      {props.element.type === "arrow-1" && (
        <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
          <line
            x1="0"
            y1={height / 2}
            x2={width - strokeSize}
            y2={height / 2}
            stroke={config.strokeColor}
            strokeWidth={config.strokeSize}
            strokeOpacity={config.strokeOpacity / 100}
          />
          <line
            x1={width - arrowSize - strokeSize}
            y1={height / 2 - arrowSize}
            x2={width - strokeSize}
            y2={height / 2 + strokeSize / 6}
            stroke={config.strokeColor}
            strokeWidth={config.strokeSize}
            strokeOpacity={config.strokeOpacity / 100}
          />
          <line
            x1={width - arrowSize - strokeSize}
            y1={height / 2 + arrowSize}
            x2={width - strokeSize}
            y2={height / 2 - strokeSize / 6}
            stroke={config.strokeColor}
            strokeWidth={config.strokeSize}
            strokeOpacity={config.strokeOpacity / 100}
          />
        </svg>
      )}
      {props.element.type === "arrow-2" && (
        <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
          <line
            x1="0"
            y1={height / 2}
            x2={width - strokeSize}
            y2={height / 2}
            stroke={config.strokeColor}
            strokeWidth={config.strokeSize}
            strokeOpacity={config.strokeOpacity / 100}
          />
          <polygon
            points={`
            ${width - arrowSize},${height / 2 - arrowSize}
            ${width - arrowSize},${height / 2 + arrowSize}
            ${width},${height / 2}
          `}
            fill={config.fillColor}
            fillOpacity={config.fillOpacity / 100}
          />
        </svg>
      )}
      {props.element.type === "arrow-3" && (
        <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
          <line
            x1="0"
            y1={height / 2}
            x2={width - strokeSize}
            y2={height / 2}
            stroke={config.strokeColor}
            strokeWidth={config.strokeSize}
            strokeOpacity={config.strokeOpacity / 100}
          />
          <circle
            cx={width - arrowSize}
            cy={height / 2}
            r={arrowSize}
            fill={config.fillColor}
            fillOpacity={config.fillOpacity / 100}
          />
        </svg>
      )}
    </div>
  );
}
