import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { LoginPage } from "./pages/Auth/Login";
import { RegistrationPage } from "./pages/Auth/Registration";
import { VerificationInfoPage } from "./pages/Auth/VerificationInfo";
import { ResetPasswordPage } from "./pages/Auth/ResetPassword";
import { SetPasswordPage } from "./pages/Auth/SetPassword";
import { PrivateRoute, PublicRoute } from "./helpers/router";
import { ConfirmEmailPage } from "./pages/Auth/ConfirmEmail";
import { AccountPage } from "./pages/Account/Account";
import { DashboardPageMiddleware } from "./pages/Dashboard";
import { VideoEditor } from "./VideoEditor/Editor";
import { MyThemiDashboardPage } from "./pages/MyThemiDashboard";
import { VideosPage } from "./pages/VideosPage";
import { MobileFallbackPage } from "./pages/mobileFallbackPage";
import { useCheckMobile } from "./hooks/useCheckMobile";
import { TermsAndConditions } from "./pages/TermsAndConditions";
import { WaitlistPosition } from "./pages/WaitlistPosition";
import { FaqPage } from "./pages/Faq";
import { EmbedPreview } from "./VideoEditor/EmbedPreview";
import { ThemiPreview } from "./VideoEditor/ThemiPreview";
import RouterWrapper from "./helpers/RouterWrapper";
import { VideoPreview } from "./VideoEditor/VideoPreview";

export const ThemiRouter = () => {
  const { isMobile } = useCheckMobile();

  return (
    <Router>
      <Switch>
        <RouterWrapper>
          <Route path="/" exact component={DashboardPageMiddleware} />
          <Route path="/waitlist" exact component={WaitlistPosition} />
          <Route
            path="/terms-and-conditions"
            exact
            component={TermsAndConditions}
          />
          <Route path="/faq" exact component={FaqPage} />
          <PublicRoute path="/login" exact component={LoginPage} />
          <PublicRoute path="/register" exact component={RegistrationPage} />
          <PublicRoute
            path="/verification-info"
            exact
            component={VerificationInfoPage}
          />
          <PublicRoute
            path="/confirm-email"
            exact
            component={ConfirmEmailPage}
          />
          <PublicRoute
            path="/reset-password"
            exact
            component={ResetPasswordPage}
          />
          <PublicRoute
            path="/reset-password/:uuid/"
            exact
            component={SetPasswordPage}
          />
          <PrivateRoute path="/embed/:videoId" exact component={EmbedPreview} />
          <PrivateRoute path="/video/:videoId" exact component={VideoPreview} />

          {isMobile ? (
            <MobileFallbackPage />
          ) : (
            <>
              <PrivateRoute
                path="/themi/:status/"
                exact
                component={VideosPage}
              />
              <PrivateRoute
                path="/themi"
                exact
                component={MyThemiDashboardPage}
              />

              <PrivateRoute path="/account/me" exact component={AccountPage} />
              <PrivateRoute
                path="/video-editor/:videoId"
                exact
                component={VideoEditor}
              />
              <PrivateRoute
                path="/video-preview/:videoId"
                exact
                component={ThemiPreview}
              />
            </>
          )}
        </RouterWrapper>
      </Switch>
    </Router>
  );
};
