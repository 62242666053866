import { Footer } from "../components/Landing/Footer";
import { Demo } from "../components/Landing/Demo";
import { Header } from "../components/Landing/Header";
import { Hero } from "../components/Landing/Hero";
import { Points } from "../components/Landing/Points";
import { ComingSoon } from "../components/Landing/ComingSoon";

import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  body {
    background: #f9efe0;
  }

  h2 {
    font-size: 47px;
    font-weight: 200;
    line-height: 1.08;
    margin-bottom: 0.5em;
    letter-spacing: -0.03em;
  }

  h4 {
    font-size: 18px;
    margin-bottom: 1em;
    line-height: 1.3;
  }

  h3 {
    font-size: 35px;
    font-weight: 700;
    line-height: 1.3;
  }

  @media (max-width: 768px) {
    h2 {
      font-size: 35px;
    }

    h3 {
      font-size: 25px;
    }

    h4 {
      font-size: 16px;
    }
  }
`;

export function Landing() {
  return (
    <div>
      <GlobalStyle />
      <Header />
      <Hero />
      <Demo />
      <Points />
      <ComingSoon />
      <Footer />
    </div>
  );
}
