import { DateTime } from "luxon";
import { useEffect, useRef } from "react";
import { css } from "styled-components/macro";
import { usePlayback } from "../../VideoEditor/contexts/PlaybackContext";

export function Timeline() {
  const { currentTime, videoDuration, setCurrentTime, scenes } = usePlayback();
  const dragWrapperContainerRef = useRef<HTMLDivElement>(null);
  const dragProgressRef = useRef<HTMLDivElement>(null);
  const draggingRef = useRef<boolean>(false);
  const dragHandlerRef = useRef<HTMLDivElement>(null);
  const videoCovered = (currentTime / videoDuration) * 100;

  useEffect(() => {
    const onMouseMove = (e: MouseEvent) => {
      if (draggingRef.current && e.currentTarget && dragHandlerRef.current) {
        const x = e.clientX;
        const bounds = dragWrapperContainerRef.current!.getBoundingClientRect();
        const offset = ((x - bounds.left) / bounds.width) * 100;
        const newOffset = Math.min(Math.max(offset, 0), 100);

        dragHandlerRef.current.style.left = `${newOffset}%`;
        dragProgressRef.current!.style.width = `${newOffset}%`;
      }
    };

    const onMouseUp = (e: MouseEvent) => {
      if (draggingRef.current) {
        draggingRef.current = false;

        const left = Number(
          dragHandlerRef.current!.style.left.replace("%", "")
        );
        const time = (left / 100) * videoDuration;

        setCurrentTime(Math.max(Math.min(time, videoDuration), 0));
      }
    };

    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);

    return () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };
  }, [videoDuration, setCurrentTime]);

  const dragHandler = (e: React.MouseEvent) => {
    draggingRef.current = true;
  };

  return (
    <div
      css={css`
        position: relative;
        margin-left: 20px;
        height: 41px;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        flex: 1 0 10%;
        padding: 0 20px;
        background: #ffffff;
        border: 1px solid #d9d9d9;
        box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
      `}
    >
      <div
        css={css`
          flex: 0 0 auto;
          font-size: 16px;
          color: #e95b2e;
          margin-right: 15px;
        `}
      >
        {DateTime.fromSeconds(currentTime / 1000).toFormat("mm:ss")}
      </div>
      <div
        css={css`
          position: relative;
          flex: 1 0 10%;
          background-color: #fae7d9;
          height: 7px;
          border-radius: 3px;
          justify-content: center;
          align-self: center;
          cursor: pointer;
        `}
        ref={dragWrapperContainerRef}
        onClick={(e) => {
          const x = e.clientX;
          const bounds =
            dragWrapperContainerRef.current!.getBoundingClientRect();
          const offset = ((x - bounds.left) / bounds.width) * 100;
          const newOffset = Math.min(Math.max(offset, 0), 100);
          const time = (newOffset / 100) * videoDuration;

          setCurrentTime(Math.max(Math.min(time, videoDuration), 0));
        }}
      >
        {scenes.map((scene) => {
          return scene.elements.map((element) => {
            if (
              ["open-question", "poll", "user-tagging", "audio"].includes(
                element.type
              )
            ) {
              return (
                <div
                  key={element.id}
                  css={css`
                    position: absolute;
                    width: 4px;
                    height: 100%;
                    border-radius: 2px;
                    left: ${(scene.start_time / videoDuration) * 100}%;
                    transform: translate(-2px, 0);
                    top: 0;
                    background: #ee8f36;
                  `}
                ></div>
              );
            }
            return null;
          });
        })}
        <div
          css={css`
            height: 100%;
            background-color: #e95b2e;
            position: absolute;
            width: ${videoCovered}%;
            border-radius: 3px;
            cursor: pointer;
          `}
          style={{
            width: `${videoCovered}%`,
          }}
          ref={dragProgressRef}
          onClick={(e) => {
            const x = e.clientX;
            const bounds =
              dragWrapperContainerRef.current!.getBoundingClientRect();
            const offset = ((x - bounds.left) / bounds.width) * 100;
            const newOffset = Math.min(Math.max(offset, 0), 100);
            const time = (newOffset / 100) * videoDuration;

            setCurrentTime(Math.max(Math.min(time, videoDuration), 0));
          }}
        ></div>
        <div
          css={css`
            position: absolute;
            width: 9px;
            height: 9px;
            z-index: 2;
            border-radius: 50%;
            transform: translate(-4px, -2px);
            cursor: pointer;
          `}
          style={{
            left: `${videoCovered}%`,
          }}
          onMouseDown={dragHandler}
          ref={dragHandlerRef}
        ></div>
      </div>
      <div
        css={css`
          flex: 0 0 auto;

          font-size: 16px;
          color: #e95b2e;
          margin-left: 15px;
        `}
      >
        {DateTime.fromSeconds(videoDuration / 1000).toFormat("mm:ss")}
      </div>
    </div>
  );
}
