import { Link } from "react-router-dom";
import { css } from "styled-components/macro";
import { useCheckMobile } from "../../hooks/useCheckMobile";
import { Wrapper } from "./Wrapper";
import { useTranslation } from "react-i18next";
import linkedinIcon from "../../assets/icons/linkedin.svg";
import twitterIcon from "../../assets/icons/twitter.svg";

export function Footer() {
  const { isMobile } = useCheckMobile();
  const { t } = useTranslation();

  return (
    <footer
      css={css`
        position: relative;
        z-index: 2;
        background: #f9efe0;
        padding: 70px 0;

        ${isMobile &&
        css`
          text-align: center;
          padding: 40px 0;
        `}
      `}
    >
      <Wrapper>
        <div
          css={css`
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
          `}
        >
          <div
            css={css`
              flex: 0 0 370px;

              ${isMobile &&
              css`
                flex: 0 0 100%;
                margin-bottom: 40px;
              `}

              p {
                margin-bottom: 20px;
                font-size: 15px;
                line-height: 1.3;

                &:last-child {
                  margin-bottom: 0;
                }
              }
            `}
          >
            <h4>Themis</h4>
            <p
              css={css`
                font-size: 15px;
                font-weight: 400;
              `}
            >
              {t("about-us.description.first")}
            </p>
            <p
              css={css`
                font-size: 15px;
                font-weight: 400;
              `}
            >
              {t("about-us.description.second")}
            </p>
          </div>
          <div
            css={css`
              flex: 1 0 10%;
              display: flex;
              flex-flow: row wrap;
              justify-content: space-between;
              max-width: 300px;
              font-size: 20px;
              line-height: 1.1;
              letter-spacing: -0.04em;
              font-weight: 400;
              text-align: center;

              ${isMobile &&
              css`
                flex: 0 0 100%;
                max-width: none;
              `}

              ul {
                list-style: none;
                padding: 0;
                margin: 0;
              }

              li {
                margin-bottom: 20px;

                &:last-child {
                  margin-bottom: 0;
                }
              }

              h4 {
                margin: 0 0 20px 0;
                font-size: 20px;
              }
            `}
          >
            <div
              css={
                isMobile &&
                css`
                  flex: 0 0 100%;
                  margin-bottom: 40px;
                `
              }
            >
              <ul>
                {/* <li>
                  <a
                    href="https://themis.eu/pricing/"
                    target="_blank"
                    rel="noopener noreferrer"
                    css={css`
                      background: #fae7d9;
                      border: 1px solid #e95b2e;
                      border-radius: 5px;
                      padding: 2px 10px;
                      color: #e95b2e;
                    `}
                  >
                    {t("about-us.pricing")}
                  </a>
                </li> */}
                <li>
                  <a
                    href="https://themis.eu/impressum/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Impressum
                  </a>
                </li>
                <li>
                  <a
                    href="https://themis.eu/datenschutzerklarung/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Datenschutz
                  </a>
                </li>
                <li>
                  <Link to="/terms-and-conditions">AGB</Link>
                </li>
              </ul>
            </div>
            <div
              css={
                isMobile &&
                css`
                  flex: 0 0 100%;
                `
              }
            >
              <ul>
                <li>
                  <a
                    href="https://www.linkedin.com/company/themis-digital/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={linkedinIcon} alt="LinkedIn" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/themis_digital"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={twitterIcon} alt="Twitter" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Wrapper>
    </footer>
  );
}
