import { notAuthorised } from "../../request";
import { UserProfile } from "../../types/UserProfile";

export interface UserRegistrationData {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  password2: string;
}

export type FormValues = UserRegistrationData;

export async function registration(userData: FormValues) {
  const API = notAuthorised;

  return await API.request<{
    userprofile: UserProfile;
    token: string;
    is_verified: boolean;
    email_confirmed: boolean;
  }>({
    method: "POST",
    url: `/api/studio/registration/`,
    data: userData,
  });
}
