import { css } from "styled-components/macro";

export function RadioComponent(props: {
  value?: string;
  label?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isSelected?: boolean;
  className?: string;
  name?: string;
}) {
  return (
    <label className={props.className || ""}>
      <input
        type="radio"
        checked={props.isSelected}
        onChange={props.onChange}
        value={props.value}
        name={props.name}
        css={css`
          width: 12px;
          height: 12px;
          border-radius: 50%;
          cursor: pointer;
          border: 1px solid #ffc39c;
          transition: 0.2s all linear;
          margin-right: 10px;
          outline: none;
          appearance: none;
          :checked {
            background-color: #e95b2e;
            border: 1px solid #ffc39c;
          }
        `}
      />
      {props.label}
    </label>
  );
}
