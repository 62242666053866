import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "../components/Modal";
import { ShareVideoForm } from "../VideoEditor/forms/ShareVideoForm";
import { getCollaborators } from "../VideoEditor/actions/getCollaborators";
import { useStorage } from "../VideoEditor/contexts/StorageContext";
import { Avatar } from "../VideoEditor/components/Avatar";
import { ReactComponent as CopyIcon } from "../assets/icons/CopyLink.svg";
import { Button } from "../components/Button";
import { SelectComponent } from "../components/SelectComponent";
import { updateCollaborator } from "../VideoEditor/actions/updateCollaborator";
import { updateRoleForAnyone } from "../VideoEditor/actions/updateRoleForAnyone";
import { useNewFetch } from "../useAPI";
import { UserProfile } from "../types/UserProfile";
import {
  Collaborator,
  CollaboratorRole,
} from "../VideoEditor/types/Collaborator";
import { Headers } from "../VideoEditor/helpers/headers";
import { removeCollaborator } from "../VideoEditor/actions/removeCollaborator";
import { customToast } from "../components/customToast";
import { serverErrorHandler } from "../helpers/serverErrorHandler";
import { sendEvent } from "../helpers/tracking";

export type RoleOptionType = { value: string; label: string };

export function ModalShare(props: {
  isOpen: boolean;
  onClose: () => void;
  userData: UserProfile | null;
}) {
  const { video } = useStorage();
  const { t } = useTranslation();
  const text = t("collab-invite.link.copy");
  const [buttonText, setButtonText] = useState(text);
  const { data: collaborators, refetch: refetchCollaborators } = useNewFetch(
    getCollaborators,
    { videoId: video!.uuid }
  );

  const linkAccessChoices: { value: string; label: string }[] = [
    {
      value: "NO_ACCESS",
      label: "no-access",
    },
    {
      value: "EDIT",
      label: "can edit",
    },
    {
      value: "VIEW",
      label: "can view",
    },
  ];

  function getRoleOptions(
    currentRole?: CollaboratorRole,
    userRole?: string
  ): any {
    if (currentRole === "OWNER") {
      return [
        {
          value: "OWNER",
          label: "collab-invite.role.owner",
        },
        {
          value: "EDITOR",
          label: "collab-invite.role.editor",
        },
        {
          value: "COMMENTER",
          label: "collab-invite.role.comment",
        },
        {
          value: "REMOVE",
          label: "collab-invite.remove",
        },
      ];
    } else if (currentRole === "EDITOR") {
      if (userRole === "EDITOR" || userRole === "COMMENTER") {
        return [
          {
            value: "EDITOR",
            label: "collab-invite.role.editor",
          },
          {
            value: "COMMENTER",
            label: "collab-invite.role.comment",
          },
          {
            value: "REMOVE",
            label: "collab-invite.remove",
          },
        ];
      } else {
        return [];
      }
    }
    return [];
  }

  return (
    <Modal
      modalIsOpen={props.isOpen}
      onClose={props.onClose}
      contentStyles={{
        maxWidth: "520px",
        minHeight: "230px",
        padding: "11px 10px 1px 20px",
      }}
    >
      <ModalHeader
        closeIcon
        onClose={props.onClose}
        css={css`
          display: flex;
          justify-content: flex-end;
        `}
      ></ModalHeader>
      <ModalBody
        css={css`
          padding: 3px 0;
          margin-top: 4px;
          margin-bottom: 10px;
          width: 100%;
        `}
      >
        <ShareVideoForm
          css={css`
            margin-bottom: 25px;
          `}
          onShare={async (values) => {
            await refetchCollaborators();

            if (video) {
              sendEvent("Invite Collaborator", {
                themiId: video.uuid,
                collaboratorRole: values.role,
              });
            }
          }}
        />

        {collaborators &&
          collaborators.map((user: Collaborator) => {
            const selectOptions = getRoleOptions(
              video?.current_user_role,
              user?.role
            );
            return (
              <div
                key={user.user.id}
                css={css`
                  display: flex;
                  justify-content: space-between;
                  margin: 10px 0;
                  :nth-child(4n-3) .avatar {
                    border: 2px solid #a47aff;
                  }
                  :nth-child(4n-2) .avatar {
                    border: 2px solid #f8b856;
                  }
                  :nth-child(4n-1) .avatar {
                    border: 2px solid #e95b2e;
                  }
                  :nth-child(4n) .avatar {
                    border: 2px solid #ffc39c;
                  }
                  :first-of-type {
                    margin-top: 40px;
                  }
                `}
              >
                <div
                  css={css`
                    display: flex;
                  `}
                >
                  <Avatar
                    key={user.user.id}
                    url={user.user.avatar?.image}
                    className="avatar"
                    size={25}
                    name={`${user.user.first_name} ${user.user.last_name}`}
                  />

                  <div
                    css={css`
                      font-weight: 500;
                      font-size: 14px;
                      margin-left: 7px;
                    `}
                  >
                    {user.user.first_name} {user.user.last_name}
                    {props.userData?.email === user.user.email &&
                      t("collab-invite.you")}
                  </div>
                </div>
                {props.userData?.email === user.user.email ? (
                  <Headers.H3
                    css={css`
                      font-size: 16px;
                      padding-right: 24px;
                      line-height: 21.79px;
                      margin-right: 21px;
                      width: 130px;
                      text-align: center;
                    `}
                  >
                    {video?.current_user_role === "OWNER"
                      ? t("collab-invite.role.owner")
                      : video?.current_user_role === "EDITOR"
                      ? t("collab-invite.role.editor")
                      : t("collab-invite.role.comment")}
                  </Headers.H3>
                ) : (
                  selectOptions.length > 0 && (
                    <SelectComponent
                      value={user.role}
                      choices={selectOptions.map((r: RoleOptionType) => {
                        return {
                          ...r,
                          label: t(r.label),
                        };
                      })}
                      onChange={async (event) => {
                        if (event.target.value === "REMOVE") {
                          if (video) {
                            try {
                              await removeCollaborator(
                                video?.uuid,
                                user.user.id
                              );
                              await refetchCollaborators();
                              customToast.success(t("status.success"));
                            } catch (error: any) {
                              customToast.error(
                                t("status.error", {
                                  error: serverErrorHandler(error),
                                })
                              );
                            }
                          }
                        } else {
                          try {
                            await updateCollaborator(
                              video!.uuid,
                              user.user.id,
                              event.target.value as CollaboratorRole
                            );
                            await refetchCollaborators();
                            customToast.success(t("status.success"));
                          } catch (error: any) {
                            customToast.error(
                              t("status.error", {
                                error: serverErrorHandler(error),
                              })
                            );
                          }
                        }
                      }}
                      css={css`
                        width: 140px;
                        margin-right: 30px;
                        div {
                          :after {
                            right: 1px !important;
                            top: 3px !important;
                          }
                        }
                        select {
                          height: 29px;
                          border-radius: 4px;
                          color: #252424;
                          padding: 0;
                          padding-left: 3px;
                          font-size: 16px;
                          font-weight: 600;
                          line-height: 21.79px;
                          border: 1px solid #252424;
                          :focus {
                            outline: none;
                          }
                        }
                      `}
                    />
                  )
                )}
              </div>
            );
          })}
        <div
          css={css`
            display: flex;
            justify-content: space-between;
          `}
        >
          <h4
            css={css`
              font-size: 14px;
              font-weight: 400;
              margin-top: 3px;
            `}
          >
            {t("collab-invite.link.anyone")}
          </h4>
          <SelectComponent
            choices={linkAccessChoices}
            onChange={async (event) => {
              await updateRoleForAnyone(video!.uuid, {
                edit_link_access_type: event.target.value,
              });
            }}
            disabled={video?.current_user_role !== "OWNER" ? true : false}
            css={css`
              width: 140px;
              margin-right: 30px;
              div {
                :after {
                  right: 1px !important;
                  top: 3px !important;
                }
              }
              select {
                height: 29px;
                border-radius: 4px;
                color: #252424;
                padding: 0;
                padding-left: 3px;
                font-size: 16px;
                font-weight: 600;
                border: 1px solid #252424;
              }
            `}
          />
        </div>
      </ModalBody>
      <ModalFooter
        css={css`
          border-top: 1px solid #8e8e8e;
          justify-content: flex-start;
          margin-top: 15px;
        `}
      >
        <Button
          icon={<CopyIcon />}
          css={css`
            background-color: transparent;
            color: #e95b2e;
            font-size: 12px;
            font-weight: 600;
            margin-left: 0 !important;
            padding-left: 9px;
          `}
          hoverStyles={`none`}
          onClick={() => {
            navigator.clipboard.writeText(
              `${process.env.REACT_APP_FRONTEND_BASE_URL}/video-editor/${
                video!.uuid
              }/`
            );
            setButtonText(t("collab-invite.link.copied"));
            setTimeout(() => {
              setButtonText(text);
            }, 1000);
          }}
        >
          {buttonText}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
