import styled from "styled-components/macro";
import { theme } from "../themes/variables";

export const CodeArea = styled.pre`
  max-width: 100%;
  font-size: 20px;
  line-height: 24px;
  white-space: normal;
  background-color: ${theme.colors.black};
  color: white;
  padding: 20px;
  max-height: 390px;
  overflow-y: auto;
`;
