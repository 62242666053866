import React from "react";
import { Field, FieldProps } from "formik";
import { InputComponent, InputProps } from "../../components/InputComponent";
import { InputErrors } from "./InputErrors";
import { css } from "styled-components/macro";
import { theme } from "../../themes/variables";

export type InputFieldProps = Omit<InputProps, "value" | "field" | "error"> & {
  name: string;
  maxLength?: number;
  errorBottom?: boolean;
  hideTooltip?: boolean;
  validate?: (value: string) => Promise<void | string>;
};

export function InputField(props: InputFieldProps) {
  const {
    id,
    name,
    disabled,
    label,
    hint,
    maxLength,
    placeholder,
    readonly,
    type,
    style,
    icon,
    className,
    errorBottom,
    predefinedText,
    onChange,
    onInput,
    onKeyDown,
    onMouseEnter,
    onMouseLeave,
    validate,
  } = props;
  return (
    <Field name={name} validate={validate}>
      {({ field, meta }: FieldProps) => {
        return (
          <div className={className}>
            <InputComponent
              id={id}
              name={name}
              label={label}
              disabled={disabled}
              readonly={readonly}
              placeholder={placeholder}
              type={type}
              hint={hint}
              style={style}
              icon={icon}
              onChange={onChange}
              onKeyDown={onKeyDown}
              onInput={onInput}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              field={field}
              error={!!meta.error && meta.touched}
              predefinedText={predefinedText}
            />

            {!props.hideTooltip && (
              <InputErrors
                currentLength={String(field.value).length}
                maxLength={maxLength}
                error={meta.error}
                touched={meta.touched}
                haveHint={!!hint}
              />
            )}
            {errorBottom && meta.error && meta.touched && (
              <div
                css={css`
                  color: ${theme.colors.error};
                  font-size: 16px;
                  margin-left: 25px;
                `}
              >
                {meta.error}
              </div>
            )}
          </div>
        );
      }}
    </Field>
  );
}
