import { useCallback, useEffect, useRef, useState } from "react";
import { css } from "styled-components/macro";
import { DateTime } from "luxon";

import { useAuth } from "../../contexts/UserContext";
import { usePlayback } from "../contexts/PlaybackContext";
import { useStorage } from "../contexts/StorageContext";

import { SceneNavigatorItem } from "./SceneNavigatorItem";
import { Button } from "./Button";
import { DisableUI } from "./DisableUI";
import { canEdit } from "../helpers/collaborator";

import { ReorderingScene } from "../types/Video";

import { ReactComponent as PlayIcon } from "../assets/icons/Play.svg";
import { ReactComponent as PauseIcon } from "../assets/icons/Pause.svg";
import { ReactComponent as DeleteIcon } from "../assets/icons/Delete-SubNav.svg";
import { ReactComponent as CardIcon } from "../assets/icons/Card-SubNav.svg";
import { ReactComponent as PlusIcon } from "../assets/icons/Plus_CreateScene.svg";
import { useTranslation } from "react-i18next";
import { generateDefaultEmptyScene } from "../helpers/video";
import { cloneDeep } from "lodash";
import { localAPI } from "../API";

export const slideWidth = 160;
export const margin = 10;
export const slideHeight = 87;

export function SceneNavigator() {
  const dragWrapperContainerRef = useRef<HTMLDivElement>(null);
  const dragProgressRef = useRef<HTMLDivElement>(null);
  const draggingRef = useRef<boolean>(false);
  const dragHandlerRef = useRef<HTMLDivElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const { t } = useTranslation();

  const { api, video } = useStorage();
  const { userprofile } = useAuth();
  const {
    play,
    pause,
    playing,
    scenes,
    setCurrentTime,
    currentTime,
    activeScene,
    videoDuration,
  } = usePlayback();
  const carouselWidth = scenes.length * slideWidth;
  const videoCovered = (currentTime / videoDuration) * 100;

  useEffect(() => {
    const calculateWidth = () => {
      if (containerRef.current) {
        setContainerWidth(containerRef.current.offsetWidth);
      }
    };

    setTimeout(() => {
      calculateWidth();
    }, 100);

    window.addEventListener("resize", calculateWidth);

    return () => {
      window.removeEventListener("resize", calculateWidth);
    };
  }, []);

  const createScene = useCallback(async () => {
    const name = t("scene.title");
    const { scene, elements } = generateDefaultEmptyScene(
      name,
      scenes.length,
      t
    );
    await api.createSceneWithContent(scene, elements);

    const startTime = scenes.reduce((sum, s) => sum + s.duration, 0);

    setCurrentTime(startTime);

    scrollRef.current?.scrollTo({
      left: scrollRef.current.scrollWidth,
      behavior: "smooth",
    });
  }, [api, t, setCurrentTime, scenes]);

  const duplicateScene = useCallback(async () => {
    if (!userprofile || !activeScene) return;

    const scene = cloneDeep(activeScene);
    const elements = activeScene.elements;

    if (scene && video) {
      const newScene = await api.createSceneWithContent(
        {
          ...scene,
          order: scene.order + 0.1,
          start_time: scene.start_time + scene.duration,
        },
        elements
      );

      if (newScene) {
        const newOrders = [...scenes, newScene]
          .sort((a, b) => (a.order > b.order ? 1 : -1))
          .map((scene, i) => ({
            ...scene,
            order: i,
          }));

        await localAPI.reorderScene(
          video.uuid,
          newOrders,
          {
            userId: userprofile.id,
          },
          null
        );

        setCurrentTime(scene.start_time + scene.duration);
      }
    }
  }, [api, setCurrentTime, activeScene, userprofile, video, scenes]);

  const isButtonOutside = carouselWidth >= containerWidth - 150;
  const hasScrollbar = carouselWidth > containerWidth;
  const createButton = (
    <DisableUI show={!canEdit(video?.current_user_role)}>
      <div
        css={css`
          position: relative;
          width: 50px;
          height: 50px;
          margin-right: 5px;
          cursor: pointer;
          background-color: #fff;
          border: 1px solid #c1c1c1;
          border-radius: 9px;
          display: flex;
          justify-content: center;
          align-self: center;
          margin-left: 30px;
        `}
        onClick={createScene}
      >
        <div
          css={css`
            width: 100%;
            height: 0;
            padding-bottom: 75%;
          `}
        ></div>
        <div
          css={css`
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
          `}
        >
          <PlusIcon
            css={css`
              color: #c1c1c1;
            `}
          />
        </div>
      </div>
    </DisableUI>
  );

  useEffect(() => {
    const onMouseMove = (e: MouseEvent) => {
      if (draggingRef.current && e.currentTarget && dragHandlerRef.current) {
        const x = e.clientX;
        const bounds = dragWrapperContainerRef.current!.getBoundingClientRect();
        const offset = ((x - bounds.left) / bounds.width) * 100;
        const newOffset = Math.min(Math.max(offset, 0), 100);

        dragHandlerRef.current.style.left = `${newOffset}%`;
        dragProgressRef.current!.style.width = `${newOffset}%`;
      }
    };

    const onMouseUp = (e: MouseEvent) => {
      if (draggingRef.current) {
        draggingRef.current = false;

        const left = Number(
          dragHandlerRef.current!.style.left.replace("%", "")
        );
        const time = (left / 100) * videoDuration;

        setCurrentTime(Math.max(Math.min(time, videoDuration), 0));
      }
    };

    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);

    return () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };
  }, [videoDuration, setCurrentTime]);

  const dragHandler = (e: React.MouseEvent) => {
    draggingRef.current = true;
  };

  const findScene = useCallback(
    (id: string) => {
      const scene = scenes.find((s) => `${s.id}` === id);
      if (!scene) return null;

      return {
        scene,
        index: scenes.indexOf(scene),
      };
    },
    [scenes]
  );

  const moveScene = useCallback(
    async (from: ReorderingScene, to: ReorderingScene) => {
      const fromScene = findScene(from.id);
      const toScene = findScene(to.id);

      if (fromScene && toScene) {
        await api.reorderScene(
          {
            ...fromScene.scene,
          },
          { ...toScene.scene }
        );
        setCurrentTime(toScene.scene.start_time);
      }
    },
    [api, findScene, setCurrentTime]
  );

  return (
    <div
      css={css`
        padding: 0;
        display: flex;
        background: #fff;
        filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.25));
        border-radius: 25px;
      `}
      id="themis-timeline"
    >
      <div
        css={css`
          flex: 0 0 50px;
          height: auto;
          border-radius: 25px 0 0 25px;
          background-color: #252424;
          display: flex;
          flex-direction: column;
          justify-content: center;
        `}
      >
        <Button
          icon={playing ? <PauseIcon /> : <PlayIcon />}
          css={css`
            background: transparent;
            color: #fff;
            min-width: 3.5em;
            padding: 10px;
            :hover {
              color: #e95b2e;
            }

            ${video?.schema.schema.elements.length === 0 &&
            css`
              color: #696969;
              pointer-events: none;
            `}
          `}
          onClick={() => {
            playing ? pause() : play();
          }}
        ></Button>

        <DisableUI show={!canEdit(video?.current_user_role)}>
          <Button
            icon={<DeleteIcon />}
            css={css`
              background: transparent;
              color: #fff;
              min-width: 3.5em;
              padding: 10px;
              :hover {
                color: #e95b2e;
              }

              ${scenes.length === 1 &&
              css`
                opacity: 0.3;
                pointer-events: none;
              `}
            `}
            onClick={() => {
              if (userprofile && activeScene) {
                api.deleteScene(activeScene.id);
              }
            }}
          ></Button>
        </DisableUI>
        <DisableUI show={!canEdit(video?.current_user_role)}>
          <Button
            icon={<CardIcon />}
            css={css`
              background: transparent;
              color: #fff;
              min-width: 3.5em;
              padding: 10px;
              :hover {
                color: #e95b2e;
              }
            `}
            onClick={duplicateScene}
          ></Button>
        </DisableUI>
      </div>

      <div
        css={css`
          padding: 10px 65px;
          padding-right: 60px;
          flex: 1 0 10%;
        `}
      >
        <div
          css={css`
            position: relative;
            padding: 10px 0;
          `}
        >
          <div
            css={css`
              position: absolute;
              left: -40px;
              top: 5px;
              font-size: 11px;
              margin-right: 8px;
            `}
          >
            {DateTime.fromSeconds(currentTime / 1000).toFormat("mm:ss")}
          </div>

          <div
            css={css`
              margin-right: 10px;
              position: relative;
              width: 100%;
              background-color: #fae7d9;
              height: 4px;
              border-radius: 3px;
              justify-content: center;
              align-self: center;
              cursor: pointer;
            `}
            ref={dragWrapperContainerRef}
            onClick={(e) => {
              const x = e.clientX;
              const bounds =
                dragWrapperContainerRef.current!.getBoundingClientRect();
              const offset = ((x - bounds.left) / bounds.width) * 100;
              const newOffset = Math.min(Math.max(offset, 0), 100);
              const time = (newOffset / 100) * videoDuration;

              setCurrentTime(Math.max(Math.min(time, videoDuration), 0));
            }}
          >
            <div
              css={css`
                height: 4px;
                background-color: #e95b2e;
                position: absolute;
                width: ${videoCovered}%;
                border-radius: 3px;
                cursor: pointer;
              `}
              style={{
                width: `${videoCovered}%`,
              }}
              ref={dragProgressRef}
              onClick={(e) => {
                const x = e.clientX;
                const bounds =
                  dragWrapperContainerRef.current!.getBoundingClientRect();
                const offset = ((x - bounds.left) / bounds.width) * 100;
                const newOffset = Math.min(Math.max(offset, 0), 100);
                const time = (newOffset / 100) * videoDuration;

                setCurrentTime(Math.max(Math.min(time, videoDuration), 0));
              }}
            ></div>

            <div
              css={css`
                position: absolute;
                width: 9px;
                height: 9px;
                background: #ffc39c;
                border: 1px solid #e95b2e;
                box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
                z-index: 2;
                border-radius: 50%;
                transform: translate(-4px, -2px);
                cursor: pointer;
              `}
              style={{
                left: `${videoCovered}%`,
              }}
              onMouseDown={dragHandler}
              ref={dragHandlerRef}
            ></div>
          </div>

          <div
            css={css`
              position: absolute;
              right: -40px;
              top: 5px;
              font-size: 11px;
            `}
          >
            {DateTime.fromSeconds(videoDuration / 1000).toFormat("mm:ss")}
          </div>
        </div>

        <div
          css={css`
            position: relative;
            padding-right: 50px;
          `}
          ref={containerRef}
        >
          <div
            ref={scrollRef}
            css={css`
              position: relative;
              width: 100%;
              height: 150px;
              overflow: scroll;
              overflow-y: hidden;
              padding-top: 10px;
              z-index: 999;

              /* width */
              ::-webkit-scrollbar {
                height: 9px;
                border-radius: 5px;
              }

              /* Track */
              ::-webkit-scrollbar-track {
                background: ${hasScrollbar ? "#f2f2f2" : "#CDCDCD"};
                border-radius: 5px;
              }

              /* Handle */
              ::-webkit-scrollbar-thumb {
                background: #cdcdcd;
                border-radius: 5px;
              }

              /* Handle on hover */
              ::-webkit-scrollbar-thumb:hover {
                background: #555;
              }
            `}
          >
            <div
              css={css`
                position: absolute;
                display: flex;
                flex-flow: row nowrap;
                width: ${carouselWidth}px;
                left: 0;
                top: 20px;
              `}
            >
              {scenes.map((scene) => {
                return (
                  <SceneNavigatorItem
                    scene={scene}
                    scrollRef={scrollRef}
                    carouselWidth={carouselWidth}
                    index={scene.order}
                    moveScene={moveScene}
                    key={scene.id}
                  />
                );
              })}

              {!isButtonOutside && (
                <div
                  css={css`
                    flex: 0 0 90px;
                    height: ${slideHeight}px;
                    display: flex;
                    align-items: center;
                  `}
                >
                  {createButton}
                </div>
              )}
            </div>
          </div>

          {isButtonOutside && (
            <div
              css={css`
                position: absolute;
                right: -20px;
                top: 0;
                height: 100%;
                display: flex;
                align-items: center;
              `}
            >
              {createButton}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
