import React from "react";
import { useHistory } from "react-router-dom";
import { Wrapper } from "./Wrapper";
import logo from "../../assets/img/logo.png";
import { css } from "styled-components/macro";

import { useTranslation } from "react-i18next";
import { ReactComponent as Arrow } from "../../assets/icons/button-arrow.svg";
import { LanguageSelector } from "../LanguageSelector";
import { Button } from "../Button";

export function Header() {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <header
      css={css`
        padding: 37px 0 30px 0;
      `}
    >
      <Wrapper>
        <div
          css={css`
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
          `}
        >
          <div>
            <a
              href="https://themis.eu"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                css={css`
                  cursor: pointer;
                  max-width: 134px;
                  max-height: 40px;
                  vertical-align: middle;
                `}
                src={logo}
                alt={`Themi Logo`}
              />
            </a>
          </div>
          <div
            css={css`
              display: flex;
              justify-content: space-between;
            `}
          >
            <Button
              iconRight
              icon={<Arrow />}
              css={css`
                background: #252424;
                border-radius: 6px;
                color: #fff;
                padding: 4px;
                font-size: 16px;
                min-width: 5.6em;
                font-weight: 700;
              `}
              onClick={() => {
                history.push("/login");
              }}
              hoverStyles={"none"}
            >
              {t("auth.sign-in")}
            </Button>
            <LanguageSelector />
          </div>
        </div>
      </Wrapper>
    </header>
  );
}
