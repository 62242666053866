import { Modal, ModalBody, ModalHeader } from "../components/Modal";
import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useHistory } from "react-router";
import { createVideoFromTemplate } from "../VideoEditor/actions/createVideoFromTemplate";
import { Button } from "../components/Button";
import { customToast } from "../components/customToast";
import { serverErrorHandler } from "../helpers/serverErrorHandler";
import { Loader } from "../components/Loader";
import { Template } from "../helpers/templates/themis";

export function TemplatePreview(props: {
  templates: Template[];
  showPreview: string | null;
  onClose: () => void;
  onSelect: (id: string) => void;
}) {
  const { t } = useTranslation();
  const [creating, setCreating] = useState(false);
  const history = useHistory();
  const template = props.templates.find(
    (template) => template.id === props.showPreview
  );

  return (
    <Modal
      modalIsOpen={!!template}
      onClose={props.onClose}
      contentStyles={{
        padding: "40px 40px 40px 40px",
        maxWidth: "1075px",
        minHeight: "560px",
      }}
    >
      <ModalHeader
        onClose={props.onClose}
        closeIcon
        css={css`
          justify-content: flex-end;
          button {
            position: absolute;
            top: 20px;
            right: 20px;
          }
        `}
      ></ModalHeader>

      <ModalBody>
        <div
          css={css`
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
          `}
        >
          <div
            css={css`
              flex: 0 0 210px;
              border-right: 1px solid rgba(216, 216, 216, 0.5);
              padding-right: 20px;
              margin-right: 40px;
            `}
          >
            {props.templates.map((template) => {
              return (
                <div
                  css={css`
                    margin-bottom: 10px;
                  `}
                  key={template.id}
                >
                  <button
                    css={css`
                      border-radius: 21px;
                      border: none;
                      background: none;
                      padding: 9px;
                      padding-right: 20px;
                      cursor: pointer;
                      display: flex;
                      align-items: center;

                      &:hover {
                        background: #f2f2f2;
                      }

                      ${template.id === props.showPreview &&
                      css`
                        background: #f2f2f2;
                      `}
                    `}
                    onClick={() => {
                      props.onSelect(template.id);
                    }}
                  >
                    <div
                      css={css`
                        width: 31px;
                        height: 31px;
                        margin-right: 10px;
                        border-radius: 50%;
                        background: ${template.iconBackground};
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        svg {
                          width: 20px;
                        }
                      `}
                    >
                      {template.icon}
                    </div>
                    <span
                      css={css`
                        font-size: 16px;
                      `}
                    >
                      {template.title}
                    </span>
                  </button>
                </div>
              );
            })}
          </div>
          <div
            css={css`
              flex: 1 0 10%;
              min-width: 0;
            `}
          >
            {template && (
              <>
                <div
                  css={css`
                    position: relative;
                    flex: 1 0 10%;
                    overflow-x: scroll;
                    min-width: 0;
                    padding-bottom: 40px;

                    /* width */
                    ::-webkit-scrollbar {
                      height: 9px;
                      border-radius: 5px;
                    }

                    /* Track */
                    ::-webkit-scrollbar-track {
                      background: transparent;
                    }

                    /* Handle */
                    ::-webkit-scrollbar-thumb {
                      background: #cdcdcd;
                      border-radius: 5px;
                    }

                    /* Handle on hover */
                    ::-webkit-scrollbar-thumb:hover {
                      background: #555;
                    }
                  `}
                >
                  <div
                    css={css`
                      display: flex;
                      flex-flow: row wrap;
                      width: ${template.previews.length * 350 +
                      (template.previews.length - 1) * 44}px;
                    `}
                  >
                    {template.previews.map((preview) => {
                      return (
                        <div
                          css={css`
                            width: 350px;
                            margin-right: 44px;
                            border: 2px solid #f2f2f2;
                            border-radius: 11px;
                            overflow: hidden;
                            height: ${350 / 1.777}px;

                            &:last-child {
                              margin-right: 0;
                            }

                            img {
                              width: 100%;
                              display: block;
                            }
                          `}
                          key={preview}
                        >
                          <img src={preview} alt={template.title} />
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div
                  css={css`
                    position: relative;
                    flex: 0 0 230px;
                    margin-top: 40px;

                    &:after {
                      content: "";
                      position: absolute;
                      background: linear-gradient(
                        90deg,
                        rgba(255, 255, 255, 0) 0%,
                        rgba(255, 255, 255, 1) 100%
                      );
                      top: 0;
                      left: -50px;
                      width: 30px;
                      height: 100%;
                      z-index: 2;
                    }
                  `}
                >
                  <div
                    css={css`
                      margin-bottom: 40px;
                    `}
                  >
                    <p>{template.description}</p>
                  </div>
                  <div
                    css={css`
                      display: flex;
                      flex-flow: row wrap;
                      justify-content: flex-end;
                    `}
                  >
                    <Button
                      css={css`
                        padding: 8px 20px;
                        font-size: 14px;
                        background-color: #e95b2e;
                      `}
                      hoverStyles="none"
                      onClick={async () => {
                        setCreating(true);

                        try {
                          const response = await createVideoFromTemplate({
                            template_id: template.id,
                          });

                          history.push(`/video-editor/${response.data.uuid}`);
                        } catch (error: any) {
                          customToast.error(
                            t("status.error", {
                              error: serverErrorHandler(error),
                            })
                          );
                        } finally {
                          setCreating(false);
                        }
                      }}
                    >
                      {creating ? (
                        <Loader />
                      ) : (
                        t("dashboard.templateData.use-template")
                      )}
                    </Button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}
