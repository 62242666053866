import React from "react";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { customToast } from "../../components/customToast";
import { InputType } from "../../components/InputComponent";
import { Button } from "../../VideoEditor/components/Button";
import { InputField } from "../fields/InputField";
import { Column, Row, Spacing } from "../../helpers/layout";
import { login } from "../../actions/auth/login";
import { useAuth } from "../../contexts/UserContext";

import { serverErrorHandler } from "../../helpers/serverErrorHandler";
import { USER_CONFIRM_EMAIL } from "../../actions/auth/resendConfirmationEmail";
import { sendEvent } from "../../helpers/tracking";
interface FormValues {
  username: string;
  password: string;
}

export function LoginForm() {
  const { t } = useTranslation();
  const { authenticate } = useAuth();
  const history = useHistory();
  const location = useLocation();

  return (
    <div>
      <Formik<FormValues>
        initialValues={{
          username: "",
          password: "",
        }}
        validationSchema={Yup.object().shape({
          username: Yup.string().email().required(),
          password: Yup.string().required(),
        })}
        onSubmit={async (values: FormValues, { setSubmitting }) => {
          try {
            setSubmitting(true);

            const response = await login(values.username, values.password);

            sendEvent("Login");

            if (!response.data.email_confirmed) {
              localStorage.setItem(USER_CONFIRM_EMAIL, values.username);

              history.push("/confirm-email");
            } else if (!response.data.is_verified) {
              history.push("/verification-info");
            } else {
              authenticate(response.data.token, response.data.userprofile);
            }
          } catch (error: any) {
            customToast.error(
              t("status.error", {
                error: serverErrorHandler(error),
              })
            );
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, isValid }) => (
          <Form noValidate>
            <Column
              gutter={Spacing.m}
              css={css`
                padding: 43px;
              `}
            >
              <InputField
                type={InputType.TEXT}
                name="username"
                placeholder={t("auth.email")}
              />
              <InputField
                type={InputType.PASSWORD}
                name="password"
                placeholder={t("auth.password")}
              />
              <Link
                css={css`
                  display: flex;
                  font-size: 16px;
                  justify-content: flex-start;
                  margin-top: 5px;
                  font-weight: bold;
                `}
                to={`/reset-password${location.search}`}
              >
                {t("auth.forgot-password")}
              </Link>

              <Row justify="flex-end" align="center">
                <Button
                  isSubmitting={isSubmitting}
                  disabled={!isValid}
                  type="submit"
                  css={css`
                    margin: 0 auto;
                    color: #fff;
                    min-width: 10em;
                    margin-top: 15px;
                  `}
                >
                  {t("auth.sign-in")}
                </Button>
              </Row>
            </Column>

            <Link
              css={css`
                display: flex;
                justify-content: center;
                font-size: 16px;
                font-weight: 400;
                margin-top: 40px;
              `}
              to={`/register${location.search}`}
            >
              {t("auth.account")}&nbsp;
              <p
                css={css`
                  font-weight: bold;
                `}
              >
                {t("auth.create-now")}
              </p>
            </Link>
          </Form>
        )}
      </Formik>
    </div>
  );
}
