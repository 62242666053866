import React, { useCallback, useState, useEffect } from "react";
import { DateTime } from "luxon";
import { debounce } from "lodash";
import striptags from "striptags";
import { css } from "styled-components/macro";
import { useDrag, useDrop } from "react-dnd";

import { VideoScene, ReorderingScene } from "../types/Video";
import { Collaborators } from "./Collaborators";
import { ScenePreview } from "./ScenePreview";
import { margin, slideHeight, slideWidth } from "./SceneNavigator";

import { useCollaboration } from "../contexts/CollaborationContext";
import { usePlayback } from "../contexts/PlaybackContext";
import { useStorage } from "../contexts/StorageContext";

export function SceneNavigatorItem(props: {
  scene: VideoScene;
  scrollRef: React.RefObject<HTMLDivElement>;
  index: number;
  carouselWidth: number;
  moveScene: (from: ReorderingScene, to: ReorderingScene) => void;
}) {
  const { scene, scrollRef, index, carouselWidth, moveScene } = props;

  const { userPositions, members } = useCollaboration();
  const { api } = useStorage();
  const { setCurrentTime, activeScene } = usePlayback();
  const [name, setName] = useState(scene.name || "Untitled");

  useEffect(() => {
    setName(scene.name);
  }, [scene]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateScene = useCallback(
    debounce((sceneId: string, text: string) => {
      api.updateScene(sceneId, {
        name: text,
      });
    }, 1000),
    [api]
  );

  const handleContentEditable = (event: any) => {
    let name = event.target.value;
    name = striptags(name, [], "") as string;

    if (name.length > 22) {
      name = name.substring(0, 22);
    }

    setName(name);
    updateScene(scene.id, name);
  };

  const collaborators = userPositions.filter((userPosition) => {
    return (
      userPosition.time >= scene.start_time &&
      userPosition.time < scene.start_time + scene.duration
    );
  });

  const sceneCollaborators = members.filter((member) => {
    return collaborators.find(
      (collaborator) => collaborator.user.id === member.id
    );
  });

  const [, drag] = useDrag(() => {
    return {
      type: "scene",
      item: {
        id: scene.id,
        order: index,
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    };
  }, [scene]);

  const [, drop] = useDrop<
    VideoScene,
    void,
    {
      isOver: boolean;
    }
  >(
    () => ({
      accept: "scene",
      drop({ id: droppedId, order }) {
        if (droppedId !== scene.id) {
          moveScene(
            {
              id: droppedId,
              order: order,
            },
            {
              id: scene.id,
              order: scene.order,
            }
          );
        }
      },
      collect(monitor) {
        return {
          isOver: monitor.isOver(),
        };
      },
    }),
    [scene]
  );

  const isActive = activeScene && activeScene.id === scene.id;

  return (
    <div
      ref={(node) => drag(drop(node))}
      css={css`
        position: relative;
        width: ${slideWidth - margin}px;
        flex: 0 0 ${slideWidth - margin}px;
        border: 2px solid #cdcdcd;
        height: auto;
        margin-right: ${margin}px;
        cursor: pointer;
        box-sizing: border-box;
        border-radius: 9px;
        overflow: hidden;
        background: #e95b2e;

        ${activeScene &&
        activeScene.id === scene.id &&
        css`
          border: 2px solid #e95b2e;
        `}
      `}
      onClick={() => {
        if (!scrollRef.current) return;

        const elPosition = index * slideWidth;
        const containerWidth = scrollRef.current.clientWidth;
        let scrollPosition = elPosition - containerWidth / 2 + slideWidth / 2;

        scrollPosition = Math.max(0, scrollPosition);
        scrollPosition = Math.min(scrollPosition, carouselWidth);

        scrollRef.current.scroll({
          left: scrollPosition,
          behavior: "smooth",
        });

        setCurrentTime(scene.start_time);
      }}
    >
      <ScenePreview scene={scene} width={slideWidth * 2} />

      <div
        css={css`
          position: absolute;
          right: 10px;
          top: 10px;
        `}
      >
        <Collaborators
          users={sceneCollaborators}
          maxCollaborators={2}
          isHover={false}
          isValueClickable={false}
        />
      </div>

      <div
        css={css`
          position: absolute;
          top: ${slideHeight - 30}px;
          right: 10px;
          padding: 0 10px;
          background-color: #ffffff;
          box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
          border-radius: 20px;
          font-size: 11px;
        `}
      >
        {DateTime.fromSeconds(scene.duration / 1000).toFormat("mm:ss")}
      </div>

      <div
        css={css`
          background: ${isActive ? "#e95b2e" : "#cdcdcd"};
          padding: 2px 10px;
          font-size: 11px;
        `}
      >
        <input
          css={css`
            background: transparent;
            border: none;
            color: ${isActive ? "#fff" : "#000"};
            font-size: 12px;
            width: 100%;
            outline: none;
            padding: none;
            font-weight: 400;
          `}
          type="text"
          value={name}
          onChange={handleContentEditable}
        />
      </div>
    </div>
  );
}
