import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";

import { ReactComponent as OpenInNewTabIcon } from "../assets/icons/OpenInNewTab.svg";

import { Modal, ModalBody, ModalHeader } from "../components/Modal";
import { Tabs, TabProps } from "../components/Tabs";
import { ExportLink } from "../businessComponents/Publish/ExportLink";
import { Publish } from "../businessComponents/Publish/Publish";

import { useStorage } from "../VideoEditor/contexts/StorageContext";

export function ModalPublish(props: { isOpen: boolean; onClose: () => void }) {
  const { video } = useStorage();
  const { t } = useTranslation();

  const tabsData: TabProps[] = [
    {
      label: t("video-editor.status-bar.publish"),
      name: "publish",
    },
    {
      label: t("publish.nav.export"),
      name: "export",
    },
  ];

  const protocol = window.location.protocol;
  const host = window.location.host;

  const link = `<iframe width="707" height="394" src="${protocol}//${host}/embed/${video?.uuid}/" title="Themi.me video player" frameborder="0" allow="autoplay; clipboard-write; web-share" allowfullscreen></iframe>`;

  const [activeTab, setActiveTab] = useState("publish");

  const onClickTab = (tabName: string) => {
    setActiveTab(tabName);
  };

  return (
    <Modal
      modalIsOpen={props.isOpen}
      onClose={props.onClose}
      contentStyles={{ maxWidth: "650px", padding: 0 }}
    >
      <ModalHeader
        closeIcon
        onClose={props.onClose}
        css={css`
          padding: 14px 21px 13px 32px;
          border-bottom: 1px solid #f2f2f2;
        `}
      >
        <OpenInNewTabIcon color="#8E8E8E" width={13} height={14} />

        <span
          css={css`
            color: #8e8e8e;
            margin-left: 10px;
          `}
        >
          {t("publish.export.publish-themi")}
        </span>
      </ModalHeader>

      <ModalBody
        css={css`
          padding: 0;
        `}
      >
        <div
          css={css`
            padding-top: 19px;
            border-bottom: 1px solid #f2f2f2;
          `}
        >
          <Tabs
            tabs={tabsData}
            activeTab={activeTab}
            onClick={onClickTab}
            align="left"
            narrowIndicator
          />
        </div>

        {activeTab === "publish" && <Publish video={video} />}

        {activeTab === "export" && <ExportLink link={link} />}
      </ModalBody>
    </Modal>
  );
}
