import React from "react";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { Header } from "../../components/Header";
import { Wrapper } from "../../components/Wrapper";
import { SEO } from "../../components/SEO";
import { SetPasswordForm } from "../../forms/auth/SetPasswordForm";
import { theme } from "../../themes/variables";
import { Headers } from "../../helpers/layout";
import { AuthBackgroundStyle } from "../../themes/variables";
import { FeedbackWidget } from "../../components/FeedbackWidget";

export function SetPasswordPage(props: {}) {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <SEO>
        <title>{t("auth.set-new-password")}</title>
      </SEO>
      <AuthBackgroundStyle />
      <Header />
      <div
        css={css`
          width: 100%;
          max-width: 500px;
          margin: 0 auto;
          padding: 20px 0;
          border: 1px solid black;
          box-shadow: 0px 0px 19px rgba(0, 0, 0, 0.11);
          border-radius: 25px;
          background-color: ${theme.colors.white};
        `}
      >
        <Headers.H3
          css={css`
            text-align: center;
            margin-bottom: 40px;
            letter-spacing: -0.05em;
            font-weight: bold;
          `}
        >
          {t("auth.set-new-password")}
        </Headers.H3>
        <SetPasswordForm />
      </div>
      <FeedbackWidget />
    </Wrapper>
  );
}
