import React from "react";
import { useTranslation, Trans } from "react-i18next";
import { css } from "styled-components/macro";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { customToast } from "../../components/customToast";
import { Button } from "./Button";
import { InputField } from "./InputField";
import { Column, Spacing } from "../../helpers/layout";
import { serverErrorHandler } from "../../helpers/serverErrorHandler";
import { CheckboxField } from "./CheckboxField";
import { waitlistRegister } from "../../actions/auth/waitlistRegister";

interface FormValues {
  email: string;
  checkbox: boolean;
}

export function SignupForm() {
  const { t } = useTranslation();
  const refQueryParam = new URLSearchParams(window.location.search).get("ref");

  return (
    <div
      css={css`
        width: 100%;
        max-width: 415px;
      `}
    >
      <Formik<FormValues>
        initialValues={{
          email: "",
          checkbox: false,
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string().email().required(),
          checkbox: Yup.boolean()
            .oneOf([true], t("auth.accept-checkbox"))
            .required(),
        })}
        onSubmit={async (values: FormValues, { setSubmitting }) => {
          try {
            setSubmitting(true);

            await waitlistRegister(values.email, refQueryParam);

            customToast.success(t("status.success"));
          } catch (error: any) {
            customToast.error(
              t("status.error", {
                error: serverErrorHandler(error),
              })
            );
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, isValid }) => (
          <Form noValidate>
            <Column gutter={Spacing.m}>
              <InputField
                type="text"
                name="email"
                placeholder={t("waitlist.email.placeholder")}
              />
              <CheckboxField
                name="checkbox"
                css={css`
                  margin-top: 27px;
                `}
              >
                {
                  <div
                    css={css`
                      width: 100%;
                      font-size: 13px;
                      margin-right: 40px;

                      a {
                        font-weight: bold;
                        :hover {
                          text-decoration: underline;
                        }
                      }
                    `}
                  >
                    <Trans i18nKey="auth.I-have-read">
                      I have read and agree with the
                      <a
                        href={"/terms-and-conditions"}
                        target="_blank"
                        rel="noreferrer"
                      >
                        terms of service.
                      </a>
                    </Trans>
                  </div>
                }
              </CheckboxField>
              <div>
                <Button
                  isSubmitting={isSubmitting}
                  disabled={!isValid}
                  type="submit"
                  css={css`
                    margin: 0 auto;
                    color: #fff;
                    min-width: 10em;
                    margin-top: 15px;
                    font-weight: 700;
                    font-size: 20px;
                  `}
                >
                  {t("waitlist.signup")}
                </Button>
              </div>
            </Column>
          </Form>
        )}
      </Formik>
    </div>
  );
}
