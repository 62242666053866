import React, { ChangeEvent } from "react";
import { css } from "styled-components/macro";
import { theme } from "../themes/variables";
import { FieldInputProps } from "formik";
import { Hint } from "./Hint";
import { InputLabel } from "./InputLabel";

export interface SelectOption {
  label: string;
  value: string;
  icon?: string;
}

export function SelectComponent(props: {
  name?: string;
  id?: string;
  label?: string;
  hint?: string;
  disabled?: boolean;
  value?: string | null;
  className?: string;
  field?: FieldInputProps<any>;
  choices: SelectOption[];
  small?: boolean;
  onChange?: (event: ChangeEvent<HTMLSelectElement>) => void;
}) {
  const value = props.choices.find((el) => el.value === props.field?.value);

  return (
    <label
      className={props.className}
      css={css`
        width: 100%;
      `}
    >
      <InputLabel label={props.label} />

      <div
        className={props.className}
        css={css`
          position: relative;
          :before {
            left: 12px;
            top: 9px;
            width: 5px;
            height: 5px;
            position: absolute;
            transform: scale(0.8);
            content: url(${value ? value.icon : ""});
            background-repeat: no-repeat;
            background-size: contain;
          }
          :after {
            content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9ImN1cnJlbnRDb2xvciIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIGNsYXNzPSJmZWF0aGVyIGZlYXRoZXItY2hldnJvbi1kb3duIj48cG9seWxpbmUgcG9pbnRzPSI2IDkgMTIgMTUgMTggOSI+PC9wb2x5bGluZT48L3N2Zz4=");
            right: 6px;
            top: ${props.small ? "3px" : `6px`};
            position: absolute;
            pointer-events: none;
            transform: scale(0.8);
          }
        `}
      >
        <select
          css={css`
            width: 100%;
            outline: none;
            padding: ${props.small ? "3px 20px" : "10px 24px"};
            border-radius: 20px;
            padding-right: 30px;
            ${value?.icon &&
            css`
              padding-left: 35px;
            `}
            border: 1px solid ${theme.colors.gray4};
            -webkit-appearance: none;
            appearance: none;
            cursor: pointer;
            background-color: #fff;
            font-size: 16px;
            font-weight: bold;
            background-image: linear-gradient(to top, #f9f9f9, #fff 33%);
            :-ms-expand {
              display: none;
            }
          `}
          id={props.id}
          disabled={props.disabled}
          value={props.value}
          {...props.field}
          name={props.name}
          onChange={(e) => {
            if (props.field) {
              props.field.onChange(e);
            }

            if (props.onChange) {
              props.onChange(e);
            }
          }}
        >
          {props.choices.map((choice) => {
            return (
              <option value={choice.value} key={choice.value}>
                {choice.label}
              </option>
            );
          })}
        </select>
      </div>

      {props.hint && <Hint>{props.hint}</Hint>}
    </label>
  );
}
