import React, { useState, useRef, useEffect } from "react";
import { css } from "styled-components/macro";
import { theme } from "../themes/variables";
import { useClickOutside } from "../VideoEditor/hooks/useClickOutside";

export function DropdownItem(props: {
  children: React.ReactNode | React.ReactNode[] | string | null;
  onClick?: () => void;
}) {
  return (
    <div
      onClick={props.onClick}
      css={css`
        display: flex;
        align-items: left;
        padding: 2px 50px 2px 6px;
        background: ${theme.colors.white};
        font-weight: 500;
        font-size: 16px;
        line-height: 120%;
        cursor: pointer;
        :hover {
          color: ${theme.colors.white};
          background: ${theme.colors.primary};
        }
      `}
    >
      {props.children}
    </div>
  );
}

export function Dropdown(props: {
  id?: string;
  opened?: boolean;
  className?: string;
  children?: React.ReactNode | React.ReactNode[] | string | null;
  options?: React.ReactNode | React.ReactNode[] | string | null;
  settings?: {
    disableOutsideClick?: boolean;
  };
  onClose?: () => void;
}) {
  const { opened: intialOpened = false, settings } = props;
  const [opened, setOpened] = useState(intialOpened);
  const dropdownRef = useRef(null);

  useEffect(() => {
    setOpened(intialOpened);
  }, [intialOpened]);

  useClickOutside(dropdownRef, () => {
    if (settings?.disableOutsideClick) return;
    setOpened(false);
    props.onClose && props.onClose();
  });

  return (
    <div
      id={props.id}
      ref={dropdownRef}
      css={css`
        position: relative;
        display: flex;
      `}
      onClick={(e) => {
        e.stopPropagation();
        setOpened(!opened);
      }}
    >
      {props.children}
      <div
        css={css`
          position: absolute;
          background: #fff;
          color: ${theme.colors.black};
          border: 1px solid #e2e2e2;
          box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
          border-radius: 6px;
          top: 100%;
          right: 0;
          padding: 4px 0;
          box-sizing: border-box;
          visibility: hidden;
          opacity: 0;
          transition: visibility 0.3s linear, opacity 0.3s linear;
          z-index: 1;
          ${opened &&
          css`
            visibility: visible;
            opacity: 1;
          `}
        `}
        className={props.className}
      >
        {props.options}
      </div>
    </div>
  );
}
