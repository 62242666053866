import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Video } from "../../VideoEditor/types/Video";
import { getVideo as getPublishedVideo } from "../actions/getVideo"; // change when publishing is ready
import { getVideo as getDraftVideo } from "../../VideoEditor/actions/getVideo"; // change when publishing is ready

export type CanvasSize = {
  width: number;
  height: number;
};

const PlayerContext = React.createContext<{
  canvasSize: CanvasSize;
  setCanvasSize: (size: CanvasSize) => void;
  video: Video | null;
  showControls: boolean;
  setShowControls: (show: boolean) => void;
}>({
  video: null,
  canvasSize: {
    width: 0,
    height: 0,
  },
  setCanvasSize: () => {},
  showControls: false,
  setShowControls: () => {},
});

export function PlayerProvider(props: {
  videoId: string;
  children: (video: Video) => React.ReactNode;
  preview?: boolean;
}) {
  const [showControls, setShowControls] = useState(false);
  const [canvasSize, setCanvasSize] = useState({
    width: 0,
    height: 0,
  });
  const [video, setVideo] = useState<Video | null>(null);
  const history = useHistory();

  useEffect(() => {
    const video = props.preview
      ? getDraftVideo(props.videoId)
      : getPublishedVideo(props.videoId);

    video
      .then((video) => {
        if (video) {
          setVideo(video);
        }
      })
      .catch(() => {
        history.push("/?no_access=true");
      });
  }, [props.videoId, history, props.preview]);

  return (
    <PlayerContext.Provider
      value={{
        video,
        canvasSize,
        setCanvasSize,
        showControls,
        setShowControls,
      }}
    >
      {video && props.children(video)}
    </PlayerContext.Provider>
  );
}

export function usePlayer() {
  const context = React.useContext(PlayerContext);
  if (context === undefined) {
    throw new Error("usePlayer must be used within a PlayerProvider");
  }
  return context;
}
