import { Footer } from "../components/Landing/Footer";
import { Header } from "../components/Landing/Header";

import { css, createGlobalStyle } from "styled-components/macro";
import { Wrapper } from "../components/Landing/Wrapper";
import { FeedbackWidget } from "../components/FeedbackWidget";

const GlobalStyle = createGlobalStyle`
  body {
    background: #f9efe0;
  }
`;

export function TermsAndConditions() {
  return (
    <div>
      <GlobalStyle />
      <Header />
      <Wrapper>
        <div
          css={css`
            ol {
              counter-reset: item;
            }
            li {
              display: block;
              position: relative;
              padding-left: 40px;
            }
            li:before {
              position: absolute;
              top: 0;
              left: 0;
              content: counters(item, ".") " ";
              counter-increment: item;
            }

            h2 {
              font-size: 35px;
              font-weight: 200;
              line-height: 1.08;
              margin-bottom: 1em;
              letter-spacing: -0.03em;
            }

            h4 {
              font-size: 18px;
              margin-bottom: 1em;
              line-height: 1.3;
            }

            .root-ol > li {
              margin-bottom: 40px;

              &:last-child {
                margin-bottom: 0;
              }
            }

            .root-ol > li:before,
            h3 {
              font-size: 25px;
              font-weight: 700;
              line-height: 1.3;
              margin-bottom: 1em;
            }

            a {
              font-weight: bold;
              text-decoration: underline;
            }
          `}
        >
          <h2>Allgemeine Geschäftsbedingungen (AGB)</h2>
          <ol className="root-ol">
            <li>
              <h3>Vertragsparteien und Definitionen</h3>
              <ol>
                <li>
                  Die vorliegenden Allgemeinen Geschäftsbedingungen („AGB“)
                  gelten für alle angebotenen Produkte, die zwischen der Themis
                  Digital GmbH, c/o Betahaus, Rudi-Dutschke-Straße 23, 10969
                  Berlin („Themis“) und ihren Vertragspartnerinnen („Kundin“),
                  die keine Verbraucherinnen im Sinne des § 13 BGB oder anderer
                  entsprechender einschlägiger gesetzlicher Bestimmungen sind,
                  geschlossen werden. Abweichende AGB der Kundin finden auf
                  Nutzungsverträge keine Anwendung, es sei denn, Themis stimmt
                  ihrer Anwendung ausdrücklich in Schriftform zu.
                </li>

                <li>
                  Themis bietet Produkte zur Sicherung, Teilen und Finden von
                  Wissensinhalten an. Die Produkte können von Einzelpersonen
                  oder von ganzen Organisationen genutzt werden. Bei der
                  Registrierung muss ein Nutzer entweder einen neuen
                  Arbeitsbereich erstellen oder einem bestehenden Arbeitsbereich
                  beitreten. Wenn ein Nutzer einen neuen Arbeitsbereich in
                  seinem eigenen Namen erstellt, ist er die Kundin. Wenn ein
                  Benutzer einen neuen Arbeitsbereich im Namen einer
                  Organisation (z. B. des Arbeitgebers des Benutzers) erstellt,
                  ist die Organisation die Kundin. Kundinnen können autorisierte
                  Nutzerinnen zu ihrem Arbeitsbereich einladen und müssen
                  mindestens eine autorisierte Nutzerin zur Administratorin
                  ernennen, die den Arbeitsbereich verwaltet und kontrolliert.
                  Wenn eine Nutzerin einem bestehenden Arbeitsbereich beitritt,
                  der von einer anderen Person oder Organisation erstellt wurde,
                  ist diese Person oder Organisation die Kundin und die Nutzerin
                  ist autorisiert den Bereich zu nutzen und gilt als Activated
                  User. Alle Kundinnen, Administratorinnen und autorisierte
                  Nutzerinnen müssen sich an diese Bedingungen halten.
                </li>
              </ol>
            </li>
            <li>
              <h3>
                Vertragsgegenstand und Zustandekommen des Nutzungsvertrages
              </h3>
              <ol>
                <li>
                  Themis betreibt das Software-as-a-Service Angebot zur
                  Sicherung, Teilen und Finden von Wissen über das Medium
                  Internet und stellt diese der Kundin zur Verfügung.
                </li>

                <li>
                  Vertragsgegenstand ist der internetbasierte Zugang zu den von
                  Themis angebotenen Produkten, in ihrer jeweils aktuellen
                  Version, mit den implementierten Funktionalitäten sowie die
                  Nutzung der durch Themis auf der Plattform bereitgestellten
                  Inhalte für die Dauer des Nutzungsvertrages durch die Kundin
                  (die „Leistungen“).
                </li>

                <li>
                  Die Leistungen umfassen die Bereitstellung des erforderlichen
                  Speicherplatzes für die vertragsgemäße Nutzung der Funktionen
                  der Plattform sowie die Speicherung der aus der Nutzung der
                  Plattform von der Kundin und/oder ihrer Activated User
                  generierten Daten auf einem Server von Themis während der
                  Vertragslaufzeit. Der konkrete Leistungs- und Funktionsumfang
                  sowie das vereinbarte Vergütungsmodell ergeben sich aus dem
                  gewählten{" "}
                  <a
                    href="https://themis.eu/pricing/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Angebot
                  </a>
                  , dass die Kundin für Ihre Zwecke wählt.
                </li>

                <li>
                  Die Leistungen werden auf Basis eines Activated User-Modells
                  betrieben, d.h. die Leistungen dürfen, je nach gewähltem
                  Paket, nur von den Nutzerinnen, die von der Kundin autorisiert
                  wurden, genutzt werden und sich über die Plattform namentlich
                  als Nutzerin registriert haben. Ein Activated User ist eine
                  Nutzerin der Leistungen von Themis, die sich namentlich auf
                  der Plattform angemeldet hat.
                </li>

                <li>
                  Die Kundin ist verantwortlich, dass die von ihr
                  bereitgestellten Angaben zutreffend sind und etwaige
                  Änderungen Themis mitgeteilt werden, wenn sie zu ihrer
                  Vertragserfüllung erforderlich sind. Insbesondere hat die
                  Kundin sicher zu stellen, dass die angegebenen E-Mail- und
                  Lieferadressen zutreffend sind und etwaige
                  Empfangsverhinderungen, die die Kundin vertreten zu hat,
                  entsprechend berücksichtigt werden (z.B. durch Kontrolle des
                  Spamordners der verwendeten E-Mailsoftware).
                </li>
              </ol>
            </li>
            <li>
              <h3>Umfang der Leistungen</h3>
              <ol>
                <li>
                  Während der Vertragslaufzeit erhält die Kundin Zugriff auf die
                  im Paket gewählten Leistungen.
                </li>

                <li>
                  Weitere Leistungen durch Themis sind nicht
                  Vertragsbestandteil.
                </li>

                <li>
                  Übergabepunkt für die Leistungen von Themis an die Kundin ist
                  der Routerausgang des Servers von Themis bzw. des von Themis
                  beauftragten externen Rechenzentrumsdienstleisters. Im Übrigen
                  fallen die Nutzung von Rechnersystemen und Leitungen Dritter
                  im Internet in den Risikobereich der Kundin.
                </li>

                <li>
                  Themis ist berechtigt, diesen Nutzungsvertrag zu ändern sowie
                  die Leistungen zu überarbeiten und den Funktionsumfang zu
                  erweitern, ohne dadurch die vereinbarten Leistungen wesentlich
                  einzuschränken. Themis wird der Kundin Änderungen dieses
                  Nutzugsvertrages vier (4) Wochen vor Inkrafttreten schriftlich
                  oder per E-Mail an die hinterlegte Kontaktadresse ankündigen.
                  Dabei wird Themis der Kundin die Änderungen sowie die Frist
                  zum Widerspruch mitteilen und ihn auf die Folgen des
                  Widerspruchs hinweisen. Der Widerspruch kann per E-Mail an die
                  angegebene Adresse erfolgen. Widerspricht die Kundin der
                  Änderung nicht schriftlich oder per E-Mail innerhalb der
                  mitgeteilten Frist ab Zugang der Änderungsmitteilung, wird die
                  Änderung Vertragsbestandteil. Widerspricht die Kundin, werden
                  sich die Parteien einvernehmlich auf eine Lösung verständigen.
                  Im Falle einer Nichteinigung ist Themis berechtigt, den
                  Vertrag zu kündigen.
                </li>
              </ol>
            </li>
            <li>
              <h3>Inhalte und Design</h3>
              <ol>
                <li>
                  Die Kundin sichert zu und gewährleistet, dass sie alle Rechte,
                  Titel und Interessen an ihren Benutzerinneninhalten besitzt
                  oder dass sie sich anderweitig alle erforderlichen Rechte an
                  ihren Benutzerinneninhalten gesichert hat, die notwendig sind,
                  um den Zugriff, die Nutzung und die Verbreitung dieser Inhalte
                  gemäß diesen Bedingungen zu ermöglichen. Im Verhältnis
                  zwischen der Kundin und Themis ist die Kundin Eigentümer aller
                  Rechte, Titel und Anteile an ihren Nutzerinneninhalten. Die
                  Kundin gewährt Themis eine gebührenfreie Lizenz zum Anzeigen,
                  Hosten, Kopieren und Verwenden ihrer Benutzerinhalte
                  ausschließlich in dem Umfang, der für die Bereitstellung des
                  Dienstes für sie erforderlich ist.
                </li>

                <li>
                  Die Kundin kann Inhalte innerhalb des Dienstes, über einen
                  Link veröffentlichen oder mit anderen teilen. Themis übernimmt
                  keine Verantwortung in Bezug auf eine solche gemeinsame
                  Nutzung von Designs und die Ermöglichung einer solchen
                  Aktivität durch Themis oder die Durchführung von Handlungen
                  durch den Dienst, um Designs auf Ihre Anweisung hin öffentlich
                  zu teilen, gilt nicht als Verstoß gegen die Verpflichtungen
                  von Themis gemäß diesen Bedingungen.
                </li>

                <li>
                  Themis ist nicht verantwortlich für die von der Kundin
                  erstellten Inhalt.
                </li>

                <li>
                  Die Kundin besitzt die Urheberrechte an den Inhalten, die von
                  ihr selbst erstellt wurden.
                </li>
              </ol>
            </li>
            <li>
              <h3>Hosting</h3>
              <ol>
                <li>
                  Der Service wird über Server von Themis betrieben bzw. des von
                  Themis beauftragten externen Rechenzentrumsdienstleisters.
                </li>

                <li>
                  Themis trägt dafür Sorge, dass die gespeicherten Daten über
                  das Internet abrufbar sind.
                </li>

                <li>
                  Der Kundin wird der für die Nutzung notwendige Speicherplatz
                  auf den Servern von Themis bereitgestellt. Die Kundin ist
                  nicht berechtigt, diesen Speicherplatz einer Dritten teilweise
                  oder vollständig, entgeltlich oder unentgeltlich zur Nutzung
                  zu überlassen.
                </li>

                <li>
                  Die Kundin verpflichtet sich, keine Inhalte auf dem
                  Speicherplatz zu speichern, deren Bereitstellung,
                  Veröffentlichung und Nutzung gegen geltendes Recht oder
                  Vereinbarungen mit Dritten verstößt.
                </li>
              </ol>
            </li>
            <li>
              <h3>Rechte der Kundin</h3>
              <ol>
                <li>
                  Die Inanspruchnahme der Leistungen ist technisch von der
                  Anmeldung des jeweiligen Activated Users abhängig. Die Kundin
                  kann Nutzerinnen auf der jeweiligen Plattform aktivieren und
                  deaktivieren. Eine Nutzerin gilt erst dann als aktiviert, wenn
                  ihre Registrierung durch eine Administratorin verifiziert
                  wird. Die Nutzerin muss sich anschließend mit ihrer
                  E-Mail-Adresse, ihrem Namen und einem vom ihr gewählten
                  Passwort anmelden. Die übrigen Profildaten sind freiwillig.
                  Die Kundin muss bis zur Deaktivierung durch die Kundin der
                  selbigen Nutzerin die vereinbarte Lizenzgebühr für diesen
                  entrichten.
                </li>

                <li>
                  Die Kundin ist berechtigt eigene Inhalte auf die Plattform zu
                  laden. Die Nutzung dieser Inhalte steht, sofern vertraglich
                  nicht anders vereinbart, ausschließlich den Activated Usern
                  der Kundin zur Verfügung. Für die Dauer des Nutzungsvertrages
                  räumt die Kundin Themis ein einfaches Recht ein, die Inhalte
                  zur Verwendung durch die Kundin und ihren Activated Usern zu
                  speichern, Datensicherungen zu erstellen, soweit erforderlich
                  mit Subunternehmern zu teilen und zum Abruf bereit zu halten.
                  Nach Ablauf des Nutzungsvertrages wird Themis die Inhalte der
                  Kundin löschen. Die Kundin ist verpflichtet, die
                  entsprechenden Inhalte vor Vertragsende zu sichern.
                </li>

                <li>
                  Die Kundin kann unbegrenzt viele Activated User gegen die
                  vereinbarten Gebühren im Rahmen des gewählten Leistungspaketes
                  hinzufügen. Activated User haben entsprechend der zwischen
                  Verkäufer und Käufer vertraglich besprochenen Laufzeit Zugriff
                  auf das jeweilige Angebot, sofern diese nicht vorab von der
                  Kundin deaktiviert werden. Die Zuteilung der einzelnen
                  Activated User auf systemisch definierte Rollen obliegt der
                  Kundin.
                </li>

                <li>
                  Themis räumt der Kundin im Rahmen der Activated User
                  Vereinbarung für die Vertragslaufzeit das nicht
                  ausschließliche, nicht unterlizenzierbare und nicht
                  übertragbare Recht ein, die Leistungen nach Maßgabe des
                  Nutzungsvertrages zu nutzen und/oder ihren Activated Usern die
                  Nutzung zu gestatten.
                </li>

                <li>
                  Sämtliche Rechte an den Leistungen verbleiben bei Themis. Die
                  Kundin ist nicht berechtigt, die Inhalte von Themis auf eigene
                  Rechner oder sonst außerhalb des Serversystems von Themis zu
                  kopieren.
                </li>

                <li>
                  Die Kundin darf die Inhalte von Themis nicht an Dritte
                  weitergeben (d.h. weder veräußern, noch vermieten oder
                  verleihen) und Dritten nicht zugänglich machen, sofern nicht
                  anders vertraglich vereinbart.
                </li>

                <li>
                  Die Kundin darf die von Themis zur Verfügung gestellten
                  Inhalte von Themis nicht übersetzen, abändern, bearbeiten,
                  dekompilieren, zurückentwickeln oder disassemblieren.
                  Gesetzlich zwingende Rechte unter dem Urhebergesetz bleiben
                  unberührt.
                </li>
              </ol>
            </li>
            <li>
              <h3>Pflichten der Kundin</h3>
              <ol>
                <li>
                  Die Kundin darf die Leistungen nicht zu rechtswidrigen Zwecken
                  verwenden.
                </li>

                <li>
                  Die Kundin schafft die erforderlichen kundenseitigen
                  Nutzungsvoraussetzungen, insbesondere die
                  Systemvoraussetzungen, Infrastruktur sowie für die
                  Telekommunikationsverbindung zwischen der Kundin und Themis
                  bis zum Übergabepunkt selbst.
                </li>

                <li>
                  Die Kundin ist verpflichtet, ihre Daten und Informationen vor
                  der Eingabe auf Viren oder sonstige schädliche Komponenten zu
                  prüfen und hierzu dem Stand der Technik entsprechende
                  Virenschutzprogramme einzusetzen.
                </li>

                <li>
                  Die Kundin hat die notwendigen Vorkehrungen zu treffen, um die
                  Nutzung der Leistungen durch Unbefugte zu verhindern. Zu
                  diesem Zwecke wird die Kundin, soweit erforderlich, seine
                  Mitarbeiter auf die Einhaltung des Urheberrechts hinweisen.
                  Die Kundin wird Themis unverzüglich unterrichten, wenn der
                  Verdacht besteht, dass Passwörter Unbefugten bekannt geworden
                  sein könnten.
                </li>

                <li>
                  Der Kundin obliegt außerdem die Überwachung ihres Personals
                  und insbesondere der Activated User. Sie ist verpflichtet,
                  diese zu verpflichten, ihrerseits die für sie geltenden
                  Bestimmungen des Vertrages einzuhalten.
                </li>

                <li>
                  Die Kundin wirkt darauf hin, dass die Activated User für den
                  Zugang nur sichere Passwörter wählen, um einen angemessenen
                  Zugangsschutz zu den Accounts zu gewährleisten.
                </li>

                <li>
                  Die Kundin darf von Themis eingerichtete Sicherheitsmaßnahmen
                  nicht umgehen oder ausschalten, sie darf Inhalte von Themis
                  nicht verändern.
                </li>

                <li>
                  Die Kundin ist selbst für Sicherungskopien von eigenen
                  Inhalten und/oder Daten von Activated Usern vor Einstellen auf
                  die Plattform verantwortlich. Soweit die Leistungen dies
                  technisch ermöglichen, hat die Kundin bzw. der Activated User
                  die von ihr während der Nutzung der Leistungen generierten
                  Daten ebenfalls regelmäßig durch Download zu sichern und
                  eigene Sicherungskopien zu erstellen.
                </li>

                <li>
                  Werden der Kundin Störungen bekannt, hat sie Themis
                  unverzüglich über Störungen der Leistungen zu unterrichten und
                  Themis – soweit es ihr möglich ist – in angemessenem Umfang
                  bei der Feststellung der Störung und ihrer Ursachen sowie
                  deren Beseitigung zu unterstützen.
                </li>
              </ol>
            </li>
            <li>
              <h3>Zahlungsarten und Zahlungsbedingungen</h3>
              <ol>
                <li>
                  Die Zahlungsverpflichtung der Kundin entsteht mit der
                  Bereitstellung der Leistung durch Themis und kann ab diesem
                  Zeitpunkt von der Themis in Rechnung gestellt werden.
                </li>

                <li>
                  Alle Preise verstehen sich zuzüglich MwSt. in Höhe des jeweils
                  gültigen Satzes.
                </li>

                <li>
                  Einwendungen gegen erfolgte Abrechnungen kann die Kundin nur
                  innerhalb von einer Woche ab Rechnungserhalt schriftlich
                  erheben. Werden diese innerhalb der Frist nicht erhoben, so
                  gilt die Abrechnung in Umfang und Höhe als anerkannt.
                </li>

                <li>
                  Zahlungen sind, sofern nicht anders vereinbart, ohne Abschlag,
                  Skonti oder andere Nachlässe zu leisten.
                </li>

                <li>
                  Beim Einsatz von Finanzinstituten und anderen
                  Zahlungsdienstleisterinnen, gelten im Hinblick auf die
                  Bezahlung zusätzlich die Geschäftsbedingungen und
                  Datenschutzhinweise dieser. Die Kundin wird gebeten diese
                  Regelungen und Hinweise als auch Informationen im Rahmen des
                  Bezahlungsvorgangs zu beachten. Dies insbesondere, weil die
                  Zurverfügungstellung von Zahlungsmethoden oder der Ablauf des
                  Zahlungsverfahrens auch von den Vereinbarungen zwischen der
                  Kundin und Finanzinstituten und Zahlungsdienstleister abhängig
                  sein können (z.B. vereinbarte Ausgabelimits, ortsbeschränkte
                  Zahlungsmöglichkeiten, Verifizierungsverfahren, etc.).
                </li>

                <li>
                  Die Kundin sorgt dafür, dass sie die ihr obliegenden
                  Voraussetzungen erfüllt, die zur erfolgreichen Bezahlung
                  mittels der gewählten Zahlungsart erforderlich sind. Hierzu
                  gehören insbesondere die hinreichende Deckung von Bank- und
                  anderen Zahlungskonten, Registrierung, Legitimierungen und
                  Autorisierung bei Bezahldiensten sowie die Bestätigung von
                  Transaktionen.
                </li>

                <li>
                  Sollte eine Zahlung aufgrund mangelnder Deckung des Kontos der
                  Kundin, Angabe einer falschen Bankverbindung oder eines
                  unberechtigten Widerspruchs der Kundin nicht durchgeführt oder
                  zurück gebucht werden, dann trägt die Kundin die hierdurch
                  entstandenen Gebühren, sofern sie die fehlgeschlagene oder
                  rückabgewickelte Buchung zu verantworten hat und im Fall einer
                  SEPA-Überweisung rechtzeitig über die Überweisung informiert
                  wurde (sogenannte "Pre-Notification").
                </li>

                <li>
                  Der Kundin stehen bei jährlicher Zahlung die nachfolgend
                  genannten Zahlungsarten zur Verfügung: Kauf auf Rechnung,
                  Kreditkarte, SEPA Lastschrift.
                </li>
                <li>
                  Bei monatlicher Zahlung stehen der Kundin die folgenden
                  Zahlungsarten zur Verfügung: Kauf auf Rechnung, Kreditkarte,
                  SEPA Lastschrift.
                </li>

                <li>
                  Bei Kauf auf Rechnung ist, sobald das Produkt geliefert sowie
                  in Rechnung gestellt wurde, der Betrag von der Kundin
                  innerhalb von zehn (10) Tagen ohne Abzug durch Zahlung auf das
                  Bankkonto von Themis zu begleichen, sofern nichts anderes
                  vereinbart ist.
                </li>

                <li>
                  Zahlungsverpflichtungen sind nicht kündbar und – sofern nicht
                  ausdrücklich im Vertrag angegeben – sind gezahlte Gebühren
                  nicht rückerstattungsfähig. Für den Fall, dass die Kundin ein
                  Leistungspaket von einem kostenpflichtigen Plan auf einen
                  kostenlosen Plan herabstuft, bleibt die Kundin für alle nicht
                  bezahlten Gebühren im Rahmen des kostenpflichtigen Plans
                  verantwortlich. Außerdem gelten die Leistungen im Rahmen des
                  kostenpflichtigen Plans nach Ablauf seiner anfänglichen
                  Laufzeit als vollständig bereitgestellt und geliefert.
                </li>
              </ol>
            </li>
            <li>
              <h3>Zahlungsverzug</h3>
              <ol>
                <li>
                  Themis ist berechtigt, der Kundin den Zugriff auf die Software
                  zu sperren oder auf eine kostenlose Version herabzustufen,
                  wenn diese mit den vertraglich vereinbarten Zahlungen
                  mindestens 28 Werktage in Verzug ist. Der Zugriff wird der
                  Kundin wieder gewährt, nachdem die noch offene
                  Zahlungsverpflichtung vollständig ausgeglichen wurde. Die
                  Kundin erkennt an und stimmt zu, dass eine Herabstufung zu
                  einer Einschränkung bestimmter Funktionen und zu einem
                  möglichen Verlust des Zugangs zu Kundinnen-Daten führen wird.
                </li>

                <li>
                  Themis behält sich das Recht zur außerordentlichen Kündigung
                  vor, sofern die Kundin der Zahlung nach vorheriger
                  schriftlicher Zahlungsaufforderung seitens Themis nicht
                  nachgekommen ist.
                </li>
              </ol>
            </li>
            <li>
              <h3>Vertragslaufzeit</h3>
              <ol>
                <li>
                  Der Nutzungsvertrag tritt, soweit nichts anderes im Angebot
                  vereinbart wurde, mit Unterzeichnung durch beide Parteien in
                  Kraft und hat eine Vertragslaufzeit von einem (1) Jahr
                  („Vertragsjahr”).
                </li>

                <li>
                  Ein kostenloses Abonnement ist 30 Tage lang nach Buchung der
                  Leistung zugänglich. Dagegen hat ein kostenpflichtiges
                  Abonnement eine Laufzeit, die abläuft oder gekündigt werden
                  kann. Der Vertrag bleibt so lange gültig, bis alle im Rahmen
                  des Vertrags abgeschlossenen Abonnements abgelaufen sind oder
                  gekündigt wurden oder der Vertrag selbst endet. Die Kündigung
                  des Vertrages führt zur Kündigung aller Abonnements und
                  Bestellungen.
                </li>

                <li>
                  Nach Ablauf der gewählten Leistung verlängert sich der Vertrag
                  jeweils automatisch um jeweils um den Zeitraum, der im
                  gewählten Leistungspaket definiert ist, wenn dieser nicht von
                  einer der Parteien mit einer Frist von vier (4) Wochen zum
                  Ende des Vertragsende ordentlich gekündigt wird.
                </li>

                <li>
                  Das Recht zur Kündigung des Nutzungsvertrages aus wichtigem
                  Grund bleibt unberührt. Zur fristlosen Kündigung ist Themis
                  insbesondere berechtigt, wenn die Kundin fällige Zahlungen
                  trotz Mahnung und Nachfristsetzung nicht leistet oder die
                  vertraglichen Bestimmungen über die Nutzung der Leistungen
                  verletzt. Eine fristlose Kündigung setzt in jedem Falle
                  voraus, dass der andere Teil schriftlich abgemahnt und
                  aufgefordert wird, den vermeintlichen Grund zur fristlosen
                  Kündigung in angemessener Zeit zu beseitigen.
                </li>

                <li>
                  Mit Beendigung des Nutzungsvertrages ist die Kundin nicht mehr
                  berechtigt, die angebotenen Leistungen zu nutzen.
                </li>
              </ol>
            </li>
            <li>
              <h3>Änderung des Leistungspaketes</h3>
              <ol>
                <li>
                  Die Kundin kann die Wahl des jeweiligen Leistungspaktes
                  während der Vertragslaufzeit und ohne Berücksichtigung der
                  Kündigungsfristen nachträglich anpassen, sofern auf das neu
                  gewählte Leistungspaket eine höhere monatliche Vergütung
                  entfällt. Das neu gewählte Leistungspaket wird unverzüglich
                  freigeschaltet.  
                </li>

                <li>
                  Sofern die Änderung des gewählten Leistungspakets innerhalb
                  eines laufenden Leistungszeitraums vorgenommen wird, so wird
                  die Differenz zwischen der neuen monatlichen Vergütung sowie
                  der vorherigen monatlichen Vergütung anteilig nach Tagen für
                  diesen ersten Leistungszeitraum berechnet. Dieser
                  Differenzbetrag ist binnen drei (3) Werktagen nach
                  Rechnungstellung zu bezahlen.
                </li>

                <li>
                  Dies gilt auch, wenn die Kundin die Möglichkeit gewählt hat,
                  die Vergütung im Voraus für eine Jahresperiode zu bezahlen. In
                  diesem Fall gilt ferner, dass auch für die weiteren
                  Leistungszeiträume der Jahresperiode die Differenz zwischen
                  der neuen monatlichen Vergütung sowie der vorherigen Vergütung
                  binnen drei (3) Werktagen nach Rechnungstellung zu bezahlen
                  ist.
                </li>
              </ol>
            </li>
            <li>
              <h3>Urheber- und Nutzungsrechte</h3>

              <ol>
                <li>
                  Die durch Themis vertriebenen Produkte sind schutzrechtlich
                  (insbesondere marken- und urheberrechtlich) geschützt. Die
                  Nutzungs- und Verwertungsrechte liegen bei Themis, bzw. den
                  jeweiligen Rechteinhaberinnen. Die Kundin verpflichtet sich,
                  diese Schutzrechte anzuerkennen und zu beachten.
                </li>

                <li>
                  Die Kundin erhält die einfachen Rechte, die erworbenen
                  Produkte zu vertragsgemäßen Zwecken zu nutzen. Im Übrigen sind
                  eine Nutzung und Verwertung der Produkte nicht zulässig.
                  Insbesondere dürfen urheberrechtlich geschützte Produkte von
                  Themis nicht im Internet oder in Intranets vervielfältigt,
                  verbreitet, öffentlich zur Verfügung gestellt oder in
                  sonstiger Art und Weise Dritten zur Verfügung gestellt werden.
                  Die öffentliche Wiedergabe, Vervielfältigung oder sonstige
                  Weiterveröffentlichung, sind nicht Bestandteil dieses
                  Vertrages und sind somit untersagt. Urheberrechtsvermerke,
                  Markenzeichen und andere Rechtsvorbehalte dürfen aus den
                  Produkten nicht entfernt werden, es sei denn, sofern dies zur
                  vertragsgemäßen Nutzung der Produkte erforderlich oder
                  gesetzlich erlaubt ist.
                </li>

                <li>
                  Sofern die Produkte einer bestimmten Nutzungslizenz
                  unterliegen, wird die Kundin über die Nutzungslizenz
                  informiert. In diesem Fall gelten die Regelungen der
                  Nutzungslizenz vor diesen AGB.
                </li>
              </ol>
            </li>
            <li>
              <h3>Nennung als Referenzkundin</h3>
              <ol>
                <li>
                  Themis ist berechtigt, die Kundin als Referenzkundin in
                  Pressemitteilungen oder sonstigen Marketingmaterialien sowie
                  auf der Webseite von Themis zu benennen. Die Kundin kann der
                  Nennung schriftlich (E-Mail ist ausreichend) widersprechen.
                </li>

                <li>
                  Die Angabe kann dabei auch mit der Darstellung des Firmenlogos
                  der Kundin erfolgen. Die Kundin räumt Themis zu diesem Zweck
                  ein Einfaches, zeitlich und räumlich unbeschränktes, nicht
                  übertragbares Nutzungsrecht hinsichtlich der hierfür
                  erforderlichen Namens- und Markenrechte ein.
                </li>
              </ol>
            </li>

            <li>
              <h3>Haftung und Gewährleistung</h3>

              <ol>
                <li>
                  Themis gewährleistet die Verfügbarkeit ihrer Produkte mit den
                  in der Leistungsbeschreibung benannten Eigenschaften.
                </li>

                <li>
                  Die Gewährleistung (Mängelhaftung) bestimmt sich vorbehaltlich
                  folgender Regelungen nach gesetzlichen Vorschriften.
                </li>

                <li>
                  Themis trägt keine Gewähr für die Anbindung der Kundin an das
                  Internet, die Internetverbindung der Kundin, die von der
                  Kundin eingesetzte Soft- und Hardware sowie etwaige durch sie
                  verursachten Störungen der Vertragsbegründung oder
                  -durchführung zwischen der Kundin und Themis.
                </li>

                <li>
                  Themis haftet auf Schadensersatz unbeschränkt, soweit die
                  Schadensursache auf Vorsatz oder grober Fahrlässigkeit beruht.
                  Ferner haftet der Themis für die leicht fahrlässige Verletzung
                  von wesentlichen Pflichten, deren Verletzung die Erreichung
                  des Vertragszwecks gefährdet, für die Verletzung von
                  Pflichten, deren Erfüllung die ordnungsgemäße Durchführung des
                  Vertrages überhaupt erst ermöglicht und auf deren Einhaltung
                  die Kundin regelmäßig vertraut (Kardinalpflichten) oder im
                  Fall vereinbarter Garantieversprechen. In diesem Fall haftet
                  Themis jedoch nur für den vorhersehbaren, vertragstypischen
                  und erwartbaren Schaden. Themis haftet nicht für die leicht
                  fahrlässige Verletzung anderer als der vorstehend genannten
                  Pflichten. Die vorstehenden Haftungsbeschränkungen gelten
                  nicht bei Verletzung von Leben, Körper und Gesundheit, für
                  einen Mangel nach Übernahme einer Garantie für die
                  Beschaffenheit des Produktes und bei arglistig verschwiegenen
                  Mängeln. Die Haftung nach dem Produkthaftungsgesetz bleibt
                  unberührt. Soweit die Haftung von Themis ausgeschlossen oder
                  beschränkt ist, gilt dies auch für die persönliche Haftung von
                  Arbeitnehmerinnen, Vertreterinnen und Erfüllungsgehilfen. Im
                  Übrigen sind Schadensersatzansprüche der Kundin
                  ausgeschlossen. Die vorstehenden Haftungsregelungen gelten
                  auch für Schadensersatzansprüche der Kundin im Rahmen der
                  gesetzlichen Gewährleistung von Themis.
                </li>
              </ol>
            </li>
            <li>
              <h3>Vertraulichkeit</h3>
              <ol>
                <li>
                  Die Parteien haben alle Informationen, die eine Partei der
                  anderen Partei unter dem Vertrag mitteilt oder von der anderen
                  Partei erhält, vertraulich zu behandeln und ausschließlich zum
                  Zweck der Leistungserbringung zu benutzen.
                </li>

                <li>
                  Die Parteien werden vertrauliche Informationen vor unbefugtem
                  Zugriff schützen und mit der gleichen Sorgfalt behandeln, die
                  sie bei ihren eigenen, gleichermaßen Vertraulichen
                  Informationen anwenden, mindestens jedoch die Sorgfalt eines
                  ordentlichen Kaufmanns.
                </li>

                <li>
                  Diese Vertraulichkeitsverpflichtungen gelten nicht für
                  Informationen, die der empfangenden Partei bekannt waren,
                  bevor sie sie von der anderen Partei unter dem Vertrag
                  erhalten hat, oder die empfangende Partei ohne Rückgriff auf
                  vertrauliche Informationen der anderen Partei selbständig
                  entwickelt hat oder die empfangende Partei von einer Dritten
                  erlangt hat, der in Bezug auf die Nutzung und Weitergabe
                  dieser Informationen nicht an Beschränkungen gebunden ist,
                  oder ohne Verschulden oder Zutun der empfangenden Partei
                  allgemein bekannt sind oder werden oder die eine Partei
                  gegenüber der empfangenden Partei durch schriftliche Erklärung
                  von der Vertraulichkeit ausgenommen hat.
                </li>
              </ol>
            </li>

            <li>
              <h3>Übertragung der Rechte und Pflichten</h3>

              <ol>
                <li>
                  Die Abtretung der Rechte und Pflichten aus diesem Vertrag ist
                  für die Kundin nur mit vorheriger ausdrücklicher Zustimmung
                  von Themis in Textform zulässig.
                </li>

                <li>
                  Themis ist berechtigt, nach Zustimmung der Kundin, Dritte mit
                  der Erfüllung der Pflichten aus diesem Vertrag zu betrauen.
                </li>
              </ol>
            </li>
            <li>
              <h3>Schlussbestimmungen</h3>
              <ol>
                <li>
                  Der Vertrag sowie die unter ihm getroffenen Vereinbarungen
                  unterliegen dem Recht der Bundesrepublik Deutschland unter
                  Ausschluss des UN-Kaufrechts. Ausschließlicher Gerichtsstand
                  ist der Sitz von Themis.
                </li>

                <li>
                  Es bestehen keine mündlichen Vereinbarungen zu dem Vertrag.
                  Änderungen oder Ergänzungen des Vertrags bedürfen zu ihrer
                  Wirksamkeit der Schriftform. Gleiches gilt für den Verzicht
                  auf dieses Schriftformerfordernis.
                </li>

                <li>
                  Sollten einzelne Bestimmungen des Vertrages unwirksam oder
                  undurchführbar sein oder werden, so wird die Wirksamkeit der
                  übrigen Bestimmungen des Vertrages hiervon nicht berührt. An
                  die Stelle der unwirksamen oder undurchführbaren Bestimmung
                  tritt eine Regelung, die dem wirtschaftlichen Zweck der
                  unwirksamen oder undurchführbaren Bestimmung so nahe wie
                  möglich kommt. Gleiches gilt für den Fall, dass die Parteien
                  nachträglich feststellen, dass der Vertrag lückenhaft ist.
                </li>
              </ol>
            </li>
          </ol>
        </div>
      </Wrapper>
      <Footer />
      <FeedbackWidget />
    </div>
  );
}
