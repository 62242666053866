import React, { useCallback, useEffect, useMemo } from "react";
import {
  CommenPutRequest,
  Comment,
  CommentPostRequest,
  CommentVisibility,
} from "../../types/Comment";
import { pusher } from "../helpers/pusher";
import { customToast } from "../../components/customToast";
import { serverErrorHandler } from "../../helpers/serverErrorHandler";
import { useTranslation } from "react-i18next";

import { sortBy } from "lodash";
import {
  createComment,
  deleteComment,
  getComments,
  updateComment,
} from "../../actions/comments";

const CommentsContext = React.createContext<{
  comments: Comment[];
  commentMode: boolean;
  setCommentMode: (value: boolean) => void;
  commentsOpen: boolean;
  toggleComments: () => void;
  openComments: () => void;
  closeComments: () => void;

  expandedComment: number;
  setExpandedComment: (id: number) => void;

  commentApi: {
    createEditorComment: (comment: CommentPostRequest) => Promise<void>;
    updateEditorComment: (comment: CommenPutRequest) => Promise<void>;
    deleteEditorComment: (commentId: number) => Promise<void>;
  };
}>({
  comments: [],
  commentMode: true,
  setCommentMode: () => {},
  commentsOpen: false,
  toggleComments: () => {},
  openComments: () => {},
  closeComments: () => {},

  expandedComment: 0,
  setExpandedComment: () => {},

  commentApi: {
    createEditorComment: () => Promise.resolve(),
    updateEditorComment: () => Promise.resolve(),
    deleteEditorComment: () => Promise.resolve(),
  },
});

export function CommentsProvider(props: {
  children: React.ReactNode;
  videoId: string;
}) {
  const { t } = useTranslation();

  const [comments, setComments] = React.useState<Comment[]>([]);
  const [commentMode, setCommentMode] = React.useState(true);
  const [commentsOpen, setCommentsOpen] = React.useState(false);

  const [expandedComment, setExpandedComment] = React.useState<number>(0);

  const fetchEditorComments = useCallback(async () => {
    try {
      const comments = await getComments(
        props.videoId,
        CommentVisibility.CREATOR
      );

      setComments(comments);
    } catch (error) {
      customToast.error(
        t("status.error", {
          error: serverErrorHandler(error),
        })
      );
    }
  }, [t, props.videoId]);

  useEffect(() => {
    fetchEditorComments();
  }, [fetchEditorComments]);

  const createEditorComment = useCallback(
    async (comment: CommentPostRequest) => {
      try {
        await createComment(comment, props.videoId);
      } catch (error) {
        customToast.error(
          t("status.error", {
            error: serverErrorHandler(error),
          })
        );
      }
    },
    [t, props.videoId]
  );

  const updateEditorComment = useCallback(
    async (comment: CommenPutRequest) => {
      try {
        await updateComment(comment, props.videoId);
      } catch (error) {
        customToast.error(
          t("status.error", {
            error: serverErrorHandler(error),
          })
        );
      }
    },
    [t, props.videoId]
  );

  const deleteEditorComment = useCallback(
    async (commentId: number) => {
      try {
        await deleteComment(commentId, props.videoId);
      } catch (error) {
        customToast.error(
          t("status.error", {
            error: serverErrorHandler(error),
          })
        );
      }
    },
    [t, props.videoId]
  );

  useEffect(() => {
    const channel = pusher.subscribe(
      `presence-video--${props.videoId}--${CommentVisibility.CREATOR}`
    );

    channel.bind("new-comment", (comment: Comment) => {
      setComments((comments) => [...comments, comment]);
    });
    channel.bind("delete-comment", (comment: { id: number }) => {
      setComments((comments) => comments.filter((c) => c.id !== comment.id));
    });
    channel.bind("edit-comment", (comment: { id: number; content: string }) => {
      setComments((comments) => {
        return comments.map((c) => {
          if (c.id === comment.id) {
            return {
              ...c,
              content: comment.content,
            };
          }
          return c;
        });
      });
    });

    return () => {
      pusher.unsubscribe(`presence-video--${props.videoId}`);
    };
  }, [props.videoId]);

  const commentApi = useMemo(
    () => ({
      createEditorComment,
      updateEditorComment,
      deleteEditorComment,
    }),
    [createEditorComment, updateEditorComment, deleteEditorComment]
  );

  return (
    <CommentsContext.Provider
      value={{
        comments: sortBy(comments, "id"),
        commentMode,
        setCommentMode: () => setCommentMode((value) => !value),
        commentsOpen,
        toggleComments: () => setCommentsOpen((open) => !open),
        openComments: () => setCommentsOpen(true),
        closeComments: () => setCommentsOpen(false),
        expandedComment,

        setExpandedComment: (id: number) => setExpandedComment(id),
        commentApi,
      }}
    >
      {props.children}
    </CommentsContext.Provider>
  );
}
export function useComments() {
  const context = React.useContext(CommentsContext);
  if (context === undefined) {
    throw new Error("useComments must be used within a CommentsProvider");
  }
  return context;
}
