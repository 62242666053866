import React, { useState, useCallback } from "react";
import { css } from "styled-components/macro";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";
import { useDebounce } from "use-debounce";

import { useStorage } from "../contexts/StorageContext";
import { Row } from "../../helpers/layout";
import { canEdit } from "../helpers/collaborator";

import { theme } from "../../themes/variables";

import { ReactComponent as DeleteIcon } from "../assets/icons/Delete.svg";

import { UserMeta } from "../../types/UserProfile";
import { LifetimeTooltip } from "../../components/Tooltip";
import { Button } from "../components/Button";
import { DisableUI } from "../components/DisableUI";
import { InputType } from "../../components/InputComponent";
import { QueryInput } from "../../components/QueryInput";
import { SelectField } from "../../forms/fields/SelectField";
import { UserMetaPreview } from "../../components/UserMetaPreview";

import { shareVideo } from "../actions/shareVideo";
import { getUsersBySearch } from "../../VideoEditor/actions/getUsersBySearch";
import { customToast } from "../../components/customToast";
import { serverErrorHandler } from "../../helpers/serverErrorHandler";

export type RoleOptionType = { value: string; label: string };
export const roleOptions: RoleOptionType[] = [
  {
    value: "OWNER",
    label: "collab-invite.role.owner",
  },
  {
    value: "EDITOR",
    label: "collab-invite.role.editor",
  },
  {
    value: "COMMENTER",
    label: "collab-invite.role.comment",
  },
  {
    value: "REMOVE",
    label: "collab-invite.remove",
  },
];

export type FormValues = {
  email: string;
  role: string;
};
export function ShareVideoForm(props: {
  className?: string;
  onShare: (values: FormValues) => void;
}) {
  const { t } = useTranslation();
  const { video } = useStorage();
  const [query, setQuery] = useState<string>("");
  const [chosenUser, setChosenUser] = useState<UserMeta | null>(null);
  const [debouncedQuery] = useDebounce(query, 500);

  const initailValues: FormValues = {
    email: "",
    role: "EDITOR",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required(),
  });

  const addPotentialViewer = useCallback((user: UserMeta) => {
    setChosenUser(user);
  }, []);

  return (
    <div
      css={css`
        width: 100%;
      `}
      className={props.className}
    >
      <Formik<FormValues>
        initialValues={initailValues}
        validationSchema={validationSchema}
        onSubmit={async (values: FormValues, { setSubmitting }) => {
          try {
            setSubmitting(true);
            if (video) {
              await shareVideo(video.uuid, values);
              props.onShare(values);
              setChosenUser(null);
              customToast.success(t("status.success"));
            }
          } catch (error: any) {
            customToast.error(
              t("status.error", {
                error: serverErrorHandler(error),
              })
            );
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, isValid, errors, setFieldValue }) => (
          <Form noValidate>
            <Row
              css={css`
                justify-content: space-between;
              `}
            >
              <div
                css={css`
                  display: flex;
                  height: 36px;
                  margin-right: 15px;
                  border: ${errors.email || errors.role
                    ? "1px solid red"
                    : "1px solid #8e8e8e"};
                  border-radius: 7px;
                  justify-content: space-between;
                  max-width: 304px;
                  width: 100%;
                  position: relative;
                `}
              >
                <QueryInput
                  getFunction={getUsersBySearch}
                  onChange={(user) => {
                    addPotentialViewer(user);
                    setFieldValue("email", user.email);
                    setQuery("");
                  }}
                  options={{
                    limit: 3,
                    offset: 0,
                    internalArgs: {
                      query: debouncedQuery.trim(),
                    },
                  }}
                  fetchOptions={{
                    enabled: debouncedQuery.trim().length >= 3,
                  }}
                  inputProps={{
                    value: query,
                    onChange: (e) => setQuery(e.target.value),
                    placeholder: t("collab-invite.input.placeholder"),
                    type: InputType.TEXT,
                    prepend: chosenUser && (
                      <div
                        css={css`
                          display: flex;
                          align-items: center;
                          justify-content: space-between;
                          width: 155px;
                          height: 24px;
                        `}
                      >
                        <div
                          css={css`
                            display: flex;
                            align-items: center;
                            background: ${theme.colors.lightBlueBackground};
                            border-radius: 7px;
                            height: 100%;
                            max-width: 100%;
                          `}
                        >
                          <span
                            css={css`
                              padding-right: 10px;
                              margin-left: 10px;
                              overflow: hidden;
                              white-space: nowrap;
                              text-overflow: ellipsis;
                            `}
                          >
                            {chosenUser.first_name} {chosenUser.last_name}
                          </span>
                          <span
                            onClick={() => setChosenUser(null)}
                            css={css`
                              width: 14px;
                              height: 14px;
                              margin-right: 10px;
                              cursor: pointer;
                              :hover {
                                svg {
                                  color: ${theme.colors.redMain};
                                }
                              }
                            `}
                          >
                            <DeleteIcon width={14} height={14} />
                          </span>
                        </div>
                      </div>
                    ),
                  }}
                  css={css`
                    input:-webkit-autofill,
                    input:-webkit-autofill:hover,
                    input:-webkit-autofill:focus,
                    input:-webkit-autofill:active {
                      -webkit-box-shadow: 0 0 0 30px white inset !important;
                    }

                    border: none;
                    background-color: transparent;
                    min-height: 32px;
                    height: 100%;
                    max-width: 200px;
                    font-size: 10px;
                    font-weight: 400;
                    line-height: 12px;
                    input {
                      height: 24px !important;
                      padding: 2px;
                      padding-left: 7px;
                      border: none;
                      background-color: transparent !important;
                      color: black !important;
                      font-size: 12px;
                    }
                  `}
                >
                  {(user) => {
                    return (
                      <UserMetaPreview
                        user={user}
                        dense
                        css={css`
                          border: 0.5px solid #8e8e8e;
                          border-radius: 7px;
                          padding: 4px 7px;
                          box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
                          margin-top: 2px;
                          cursor: pointer;
                        `}
                      />
                    );
                  }}
                </QueryInput>

                <SelectField
                  choices={roleOptions
                    .filter((role) => role.value !== "REMOVE")
                    .map((r) => {
                      return {
                        ...r,
                        label: t(r.label),
                      };
                    })}
                  name={"role"}
                  css={css`
                    margin-top: 2px;
                    width: 105px;
                    position: absolute;
                    top: 0;
                    right: 0;
                    padding-left: 2px;
                    div {
                      :after {
                        right: 1px !important;
                        top: 3px !important;
                      }
                    }
                    select {
                      height: 24px !important;
                      padding: 2px;
                      border: none;
                      background-color: transparent !important;
                      color: black !important;
                      font-size: 12pimport { components } from 'react-select';
x;
                      font-weight: 600;
                      background-image: none;

                      :focus {
                        outline: none;
                      }
                    }
                  `}
                />
              </div>

              <LifetimeTooltip
                activateOnClick
                text={t("tooltip.access")}
                lifeTime={5000}
                position="center"
                show={!canEdit(video?.current_user_role)}
                verticalPosition="top"
              >
                <DisableUI show={!canEdit(video?.current_user_role)}>
                  <Button
                    isSubmitting={isSubmitting}
                    disabled={!isValid}
                    type="submit"
                    css={css`
                      padding: 5px;
                      min-width: 7em;
                      color: #fff;
                      align-self: center;
                      font-size: 20px;
                      font-weight: 700;
                      margin-right: 20px;
                      margin-left: 0;
                      :hover {
                        color: #fff;
                        border: none;
                        background-color: #fe521c;
                      }
                      :disabled {
                        background-color: #f2f2f2;
                        color: #8e8e8e;
                      }
                      ${errors.email &&
                      css`
                        pointer-events: none;
                      `}
                    `}
                  >
                    {t("collab-invite.button.invite")}
                  </Button>
                </DisableUI>
              </LifetimeTooltip>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
}
