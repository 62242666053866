import styled, { css } from "styled-components/macro";
import { useDrag } from "react-dnd";
import { Headers } from "../helpers/headers";
import { ReactComponent as RectangleIcon } from "../assets/icons/Rectangle.svg";
import { ReactComponent as StarIcon } from "../assets/icons/Star.svg";
import { ReactComponent as ElipseIcon } from "../assets/icons/Elipse.svg";
import { ReactComponent as PolygonIcon } from "../assets/icons/Polygon.svg";
import { ReactComponent as ShapesIcon } from "../assets/icons/Shapes.svg";
import { ReactComponent as ArrowsIcon } from "../assets/icons/Arrows.svg";
import { ReactComponent as Arrow1Icon } from "../assets/icons/Arrow1.svg";
import { ReactComponent as Arrow2Icon } from "../assets/icons/Arrow2.svg";
import { ReactComponent as Arrow3Icon } from "../assets/icons/Arrow3.svg";
import { ReactComponent as StrokeSizeIcon } from "../assets/icons/StrokeSize.svg";
import { ColorAndVisibility } from "./ColorAndVisibility";
import { useEditor } from "../contexts/EditorContext";
import { Button } from "./Button";
import { VideoElementShape } from "../types/Video";
import { cloneDeep } from "lodash";
import { useTranslation } from "react-i18next";
import { usePlayback } from "../contexts/PlaybackContext";
import { useStorage } from "../contexts/StorageContext";
import { useAuth } from "../../contexts/UserContext";
import { v4 as uuid } from "uuid";

type ElementProps = {
  icon: JSX.Element;
  label: string;
  type: VideoElementShape["type"];
  noBorder: boolean;
};

const Element = (props: ElementProps) => {
  const { shapeConfig } = useEditor();
  const { activeScene } = usePlayback();
  const { api } = useStorage();
  const { userprofile } = useAuth();

  let config = cloneDeep(shapeConfig);

  if (["arrow-1", "arrow-2", "arrow-3"].includes(props.type)) {
    config.fillColor = config.strokeColor;
  }

  const [{ isDragging }, drag, dragPreview] = useDrag(
    () => ({
      type: "element",
      item: {
        type: props.type,
        config: config,
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [shapeConfig]
  );

  const createElement = () => {
    if (!activeScene || !userprofile) return;

    api.createElement({
      id: uuid(),
      scene_id: activeScene.id,
      order: activeScene.elements.length,
      type: props.type,
      states: [
        {
          id: uuid(),
          left: 100,
          top: 100,
          width: 100,
          height: 100,
          scale: 1,
          rotation: 0,
          start_time: 0,
          duration: 5000,
        },
      ],
      config: config,
    });
  };

  return (
    <div
      ref={dragPreview}
      onClick={createElement}
      style={{ opacity: isDragging ? 0.5 : 1 }}
      css={css`
        position: relative;
        padding: 10px;
        border-radius: 5px;
        width: calc(50% - 10px);
        border: 1px solid #000;
        color: #000;
        height: auto;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-bottom: 10px;
        margin-right: 10px;
        flex-direction: column;
        cursor: grab;
        background: #ffffff;
        transform: translate(0, 0);

        &:hover {
          border: 1px solid #e95b2e;
          color: #e95b2e !important;
        }

        ${props.noBorder &&
        css`
          background: transparent;
          width: calc(50% - 10px);
          border: none;
          color: #000;
          padding: 1px;
          margin: 0 8px 0 2px;
          :hover {
            border: none;
            color: #000;
          }
        `}
      `}
    >
      <div
        css={css`
          margin-bottom: 0px;
        `}
      >
        {props.icon}
      </div>
      <div>
        <h5
          css={css`
            margin: 0;
            font-weight: 500;
            font-size: 14px;
            :hover {
              color: #e95b2e !important;
            }
          `}
        >
          {props.noBorder ? "" : props.label}
        </h5>
        <div
          css={css`
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
          `}
          ref={drag}
        ></div>
      </div>
    </div>
  );
};

export function ShapesSidebar() {
  const { shapeConfig, setShapeConfig } = useEditor();
  const { t } = useTranslation();

  const elementShapes: ElementProps[] = [
    {
      icon: <RectangleIcon stroke={"currentColor"} strokeWidth={2} />,
      label: t("shapes.sidebar.shape.rectangle"),
      type: "rectangle",
      noBorder: false,
    },
    {
      icon: <ElipseIcon stroke={"currentColor"} strokeWidth={2} />,
      label: t("shapes.sidebar.shape.elipse"),
      type: "ellipse",
      noBorder: false,
    },
    {
      icon: <PolygonIcon stroke={"currentColor"} strokeWidth={2} />,
      label: t("shapes.sidebar.shape.polygon"),
      type: "polygon",
      noBorder: false,
    },
    {
      icon: <StarIcon stroke={"currentColor"} strokeWidth={2} />,
      label: t("shapes.sidebar.shape.star"),
      type: "star",
      noBorder: false,
    },
  ];

  const elementArrows: ElementProps[] = [
    {
      icon: <Arrow1Icon fill={"black"} />,
      label: "Arrow1",
      type: "arrow-1",
      noBorder: true,
    },
    {
      icon: <Arrow2Icon fill={"black"} />,
      label: "Arrow2",
      type: "arrow-2",
      noBorder: true,
    },
    {
      icon: <Arrow3Icon fill={"black"} />,
      label: "Arrow3",
      type: "arrow-3",
      noBorder: true,
    },
  ];

  const onSetShapeConfig = () => {
    if (shapeConfig.strokeSize === 1) {
      setShapeConfig("strokeSize", 2);
    } else if (shapeConfig.strokeSize === 2) {
      setShapeConfig("strokeSize", 3);
    } else if (shapeConfig.strokeSize === 3) {
      setShapeConfig("strokeSize", 4);
    } else {
      setShapeConfig("strokeSize", 1);
    }
  };

  return (
    <div>
      <Section>
        <SectionTitle
          css={css`
            display: flex;
          `}
        >
          <ShapesIcon
            css={css`
              width: 17px;
              height: 17px;
              align-self: center;
              color: #e95b2e;
              margin-right: 9px;
            `}
          />
          <Headers.H3>{t("shapes.sidebar.title.shape")}</Headers.H3>
        </SectionTitle>
        <div
          css={css`
            display: flex;
            flex-flow: row wrap;
          `}
        >
          {elementShapes.map((element: ElementProps, key) => (
            <Element key={key} {...element} />
          ))}
        </div>
      </Section>
      <hr
        css={css`
          background-color: #d9d9d9;
          height: 1px;
          border: 0;
          margin: 20px 0 25px 0 !important;
        `}
      />
      <Section>
        <SectionTitle
          css={css`
            display: flex;
          `}
        >
          <ArrowsIcon
            css={css`
              align-self: center;
              margin-right: 2px;
            `}
          />
          <Headers.H3>{t("shapes.sidebar.title.arrows")}</Headers.H3>
        </SectionTitle>
        <div
          css={css`
            display: flex;
            flex-flow: row wrap;
          `}
        >
          {elementArrows.map((element: ElementProps, key) => (
            <Element key={key} {...element} />
          ))}
        </div>
      </Section>
      <hr
        css={css`
          background-color: #d9d9d9;
          height: 1px;
          border: 0;
          margin: 20px 0 25px 0 !important;
        `}
      />
      <ColorAndVisibility
        title={t("shapes.sidebar.title.fill")}
        fillColor={shapeConfig.fillColor}
        colorChangeHandler={(value: string) => {
          setShapeConfig("fillColor", value);
        }}
        opacityValue={shapeConfig.fillOpacity}
        opacityChangeHandler={(value: string) => {
          setShapeConfig("fillOpacity", Number(value));
        }}
      />
      <hr
        css={css`
          background-color: #d9d9d9;
          height: 1px;
          border: 0;
          margin: 30px 0 25px 0 !important;
        `}
      />
      <ColorAndVisibility
        title={t("shapes.sidebar.title.stroke")}
        fillColor={shapeConfig.strokeColor}
        colorChangeHandler={(value: string) => {
          setShapeConfig("strokeColor", value);
        }}
        opacityValue={shapeConfig.strokeOpacity}
        opacityChangeHandler={(value: string) => {
          setShapeConfig("strokeOpacity", Number(value));
        }}
      />
      <Button
        icon={<StrokeSizeIcon />}
        css={css`
          background-color: #ffffff;
          border: 1px solid #ccc;
          border-radius: 6px;
          margin-top: 2px;
          color: #000000;
          min-width: 4em;
          justify-content: space-between;
          padding: 3px 8px;
          font-size: 12px;
        `}
        onClick={onSetShapeConfig}
      >
        {shapeConfig.strokeSize}
      </Button>
    </div>
  );
}

const Section = styled.div`
  margin-bottom: 20px;
`;

const SectionTitle = styled.div`
  margin-bottom: 10px;
`;
