import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";

import { ReactComponent as MicIcon } from "../VideoEditor/assets/icons/MicIcon.svg";
import { ReactComponent as VideoIcon } from "../VideoEditor/assets/icons/VideoIcon.svg";

import { Modal, ModalBody, ModalHeader } from "../components/Modal";
import { Headers } from "../VideoEditor/helpers/headers";
import {
  RecordingType,
  useRecording,
} from "../VideoEditor/contexts/RecordingContext";

export function ModalRecord(props: { isOpen: boolean; onClose: () => void }) {
  const { t } = useTranslation();
  const { record } = useRecording();

  const options: Array<{
    type: RecordingType;
    icon: JSX.Element;
    title: string;
    description: string;
  }> = [
    {
      type: "audio",
      icon: <MicIcon />,
      title: t("record.modal.audio.title"),
      description: t("record.modal.audio.description"),
    },
    {
      type: "video",
      icon: <VideoIcon />,
      title: t("record.modal.video.title"),
      description: t("record.modal.video.description"),
    },
  ];

  return (
    <Modal
      modalIsOpen={props.isOpen}
      onClose={props.onClose}
      contentStyles={{ maxWidth: "650px", padding: 0 }}
    >
      <ModalHeader
        closeIcon
        onClose={props.onClose}
        css={css`
          padding: 14px 21px 13px 32px;
          border-bottom: 1px solid #f2f2f2;
        `}
      >
        {t("record.modal.title")}
      </ModalHeader>

      <ModalBody
        css={css`
          padding: 0;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            padding: 45px 10px;
          `}
        >
          {options.map((option, index) => {
            return (
              <button
                key={index}
                css={css`
                  flex: 1 0 30%;
                  text-align: center;
                  cursor: pointer;
                  background: none;
                  border: none;

                  &:first-child {
                    margin-right: 10px;
                  }
                `}
                onClick={() => {
                  record(option.type);
                  props.onClose();
                }}
              >
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    width: 81px;
                    height: 81px;
                    margin: 0 auto 30px;
                    background: #fae7d9;
                    border-radius: 7px;
                  `}
                >
                  {option.icon}
                </div>
                <Headers.H3>{option.title}</Headers.H3>
                <Headers.H4
                  css={css`
                    font-size: 14px;
                    color: #7f7f7f;
                  `}
                >
                  {option.description}
                </Headers.H4>
              </button>
            );
          })}
        </div>
      </ModalBody>
    </Modal>
  );
}
