import { authorised } from "../../request";
import { buildQueryString } from "../helpers/buildQueryString";
import { Collaborator, CollaboratorRole } from "../types/Collaborator";

type Args = {
  videoId: string;
  role?: CollaboratorRole | "ALL";
};

export async function getCollaborators(args: Args) {
  const API = authorised();

  return API.request<Collaborator[]>({
    method: "GET",
    url: `/api/studio/themis/${args.videoId}/collaborators/?${buildQueryString({
      role: args.role,
    })}`,
  });
}
