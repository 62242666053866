import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { FeedbackWidget } from "../components/FeedbackWidget";
import { CanvasSettings } from "./components/CanvasSettings";
import { InteractivitySettings } from "./components/InteractivitySettings";
import { Navigation } from "./components/Navigation";
import { TooltipArea } from "../components/Tooltip";
import { SceneEditor } from "./components/SceneEditor";
import { SceneNavigator } from "./components/SceneNavigator";
import { SidebarSelector } from "./components/SidebarSelector";
import { StatusBar } from "./components/StatusBar";
import { useEditor, NavType } from "./contexts/EditorContext";
import { WalkThrough } from "./components/WalkThrough";
import { useLocation } from "react-router-dom";
import CommentsSidebar from "./components/Comments/CommentsSidebar";
import { ModalAiSuccess } from "../modals/ModalAiSuccess";
import { ModalAiError } from "../modals/ModalAiError";
import { useAi } from "./contexts/AiContext";
import { canEdit } from "../VideoEditor/helpers/collaborator";
import { useStorage } from "./contexts/StorageContext";
import { DisableUI } from "./components/DisableUI";
import { ModalRecord } from "../modals/ModalRecord";
import { theme } from "../themes/variables";

export function EditorLayout() {
  const {
    activeNav,
    canvasSize,
    activeNavType,
    recordModalVisible,
    setRecordModalVisible,
  } = useEditor();
  const location = useLocation();
  const { t } = useTranslation();
  const { video } = useStorage();
  const { statusModal, closeStatusModal } = useAi();

  return (
    <div
      css={css`
        display: flex;
        flex-flow: row wrap;
        height: 100vh;
        background: #fff;
      `}
    >
      {location.search === "?product_tour=true" && <WalkThrough />}

      <div
        css={css`
          width: 94px;
          flex: 0 0 94px;
          height: 100%;
          border-right: 1px solid #cdcdcd;
          position: relative;
          z-index: 2;
        `}
      >
        <TooltipArea
          show={!canEdit(video?.current_user_role)}
          position="center"
          text={t("tooltip.access")}
          css={css`
            height: 100%;
          `}
        >
          <DisableUI
            show={!canEdit(video?.current_user_role)}
            css={css`
              height: 100%;
            `}
          >
            <Navigation />
          </DisableUI>
        </TooltipArea>
      </div>
      <div
        css={css`
          position: relative;
          flex: 1 0 10%;
          height: 100%;
          display: flex;
          flex-flow: column;
          overflow: hidden;
          min-height: 0;
        `}
      >
        <div
          css={css`
            padding: 8px 0;
            flex: 0 0 52px;
            border-bottom: 1px solid #d0d0d0;
          `}
        >
          <StatusBar />
        </div>

        <div
          css={css`
            display: flex;
            flex-flow: row wrap;
            flex: 1 0 10%;
            min-height: 0;
          `}
        >
          <div
            css={css`
              width: 295px;
              flex: 0 0 295px;
              height: 100%;
              min-height: 0;
              padding-top: 10px;
              padding-right: 10px;
              box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
              border-right: 1px solid #cdcdcd;
              margin-left: -295px;
              transition: margin 500ms;

              ${activeNav &&
              activeNavType === NavType.Select &&
              css`
                margin-left: 0;
              `}
            `}
          >
            <div
              css={css`
                height: 100%;
                min-height: 0;
                padding: 20px 30px 20px 40px;
                box-sizing: border-box;
                overflow: scroll;

                /* width */
                ::-webkit-scrollbar {
                  width: 9px;
                  border-radius: 5px;
                }

                /* Track */
                ::-webkit-scrollbar-track {
                  background: transparent;
                  border-radius: 5px;
                }

                /* Handle */
                ::-webkit-scrollbar-thumb {
                  background: #cdcdcd;
                  border-radius: 5px;
                }

                /* Handle on hover */
                ::-webkit-scrollbar-thumb:hover {
                  background: #555;
                }
              `}
            >
              {activeNav && activeNavType === NavType.Select && (
                <SidebarSelector />
              )}
            </div>
          </div>

          <div
            css={css`
              flex: 1 0 10%;
              height: 100%;
              display: flex;
              flex-flow: column;
              background: #fafafa;
            `}
          >
            <div
              className="editor-toolbar"
              css={css`
                width: 100%;
                height: 54px;
                background: #fafafa;
                .tox .tox-tbtn.tox-tbtn--enabled {
                  color: white;
                  background: ${theme.colors.primary};
                }
                .tox .tox-tbtn.tox-tbtn--enabled:hover {
                  background: #ffe8de;
                  color: rgba(34, 47, 62, 0.5);
                }
                .tox .tox-split-button:hover {
                  box-shadow: none;
                }
                .tox .tox-tbtn--enabled svg,
                .tox .tox-tbtn--enabled:hover svg {
                  fill: currentColor;
                }
                .tox.tox-tinymce-inline .tox-editor-header {
                  border: none;
                  border-radius: unset;
                  border-bottom: 1px solid #cdcdcd;
                  padding: 8px 0;
                }
                .tox:not([dir="rtl"]) .tox-toolbar__group:not(:last-of-type) {
                  border-right: 1px solid #cdcdcd;
                }
                .tox .tox-tbtn {
                  height: 26px;
                }
                .tox .tox-tbtn--bespoke {
                  background: none;
                  border: 1px solid #252424;
                  border-radius: 15px;
                }
                .tox .tox-tbtn--bespoke:hover {
                  border: 1px solid transparent;
                }
                .tox .tox-tbtn--bespoke + .tox-tbtn--bespoke {
                  background: #f2f2f2;
                  border-radius: 2px;
                  border: none;
                  width: 56px;
                }
                .tox .tox-toolbar__group:last-of-type svg {
                  fill: #e95b2e !important;
                }
                .tox .tox-toolbar__group:last-of-type:hover svg {
                  opacity: 0.6;
                  fill: #e95b2e !important;
                }
                .tox .tox-tbtn.tox-tbtn--enabled svg {
                  fill: white !important;
                }

                .tox-tinymce--disabled {
                  visibility: hidden !important;
                  opacity: 0 !important;
                  display: none !important;
                }
                .tox .tox-dialog-wrap__backdrop {
                  background-color: rgba(39, 39, 39, 0.4);
                }
                .tox .tox-dialog__header .tox-button {
                  border-radius: 50%;
                  background: #e95b2e;
                  color: white;
                  padding: 0;
                }
                .tox .tox-dialog__header .tox-button:hover {
                  background: #e95b2e;
                  color: white !important;
                }
                .tox .tox-dialog {
                  border-radius: 20px;
                }
                .tox .tox-dialog__footer .tox-dialog__footer-end .tox-button {
                  border: none;
                }
                .tox
                  .tox-dialog__footer
                  .tox-dialog__footer-end
                  :nth-child(even) {
                  background: #e95b2e;
                }
                .tox .tox-collection--list .tox-collection__item--active {
                  color: ${theme.colors.white};
                  background: ${theme.colors.primary};
                }
                .tox .tox-collection--list .tox-collection__item--enabled {
                  color: ${theme.colors.primary}!important;
                  background: #f5e8de !important;
                }
                .tox
                  .tox-collection--list:not(.tox-background-menu)
                  .tox-collection__item.tox-collection__item--active:not(.tox-collection__item--state-disabled):not(.tox-fancymenuitem)
                  .tox-collection__item-label
                  > * {
                  background: none !important;
                }
                .tox
                  .tox-tbtn:not(.tox-tbtn--disabled):not(.tox-tbtn--enabled):focus,
                .tox
                  .tox-tbtn:not(.tox-tbtn--disabled):not(.tox-tbtn--enabled):focus-visible,
                .tox
                  .tox-tbtn:not(.tox-tbtn--disabled):not(.tox-tbtn--enabled):hover {
                  background: inherit;
                  box-shadow: none;
                  color: inherit;
                }
                .tox
                  .tox-tbtn:not(.tox-tbtn--disabled):not(.tox-tbtn--enabled):active {
                  background: inherit;
                  box-shadow: none;
                  color: inherit;
                }
                .tox .tox-tbtn--bespoke .tox-tbtn__select-label {
                  color: ${theme.colors.black};
                }
                .tox
                  .tox-tbtn:not(.tox-tbtn--disabled):not(.tox-tbtn--enabled):focus
                  svg,
                .tox
                  .tox-tbtn:not(.tox-tbtn--disabled):not(.tox-tbtn--enabled):focus-visible
                  svg,
                .tox
                  .tox-tbtn:not(.tox-tbtn--disabled):not(.tox-tbtn--enabled):hover
                  svg {
                  fill: rgba(34, 47, 62, 0.5);
                }
                .tox
                  .tox-collection--list:not(.tox-background-menu)
                  .tox-collection__item.tox-collection__item--active:not(.tox-collection__item--state-disabled):not(.tox-fancymenuitem)
                  svg {
                  fill: currentColor;
                  color: inherit;
                }
              `}
              onMouseDown={(e) => {
                e.stopPropagation();
              }}
            ></div>

            <div
              css={css`
                flex: 1 0 10%;
                padding: 20px;
              `}
            >
              <SceneEditor />
            </div>

            <div
              css={css`
                position: relative;
                flex: 0 0 auto;
                width: 100%;
                max-width: ${canvasSize.width + 4}px;
                left: -2px;
                margin-left: 10px;
                align-self: center;
                padding-bottom: 20px;
              `}
            >
              <SceneNavigator />
            </div>
          </div>
        </div>
        <CanvasSettings />
        <CommentsSidebar />
        <InteractivitySettings />
      </div>
      <FeedbackWidget />
      <ModalAiSuccess
        isOpen={statusModal === "success"}
        onClose={closeStatusModal}
      />
      <ModalAiError
        isOpen={statusModal === "error"}
        onClose={closeStatusModal}
      />
      <ModalRecord
        isOpen={recordModalVisible}
        onClose={() => {
          setRecordModalVisible(false);
        }}
      />
    </div>
  );
}
