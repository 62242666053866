import React, { useEffect, useState } from "react";
import { css } from "styled-components/macro";
import { SEO } from "../components/SEO";
import { PageLayout } from "../components/PageLayout";
import { FeedbackWidget } from "../components/FeedbackWidget";
import { Headers } from "../helpers/layout";
import { useAuth } from "../contexts/UserContext";
import Image from "../assets/img/Dashboard.png";
import { Button } from "../components/Button";
import { ReactComponent as PlusIcon } from "../VideoEditor/assets/icons/Plus_CreateScene.svg";
import { serverErrorHandler } from "../helpers/serverErrorHandler";
import { customToast } from "../components/customToast";
import { createVideo } from "../VideoEditor/actions/createVideo";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Loader } from "../components/Loader";
import { Landing } from "./Landing";
import { TemplateCard } from "../components/TemplateCard";
import { TemplatePreview } from "../modals/TemplatePreview";
import { getThemisTemplates } from "../helpers/templates/themis";
import { ScreensTourModal } from "../modals/ScreensTourModal";
import { SCREENS_TOUR_SEEN } from "../components/Carousel";
import { useLocation } from "react-router-dom";
import { ModalNoAccess } from "../modals/ModalNoAccess";
import { sendEvent } from "../helpers/tracking";

export type Template = {
  id: string;
  title: string;
  description: string;
  icon: React.ReactNode;
  iconBackground: string;
  previews: string[];
};

export function DashboardPage(props: {}) {
  const { userprofile } = useAuth();
  const [creating, setCreating] = React.useState(false);
  const history = useHistory();
  const { t } = useTranslation();
  const location = useLocation();
  const [showPreview, setShowPreview] = React.useState<string | null>(null);
  const [showTour, setShowTour] = useState(
    !localStorage.getItem(SCREENS_TOUR_SEEN)
  );
  const templatesData = getThemisTemplates(t);

  useEffect(() => {
    localStorage.setItem(SCREENS_TOUR_SEEN, "true");
  }, []);

  return (
    <PageLayout>
      <SEO>
        <title>Dashboard</title>
      </SEO>
      <div>
        {showTour && (
          <ScreensTourModal
            isOpen={showTour}
            onClose={() => setShowTour(false)}
          />
        )}
      </div>

      <ModalNoAccess
        isOpen={location.search === "?no_access=true"}
        onClose={() => history.push("/")}
      />

      <div
        css={css`
          display: flex;
          flex-direction: column;
          position: relative;
        `}
      >
        <div
          css={css`
            position: relative;
            max-width: 986px;
            width: 100%;
            margin: 58px auto;
            padding-top: 63px;
          `}
        >
          <Headers.H2
            css={css`
              position: absolute;
              top: 0;
              left: 0;
            `}
          >
            {t("dashboard.title")}&nbsp;{`${userprofile?.first_name}`}!
          </Headers.H2>
          <div
            css={css`
              width: 100%;
              height: 260px;
              display: flex;
              flex-direction: row;
            `}
          >
            <div
              css={css`
                background-image: url(${Image});
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
                flex: 0 0 237px;
                height: 100%;
              `}
            ></div>
            <div
              css={css`
                padding: 33px 55px;
                border: 1px solid #cdcdcd;
                border-radius: 0 26px 26px 0px;
                border-left: none;
                flex: 1 0 10%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
              `}
            >
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                `}
              >
                <Headers.H3>{t("dashboard.heroImage.title")}</Headers.H3>
                <Headers.H4
                  css={css`
                    font-size: 22px;
                    margin-top: -5px;
                  `}
                >
                  {t("dashboard.heroImage.subtitle")}
                </Headers.H4>
              </div>

              <Button
                css={css`
                  background-color: #e95b2e;
                  color: #fff;
                  margin-bottom: -13px;
                  font-size: 20px;
                  font-weight: 700;
                  max-width: 150px;
                  width: 100%;
                `}
                onClick={() => {
                  setShowTour(true);
                }}
                hoverStyles={`background:#FE521C`}
              >
                {t("dashboard.heroImage.button")}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div
        css={css`
          display: flex;
          flex-direction: column;
        `}
      >
        <Headers.H3
          css={css`
            font-size: 26px;
            margin: 0 auto;
          `}
        >
          {t("dashboard.template.title")}
        </Headers.H3>
        <Headers.H4
          css={css`
            font-size: 20px;
            margin: -1px auto;
            padding: 0 70px;
            text-align: center;
            line-height: 27px;
          `}
        >
          {t("dashboard.template.subtitle.first")}
          <br />
          {t("dashboard.template.subtitle.second")}
        </Headers.H4>
        <div
          css={css`
            display: flex;
            flex-flow: row wrap;
            margin-top: 57px;
          `}
        >
          {templatesData.map((data) => {
            return (
              <div
                key={data.id}
                css={css`
                  flex: 0 0 calc(33% - 11px);
                  margin-right: 20px;
                  &:last-child {
                    margin-right: 0;
                  }
                `}
              >
                <TemplateCard
                  template={data}
                  key={data.id}
                  onShowPreview={() => {
                    setShowPreview(data.id);
                  }}
                />
              </div>
            );
          })}
        </div>
        <Button
          css={css`
            border: 1px solid #000;
            background-color: transparent;
            color: #000;
            align-self: center;
            margin-top: 40px;
            font-size: 16px;
            font-weight: 700;
          `}
          hoverStyles="none"
          icon={
            <PlusIcon
              css={css`
                width: 12px;
                height: 12px;
              `}
            />
          }
          onClick={async function () {
            setCreating(true);

            try {
              const response = await createVideo({
                name: "New Themi",
              });

              sendEvent("Create Themi", {
                themiId: response.data.uuid,
              });

              history.push(`/video-editor/${response.data.uuid}`);
            } catch (error: any) {
              customToast.error(
                t("status.error", {
                  error: serverErrorHandler(error),
                })
              );
            } finally {
              setCreating(false);
            }
          }}
        >
          {creating ? <Loader /> : t("dashboard.template.createYourOwn-button")}
        </Button>
      </div>
      <TemplatePreview
        templates={templatesData}
        showPreview={showPreview}
        onClose={() => setShowPreview(null)}
        onSelect={(templateId) => setShowPreview(templateId)}
      />
      <FeedbackWidget />
    </PageLayout>
  );
}

export function DashboardPageMiddleware(props: {}) {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return <Landing />;
  }

  return <DashboardPage {...props} />;
}
