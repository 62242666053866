import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { css } from "styled-components/macro";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { customToast } from "../../components/customToast";
import { Button } from "../../VideoEditor/components/Button";
import { InputField } from "../fields/InputField";
import { InputType } from "../../components/InputComponent";
import { Column, Spacing, Row } from "../../helpers/layout";
import { resetPassword } from "../../actions/auth/resetPassword";

import { serverErrorHandler } from "../../helpers/serverErrorHandler";

interface FormValues {
  email: string;
}

export function ResetPasswordForm() {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <div>
      <Formik<FormValues>
        initialValues={{
          email: "",
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string().email().required(),
        })}
        onSubmit={async (values: FormValues, { setSubmitting }) => {
          try {
            setSubmitting(true);

            await resetPassword(values.email);
            customToast.success(t("status.success"));
            history.push("/login");
          } catch (error: any) {
            customToast.error(
              t("status.error", {
                error: serverErrorHandler(error),
              })
            );
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, isValid }) => (
          <Form noValidate>
            <Column
              gutter={Spacing.m}
              css={css`
                padding: 0 11px;
              `}
            >
              <div
                css={css`
                  text-align: center;
                  margin-top: 0;
                  margin-bottom: 40px;
                  font-weight: 400;
                  padding: 0 60px;
                  letter-spacing: -0.05em;
                  line-height: 19.2px;
                `}
              >
                {t("auth.reset-info")}
              </div>

              <InputField
                name="email"
                type={InputType.TEXT}
                placeholder={t("auth.email")}
                css={css`
                  padding: 0 30px;
                `}
              />

              <Row justify="flex-end" align="center">
                <Button
                  isSubmitting={isSubmitting}
                  disabled={!isValid}
                  type="submit"
                  css={css`
                    margin: 30px auto;
                    color: #fff;
                  `}
                >
                  {t("auth.reset-password")}
                </Button>
              </Row>
            </Column>
            <Link
              css={css`
                display: flex;
                justify-content: center;
                font-size: 16px;
                font-weight: 500;
                cursor: pointer;
              `}
              to="/login"
            >
              {t("auth.back-to-login")}
            </Link>
          </Form>
        )}
      </Formik>
    </div>
  );
}
