import { authorised } from "../../request";

export async function updateRoleForAnyone(videoId:string,data:{edit_link_access_type:string}) {
    const API = authorised();

    return await API.request({
      method: "PUT",
      url: `/api/studio/themis/${videoId}/privacy-settings/`,
      data
    });
}