import React, { useState } from "react";
import { css } from "styled-components/macro";

import { Loader2 } from "./Loader";
import { ReactComponent as CheckIcon } from "../assets/icons/Check.svg";

import { theme } from "../themes/variables";

enum ButtonState {
  INITIAL = "INITIAL",
  LOADING = "LOADING",
  LOADED = "LOADED",
}

export type ActionButtonProps = {
  title?: string;
  name?: string;
  onClick: (callback: Promise<void>) => Promise<void>;
  disabled?: boolean;
  minLoadingTime?: number;
  minReturnToInitialTime?: number;
  children?: React.ReactNode;
  className?: string;
};

export function ActionButton(props: ActionButtonProps) {
  const {
    title,
    name,
    onClick,
    disabled,
    minLoadingTime = 1000,
    minReturnToInitialTime = 5000,
    children,
    className,
  } = props;

  const [buttonState, setButtonState] = useState<ButtonState>(
    ButtonState.INITIAL
  );

  const callback = async (before: number): Promise<void> => {
    return new Promise((resolve) => {
      const after = performance.now();
      const diff = after - before;

      if (diff < minLoadingTime) {
        setTimeout(() => {
          resolve();
        }, minLoadingTime - diff);
      } else {
        resolve();
      }
    });
  };

  const onClickButton = async () => {
    if (buttonState !== ButtonState.INITIAL) return;

    setButtonState(ButtonState.LOADING);

    const before = performance.now();

    await onClick(callback(before));
    await callback(before);
    setButtonState(ButtonState.LOADED);

    setTimeout(() => {
      setButtonState(ButtonState.INITIAL);
    }, minReturnToInitialTime);
  };

  return (
    <button
      type={"button"}
      name={name}
      title={title}
      css={css`
        padding: 11px 40px 11px 15px;
        background: ${disabled ? "#F2F2F2" : theme.colors.primary};
        color: ${disabled ? "#8E8E8E" : theme.colors.white};
        border-radius: 30px;
        border: none;
        font-weight: 700;
        font-size: 20px;
        line-height: 120%;
        cursor: pointer;
        background-position: center;
        transition: background 0.6s;
        :disabled {
          cursor: initial;
        }
        ${!disabled &&
        css`
          :hover {
            background: #ff812fd9
              radial-gradient(circle, transparent 1%, #ff812fd9 1%)
              center/15000%;
          }
          :active {
            background-color: ${theme.colors.primaryLighter};
            background-size: 100%;
            transition: background 0s;
          }
        `}
      `}
      disabled={disabled}
      className={className}
      onClick={onClickButton}
    >
      <div
        css={css`
          display: flex;
          justify-content: center;
          align-items: center;
        `}
      >
        {/* State icon */}
        <div
          css={css`
            width: 15px;
            height: 15px;
            margin-right: 10px;
          `}
        >
          {buttonState === ButtonState.LOADING && <Loader2 size={15} />}

          {buttonState === ButtonState.LOADED && (
            <CheckIcon color={theme.colors.white} width={15} height={15} />
          )}
        </div>

        <span
          css={css`
            display: flex;
            vertical-align: middle;
            font-size: 20px;
            line-height: 24px;
          `}
        >
          {children}
        </span>
      </div>
    </button>
  );
}
