import React, { useState, useRef, useEffect, useCallback } from "react";
import { AxiosResponse } from "axios";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";

import { theme } from "../themes/variables";

import { InputElement, InputProps } from "./InputElement";
import { Loader2 } from "./Loader";

import { useClickOutside } from "../VideoEditor/hooks/useClickOutside";
import { useNewFetch, UseNewFetchOptions } from "../useAPI";
import { customToast } from "../components/customToast";
import { serverErrorHandler } from "../helpers/serverErrorHandler";

export type QueryInputProps<T, Q> = {
  inputProps?: Omit<InputProps, "className">;
  fetchOptions?: UseNewFetchOptions;
  onChange: (elem: T) => void;
  getFunction: (args: Q) => Promise<
    AxiosResponse<{
      count: number;
      next: null | string;
      previous: null | string;
      results: T[];
    }>
  >;
  options?: {
    limit?: number;
    offset?: number;
    internalArgs?: Omit<Q, "limit" | "offset">;
  };
  children: (row: T) => React.ReactNode | React.ReactNode[] | string | null;
  className?: string;
};

export function QueryInput<T, Q>(props: QueryInputProps<T, Q>) {
  const {
    inputProps,
    onChange,
    getFunction,
    options,
    children,
    className,
    fetchOptions = {
      enabled: true,
    },
  } = props;

  const inputRef = useRef(null);
  const [focused, setFocused] = useState(false);
  const { t } = useTranslation();
  const { data, error, isLoading, setData } = useNewFetch(
    getFunction,
    {
      ...options?.internalArgs,
      limit: options?.limit,
      offset: options?.offset,
    } as Q,
    fetchOptions
  );

  useEffect(() => {
    if (!fetchOptions.enabled) {
      setData({
        count: 0,
        next: null,
        previous: null,
        results: [],
      });
    }
    // eslint-disable-next-line
  }, [fetchOptions.enabled]);

  useClickOutside(inputRef, () => {
    setFocused(false);
  });

  const onChose = useCallback(
    (elem: T) => {
      onChange(elem);
      setData({
        count: 0,
        next: null,
        previous: null,
        results: [],
      });
      if (inputProps) inputProps.value = "";
    },
    [inputProps, onChange, setData]
  );

  if (error) {
    customToast.error(
      t("status.error", {
        error: serverErrorHandler(error),
      })
    );
  }

  return (
    <div
      ref={inputRef}
      css={css`
        position: relative;
      `}
    >
      <InputElement
        append={
          isLoading ? (
            <div
              css={css`
                width: 15px;
                height: 15px;
              `}
            >
              <Loader2 size={15} />
            </div>
          ) : (
            <div
              css={css`
                width: 15px;
                height: 15px;
              `}
            ></div>
          )
        }
        className={className}
        {...inputProps}
        onFocus={() => setFocused(true)}
      />

      <div
        css={css`
          position: absolute;
          width: 100%;
          background: ${theme.colors.white};
          z-index: 1;
          ${!focused &&
          css`
            visibility: hidden;
          `}
        `}
      >
        {data?.results.map((elem, i) => {
          return (
            <div onClick={() => onChose(elem)} key={i}>
              {children(elem)}
            </div>
          );
        })}
      </div>
    </div>
  );
}
