import React from "react";
import styled, { css } from "styled-components";
import { theme } from "../themes/variables";

export enum Spacing {
  none = "0",
  xs = "0.25rem",
  s = "0.5rem",
  m = "1rem",
  l = "1.5rem",
  xl = "2rem",
  xxl = "4rem",
  xxxl = "8rem",
  xxxxl = "16rem",
}

export type Align =
  | "flex-start"
  | "flex-end"
  | "space-between"
  | "center"
  | "space-around";

export const Column = styled.div<{
  align?: Align;
  justify?: Align;
  gutter?: Spacing;
}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => props.justify || "flex-start"};
  align-items: ${(props) => props.align || "flex-start"};

  > * {
    width: 100%;
  }

  > * + * {
    margin-top: ${(props) => props.gutter || Spacing.m};
  }
`;

export const Row = styled.div<{
  align?: Align;
  justify?: Align;
  gutter?: Spacing;
  width?: number | string;
  wrap?: "wrap" | "nowrap";
}>`
  display: flex;
  flex-wrap: ${(props) => props.wrap || "nowrap"};
  justify-content: ${(props) => props.justify || "flex-start"};
  align-items: ${(props) => props.align || "flex-start"};
  width: ${({ width }) => {
    if (width) {
      if (typeof width === "number") {
        if (width <= 1) {
          return width * 100 + "%";
        } else {
          return width + "px";
        }
      } else {
        return width;
      }
    }
    return undefined;
  }};

  ${(props) => {
    const gutter = props.gutter || Spacing.m;

    if (props.wrap) {
      return css`
        margin-right: ${"-" + gutter};
        > * {
          margin-right: ${gutter};
        }
      `;
    } else {
      return css`
        > * + * {
          margin-left: ${gutter};
        }
      `;
    }
  }};
`;

export const nl2br = (text: string) => {
  return text.split(/\n/).map((item, i) => {
    return <p key={i}>{item}</p>;
  });
};

const H1 = styled.h1`
  color: ${theme.colors.black};
  font-size: 40px;
  font-weight: 700;
  line-height: 60px;
`;

const H2 = styled.h2`
  color: ${theme.colors.black};
  font-size: 30px;
  font-weight: 600;
  line-height: 46px;
`;

const H3 = styled.h3`
  color: ${theme.colors.black};
  font-size: 25px;
  font-weight: 500;
  line-height: 42px;
`;

const H4 = styled.h4`
  color: ${theme.colors.black};
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
`;

const H5 = styled.h5`
  color: ${theme.colors.black};
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
`;

const H6 = styled.h6`
  color: ${theme.colors.black};
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
`;
export function splitLines(str: string) {
  return str.split("\n").map((s) => {
    return (
      <React.Fragment key={s}>
        {s}
        <br />
      </React.Fragment>
    );
  });
}

export const Headers = {
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
};
