import { useParams } from "react-router-dom";
import { PlayerProvider } from "../VideoPlayer/contexts/PlayerContext";
import { VideoPlayer } from "../VideoPlayer/VideoPlayer";
import { PlaybackProvider } from "./contexts/PlaybackContext";

export function ThemiPreview() {
  const params = useParams<{
    videoId: string;
  }>();

  return (
    <PlayerProvider videoId={params.videoId} preview>
      {(video) => (
        <PlaybackProvider video={video} autoPause>
          <VideoPlayer preview />
        </PlaybackProvider>
      )}
    </PlayerProvider>
  );
}
