import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";

import { clipboardCopy } from "../../helpers/clipboardCopy";

import { Button } from "../../components/Button";
import { CodeArea } from "../../components/CodeArea";
import { LifetimeTooltip } from "../../components/Tooltip";

export type PublishExportLinkType = {
  link: string;
};

export function ExportLink(props: PublishExportLinkType) {
  const { t } = useTranslation();

  const [tooltip, setTooltip] = useState(false);

  const onClipboardCopy = async () => {
    await clipboardCopy(props.link);
    setTooltip(true);
  };

  return (
    <div
      css={css`
        width: 100%;
        padding: 0 19px;
        padding-bottom: 12px;
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 17px 0 23px 0;
        `}
      >
        <div
          css={css`
            font-weight: 500;
            font-size: 20px;
            line-height: 120%;
          `}
        >
          {t("publish.export.export-html")}
        </div>

        <div
          css={css`
            position: relative;
          `}
        >
          <LifetimeTooltip
            isActive={tooltip}
            text={t("tooltip.copy")}
            position="center"
            verticalPosition="top"
            onDeactivate={() => setTooltip(false)}
            lifeTime={3000}
            css={css`
              display: flex;
            `}
          >
            <Button onClick={onClipboardCopy} hoverStyles="none">
              {t("publish.export.copy-code")}
            </Button>
          </LifetimeTooltip>
        </div>
      </div>

      <CodeArea>{props.link}</CodeArea>
    </div>
  );
}
