import { Form, Formik } from "formik";
import { css } from "styled-components/macro";
import * as Yup from "yup";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { customToast } from "../../components/customToast";
import { useAuth } from "../../contexts/UserContext";
import { CheckboxField } from "../fields/CheckboxField";
import { InputField } from "../fields/InputField";
import { InputType } from "../../components/InputComponent";
import { Button } from "../../VideoEditor/components/Button";
import { theme } from "../../themes/variables";
import { Column, Row, Spacing } from "../../helpers/layout";
import { FormValues, registration } from "../../actions/auth/registration";
import { serverErrorHandler } from "../../helpers/serverErrorHandler";
import { USER_CONFIRM_EMAIL } from "../../actions/auth/resendConfirmationEmail";
import PasswordInstructions from "../../components/PasswordInstructions";
import { useCheckMobile } from "../../hooks/useCheckMobile";
import { sendEvent } from "../../helpers/tracking";

export function RegistrationForm() {
  const { authenticate } = useAuth();
  const { isMobile } = useCheckMobile();
  const history = useHistory();
  const re = new RegExp("[a-zA-Z-]");
  const passwordRe = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#&$%^*+,.:;<=>?({//\\\\})\\[\\]`~|\"'_-])(?=.{9,150})"
  );
  const { t } = useTranslation();

  const emailQueryArgument = new URLSearchParams(history.location.search).get(
    "email"
  );

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().matches(re).required(),
    last_name: Yup.string().matches(re).required(),
    email: Yup.string().email().required(),
    password: Yup.string().matches(passwordRe).required(),
    password2: Yup.string()
      .oneOf([Yup.ref("password"), null], t("auth.pwd-not-match"))
      .required(t("auth.pwd-not-match")),
    checkbox: Yup.boolean().oneOf([true], t("auth.accept-checkbox")).required(),
  });

  const initialValues: FormValues = {
    first_name: "",
    last_name: "",
    email: emailQueryArgument || "",
    password: "",
    password2: "",
  };

  return (
    <div>
      <Formik<FormValues>
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values: FormValues, { setSubmitting }) => {
          try {
            setSubmitting(true);

            const response = await registration(values);

            sendEvent("Registration");

            if (!response.data.email_confirmed) {
              localStorage.setItem(USER_CONFIRM_EMAIL, values.email);

              history.push("/confirm-email");
            } else if (!response.data.is_verified) {
              history.push("/verification-info");
            } else {
              authenticate(response.data.token, response.data.userprofile);

              history.push("/");
            }
          } catch (error: any) {
            customToast.error(
              t("status.error", {
                error: serverErrorHandler(error),
              })
            );
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, isValid, values }) => {
          return (
            <Form noValidate>
              <Column
                gutter={Spacing.m}
                css={css`
                  margin-top: 30px;
                `}
              >
                <Row gutter={Spacing.none} justify="flex-start" wrap="wrap">
                  <div
                    css={css`
                      flex: 0 0 50%;
                      padding-right: 10px;

                      ${isMobile &&
                      css`
                        flex: 0 0 100%;
                        padding-right: 0;
                        margin-bottom: 10px;
                      `}
                    `}
                  >
                    <InputField
                      name="first_name"
                      type={InputType.TEXT}
                      label={t("auth.first-name")}
                      placeholder={t("auth.first-name")}
                      css={css`
                        width: 100%;
                      `}
                    />
                  </div>
                  <div
                    css={css`
                      flex: 0 0 50%;

                      ${isMobile &&
                      css`
                        flex: 0 0 100%;
                        padding-right: 0;
                      `}
                    `}
                  >
                    <InputField
                      name="last_name"
                      type={InputType.TEXT}
                      label={t("auth.last-name")}
                      placeholder={t("auth.last-name")}
                      css={css`
                        width: 100%;
                      `}
                    />
                  </div>
                </Row>
                <Row gutter={Spacing.none} wrap="wrap">
                  <div
                    css={css`
                      flex: 0 0 100%;
                    `}
                  >
                    <InputField
                      name="email"
                      type={InputType.EMAIL}
                      label={t("auth.register.email")}
                      placeholder={t("auth.email")}
                      disabled={!!emailQueryArgument}
                    />
                  </div>
                  <div
                    css={
                      !isMobile &&
                      css`
                        flex: 0 0 50%;
                      `
                    }
                  ></div>
                </Row>
                <Row gutter={Spacing.none} wrap="wrap">
                  <div
                    css={css`
                      flex: 0 0 50%;
                      padding-right: 10px;

                      ${isMobile &&
                      css`
                        flex: 0 0 100%;
                        padding-right: 0;
                        margin-bottom: 10px;
                      `}
                    `}
                  >
                    <InputField
                      hideTooltip
                      name="password"
                      type={InputType.PASSWORD}
                      label={t("auth.register.create-password")}
                      placeholder={t("auth.password")}
                    />
                  </div>
                  <div
                    css={css`
                      flex: 0 0 50%;

                      ${isMobile &&
                      css`
                        flex: 0 0 100%;
                        padding-right: 0;
                      `}
                    `}
                  >
                    <InputField
                      name="password2"
                      type={InputType.PASSWORD}
                      label={t("auth.register.repeat-password")}
                      placeholder={t("auth.password")}
                    />
                  </div>
                </Row>

                <PasswordInstructions password={values.password} />
              </Column>

              <CheckboxField
                name="checkbox"
                css={css`
                  margin-top: 27px;
                `}
              >
                {
                  <div
                    css={css`
                      width: 100%;
                      font-size: 16px;
                      margin-right: 40px;
                      a {
                        font-weight: bold;
                      }
                    `}
                  >
                    <Trans i18nKey="auth.I-have-read">
                      I have read and agree with the
                      <a
                        href={`${process.env.REACT_APP_FRONTEND_BASE_URL}/terms-and-conditions`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        terms of service.
                      </a>
                    </Trans>
                  </div>
                }
              </CheckboxField>
              <div
                css={css`
                  margin-top: 20px;
                  display: flex;
                  flex-direction: column;
                `}
              >
                <Button
                  isSubmitting={isSubmitting}
                  type="submit"
                  disabled={!isValid}
                  css={css`
                    margin: 0 auto;
                    color: ${theme.colors.white};
                    font-weight: 500;
                    min-width: 14em;
                  `}
                >
                  {t("auth.register.button")}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
