import React, { useEffect, useState, useMemo, useRef } from "react";
import { Video, VideoElement, VideoScene } from "../types/Video";
import useInterval from "@use-it/interval";
import {
  calculateVideoDuration,
  getVideoScenes,
  getMaxSceneOrder,
} from "../helpers/video";

const PlaybackContext = React.createContext<{
  currentTime: number;
  videoDuration: number;
  playing: boolean;
  play: () => void;
  pause: () => void;
  setCurrentTime: (time: number) => void;
  currentFrame: VideoElement[];
  scenes: VideoScene[];
  activeScene: VideoScene | null;
  maxSceneOrder: number;
}>({
  currentTime: 0,
  videoDuration: 0,
  maxSceneOrder: 0,
  activeScene: null,
  playing: false,
  play: () => {},
  pause: () => {},
  setCurrentTime: (time: number) => {},
  currentFrame: [],
  scenes: [],
});

const interval = 15;

// Normalize the order property so it's always bigger than zero
export function normalizeOrder(elements: VideoElement[]) {
  const minOrder = Math.min(...elements.map((e, i) => e.order || i));

  return elements.map((e, i) => {
    const order = e.order || i;

    return {
      ...e,
      order: order - minOrder,
    };
  });
}

export function PlaybackProvider(props: {
  children: React.ReactNode;
  video: Video;
  autoPause?: boolean;
}) {
  const { video } = props;
  const [playing, setPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const videoDuration = useMemo(
    () => (video ? calculateVideoDuration(video.schema.schema) : 0),
    [video]
  );
  const scenes = useMemo(
    () => (video ? getVideoScenes(video.schema.schema) : []),
    [video]
  );
  const [activeScene, setActiveScene] = useState<VideoScene | null>(null);
  let currentFrame = useMemo(
    () => (activeScene && activeScene.elements ? activeScene.elements : []),
    [activeScene]
  );
  const stopIds = useRef<string[]>([]);
  const maxSceneOrder = useMemo(() => getMaxSceneOrder(scenes), [scenes]);

  currentFrame = normalizeOrder(currentFrame);

  useInterval(() => {
    if (playing) {
      setCurrentTime((time) => {
        if (time <= videoDuration) {
          return time + interval;
        }

        return time;
      });
    }
  }, interval);

  useEffect(() => {
    if (currentTime >= videoDuration) {
      setPlaying(false);
      stopIds.current = [];

      return;
    }

    if (
      props.autoPause &&
      activeScene &&
      stopIds.current.indexOf(activeScene.id) === -1
    ) {
      const hasInteraciveElement = activeScene.elements.some((element) =>
        ["open-question", "poll", "user-tagging", "audio"].includes(
          element.type
        )
      );

      if (hasInteraciveElement) {
        setPlaying(false);
        stopIds.current.push(activeScene.id);
      }
    }
  }, [currentTime, videoDuration, activeScene, props.autoPause]);

  useEffect(() => {
    const scene = scenes.find((scene) => {
      return (
        currentTime >= scene.start_time &&
        currentTime < scene.start_time + scene.duration
      );
    });

    if (scene) {
      setActiveScene(scene);
    } else {
      setActiveScene(null);
    }
  }, [scenes, currentTime]);

  return (
    <PlaybackContext.Provider
      value={{
        scenes,
        currentTime,
        videoDuration,
        currentFrame,
        playing,
        activeScene,
        maxSceneOrder,
        play: () => {
          setPlaying(true);

          if (currentTime >= videoDuration) {
            setCurrentTime(0);
          }
        },
        pause: () => {
          setPlaying(false);
        },
        setCurrentTime: (time: number) => {
          setCurrentTime(time);
        },
      }}
    >
      {props.children}
    </PlaybackContext.Provider>
  );
}

export function usePlayback() {
  const context = React.useContext(PlaybackContext);

  if (context === undefined) {
    throw new Error("usePlayback must be used within a PlaybackProvider");
  }

  return context;
}
