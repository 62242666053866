import { css } from "styled-components/macro";
import { Wrapper } from "./Wrapper";
import recordVideo from "../../assets/videos/record.mp4";
import { useEffect, useRef, useState } from "react";
import { useCheckMobile } from "../../hooks/useCheckMobile";
import { useTranslation } from "react-i18next";
import { Headers } from "../../helpers/layout";

export function Demo() {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const { isMobile } = useCheckMobile();
  const { t } = useTranslation();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      {
        threshold: 0.5,
      }
    );

    const el = ref.current;

    if (el) {
      observer.observe(el);

      return () => {
        observer.unobserve(el!);
      };
    }
  }, []);

  useEffect(() => {
    if (isVisible && videoRef.current) {
      videoRef.current.play();
    }
  }, [isVisible]);

  return (
    <section
      css={css`
        padding: 150px 0;
        text-align: center;

        ${isMobile &&
        css`
          padding: 50px 0;
        `}
      `}
    >
      <Wrapper>
        <div
          css={css`
            width: 100%;
            max-width: 720px;
            margin: 0 auto;
          `}
        >
          <Headers.H2
            css={css`
              font-size: 47px;
            `}
          >
            {t("waitlist.demo.title")}
          </Headers.H2>
          <Headers.H4
            css={css`
              font-size: 20px;
              line-height: 26px;
            `}
          >
            {t("waitlist.demo.description")}
          </Headers.H4>
        </div>
        <div
          css={css`
            width: 100%;
            max-width: 979px;
            margin: 0 auto;
            margin-top: 45px;
            border-radius: 16px;
            border: 2px solid #000;
            overflow: hidden;
            opacity: 0;
            transition: all 700ms ease-out;
            transform: translate3d(0, 150px, 0);

            ${isVisible &&
            css`
              opacity: 1;
              transform: translate3d(0, 0, 0);
            `}

            video {
              position: relative;
              width: 100%;
              margin: 0;
              top: -3px;
              margin-bottom: -20px;
            }
          `}
          ref={ref}
        >
          <video ref={videoRef} src={recordVideo} loop muted></video>
        </div>
      </Wrapper>
    </section>
  );
}
