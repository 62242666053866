import { css } from "styled-components/macro";
import { Wrapper } from "./Wrapper";
import pointOne from "../../assets/img/landing-point-1.png";
import pointTwo from "../../assets/img/landing-point-2.png";
import pointThree from "../../assets/img/landing-point-3.png";
import pointFour from "../../assets/img/landing-point-4.png";
import { useEffect, useRef, useState } from "react";
import { Howl } from "howler";
import scrollwheel from "../../assets/sounds/scrollwheel.mp3";
import { useCheckMobile } from "../../hooks/useCheckMobile";
import { useTranslation } from "react-i18next";
import { Headers } from "../../helpers/layout";

const sound = new Howl({
  src: [scrollwheel],
  html5: true,
});

export function Points() {
  const [activePoint, setActivePoint] = useState(0);
  const ref = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);
  const { isMobile } = useCheckMobile();
  const { t } = useTranslation();

  const points = [
    {
      image: pointOne,
      label: t("features.title.template"),
      labelColor: "#E95B2E",
      title: t("features.template.description"),
    },
    {
      image: pointTwo,
      label: t("features.title.collaboration"),
      labelColor: "#A47AFF",
      title: t("features.collaboration.description"),
    },
    {
      image: pointThree,
      labelColor: "#F8B856",
      label: t("features.title.interactivity"),
      title: t("features.interactivity.description"),
    },
    {
      image: pointFour,
      labelColor: "#FF812F",
      label: t("features.title.search"),
      title: t("features.search.description"),
    },
  ];

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = document.documentElement.scrollTop;
      const windowHeight = window.innerHeight;
      const points = document.querySelectorAll(
        ".point"
      ) as NodeListOf<HTMLDivElement>;
      const activePoint = Array.from(points).findIndex((point) => {
        return (
          point.offsetTop + point.clientHeight / 2 >
          scrollTop + windowHeight * 0.3
        );
      });
      setActivePoint(activePoint);
    };

    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      },
      {
        threshold: 0.2,
      }
    );

    const el = ref.current;

    if (el) {
      observer.observe(el);

      return () => {
        observer.unobserve(el!);
      };
    }
  }, []);

  useEffect(() => {
    sound.play();
  }, [activePoint]);

  return (
    <section
      css={css`
        padding: 150px 0;
      `}
      ref={ref}
    >
      <Wrapper>
        <div
          css={css`
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
          `}
        >
          <div
            css={css`
              width: 100%;
              max-width: 430px;

              ul {
                list-style: none;
                padding: 25vh 0;
                margin: 0;
              }

              li {
                margin-bottom: 130px;

                &:last-child {
                  margin-bottom: 0;
                }
              }
            `}
          >
            <ul
              css={css`
                h3 {
                  line-height: 1.1;
                  letter-spacing: -0.04em;
                }
              `}
            >
              {points.map((point, index) => {
                const isActive = index === activePoint;

                return (
                  <li
                    key={`point-${index}`}
                    className="point"
                    css={css`
                      opacity: 0.3;
                      transition: opacity 0.3s ease-in-out;
                      cursor: pointer;

                      &:hover {
                        opacity: 0.6;
                      }

                      ${isActive &&
                      css`
                        opacity: 1;
                      `};
                    `}
                    onClick={(e) => {
                      e.preventDefault();
                      window.scrollTo({
                        top:
                          e.currentTarget.offsetTop -
                          (window.innerHeight - e.currentTarget.offsetHeight) /
                            2,
                        behavior: "smooth",
                      });
                    }}
                  >
                    {point.label && (
                      <h4
                        css={css`
                          background: ${point.labelColor};
                          border-radius: 11.5px;
                          color: #fff;
                          padding: 2px 14px;
                          display: inline-block;
                          font-weight: 400;
                          font-size: 16px;
                          letter-spacing: -0.04em;
                        `}
                      >
                        {point.label}
                      </h4>
                    )}
                    <Headers.H3
                      css={css`
                        font-weight: 600;
                        font-size: 35px;
                      `}
                    >
                      {point.title}
                    </Headers.H3>
                  </li>
                );
              })}
            </ul>
          </div>
          <div
            css={css`
              flex: 1 0 10%;
              max-width: 50vh;

              img {
                width: 100%;
              }
            `}
          >
            <div
              css={css`
                position: sticky;
                top: 25vh;
                right: 0;

                ${isMobile &&
                css`
                  top: auto;
                  bottom: 20px;
                  right: 20px;
                  position: absolute;

                  ${isVisible &&
                  css`
                    position: fixed;
                    width: 60vw;
                    height: 60vw;
                    z-index: 100;
                  `}
                `}
              `}
            >
              {points.map((point, index) => {
                const isActive = index === activePoint;

                return (
                  <div
                    css={css`
                      position: absolute;
                      top: 0;
                      right: 0;
                      opacity: 0;
                      transition: opacity 0.3s ease-in-out;

                      &:last-child {
                        margin-bottom: 0;
                      }

                      ${isActive &&
                      css`
                        opacity: 1;
                      `};
                    `}
                    key={`point-${index}`}
                  >
                    <img src={point.image} alt={point.title} />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </Wrapper>
    </section>
  );
}
