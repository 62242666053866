import React from "react";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { Header } from "../../components/Header";
import { Wrapper } from "../../components/Wrapper";
import { SEO } from "../../components/SEO";
import { RegistrationForm } from "../../forms/auth/RegistrationForm";
import { Headers } from "../../helpers/layout";
import { AuthBackgroundStyle } from "../../themes/variables";
import { FeedbackWidget } from "../../components/FeedbackWidget";

export function RegistrationPage() {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <SEO>
        <title>{t("auth.register.title")}</title>
      </SEO>
      <AuthBackgroundStyle />
      <Header />
      <div
        css={css`
          width: 100%;
          max-width: 850px;
          margin: 0 auto;
          padding: 20px;
          border: 1px solid black;
          box-shadow: 0px 0px 19px rgba(0, 0, 0, 0.11);
          border-radius: 21px;
          background-color: #fff;
        `}
      >
        <Headers.H3
          css={css`
            text-align: center;
            letter-spacing: -0.05em;
            font-weight: bold;
          `}
        >
          {t("auth.register.title")}
        </Headers.H3>
        <Headers.H4
          css={css`
            text-align: center;
            line-height: 19.2px;
            letter-spacing: -0.05em;
          `}
        >
          {t("auth.register.description")}
        </Headers.H4>

        <RegistrationForm
          css={css`
            margin-top: 30px;
          `}
        />
      </div>
      <FeedbackWidget />
    </Wrapper>
  );
}
