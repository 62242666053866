import { notAuthorised } from "../../request";

export async function waitlistRegister(
  email: string,
  inviter_code: string | null
) {
  const API = notAuthorised;

  return await API.request({
    method: "POST",
    url: `/api/studio/join-waitlist/`,
    data: {
      email,
      inviter_code,
    },
  });
}
