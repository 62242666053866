import orderBy from "lodash/orderBy";
import cloneDeep from "lodash/cloneDeep";
import humanizeDuration, { Options } from "humanize-duration";
import { VideoStateUpdate, VideoStateUpdateDraft } from "../db";
import {
  Video,
  VideoElement,
  VideoElementState,
  VideoElementText,
  VideoScene,
  VideoSchema,
} from "../types/Video";
import { v4 as uuid } from "uuid";
import { canvasHeight, canvasWidth } from "../components/ScenePreview";
import hexToRgba from "hex-to-rgba";
import { calculateRelativeSize } from "./renderer";

export const humanize = humanizeDuration.humanizer({
  language: "en",
});

export function calculateCanvasSize(size: { width: number; height: number }) {
  const aspectRatio = size.width / size.height;
  const canvasAspectRatio = 16 / 9;

  if (aspectRatio > canvasAspectRatio) {
    return {
      width: size.height * canvasAspectRatio,
      height: size.height,
    };
  }

  return {
    width: size.width,
    height: size.width / canvasAspectRatio,
  };
}

export const getDuration = (
  ms: number,
  locale: string = "en",
  options: Options = {
    language: locale,
    units: ["m", "s"],
    round: true,
  }
) => {
  return humanize(ms, options);
};

export function calculateElementDuration(element: VideoElement) {
  return element.states.reduce((duration, state) => {
    return duration + state.duration;
  }, 0);
}

export function calculateElementStartingTime(element: VideoElement) {
  return element.states ? element.states[0].start_time : 0;
}

export function calculateElementsDuration(elements: VideoElement[]) {
  const stats = elements.reduce(
    (found, element) => {
      const elementStartingTime = calculateElementStartingTime(element);
      const elementDuration = calculateElementDuration(element);
      const min = Math.min(found.min, elementStartingTime);
      const max = Math.max(found.max, elementStartingTime + elementDuration);

      return {
        min,
        max,
      };
    },
    {
      min: 0,
      max: 0,
    }
  );

  return stats.max - stats.min;
}

export function calculateVideoDuration(schema?: VideoSchema) {
  if (!schema) return 0;
  const scenes = getVideoScenes(schema);

  return scenes.reduce((duration, scene) => {
    return duration + scene.duration;
  }, 0);
}

export function findElementState(element: VideoElement, currentTime: number) {
  // order reverse
  const states = orderBy(element.states, (s) => s.start_time, ["desc"]);

  return states.reduce((found: VideoElementState | null, state) => {
    if (found) return found;

    if (state.start_time <= currentTime) {
      return state;
    }

    return found;
  }, null);
}

export function calculateCanvasStartingTime(
  canvasesDuration: number[],
  i: number
) {
  if (i === 0) {
    return 0;
  }

  if (i < canvasesDuration.length) {
    return canvasesDuration.slice(0, i).reduce((sum, d) => sum + d);
  }

  return 0;
}

const sceneExists = (scenes: VideoScene[], sceneId: string) => {
  return !!scenes.find((scene) => scene.id === sceneId);
};

const elementExists = (elements: VideoElement[], elementId: string) => {
  return !!elements.find((element) => element.id === elementId);
};

export const compileVideo = (
  initialVideo: Video,
  events: VideoStateUpdate[]
) => {
  const video = cloneDeep(initialVideo);
  const processedEventIds: string[] = [];

  for (const event of events) {
    if (
      event.fake &&
      event.remote_id &&
      initialVideo.schema.last_change &&
      Number(event.remote_id) < Number(initialVideo.schema.last_change.id)
    )
      continue;

    if (processedEventIds.includes(event.event_id)) continue;
    processedEventIds.push(event.event_id);

    if (event.action === "create-scene" && event.element_id) {
      if (!sceneExists(video.schema.schema.scenes, event.element_id)) {
        video.schema.schema.scenes.push(event.content);
      }
    }

    if (event.action === "update-scene") {
      video.schema.schema.scenes = video.schema.schema.scenes.map((scene) => {
        if (scene.id === event.element_id) {
          return event.content;
        }

        return scene;
      });
    }

    if (event.action === "delete-scene") {
      video.schema.schema.scenes = video.schema.schema.scenes.filter(
        (element) => element.id !== event.element_id
      );
    }

    if (event.action === "reorder-scene") {
      video.schema.schema.scenes = video.schema.schema.scenes.map((scene) => {
        const orders = event.content.orders || [];
        const currentOrder = orders.find((el: any) => el.id === scene.id);

        if (currentOrder && scene.id === currentOrder.id) {
          return {
            ...scene,
            order: currentOrder.order,
          };
        }

        return scene;
      });
    }

    if (event.action === "create-element" && event.element_id) {
      if (!elementExists(video.schema.schema.elements, event.element_id)) {
        video.schema.schema.elements.push(event.content);
      }
    }

    if (event.action === "create-elements") {
      const elementsId = video.schema.schema.elements.map(
        (element) => element.id
      );

      for (const element of event.content) {
        if (!elementsId.includes(element.id)) {
          video.schema.schema.elements.push(element);
        }
      }
    }

    if (event.action === "update-element") {
      video.schema.schema.elements = video.schema.schema.elements.map(
        (element) => {
          if (element.id === event.element_id) {
            return event.content;
          }

          return element;
        }
      );
    }

    if (event.action === "delete-element") {
      video.schema.schema.elements = video.schema.schema.elements.filter(
        (element) => element.id !== event.element_id
      );
    }

    if (event.action === "delete-elements") {
      const elementsId = event.content.map(
        (element: VideoElement) => element.id
      );

      video.schema.schema.elements = video.schema.schema.elements.filter(
        (element) => !elementsId.includes(element.id)
      );
    }
  }

  return video;
};

export const getReverseAction = (
  currentVideo: Video,
  event: VideoStateUpdateDraft
): VideoStateUpdateDraft | null => {
  if (event.action === "create-scene") {
    return {
      action: "delete-scene",
      element_id: event.element_id,
      video_id: event.video_id,
      user_id: event.user_id,
      timestamp: event.timestamp,
      update_group_id: event.update_group_id,
      content: null,
      remote_id: null,
    };
  }

  if (event.action === "update-scene") {
    const scene = currentVideo.schema.schema.scenes.find(
      (scene) => scene.id === event.element_id
    );

    if (!scene) {
      return null;
    }

    return {
      action: "update-scene",
      content: scene,
      element_id: event.element_id,
      video_id: event.video_id,
      user_id: event.user_id,
      timestamp: event.timestamp,
      update_group_id: event.update_group_id,
      remote_id: null,
    };
  }

  if (event.action === "reorder-scene") {
    const orders = event.content.orders || [];
    const isEveryScene = currentVideo.schema.schema.scenes.every((scene) => {
      const foundScene = orders.find((el: any) => el.id === scene.id);

      if (foundScene) return true;

      return false;
    });

    if (!isEveryScene) {
      return null;
    }

    return {
      action: "reorder-scene",
      content: {
        orders: currentVideo.schema.schema.scenes.map((scene) => {
          return { id: scene.id, order: scene.order };
        }),
      },
      element_id: event.element_id,
      video_id: event.video_id,
      user_id: event.user_id,
      timestamp: event.timestamp,
      update_group_id: event.update_group_id,
      remote_id: null,
    };
  }

  if (event.action === "delete-scene") {
    const element = currentVideo.schema.schema.scenes.find(
      (element) => element.id === event.element_id
    );

    if (!element) {
      return null;
    }

    return {
      action: "create-scene",
      content: element,
      element_id: event.element_id,
      video_id: event.video_id,
      user_id: event.user_id,
      timestamp: event.timestamp,
      update_group_id: event.update_group_id,
      remote_id: null,
    };
  }

  if (event.action === "create-element") {
    return {
      action: "delete-element",
      element_id: event.element_id,
      video_id: event.video_id,
      user_id: event.user_id,
      timestamp: event.timestamp,
      update_group_id: event.update_group_id,
      content: null,
      remote_id: null,
    };
  }

  if (event.action === "create-elements") {
    return {
      action: "delete-elements",
      video_id: event.video_id,
      user_id: event.user_id,
      timestamp: event.timestamp,
      update_group_id: event.update_group_id,
      content: event.content,
      remote_id: null,
    };
  }

  if (event.action === "update-element") {
    const element = currentVideo.schema.schema.elements.find(
      (element) => element.id === event.element_id
    );

    if (!element) {
      return null;
    }

    return {
      action: "update-element",
      content: element,
      element_id: event.element_id,
      video_id: event.video_id,
      user_id: event.user_id,
      timestamp: event.timestamp,
      update_group_id: event.update_group_id,
      remote_id: null,
    };
  }

  if (event.action === "delete-element") {
    const element = currentVideo.schema.schema.elements.find(
      (element) => element.id === event.element_id
    );

    if (!element) {
      return null;
    }

    return {
      action: "create-element",
      content: element,
      element_id: event.element_id,
      video_id: event.video_id,
      user_id: event.user_id,
      timestamp: event.timestamp,
      update_group_id: event.update_group_id,
      remote_id: null,
    };
  }

  if (event.action === "delete-elements") {
    const elementId = event.content.map((element: VideoElement) => element.id);

    const elements = currentVideo.schema.schema.elements.filter((element) =>
      elementId.includes(element.id)
    );

    if (!elements) {
      return null;
    }

    return {
      action: "create-elements",
      content: elements,
      video_id: event.video_id,
      user_id: event.user_id,
      timestamp: event.timestamp,
      update_group_id: event.update_group_id,
      remote_id: null,
    };
  }

  return null;
};

export const getMaxSceneOrder = (scenes: VideoScene[]) => {
  return scenes.reduce((maxOrder, scene) => {
    if (scene.order && scene.order > maxOrder) {
      return scene.order;
    }

    return maxOrder;
  }, 0);
};

export const getVideoScenes = (schema: VideoSchema) => {
  let scenes: VideoScene[] = cloneDeep(schema.scenes);

  for (const element of schema.elements) {
    const sceneIndex = scenes.findIndex((s) => s.id === element.scene_id);

    if (sceneIndex !== -1) {
      scenes[sceneIndex].elements = scenes[sceneIndex].elements || [];
      scenes[sceneIndex].elements = [...scenes[sceneIndex].elements, element];
    }
  }

  scenes = scenes.map((scene) => {
    const sceneDuration = scene.duration || 5 * 1000;
    const recordingDuration = schema.elements.reduce((maxDuration, el) => {
      if (
        (el.type === "audio" || el.type === "video") &&
        el.scene_id === scene.id
      ) {
        const elDuration =
          Math.ceil(Math.max(...el.states.map((o) => o.duration)) / 1000) *
          1000;

        return Math.max(elDuration, maxDuration);
      }

      return maxDuration;
    }, 0);

    return {
      ...scene,
      duration: recordingDuration
        ? Math.max(sceneDuration, recordingDuration)
        : sceneDuration,
    };
  });

  return scenes
    .sort((a, b) =>
      a.order !== undefined && b.order !== undefined ? a.order - b.order : 0
    )
    .map((scene, i) => {
      return {
        ...scene,
        order: i,
        elements: scene.elements || [],
        start_time: scenes.slice(0, i).reduce((sum, s) => sum + s.duration, 0),
      };
    });
};

export function generateDefaultEmptyScene(name: string, order: number, t: any) {
  const sceneId = uuid();
  const scene: VideoScene = {
    id: sceneId,
    backgroundColor: "#fff",
    duration: 5000,
    order,
    name,
    enableCollaboration: true,
    start_time: 0,
    elements: [],
  };

  const firstElement: VideoElement = {
    id: uuid(),
    scene_id: sceneId,
    type: "text",
    text: `<p style="font-family: Arial; font-size: ${calculateRelativeSize(
      32
    )};">${t("text.sidebar.typeSomething")}</p>`,
    touched: false,
    states: [
      {
        id: uuid(),
        start_time: 0,
        duration: 5000,
        top: 0.05 * canvasWidth,
        left: 0.1 * canvasWidth,
        scale: 1,
        rotation: 0,
        width: 0.8 * canvasWidth,
        height: 0.15 * canvasHeight,
      },
    ],
    order: 0,
  };

  const secondElement: VideoElement = {
    id: uuid(),
    scene_id: sceneId,
    type: "text",
    text: `<p style="font-family: Arial; font-size: ${calculateRelativeSize(
      16
    )};">${t("text.sidebar.typeSomething")}</p>`,
    touched: false,
    states: [
      {
        id: uuid(),
        start_time: 0,
        duration: 5000,
        top:
          firstElement.states[0].top +
          firstElement.states[0].height +
          0.05 * canvasHeight,
        left: 0.1 * canvasWidth,
        scale: 1,
        rotation: 0,
        width: 0.38 * canvasWidth,
        height: 0.4 * canvasHeight,
      },
    ],
    order: 1,
  };

  const thirdElement: VideoElement = {
    id: uuid(),
    scene_id: sceneId,
    type: "record-prompt",
    instruction: "Use recordings to explain your content in more detail.",
    touched: false,
    config: {},
    states: [
      {
        id: uuid(),
        start_time: 0,
        duration: 5000,
        top:
          firstElement.states[0].top +
          firstElement.states[0].height +
          0.05 * canvasHeight,
        left: 0.52 * canvasWidth,
        scale: 1,
        rotation: 0,
        width: 0.38 * canvasWidth,
        height: 0.4 * canvasHeight,
      },
    ],
    order: 2,
  };

  const elements: VideoElement[] = [firstElement, secondElement, thirdElement];

  return { scene, elements };
}

export function convertToRichText(element: VideoElementText): VideoElementText {
  if (!element.config || !Object.keys(element.config).length) return element;

  const styleArray = [];

  if (element.config.fontFamily) {
    styleArray.push(`font-family:${element.config.fontFamily}`);
  }

  if (element.config.fontSize) {
    styleArray.push(
      `font-size:${calculateRelativeSize(element.config.fontSize)}`
    );
  }

  if (element.config.color) {
    styleArray.push(`color:${hexToRgba(element.config.color)}`);
  }

  if (element.config.bold) {
    styleArray.push(`font-weight:bold`);
  }

  if (element.config.italic) {
    styleArray.push(`font-style:italic`);
  }

  if (element.config.underline) {
    styleArray.push(`text-decoration:underline`);
  }

  if (element.config.textAlign) {
    styleArray.push(`text-align:${element.config.textAlign}`);
  }

  const styleString = styleArray.join(";");

  return {
    ...element,
    text: `<p style="${styleString}">${element.text}</p>`,
  };
}
