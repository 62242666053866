import { useState } from "react";
import { css } from "styled-components/macro";
import { Headers } from "../helpers/layout";
import { Button } from "../components/Button";
import { ReactComponent as ShowMoreIcon } from "../assets/icons/ShowMore.svg";
import { ReactComponent as ShowLessIcon } from "../assets/icons/ShowLess.svg";

function AccordionItem(props: { title: string; content: React.ReactNode }) {
  const [isActive, setIsActive] = useState(false);

  const toggleActive = () => {
    setIsActive((a) => !a);
  };
  return (
    <div
      css={css`
        max-width: 930px;
        width: 100%;
        margin-bottom: 25px;
        padding-left: 5px;
      `}
    >
      <div
        css={css`
          display: flex;
          justify-content: space-between;
        `}
      >
        <Headers.H4
          css={css`
            font-weight: 600;
            width: 100%;
            line-height: 28px;
          `}
        >
          {props.title}
        </Headers.H4>
        <Button
          hoverStyles="none"
          icon={
            isActive ? (
              <ShowLessIcon />
            ) : (
              <ShowMoreIcon
                css={`
                  align-items: center;
                `}
              />
            )
          }
          css={css`
            background-color: transparent;
            min-width: 2em;
            padding: 10px 6px;
          `}
          onClick={toggleActive}
        ></Button>
      </div>

      {isActive && (
        <div
          css={css`
            width: 100%;
          `}
        >
          <Headers.H4
            css={css`
              font-size: 14px;
              color: #8e8e8e;
              line-height: 20px;
              margin-top: 10px;
              margin-right: 50px;
              text-align: justify;
            `}
          >
            {props.content}
          </Headers.H4>
        </div>
      )}
    </div>
  );
}

export function Accordion(props: {
  data: { id: number; title: string; content: React.ReactNode }[];
}) {
  return (
    <div>
      {props.data.map((el) => {
        return (
          <div
            key={el.id}
            css={css`
              display: flex;
            `}
          >
            <AccordionItem {...el} />
          </div>
        );
      })}
    </div>
  );
}
