import { useDrag } from "react-dnd";
import { css } from "styled-components/macro";
import { Headers } from "../helpers/headers";
import { TooltipArea } from "../../components/Tooltip";
import { ReactComponent as InteractiveIcon } from "../assets/icons/Interactive.svg";
import { ReactComponent as MultipleChoiceIcon } from "../assets/icons/MultipleChoice.svg";
// import { ReactComponent as OpenQuestionIcon } from "../assets/icons/OpenQuestion.svg";
//import { ReactComponent as ReactionsIcon } from "../assets/icons/Reactions.svg";
import { ReactComponent as SingleChoiceIcon } from "../assets/icons/SingleChoice.svg";
import { ReactComponent as UserTagIcon } from "../assets/icons/UserTag.svg";
import { useTranslation } from "react-i18next";
import { VideoElementResources } from "../types/Video";

type ElementResources = {
  icon?: JSX.Element;
  subtype?: string;
  label: string;
  type: VideoElementResources["type"];
  tooltipText?: string;
};

const Element = (props: ElementResources) => {
  const [{ isDragging }, drag, dragPreview] = useDrag(() => ({
    type: "element",
    item: {
      type: props.type,
      subtype: props.subtype,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  const styles = css`
    position: relative;
    padding: 10px 6px 6px 6px;
    border-radius: 5px;
    width: calc(50% - 10px);
    border: 1px solid #000;
    color: #000;
    height: 84px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 10px;
    margin-right: 10px;
    flex-direction: column;
    cursor: grab;
    background: #ffffff;
    &:hover {
      border: 1px solid #e95b2e;
      color: #e95b2e !important;
    }
  `;

  return (
    <TooltipArea
      position="center"
      text={props.tooltipText}
      offsetY={-40}
      staticPosition
      css={styles}
      show={!!props.tooltipText}
    >
      <div
        ref={dragPreview}
        style={{ opacity: isDragging ? 0.5 : 1 }}
        css={!props.tooltipText && styles}
      >
        <div
          css={css`
            margin-bottom: 0px;
          `}
        >
          {props.icon}
        </div>
        <div>
          <h5
            css={css`
              margin: 0;
              line-height: 16.8px;
              font-size: 14px;
              font-weight: 500;
              :hover {
                color: #e95b2e !important;
              }
            `}
          >
            {props.label}
          </h5>
          <div
            css={css`
              position: absolute;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
            `}
            ref={drag}
          ></div>
        </div>
      </div>
    </TooltipArea>
  );
};

export function ResourcesSidebar() {
  const { t } = useTranslation();

  const elementResources: ElementResources[] = [
    {
      icon: <MultipleChoiceIcon />,
      label: t("resourses.sidebar.elements.multiple-choice"),
      type: "poll",
      subtype: "multiple-choice",
      tooltipText: t("resources.tooltip.poll"),
    },
    // {
    //   icon: <OpenQuestionIcon />,
    //   label: t("resourses.sidebar.elements.question"),
    //   type: "open-question",
    // },
    {
      icon: <SingleChoiceIcon />,
      label: t("resourses.sidebar.elements.single-choice"),
      type: "poll",
      subtype: "single-choice",
      tooltipText: t("resources.tooltip.poll"),
    },
    // {
    //   icon: <ReactionsIcon />,
    //   label: t("resourses.sidebar.elements.reaction"),
    //   type: "reaction",
    // },
    {
      icon: <UserTagIcon />,
      label: t("resourses.sidebar.elements.tagging"),
      type: "user-tagging",
      tooltipText: t("resources.tooltip.user-card"),
    },
  ];

  return (
    <div>
      <div
        css={css`
          display: flex;
          min-width: 216px;
        `}
      >
        <InteractiveIcon
          css={css`
            align-self: center;
          `}
        />
        <Headers.H3
          css={css`
            margin-left: 8px;
            font-size: 19px;
          `}
        >
          {t("resourses.sidebar.title.elements")}
        </Headers.H3>
      </div>

      <div
        css={css`
          display: flex;
          flex-flow: row wrap;
          margin-top: 30px;
        `}
      >
        {elementResources.map((element, key) => (
          <Element key={key} {...element} />
        ))}
      </div>
      <hr
        css={css`
          background-color: #d9d9d9;
          height: 1px;
          border: 0;
          margin: 30px 0 25px 0 !important;
        `}
      />
    </div>
  );
}
