import React, { useState, useEffect, useCallback } from "react";
// import useInterval from "@use-it/interval";
import { useAuth } from "../../contexts/UserContext";
import { uploadAiFile } from "../actions/uploadAiFile";
import {
  FileStatus,
  getAiFileStatus,
  UploadStatus,
} from "../actions/getAiFileStatus";
import { downloadMLSchema } from "../actions/downloadMLSchema";
import { useStorage } from "./StorageContext";

export const statusPercent: {
  [key in UploadStatus]: number;
} = {
  [UploadStatus.ERROR]: 0,
  [UploadStatus.UPLOADING]: 0,
  [UploadStatus.PARSING]: 25,
  [UploadStatus.SUMMARIZING]: 50,
  [UploadStatus.GENERATING_LAYOUT]: 75,
  [UploadStatus.COMPLETED]: 100,
};

const AiContext = React.createContext<{
  uploadFiles: (files: File[]) => Promise<void>;
  status: FileStatus[];
  errors?: string[] | null;
  percentage: number;
  statusModal: "success" | "error" | false;
  closeStatusModal: () => void;
}>({
  uploadFiles: () => Promise.resolve(),
  status: [],
  errors: [],
  percentage: 0,
  statusModal: false,
  closeStatusModal: () => {},
});

export function AiProvider(props: {
  children: React.ReactNode;
  videoId: string;
}) {
  const { userprofile } = useAuth();
  const [status, setStatus] = useState<FileStatus[]>([]);
  const [errors, setErrors] = useState<string[] | null>(null);
  const [percentage, setPercentage] = useState(0);
  const { api } = useStorage();
  const [statusModal, setStatusModal] = useState<"success" | "error" | false>(
    false
  );

  const getFileStatus = useCallback(async () => {
    const { data } = await getAiFileStatus({
      videoId: props.videoId,
    });

    if (data.length > 0) {
      setPercentage(
        data.reduce((acc, val) => {
          return acc + statusPercent[val.status];
        }, 0) / data.length
      );

      setStatus(data);
    } else {
      setStatus([]);
    }
  }, [props.videoId]);

  const uploadFiles = useCallback(
    async (files: File[]) => {
      const arrayOfFiles = files;

      try {
        await Promise.all(
          arrayOfFiles.map(async (file) => {
            const response = await uploadAiFile({
              file,
              user_id: userprofile?.id!,
              video_id: props.videoId,
            });

            const uploadedFilesValue = localStorage.getItem(
              "THEMIS_ML_UPLOADED_FILE_IDS"
            );

            const uploadedFiles = uploadedFilesValue
              ? (JSON.parse(uploadedFilesValue) as string[])
              : [];

            localStorage.setItem(
              "THEMIS_ML_UPLOADED_FILE_IDS",
              JSON.stringify([...uploadedFiles, response.file_id])
            );
          })
        );

        await getFileStatus();
      } catch (e: any) {
        setErrors((errors) => {
          return errors ? [e, ...errors] : [e];
        });
      }
    },
    [userprofile, props.videoId, getFileStatus]
  );

  useEffect(() => {
    const downloadSchema = async (fileId: string) => {
      const response = await downloadMLSchema({
        videoId: props.videoId,
        fileId,
      });

      return response.data;
    };

    async function createSequence(fileId: string) {
      const response = await downloadSchema(fileId);
      const processedFilesValue = localStorage.getItem(
        "THEMIS_ML_PROCESSED_FILE_IDS"
      );

      const processedFiles = processedFilesValue
        ? (JSON.parse(processedFilesValue) as string[])
        : [];

      if (processedFiles.includes(fileId)) return;

      await api.createSceneWithContent(response.scenes[0], response.elements);

      localStorage.setItem(
        "THEMIS_ML_PROCESSED_FILE_IDS",
        JSON.stringify([...processedFiles, fileId])
      );
    }

    if (percentage === 100) {
      const notProcessedFiles = status.filter((fileStatus) => {
        const processedFiles = localStorage.getItem(
          "THEMIS_ML_PROCESSED_FILE_IDS"
        );

        const uploadedFilesValue = localStorage.getItem(
          "THEMIS_ML_UPLOADED_FILE_IDS"
        );

        return (
          fileStatus.status === UploadStatus.COMPLETED &&
          !processedFiles?.includes(fileStatus.file_id) &&
          uploadedFilesValue?.includes(fileStatus.file_id)
        );
      });

      if (notProcessedFiles.length > 0) {
        status.forEach(async (fileStatus) => {
          await createSequence(fileStatus.file_id);
        });

        setStatusModal("success");
      }
    }
  }, [status, percentage, api, props.videoId]);

  useEffect(() => {
    if (errors && errors.length) setStatusModal("error");
  }, [errors]);

  useEffect(() => {
    getFileStatus();

    const interval = setInterval(() => {
      getFileStatus();
    }, 10000);

    return () => clearInterval(interval);
  }, [getFileStatus]);

  return (
    <AiContext.Provider
      value={{
        uploadFiles,
        status,
        percentage,
        errors,
        statusModal,
        closeStatusModal: () => setStatusModal(false),
      }}
    >
      {props.children}
    </AiContext.Provider>
  );
}

export function useAi() {
  const context = React.useContext(AiContext);

  if (context === undefined) {
    throw new Error("useAi must be used within a AiProvider");
  }

  return context;
}
