import { useEffect, useState } from "react";
import { css } from "styled-components/macro";

function randomIntFromInterval(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export function MovingAvatar(props: {
  src: string;
  className?: string;
  positionBoundaries: {
    x: number[];
    y: number[];
  };
  timeout: number;
}) {
  const [position, setPosition] = useState({
    x: 0,
    y: 0,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const x = randomIntFromInterval(
        props.positionBoundaries.x[0],
        props.positionBoundaries.x[1]
      );
      const y = randomIntFromInterval(
        props.positionBoundaries.y[0],
        props.positionBoundaries.y[1]
      );

      setPosition({
        x,
        y,
      });
    }, props.timeout);

    return () => clearInterval(interval);
  }, [props.positionBoundaries, props.timeout]);

  return (
    <img
      className={props.className}
      css={css`
        position: absolute;
        width: 65px;
        transition: all 1s ease-out;
      `}
      style={{
        transform: `translate3d(${position.x}px, ${position.y}px, 0)`,
      }}
      src={props.src}
      alt="Avatar"
    />
  );
}
