import { useCallback, useState } from "react";
import { css } from "styled-components/macro";
import { debounce } from "lodash";
import striptags from "striptags";
import { useTranslation } from "react-i18next";
import { useStorage } from "../contexts/StorageContext";
import { updateVideo } from "../actions/updateVideo";
import { canEdit } from "../helpers/collaborator";
import { customToast } from "../../components/customToast";
import { serverErrorHandler } from "../../helpers/serverErrorHandler";
import { localAPI } from "../API";
import { ContentEditable } from "./ContentEditable";
import { ReactComponent as TitleIcon } from "../assets/icons/Title.svg";

export function Title() {
  const { video } = useStorage();
  const [title, setTitle] = useState(video ? video.name : "Themi");
  const { t } = useTranslation();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateTitle = useCallback(
    debounce(async (title: string) => {
      if (!video) return;

      try {
        const response = await updateVideo(video.uuid, { name: title });

        await localAPI.saveVideo(video.uuid, {
          name: response.name,
        });
      } catch (error: any) {
        customToast.error(
          t("status.error", {
            error: serverErrorHandler(error),
          })
        );
      }
    }, 1000),
    [video?.uuid]
  );

  const handleContentEditable = (event: any) => {
    const content = striptags(event.target.value, [], "");

    setTitle(content);
    updateTitle(content);
  };

  if (!video) return null;

  return (
    <div
      css={css`
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
      `}
    >
      <TitleIcon />
      <ContentEditable
        html={title}
        disabled={!canEdit(video.current_user_role)}
        onChange={handleContentEditable}
        style={{
          maxWidth: "350px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          marginLeft: "6px",
        }}
      />
    </div>
  );
}
