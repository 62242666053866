import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";
import styled, { css } from "styled-components/macro";
import { v4 as uuid } from "uuid";
import { Dropdown, DropdownItem } from "../../components/Dropdown";
import { useEditor, navItems, NavItemBlank } from "../contexts/EditorContext";
import { usePlayback } from "../contexts/PlaybackContext";
import { useStorage } from "../contexts/StorageContext";
import { useAuth } from "../../contexts/UserContext";
import { canEdit } from "../helpers/collaborator";
import { ReactComponent as RecordIcon } from "../assets/icons/RecordIcon.svg";
import { ReactComponent as MediaIcon } from "../assets/icons/Media.svg";
import { ReactComponent as DrawIcon } from "../assets/icons/Draw.svg";
import { ReactComponent as ShapesIcon } from "../assets/icons/Shapes.svg";
import { ReactComponent as ResourcesIcon } from "../assets/icons/Resources.svg";
import { ReactComponent as ResultsIcon } from "../assets/icons/Results.svg";
import { ReactComponent as TextIcon } from "../assets/icons/Text.svg";
import { ReactComponent as ArrowRightIcon } from "../assets/icons/ArrowRight.svg";
import { ReactComponent as LettetTBigIcon } from "../assets/icons/LettetTBig.svg";
import { ReactComponent as LettetTSmallIcon } from "../assets/icons/LettetTSmall.svg";
import { ReactComponent as BulletIcon } from "../assets/icons/Bullet.svg";
import AiIcon from "../assets/icons/AiIcon.png";
import { calculateRelativeSize } from "../helpers/renderer";

export enum NavType {
  Dropdown = "dropdown",
  Select = "select",
}

export type NavItem = NavItemBlank & {
  label: string;
  icon: React.ReactNode;
  options?: {
    children: React.ReactNode;
    action: () => void;
  }[];
};

const NavigationItemContainer = styled.div`
  padding: 15px 0;
  margin-bottom: 10px;

  &:last-child {
    margin-top: 25px;
    padding-top: 25px;
    border-top: 1px solid #cdcdcd;
  }

  @media (max-height: 800px) {
    padding: 10px 0;
    margin-bottom: 5px;
  }
`;

function NavigationItem(props: {
  item: NavItem;
  isActive: boolean;
  className?: string;
  handleOpen: (item: NavItem) => void;
}) {
  const { item, isActive } = props;

  return (
    <div
      onClick={(e) => {
        props.handleOpen(item);
      }}
      css={css`
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        cursor: pointer;

        &:hover {
          color: #e95b2e;
        }

        ${isActive &&
        css`
          color: #e95b2e;
        `}
      `}
      className={props.className}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        `}
      >
        {item.icon}
      </div>

      <span
        css={css`
          align-self: center;
          text-align: center;
          padding: 0 10px;
          margin-top: 3px;
          font-weight: 600;
          font-size: 14px;
        `}
      >
        {item.label}
        {item.type === NavType.Dropdown && (
          <ArrowRightIcon
            css={css`
              margin-left: 5px;
            `}
          />
        )}
      </span>
    </div>
  );
}

function DropdownItemOption(props: { icon: React.ReactNode; text: string }) {
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
        font-weight: 500;
        font-size: 16px;
        line-height: 28px;
      `}
    >
      <div
        css={css`
          width: 22px;
        `}
      >
        {props.icon}
      </div>
      <div
        css={css`
          font-weight: 500;
          font-size: 16px;
          line-height: 28px;
        `}
      >
        {props.text}
      </div>
    </div>
  );
}

function NavigationPure() {
  const [value, setValue] = useState(0);
  const { activeNav, setActiveNav, setRecordModalVisible } = useEditor();
  const { userprofile } = useAuth();
  const { activeScene } = usePlayback();
  const { video, api } = useStorage();
  const { t } = useTranslation();

  const items: Array<NavItem> = navItems.map((item) => {
    if (item.key === "ai") {
      return {
        ...item,
        icon: <img width={30} src={AiIcon} alt="ai" />,
        label: t("ai.themi-ai"),
      };
    } else if (item.key === "record") {
      return {
        ...item,
        icon: <RecordIcon />,
        label: t("video-editor.side-bar.record"),
      };
    } else if (item.key === "media") {
      return {
        ...item,
        icon: <MediaIcon />,
        label: t("video-editor.side-bar.media"),
      };
    } else if (item.key === "text") {
      return {
        type: item.type,
        key: item.key,
        icon: <TextIcon />,
        label: t("video-editor.side-bar.text"),
        options: [
          {
            children: (
              <DropdownItemOption
                icon={<LettetTBigIcon />}
                text={t("navigation.headline")}
              />
            ),
            action: () => {
              if (!activeScene || !userprofile) return;

              api.createElement({
                id: uuid(),
                scene_id: activeScene.id,
                order: activeScene.elements.length,
                type: "text",
                text: `<p style="font-family: Arial; font-size: ${calculateRelativeSize(
                  32
                )};">${t("text.sidebar.typeSomething")}</p>`,
                states: [
                  {
                    id: uuid(),
                    left: 100,
                    top: 100,
                    width: 220,
                    height: 150,
                    scale: 1,
                    rotation: 0,
                    start_time: 0,
                    duration: 5000,
                  },
                ],
              });
            },
          },
          {
            children: (
              <DropdownItemOption
                icon={<LettetTSmallIcon />}
                text={t("navigation.body-text")}
              />
            ),
            action: () => {
              if (!activeScene || !userprofile) return;

              api.createElement({
                id: uuid(),
                scene_id: activeScene.id,
                order: activeScene.elements.length,
                type: "text",
                text: `<p style="font-family: Arial; font-size: ${calculateRelativeSize(
                  16
                )};">${t("text.sidebar.typeSomething")}</p>`,
                states: [
                  {
                    id: uuid(),
                    left: 100,
                    top: 100,
                    width: 200,
                    height: 100,
                    scale: 1,
                    rotation: 0,
                    start_time: 0,
                    duration: 5000,
                  },
                ],
              });
            },
          },
          {
            children: (
              <DropdownItemOption
                icon={<BulletIcon />}
                text={t("navigation.bullet-points")}
              />
            ),
            action: () => {
              if (!activeScene || !userprofile) return;

              api.createElement({
                id: uuid(),
                scene_id: activeScene.id,
                order: activeScene.elements.length,
                type: "text",
                text: `<ul style="font-family: Arial; font-size: ${calculateRelativeSize(
                  16
                )};">
                    <li>${t("text.sidebar.typeSomething")}</li>
                  </ul>`,
                states: [
                  {
                    id: uuid(),
                    left: 100,
                    top: 100,
                    width: 200,
                    height: 100,
                    scale: 1,
                    rotation: 0,
                    start_time: 0,
                    duration: 5000,
                  },
                ],
              });
            },
          },
        ],
      };
    } else if (item.key === "shape") {
      return {
        ...item,
        icon: <ShapesIcon />,
        label: t("video-editor.side-bar.shapes"),
      };
    } else if (item.key === "draw") {
      return {
        ...item,
        icon: <DrawIcon />,
        label: t("video-editor.side-bar.draw"),
      };
    } else if (item.key === "resources") {
      return {
        ...item,
        icon: <ResourcesIcon />,
        label: t("video-editor.side-bar.resources"),
      };
    } else {
      return {
        ...item,
        icon: <ResultsIcon />,
        label: t("video-editor.side-bar.results"),
      };
    }
  });

  useEffect(() => {
    const calculateOffset = debounce(
      () => {
        if (activeNav) {
          const navElement = document.getElementById(`nav--${activeNav}`);
          if (navElement) {
            const newPoitionValue = navElement.offsetTop;
            setValue(newPoitionValue);
          }
        }
      },
      200,
      {
        leading: true,
      }
    );

    calculateOffset();

    window.addEventListener("resize", calculateOffset);

    return () => {
      window.removeEventListener("resize", calculateOffset);
    };
  }, [activeNav]);

  const handleOpen = useCallback(
    (item: NavItem) => {
      if (!canEdit(video?.current_user_role)) return;

      if (item.key === "record") {
        setRecordModalVisible(true);
      } else if (activeNav !== item.key) {
        setActiveNav(item.key);
      } else {
        setActiveNav(null);
      }
    },
    [video, activeNav, setActiveNav, setRecordModalVisible]
  );

  return (
    <div
      css={css`
        background: #fff;
        height: 100%;
        color: #000;
        display: flex;
        flex-direction: column;
        justify-content: center;
        z-index: 999;
      `}
    >
      <div>
        {items.map((item, index) => {
          if (process.env.REACT_APP_ENABLE_AI !== "true" && item.key === "ai") {
            return null;
          }

          if (item.type === NavType.Dropdown) {
            return (
              <NavigationItemContainer key={item.key} id={"nav--" + item.key}>
                <Dropdown
                  id={`nav--${item.key}`}
                  opened={activeNav === item.key}
                  css={css`
                    right: unset;
                    left: 100%;
                    top: 75%;
                  `}
                  settings={{ disableOutsideClick: true }}
                  options={item.options?.map((option, key) => {
                    return (
                      <DropdownItem
                        css={css`
                          padding: 2px 15px 2px 6px;
                        `}
                        onClick={option.action}
                        key={key}
                      >
                        {option.children}
                      </DropdownItem>
                    );
                  })}
                >
                  <NavigationItem
                    item={item}
                    isActive={activeNav === item.key}
                    handleOpen={handleOpen}
                  />
                </Dropdown>
              </NavigationItemContainer>
            );
          }

          return (
            <NavigationItemContainer key={item.key} id={"nav--" + item.key}>
              <NavigationItem
                item={item}
                isActive={activeNav === item.key}
                handleOpen={handleOpen}
              />
            </NavigationItemContainer>
          );
        })}
      </div>
      {activeNav && (
        <div
          css={css`
            width: 13px;
            height: 70px;
            background-color: #e95b2e;
            border-radius: 9.5px;
            margin-left: -6px;
            position: absolute;
            top: ${value}px;
            left: 0;
            transition: top 0.4s ease;
            margin-top: 7px;
          `}
        ></div>
      )}
    </div>
  );
}

export const Navigation = React.memo(NavigationPure);
