import React from "react";
import { css } from "styled-components/macro";
import { ReactComponent as CancelIcon } from "../../VideoEditor/assets/icons/Cancel.svg";
import { Row, Headers } from "../../helpers/layout";
import { Button } from "../Button";

type ModalHeaderProps = {
  className?: string;
  children?: React.ReactNode[] | React.ReactNode | string | null;
  closeIcon?: boolean;
  onClose?: () => void;
};

export function ModalHeader(props: ModalHeaderProps) {
  return (
    <Row
      align={"center"}
      justify={"space-between"}
      css={css`
        margin-bottom: 0;
      `}
      className={props.className}
    >
      {props.children && (
        <Headers.H4
          css={css`
            margin-bottom: 0;
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
          `}
        >
          {props.children}
        </Headers.H4>
      )}

      {props.closeIcon && (
        <Button
          css={css`
            padding: 0;
            margin: 0;
            cursor: pointer;
            white-space: nowrap;
            border: 0;
            text-transform: none;
            -webkit-appearance: none;
            outline: none;
            background: #e95b2e;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            line-height: 18px;
            font-weight: bold;
            width: 18px;
            height: 18px;
            min-width: 0;
          `}
          onClick={props.onClose}
          icon={
            <CancelIcon
              css={css`
                width: 7px;
                height: 7px;
              `}
            />
          }
          hoverStyles={"none"}
        />
      )}
    </Row>
  );
}
