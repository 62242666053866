import { ShareButtons } from "./ShareButtons";
import { SignupForm } from "./SignupForm";
import { css } from "styled-components/macro";
import { Wrapper } from "./Wrapper";
import Desktop from "../../assets/img/waitlist_mainscreen.png";
import Record from "../../assets/img/landing-record-element.png";
import Floating from "../../assets/img/landing-floating-element.png";
import AvatarOne from "../../assets/img/landing-avatar-1.png";
import AvatarTwo from "../../assets/img/landing-avatar-2.png";
import { useEffect, useState } from "react";
import { MovingAvatar } from "./MovingAvatar";
import { useCheckMobile } from "../../hooks/useCheckMobile";
import { useTranslation } from "react-i18next";
import { Headers } from "../../helpers/layout";

export function Hero() {
  const { isMobile } = useCheckMobile();
  const { t } = useTranslation();
  const [firstAnimated, setFirstAnimated] = useState(false);
  const [secondAnimated, setSecondAnimated] = useState(false);

  useEffect(() => {
    const timeout1 = setTimeout(() => {
      setFirstAnimated(true);
    }, 1000);

    const timeout2 = setTimeout(() => {
      setSecondAnimated(true);
    }, 2000);

    return () => {
      clearTimeout(timeout1);
      clearTimeout(timeout2);
    };
  }, []);

  return (
    <section>
      <Wrapper>
        <div
          css={css`
            height: 40vh;
            min-height: 700px;
            display: flex;
            flex-flow: row wrap;
            align-items: center;

            ${isMobile &&
            css`
              height: auto;
            `}
          `}
        >
          <div
            css={css`
              width: 100%;
              max-width: 510px;

              h4 {
                width: 100%;
                max-width: 400px;
              }
            `}
          >
            <Headers.H2
              css={css`
                font-size: 47px;
              `}
            >
              {t("waitlist.hero-firstpart.title")}
              <br />
              {t("waitlist.hero-secondpart.title")}
              <br />
              {t("waitlist.hero-thirdpart.title")}
            </Headers.H2>
            <Headers.H4
              css={css`
                font-size: 18px;
                line-height: 22px;
              `}
            >
              {t("waitlist.hero.secondpart.sub-title")}
            </Headers.H4>
            <SignupForm />
            <div
              css={css`
                margin-top: 20px;
              `}
            >
              <p
                css={css`
                  margin-bottom: 15px;
                  font-size: 15px;
                  font-weight: 400;
                `}
              >
                {t("waitlist.colleagues")}
              </p>
              <ShareButtons />
            </div>
          </div>
          <div
            css={css`
              flex: 1 0 10%;
              padding-left: 33px;
              align-self: flex-end;

              ${isMobile &&
              css`
                padding: 150px 0 0px;
              `}
            `}
          >
            <div
              css={css`
                position: relative;
                width: 100%;
              `}
            >
              <div
                css={css`
                  position: relative;

                  &:after {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 70px;
                    background: linear-gradient(
                      180deg,
                      rgba(255, 255, 255, 0) 0%,
                      rgba(249, 239, 224, 1) 50%,
                      #f9efe0 100%
                    );
                  }
                `}
              >
                <img
                  css={css`
                    width: 100%;
                    height: auto;
                  `}
                  src={Desktop}
                  alt="Themi Video Creator"
                />
              </div>
              <img
                css={css`
                  position: absolute;
                  width: 40%;
                  bottom: 30px;
                  left: -25px;
                  transform: translate3d(-50px, 0, 0);
                  transition: all 1s ease-out;
                  opacity: 0;

                  ${firstAnimated &&
                  css`
                    opacity: 1;
                    transform: translate3d(0, 0, 0);
                  `}
                `}
                src={Record}
                alt="Themi Video Creator"
              />
              <img
                css={css`
                  position: absolute;
                  width: 40%;
                  right: 20%;
                  bottom: 100%;
                  margin-bottom: -40px;
                  transition: all 1s ease-out;
                  transform: translate3d(0, -50px, 0);

                  opacity: 0;

                  ${secondAnimated &&
                  css`
                    opacity: 1;
                    transform: translate3d(0, 0, 0);
                  `}
                `}
                src={Floating}
                alt="Themi Video Creator"
              />
              <MovingAvatar
                css={css`
                  left: -60px;
                  bottom: 80px;
                `}
                timeout={4000}
                src={AvatarOne}
                positionBoundaries={{
                  x: [0, 200],
                  y: [0, -200],
                }}
              />
              <MovingAvatar
                css={css`
                  right: 20%;
                  top: -100px;
                `}
                timeout={3000}
                src={AvatarTwo}
                positionBoundaries={{
                  x: [0, -200],
                  y: [0, 300],
                }}
              />
            </div>
          </div>
        </div>
      </Wrapper>
    </section>
  );
}
