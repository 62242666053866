import React, { useState } from "react";
import { css } from "styled-components/macro";

import { Share } from "./Share";
import { Viewers } from "./Viewers";

import { Video, VideoPublishStatus } from "../../VideoEditor/types/Video";

export type PublishProps = {
  video: Video | null;
};

export function Publish(props: PublishProps) {
  const [firstTimePublished, setFirstTimePublished] = useState(false);

  return (
    <div
      css={css`
        padding: 27px 24px 22px 24px;
      `}
    >
      {props.video?.publish_status === VideoPublishStatus.DRAFT && (
        <Share onShare={() => setFirstTimePublished(true)} />
      )}
      {props.video?.publish_status === VideoPublishStatus.PUBLISHED && (
        <Viewers firstTimePublished={firstTimePublished} />
      )}
    </div>
  );
}
