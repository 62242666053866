import { css } from "styled-components/macro";

export function NumberInput(props: {
  value: number;
  onChange: (value: number) => void;
  min: number;
  max: number;
}) {
  return (
    <div
      css={css`
        display: flex;
        justify-content: space-evenly;
        width: 46px;
        height: 23px;
        background-color: #f2f2f2;
        border: 1px solid #cdcdcd;
        border-radius: 3px;
      `}
    >
      <button
        css={css`
          border: none;
          cursor: pointer;
        `}
        onClick={() => {
          props.onChange(Math.max(props.min, props.value - 1));
        }}
      >
        -
      </button>
      <span
        css={css`
          align-self: center;
        `}
      >
        {props.value}
      </span>
      <button
        css={css`
          border: none;
          cursor: pointer;
        `}
        onClick={() => {
          props.onChange(Math.min(props.max, props.value + 1));
        }}
      >
        +
      </button>
    </div>
  );
}
