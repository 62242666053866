import { css } from "styled-components/macro";
import { useCallback, useEffect, useRef, useState } from "react";
import { ReactComponent as AddIcon } from "../assets/icons/Add.svg";
import { ReactComponent as EyeIcon } from "../assets/icons/Eye.svg";
import { ReactComponent as DeleteIcon } from "../assets/icons/Delete.svg";
import { Button } from "./Button";
import { Headers } from "../helpers/headers";
import { InputComponent } from "./InputComponent";
import { SketchPicker } from "react-color";
import { useClickOutside } from "../hooks/useClickOutside";
import { debounce } from "lodash";

export function ColorAndVisibility(props: {
  title: string;
  fillColor: string;
  opacityValue?: number;
  colorChangeHandler: (value: string) => void;
  opacityChangeHandler?: (value: string) => void;
  className?: string;
}) {
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [color, setColor] = useState(props.fillColor);
  const picker = useRef(null);

  useClickOutside(picker, () => {
    setShowColorPicker(false);
  });

  useEffect(() => {
    setColor(props.fillColor);
  }, [props.fillColor]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateColor = useCallback(
    debounce((value) => {
      props.colorChangeHandler(value);
    }, 500),
    [props]
  );

  const canChangeOpacity = !!(
    props.opacityChangeHandler && props.opacityValue !== undefined
  );
  const canAddMoreColors = false;

  return (
    <div className={props.className}>
      <div
        css={css`
          display: flex;
          justify-content: space-between;
        `}
      >
        <Headers.H4>{props.title}</Headers.H4>
        {canAddMoreColors && (
          <AddIcon
            css={css`
              align-self: center;
              color: #000;
            `}
          />
        )}
      </div>
      <div
        css={css`
          display: flex;
          margin-top: 5px;
        `}
      >
        <div
          css={css`
            position: relative;
          `}
        >
          <div
            css={css`
              position: absolute;
              top: 40px;
              z-index: 2;
            `}
            ref={picker}
          >
            {showColorPicker && (
              <SketchPicker
                disableAlpha
                presetColors={[]}
                color={color.toUpperCase()}
                onChange={(color) => {
                  setColor(color.hex);
                  updateColor(color.hex);
                }}
              />
            )}
          </div>
          <div
            css={css`
              display: flex;
              background-color: #ffffff;
              border: 1px solid #cdcdcd;
              border-radius: 6px;
              padding: 2px 0;
              justify-content: center;
              align-items: center;
            `}
          >
            <div
              css={css`
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
              `}
              onClick={() => {
                setShowColorPicker((show) => !show);
              }}
            >
              <div
                css={css`
                  background-color: ${color};
                  width: 23px;
                  height: 21px;
                  border-radius: 6px;
                  align-self: center;
                  margin-left: 4px;
                `}
              ></div>
              <div
                css={css`
                  width: 62px;
                  padding: 4px;
                  font-size: 12px;
                `}
              >
                {color.toUpperCase()}
              </div>
            </div>
            {canChangeOpacity && (
              <div
                css={css`
                  border-left: 1px solid rgba(86, 83, 83, 0.3);
                  display: flex;
                  overflow: hidden;
                  :after {
                    content: "%";
                    font-size: 12px;
                    align-self: center;
                    margin-right: 3px;
                  }
                `}
              >
                <InputComponent
                  value={props.opacityValue!.toString()}
                  onChange={(e) => props.opacityChangeHandler!(e.target.value)}
                  css={css`
                    padding-left: 5px;
                    width: 28px;
                    font-size: 12px;
                    border: none;
                  `}
                />
              </div>
            )}
          </div>
        </div>
        {canChangeOpacity && (
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              min-width: 3.65em;
              margin-left: 15px;
            `}
          >
            <Button
              icon={<EyeIcon />}
              css={css`
                background-color: transparent;
                padding: 0;
                min-width: 1em;
              `}
              onClick={() => {
                if (props.opacityValue !== 0) {
                  props.opacityChangeHandler!("0");
                } else {
                  props.opacityChangeHandler!("100");
                }
              }}
            ></Button>

            <Button
              icon={<DeleteIcon />}
              css={css`
                background-color: transparent;
                padding: 0;
                min-width: 1em;
              `}
            ></Button>
          </div>
        )}
      </div>
    </div>
  );
}
