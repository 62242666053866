import { useEditor } from "../contexts/EditorContext";
import { DrawSidebar } from "./DrawSidebar";
import { ImagesSidebar } from "./ImagesSidebar";
import { ResourcesSidebar } from "./ResourcesSidebar";
import { ShapesSidebar } from "./ShapesSidebar";
// import { TextSidebar } from "./TextSidebar";
import { UploadSidebar } from "./UploadSidebar";
import { AiSidebar } from "./AiSidebar";

export function SidebarSelector() {
  const { activeNav } = useEditor();

  if (activeNav === "ai") {
    return <AiSidebar />;
  }

  if (activeNav === "media") {
    return <ImagesSidebar />;
  }

  if (activeNav === "draw") {
    return <DrawSidebar />;
  }

  if (activeNav === "shape") {
    return <ShapesSidebar />;
  }

  if (activeNav === "resources") {
    return <ResourcesSidebar />;
  }

  if (activeNav === "upload") {
    return <UploadSidebar />;
  }

  return null;
}
