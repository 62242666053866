import React from "react";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";

import { ActionButton } from "../../components/ActionButton";
import { customToast } from "../../components/customToast";
import { LifetimeTooltip } from "../../components/Tooltip";

import { VideoPublishStatus } from "../../VideoEditor/types/Video";

import { useStorage } from "../../VideoEditor/contexts/StorageContext";
import { publishVideo } from "../../VideoEditor/actions/publishVideo";
import { serverErrorHandler } from "../../helpers/serverErrorHandler";
import { sendEvent } from "../../helpers/tracking";
import { canEdit } from "../../VideoEditor/helpers/collaborator";
import { useAuth } from "../../contexts/UserContext";
import { calculateVideoDuration } from "../../VideoEditor/helpers/video";
import { DisableUI } from "../../VideoEditor/components/DisableUI";

export function Share(props: { onShare: () => void }) {
  const {
    video,
    api: { getLastEvent, updateVideoPublishStatus },
  } = useStorage();

  const { t } = useTranslation();
  const { userprofile } = useAuth();

  const onPublish = async (callback: Promise<void>) => {
    if (video?.uuid) {
      try {
        const lastEventId = await getLastEvent();
        if (!lastEventId) {
          customToast.error(
            t("status.error", {
              error: serverErrorHandler(t("publish.share.error")),
            })
          );

          return;
        }
        await publishVideo(video.uuid, lastEventId);
        await callback;
        props.onShare();
        await updateVideoPublishStatus(VideoPublishStatus.PUBLISHED);

        sendEvent("Publish Themi", {
          themiId: video.uuid,
          publisher: userprofile?.id,
          numberOfSequences: video.schema.schema.scenes.length,
          duration: calculateVideoDuration(video.schema.schema) / 1000,
          features: video.schema.schema.elements.reduce((features, element) => {
            if (!features.includes(element.type)) {
              features.push(element.type);
            }
            return features;
          }, [] as string[]),
        });
      } catch (error) {
        customToast.error(
          t("status.error", {
            error: serverErrorHandler(error),
          })
        );
      }
    }
  };

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        flex-direction: column;
      `}
    >
      <p
        css={css`
          font-size: 20px;
          line-height: 24px;
          font-weight: 500;
          margin-bottom: 14px;
        `}
      >
        {t("publish.share.ready")}
      </p>

      <LifetimeTooltip
        show={!canEdit(video?.current_user_role)}
        activateOnClick
        text={t("tooltip.access")}
        lifeTime={5000}
        position="center"
      >
        <DisableUI show={!canEdit(video?.current_user_role)}>
          <ActionButton onClick={onPublish}>
            {t("video-editor.status-bar.publish")}
          </ActionButton>
        </DisableUI>
      </LifetimeTooltip>

      <p
        css={css`
          margin-top: 34px;
          font-size: 16px;
          line-height: 151%;
          color: #7f7f7f;
          text-align: center;
        `}
      >
        {t("publish.share.description")}
      </p>
    </div>
  );
}
