import { css } from "styled-components/macro";
import { Wrapper } from "../components/Wrapper";
import { Search } from "../components/Search";
import { LanguageSelector } from "../components/LanguageSelector";
import { Link } from "react-router-dom";
import { ReactComponent as ArrowIcon } from "../assets/icons/Arrow.svg";
import { Headers, splitLines } from "../helpers/layout";
import { Footer } from "../components/Footer";
import { useTranslation } from "react-i18next";
import { Accordion } from "../components/Accordion";
import { useAuth } from "../contexts/UserContext";
import { FeedbackWidget } from "../components/FeedbackWidget";

export function FaqPage() {
  const { t } = useTranslation();
  const { isAuthenticated } = useAuth();
  const faqData: { id: number; title: string; content: React.ReactNode }[] = [
    {
      id: 1,
      title: t("faq.question.first"),
      content: splitLines(t("faq.answer.first")),
    },
    {
      id: 2,
      title: t("faq.question.second"),
      content: splitLines(t("faq.answer.second")),
    },
    {
      id: 3,
      title: t("faq.question.third"),
      content: splitLines(t("faq.answer.third")),
    },
    {
      id: 4,
      title: t("faq.question.forth"),
      content: splitLines(t("faq.answer.forth")),
    },
    {
      id: 5,
      title: t("faq.question.fifth"),
      content: splitLines(t("faq.answer.fifth")),
    },
    {
      id: 6,
      title: t("faq.question.sixth"),
      content: splitLines(t("faq.answer.sixth")),
    },
    {
      id: 7,
      title: t("faq.question.seventh"),
      content: splitLines(t("faq.answer.seventh")),
    },
    {
      id: 8,
      title: t("faq.question.eighth"),
      content: splitLines(t("faq.answer.eighth")),
    },
  ];
  return (
    <>
      <Wrapper
        css={css`
          padding: 26px 0 65px;
          min-height: calc(100vh - 36px - 48px);
          box-sizing: border-box;
        `}
      >
        <div
          css={css`
            position: relative;
            display: flex;
            flex-flow: row wrap;
            justify-content: stretch;
          `}
        >
          <aside
            css={css`
              flex: 0 0 350px;
              padding-right: 33px;
              padding-left: 27px;
              box-sizing: border-box;
              display: flex;
              flex-direction: column;
              min-height: calc(100vh - 200px);
            `}
          >
            {isAuthenticated && (
              <div
                css={css`
                  display: flex;
                  margin-top: 60px;
                `}
              >
                <Link to="/">
                  <ArrowIcon />
                </Link>
                <Link
                  to="/"
                  css={css`
                    font-size: 20px;
                    font-weight: 600;
                    letter-spacing: -0.05em;
                    margin-left: 20px;
                  `}
                >
                  {t("myThemi.dashboard.button.back")}
                </Link>
              </div>
            )}
            <div
              css={css`
                margin-top: ${isAuthenticated ? "40px" : "113px"};
              `}
            >
              <Headers.H4
                css={css`
                  font-weight: 500;
                  color: #e95b2e;
                `}
              >
                Support
              </Headers.H4>
              <Headers.H3
                css={css`
                  font-size: 40px;
                `}
              >
                FAQs Center
              </Headers.H3>
            </div>

            <div
              css={css`
                margin-top: 20px;
                line-height: 20px;
                font-family: 600;
                a {
                  font-weight: bold;
                  text-decoration: underline;
                }
              `}
            >
              {splitLines(t("faq.description"))}
            </div>
          </aside>
          <main
            css={css`
              flex: 1 1 10%;
              padding-left: 70px;
              box-sizing: border-box;
            `}
          >
            <header
              css={css`
                margin-bottom: 15px;
                display: flex;
                flex-flow: row wrap;
                justify-content: flex-end;
              `}
            >
              <div
                css={css`
                  flex: 0 0 230px;
                `}
              >
                <Search />
              </div>
              <LanguageSelector
                css={css`
                  margin-top: 7px;
                `}
              />
            </header>
            <section
              css={css`
                margin-top: 115px;
              `}
            >
              <Accordion data={faqData} />
            </section>
          </main>
        </div>
      </Wrapper>
      <Footer />
      <FeedbackWidget />
    </>
  );
}
