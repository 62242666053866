export type SequenceTemplate = {
  id: string;
  title: string;
  description: string;
  preview: string;
};

export function getSequencesTemplates(t: any) {
  return [
    {
      id: "1",
      title: t("sequence.templates.first"),
      preview: require("../../assets/templates/user_tagging_cover.jpg"),
    },
    {
      id: "2",
      title: t("sequence.templates.second"),
      preview: require("../../assets/templates/questionaire.jpg"),
    },
    {
      id: "3",
      title: t("sequence.templates.third"),
      preview: require("../../assets/templates/reactions.jpg"),
    },
    {
      id: "4",
      title: t("sequence.templates.forth"),
      preview: require("../../assets/templates/image_and_text.jpg"),
    },
    {
      id: "5",
      title: t("sequence.templates.fifth"),
      preview: require("../../assets/templates/header_and_bullet_points.jpg"),
    },
    {
      id: "6",
      title: t("sequence.templates.sixth"),
      preview: require("../../assets/templates/header_and_body_text.jpg"),
    },
  ];
}
