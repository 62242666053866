import { css } from "styled-components/macro";
import mailIcon from "../../assets/icons/mail.svg";
import whatsappIcon from "../../assets/icons/whatsapp.svg";
import linkedinIcon from "../../assets/icons/linkedin.svg";
import twitterIcon from "../../assets/icons/twitter.svg";
import { useCheckMobile } from "../../hooks/useCheckMobile";
import { useTranslation } from "react-i18next";

export function ShareButtons() {
  const { isMobile } = useCheckMobile();
  const { t } = useTranslation();
  const subject = t("share-button.subject");
  const body = t("share-button.body");
  const url = `${process.env.REACT_APP_FRONTEND_BASE_URL}/`;
  const hashtags = "themis, knowledgemanagement";

  return (
    <div
      css={css`
        a {
          margin-right: 25px;

          &:last-child {
            margin-right: 0;
          }
        }
      `}
    >
      <a
        href={`mailto:?subject=${subject}&body=${body}`}
        target="_blank"
        rel="noreferrer"
      >
        <img src={mailIcon} alt="Email" />
      </a>
      <a
        href={`https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${subject}&summary=${body}&source=${url}`}
        target="_blank"
        rel="noreferrer"
      >
        <img src={linkedinIcon} alt="LinkedIn" />
      </a>
      {isMobile && (
        <a
          href={`whatsapp://send?text=${body}`}
          target="_blank"
          rel="noreferrer"
        >
          <img src={whatsappIcon} alt="WhatsApp" />
        </a>
      )}
      <a
        href={`https://twitter.com/intent/tweet?text=${body}&hashtags=${hashtags}`}
        target="_blank"
        rel="noreferrer"
      >
        <img src={twitterIcon} alt="Twitter" />
      </a>
    </div>
  );
}
