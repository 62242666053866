import mixpanel, { Dict } from "mixpanel-browser";

export function sendEvent(event: string, properties?: Dict) {
  if (process.env.REACT_APP_MIXPANEL_TOKEN && mixpanel) {
    mixpanel.track(event, properties);
  } else {
    console.log("Tracking event", event, properties);
  }
}
export function identifyUser(userId: number) {
  if (process.env.REACT_APP_MIXPANEL_TOKEN && mixpanel) {
    mixpanel.identify(String(userId));
  } else {
    console.log("Identifying user", userId);
  }
}
