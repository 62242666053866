import { authorised } from "../../request";
import { Video } from "../types/Video";

export async function shareVideo(videoId: string,input:{email:string;role:string}){
    const API = authorised();

    return API.request<Video>({
        method:"POST",
        url:`/api/studio/themis/${videoId}/collaborators/`,
        data:input,
    })
}