import { css } from "styled-components/macro";
import { Wrapper } from "./Wrapper";
import logo from "../assets/img/logo.png";
import { useTranslation } from "react-i18next";

export function Footer() {
  const { t } = useTranslation();
  return (
    <div
      css={css`
        background: #fae7d9;
      `}
    >
      <Wrapper>
        <div
          css={css`
            padding: 10px 0;
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
          `}
        >
          <div>
            <a
              href={`https://themis.eu/`}
              target={`_blank`}
              rel={`noopener noreferrer`}
              css={css`
                color: #e95b2e;
                margin-right: 45px;
              `}
            >
              Home
            </a>
            <a
              href={`https://themis.eu/about`}
              target={`_blank`}
              rel={`noopener noreferrer`}
              css={css`
                color: #e95b2e;
                margin-right: 45px;
              `}
            >
              {t("footer.about-us")}
            </a>
          </div>
          <div>
            <a
              href={`https://themis.eu`}
              target={`_blank`}
              rel={`noopener noreferrer`}
              css={css`
                color: #e95b2e;
                margin-right: 45px;
              `}
            >
              <img
                css={css`
                  cursor: pointer;
                  max-width: 134px;
                  max-height: 40px;
                  vertical-align: middle;
                `}
                src={logo}
                alt={`Themi Logo`}
              />
            </a>
          </div>
          <div>
            <a
              href={`https://themis.eu/pricing`}
              target={`_blank`}
              rel={`noopener noreferrer`}
              css={css`
                color: #e95b2e;
                margin-right: 45px;
              `}
            >
              Upgrade
            </a>
            <a
              href={`/faq`}
              target={`_blank`}
              rel={`noopener noreferrer`}
              css={css`
                color: #e95b2e;
              `}
            >
              FAQ
            </a>
          </div>
        </div>
      </Wrapper>
    </div>
  );
}
