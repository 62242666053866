import React, { RefObject, useCallback, useEffect } from "react";
import { Howl } from "howler";
import scrollwheel from "../../assets/sounds/scrollwheel.mp3";

export type RecordingType = "audio" | "video" | "screen";
export type RecordedMedia = {
  type: RecordingType;
  file: File;
  duration: number;
};
export type RecordingStatus = "active" | "paused" | "not-active";

function getContraints(type: RecordingType): MediaStreamConstraints {
  switch (type) {
    case "audio":
      return { audio: true };
    case "video":
      return { video: true, audio: true };
    case "screen":
      return { video: true, audio: true };
  }
}

const sound = new Howl({
  src: [scrollwheel],
  html5: true,
});

const RecordingContext = React.createContext<{
  recording: RecordingStatus;
  recordingType: RecordingType;
  recordingTime: number;
  countdownTime: number;
  record: (type: RecordingType) => void;
  hasPermission: boolean | null;
  deviceSupported: boolean | null;
  mediaRecorder: RefObject<MediaRecorder | null>;
  onRecordingStop: React.MutableRefObject<(data: RecordedMedia) => void>;
  cancelRecording: () => void;
  pauseRecording: () => void;
  stopRecording: () => void;
  resumeRecording: () => void;
}>({
  recording: "not-active",
  recordingType: "video",
  recordingTime: 0,
  countdownTime: 0,
  record: () => {},
  hasPermission: null,
  deviceSupported: null,
  mediaRecorder: React.createRef(),
  onRecordingStop: React.createRef() as React.MutableRefObject<
    (data: RecordedMedia) => void
  >,
  cancelRecording: () => {},
  pauseRecording: () => {},
  stopRecording: () => {},
  resumeRecording: () => {},
});

const timeout = 3;

export function RecordingProvider(props: { children: React.ReactNode }) {
  const [passedTime, setPassedTime] = React.useState<number>(0);
  const onRecordingStop = React.useRef<(data: RecordedMedia) => void>(() => {});
  const startTime = React.useRef<number>(0);
  const mediaChunks = React.useRef<Blob[]>([]);
  const deviceSupported = !!navigator.mediaDevices;
  const [hasPermission, setHasPermission] = React.useState<boolean | null>(
    null
  );
  const mediaRecorder = React.useRef<MediaRecorder | null>(null);
  const [recording, setRecording] =
    React.useState<RecordingStatus>("not-active");
  const [recordingType, setRecordingType] =
    React.useState<RecordingType>("video");

  const countdownTime = Math.max(timeout - passedTime, 0);

  useEffect(() => {
    if (recording !== "active") return;

    const interval = setInterval(() => {
      if (recording) {
        setPassedTime((time) => time + 1);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [recording]);

  useEffect(() => {
    if (countdownTime > 0) {
      sound.play();
    }
  }, [countdownTime]);

  const record = useCallback((recordingType: RecordingType) => {
    setRecording("active");
    setRecordingType(recordingType);

    const constraints = getContraints(recordingType);

    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((_stream) => {
        mediaRecorder.current = new MediaRecorder(_stream);

        setTimeout(() => {
          mediaRecorder.current!.start();
        }, timeout * 1000);

        mediaRecorder.current.ondataavailable = ({ data }: BlobEvent) => {
          mediaChunks.current.push(data);
        };

        mediaRecorder.current.onstop = () => {
          const [chunk] = mediaChunks.current;

          const blobProperty: BlobPropertyBag = Object.assign(
            { type: chunk.type },
            recordingType === "video"
              ? { type: "video/mp4" }
              : { type: "audio/wav" }
          );
          const blob = new Blob(mediaChunks.current, blobProperty);
          const filename =
            recordingType === "video" ? "video.mp4" : "audio.wav";

          const file = new File([blob], filename, blobProperty);
          const endTime = new Date().getTime();

          onRecordingStop.current({
            type: recordingType,
            file,
            duration: Math.max(endTime - startTime.current - timeout * 1000, 0),
          });
        };

        setHasPermission(true);
      })
      .catch((err) => {
        if (err.name === "NotAllowedError") {
          setHasPermission(false);
        }
      });

    setPassedTime(0);
    startTime.current = new Date().getTime();
    mediaChunks.current = [];
  }, []);

  const cancelRecording = useCallback(() => {
    mediaRecorder.current = null;
    mediaChunks.current = [];

    setRecording("not-active");
  }, []);

  const stopRecording = useCallback(() => {
    setRecording("not-active");

    if (mediaRecorder.current && mediaRecorder.current.state === "recording") {
      mediaRecorder.current.stop();

      const tracks = mediaRecorder.current.stream.getTracks();

      tracks.forEach((track) => {
        track.stop();
      });
    }
  }, []);

  const pauseRecording = useCallback(() => {
    if (mediaRecorder.current) {
      setRecording("paused");
      mediaRecorder.current!.pause();
    }
  }, []);

  const resumeRecording = useCallback(() => {
    if (mediaRecorder.current) {
      setRecording("active");
      mediaRecorder.current!.resume();
    }
  }, []);

  return (
    <RecordingContext.Provider
      value={{
        recording,
        recordingType,
        recordingTime: Math.max(passedTime - timeout, 0),
        countdownTime,
        record,
        hasPermission,
        deviceSupported,
        mediaRecorder,
        onRecordingStop,
        cancelRecording,
        pauseRecording,
        resumeRecording,
        stopRecording,
      }}
    >
      {props.children}
    </RecordingContext.Provider>
  );
}

export function useRecording(props?: {
  onRecordingStop: (file: RecordedMedia) => void;
}) {
  const context = React.useContext(RecordingContext);

  if (context === undefined) {
    throw new Error("useRecording must be used within a RecordingProvider");
  }

  useEffect(() => {
    if (!props) return;

    context.onRecordingStop.current = props.onRecordingStop;
  }, [props, context.onRecordingStop]);

  return context;
}
