import { authorised } from "../../request";
import { Video } from "../types/Video";

export async function createVideoFromTemplate(input: { template_id: string }) {
  const API = authorised();

  return API.request<Video>({
    method: "POST",
    url: `/api/studio/themis/from-template/`,
    data: input,
  });
}
