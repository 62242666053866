import { useEffect, useRef, useState } from "react";
import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { useEditor } from "../contexts/EditorContext";
import { ReactComponent as SettingsIcon } from "../assets/icons/Settings.svg";
import { ReactComponent as ClockIcon } from "../assets/icons/Clock.svg";
import { Headers } from "../helpers/headers";
import { Button } from "./Button";
import { ToggleSwitch } from "./ToggleSwitch";
import { ColorAndVisibility } from "./ColorAndVisibility";
import { usePlayback } from "../contexts/PlaybackContext";
import { useStorage } from "../contexts/StorageContext";
import { NumberInput } from "./NumberInput";
import { RadioComponent } from "./RadioComponent";
import { canEdit } from "../helpers/collaborator";
import { useClickOutside } from "../../VideoEditor/hooks/useClickOutside";
import { getSequencesTemplates } from "../helpers/templates/sequences";
import { ReactComponent as LeftArrowIcon } from "../assets/icons/LeftArrow.svg";

export function CanvasSettings() {
  const { activeScene } = usePlayback();
  const { video, api } = useStorage();
  const [isToggle, setIsToggle] = useState(false);
  const [top, setTop] = useState(0);
  const [typeAnimation, setTypeAnimation] = useState("fade-in");
  const [mode, setMode] = useState<"templates" | "all">("all");
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);

  const choices: { value: string; label: string }[] = [
    { value: "fadeIn", label: t("animation.fade-in") },
    { value: "fadeOut", label: t("animation.fade-out") },
    { value: "slidedDown", label: t("animation.slide-down") },
    { value: "slide", label: t("animation.slide") },
    { value: "slideUp", label: t("animation.slide-up") },
  ];

  const {
    settingsOpen,
    closeSettings,
    toggleSettings,
    setSettingsMode,
    settingsMode,
    canvasSize,
  } = useEditor();

  useClickOutside(ref, () => {
    closeSettings();
  });

  useEffect(() => {
    const calculateOffsetTop = () => {
      const el = document.querySelector(".themis-canvas-wrapper");

      if (el) {
        const rect = el.getBoundingClientRect();
        setTop(rect.top);
      }
    };

    setTimeout(() => {
      calculateOffsetTop();
    }, 100);

    window.addEventListener("resize", calculateOffsetTop);

    return () => {
      window.removeEventListener("resize", calculateOffsetTop);
    };
  }, []);

  if (!activeScene) return null;
  const results = getSequencesTemplates(t);
  const sequenceTemplates = results.length > 4 ? results.slice(0, 4) : results;
  return (
    <div
      ref={ref}
      css={css`
        position: absolute;
        width: 253px;
        right: -252px;
        top: 59px;
        height: calc(100% - 59px);
        min-height: 0px;
        transition: right 0.2s ease-in-out;
        box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25);
        z-index: 2000;
        background: #fff;

        ${settingsOpen &&
        css`
          right: 0;
        `};
      `}
    >
      <div
        id="themis-canvasSettings"
        style={{
          position: "absolute",
          top: 100,
          left: -252,
        }}
      ></div>
      {!!top && canEdit(video?.current_user_role) && (
        <div
          css={css`
            position: absolute;
            width: 47px;
            height: 51px;
            top: ${top - 64 + canvasSize.height / 2 - 92 / 2}px;
            left: -46px;
            background: #e95b2e;
            cursor: pointer;
            box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
            border-radius: 7px 2px 2px 7px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          `}
          onClick={toggleSettings}
        >
          <SettingsIcon />
        </div>
      )}

      <div
        css={css`
          position: relative;
          background: #fff;
          height: 100%;
          padding: 0 11px;
          z-index: 2;
          display: flex;
          flex-direction: column;
        `}
      >
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            margin-top: 5px;
            height: auto;
          `}
        >
          <Button
            css={css`
              padding: 10px 35px;
              border: ${settingsMode === "canvas"
                ? "none"
                : "1px solid #cdcdcd"};
              color: ${settingsMode === "canvas" ? "#E95B2E" : "#cdcdcd"};
              background-color: ${settingsMode === "canvas"
                ? "#FAE7D9"
                : "transparent"};
              font-weight: 600;
            `}
            onClick={() => {
              setSettingsMode("canvas");
              setMode("all");
            }}
          >
            Canvas
          </Button>
          <Button
            disabled={true}
            css={css`
              color: #ccc;
              background-color: #eee !important;
              font-weight: 600;
              margin-left: 3px;
            `}
            onClick={() => {
              setSettingsMode("transitions");
            }}
          >
            {t("canvas.settings.sidebar.button.transitions")}
          </Button>
        </div>

        {settingsMode === "canvas" && mode === "all" ? (
          <div>
            <div>
              <div
                css={css`
                  display: flex;
                  justify-content: space-between;
                  margin-top: 10px;
                `}
              >
                <Headers.H4
                  css={css`
                    font-weight: 500;
                  `}
                >
                  {t("canvas.settings.sidebar.title.template")}
                </Headers.H4>
                <button
                  css={css`
                    border: none;
                    background-color: transparent;
                    align-self: center;
                    text-decoration: underline;
                    font-size: 10px;
                    cursor: pointer;
                  `}
                  onClick={() => {
                    setMode("templates");
                  }}
                >
                  {t("canvas.settings.sidebar.button.view-all")}
                </button>
              </div>
              <Headers.H4
                css={css`
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 19.6px;
                `}
              >
                {t("sequence.templates.description")}
              </Headers.H4>
              <div
                css={css`
                  display: flex;
                  flex-flow: row wrap;
                  margin-top: 7px;
                `}
              >
                {sequenceTemplates &&
                  sequenceTemplates.map((el) => {
                    return (
                      <div
                        key={el.id}
                        css={css`
                          width: 48%;
                          height: 71px;
                          background-image: url(${el.preview});
                          background-size: cover;
                          background-position: center;
                          background-repeat: no-repeat;
                          border: 0.5px solid #8e8e8e;
                          border-radius: 9px;
                          margin-bottom: 4%;
                          &:nth-child(odd) {
                            margin-right: 4%;
                          }
                          cursor: not-allowed;
                        `}
                      ></div>
                    );
                  })}
              </div>
            </div>
            <hr
              css={css`
                background-color: #d9d9d9;
                height: 1px;
                border: 0;
                margin: 12px 0 10px 0 !important;
              `}
            />
            <div
              css={css`
                margin-top: 30px;
              `}
            >
              <Headers.H4
                css={css`
                  font-weight: 500;
                `}
              >
                {t("canvas.settings.sidebar.title.background-color")}
              </Headers.H4>
              <ColorAndVisibility
                title={t("canvas.settings.sidebar.title.fill")}
                fillColor={activeScene.backgroundColor}
                colorChangeHandler={(fillColor) => {
                  api.updateScene(activeScene.id, {
                    backgroundColor: fillColor,
                  });
                }}
              />
            </div>
            <div
              css={css`
                display: flex;
                flex-direction: column;
                margin-top: 40px;
              `}
            >
              <Headers.H4
                css={css`
                  font-weight: 500;
                `}
              >
                {t("canvas.settings.sidebar.title.duration")}
              </Headers.H4>
              <div
                css={css`
                  display: flex;
                  justify-content: space-between;
                  margin-top: 5px;
                `}
              >
                <div
                  css={css`
                    display: flex;
                  `}
                >
                  <ClockIcon
                    css={css`
                      align-self: center;
                    `}
                  />
                  <Headers.H5
                    css={css`
                      margin-left: 10px;
                      width: 90px;
                      word-break: break-word;
                      line-height: 14.4px;
                    `}
                  >
                    {t("canvas.settings.sidebar.subtitle.duration")}
                  </Headers.H5>
                </div>
                <NumberInput
                  value={activeScene.duration / 1000}
                  onChange={(value) => {
                    api.updateScene(activeScene.id, {
                      duration: value * 1000,
                    });
                  }}
                  min={1}
                  max={600}
                />
              </div>
              <Headers.H6
                css={css`
                  margin-top: 40px;
                  color: #8e8e8e;
                `}
              >
                {t("canvas.settings.sidebar.description.duration")}
              </Headers.H6>
            </div>
          </div>
        ) : (
          <div
            css={css`
              margin-top: 5px;
              min-height: 0;
              flex: 1 0 10%;
              display: flex;
              flex-direction: column;
              ${mode === "all" &&
              css`
                display: none;
              `}
            `}
          >
            <Button
              icon={
                <LeftArrowIcon
                  css={css`
                    width: 11px;
                    height: 10px;
                    align-self: center;
                    margin-right: -7px;
                  `}
                />
              }
              css={css`
                background-color: transparent;
                margin-bottom: 10px;
                padding: 0;
                min-width: 2em;
                padding-left: 15px;
                flex: 0 0 auto;
                align-self: flex-start;
                margin-left: 13px;
                :hover {
                  background-color: transparent;
                }
              `}
              onClick={() => {
                setMode("all");
              }}
            >
              {t("media.sidebar.button.back")}
            </Button>
            <div
              css={css`
                overflow-y: scroll;
                min-height: 0;
                flex: 1 0 10%;
                direction: rtl;
                /* width */
                ::-webkit-scrollbar {
                  height: 100%;
                  border-radius: 5px;
                  width: 8px;
                }

                /* Track */
                ::-webkit-scrollbar-track {
                  background: #f2f2f2;
                }

                /* Handle */
                ::-webkit-scrollbar-thumb {
                  background: #8e8e8e;
                  border-radius: 5px;
                }

                /* Handle on hover */
                ::-webkit-scrollbar-thumb:hover {
                  background: #555;
                }
              `}
            >
              {results.map((item) => {
                return (
                  <div
                    key={item.id}
                    css={css`
                      width: 200px;
                      height: auto;
                      margin-bottom: 13px;
                      margin-right: 0;
                    `}
                  >
                    <img
                      src={item.preview}
                      css={css`
                        object-fit: cover;
                        width: 100%;
                        height: 108px;
                        border-radius: 9px;
                        border: 0.5px solid #626262;
                        cursor: not-allowed;
                      `}
                      alt="sequence-templates"
                    />
                    <Headers.H4
                      css={css`
                        font-size: 14px;
                        line-height: 10.8px;
                        color: #626262;
                        direction: ltr;
                      `}
                    >
                      {item.title}
                    </Headers.H4>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        {settingsMode === "transitions" && mode === "all" && (
          <div>
            <div
              css={css`
                margin-top: 13px;
              `}
            >
              <Headers.H4
                css={css`
                  font-weight: 500;
                `}
              >
                {t("canvas.sidebar.transitions.title.style")}
              </Headers.H4>
              <div
                css={css`
                  display: flex;
                  justify-content: space-between;
                  margin-top: -6px;
                `}
              >
                <Headers.H5
                  css={css`
                    color: #8e8e8e;
                  `}
                >
                  {t("canvas.settings.sidebar.transitions.sub-title")}
                </Headers.H5>
                <ToggleSwitch
                  value={isToggle}
                  onChange={() => setIsToggle(!isToggle)}
                />
              </div>
              <div
                css={css`
                  margin-top: 10px;
                  width: 100%;
                  height: 128px;
                  background-color: #000000;
                  border-radius: 7px;
                `}
              ></div>
              <div
                css={css`
                  display: flex;
                  flex-flow: wrap;
                  justify-content: space-between;
                  margin-top: 20px;
                `}
              >
                {choices.map((choice, idx) => {
                  return (
                    <RadioComponent
                      key={idx}
                      name="animation"
                      value={choice.value}
                      label={choice.label}
                      isSelected={choice.value === typeAnimation}
                      onChange={(e) => setTypeAnimation(e.target.value)}
                      css={css`
                        min-width: 110px;
                      `}
                    ></RadioComponent>
                  );
                })}
              </div>
            </div>
            <hr
              css={css`
                background-color: #d9d9d9;
                height: 1px;
                border: 0;
                margin: 30px 0 10px 0 !important;
              `}
            />
            <div css={css``}>
              <Headers.H4
                css={css`
                  line-height: 21px;
                  font-weight: 500;
                `}
              >
                {t(
                  "canvas.settings.sidebar.transitions.title.transition-between-pages"
                )}
              </Headers.H4>
              <div
                css={css`
                  display: flex;
                  justify-content: space-between;
                  margin-top: -3px;
                `}
              >
                <Headers.H5
                  css={css`
                    color: #8e8e8e;
                  `}
                >
                  {t("canvas.settings.sidebar.transitions.sub-title")}
                </Headers.H5>
                <ToggleSwitch
                  value={isToggle}
                  onChange={() => setIsToggle(!isToggle)}
                />
              </div>
              <div
                css={css`
                  display: flex;
                  justify-content: space-between;
                  margin-top: 20px;
                `}
              >
                <div
                  css={css`
                    display: flex;
                  `}
                >
                  <ClockIcon
                    css={css`
                      align-self: center;
                    `}
                  />
                  <Headers.H5
                    css={css`
                      margin-left: 10px;
                      width: 90px;
                      word-break: break-word;
                      line-height: 14.4px;
                    `}
                  >
                    {t("canvas.settings.sidebar.subtitle.duration")}
                  </Headers.H5>
                </div>

                <NumberInput value={4} onChange={(e) => {}} min={0} max={4} />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
