import React from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { css } from "styled-components/macro";
import { useAuth } from "../contexts/UserContext";
import logo from "../assets/img/logo.png";
import { Button } from "../VideoEditor/components/Button";
import { LanguageSelector } from "./LanguageSelector";

export function Header(props: {}) {
  const { isAuthenticated } = useAuth();
  const history = useHistory();
  const location = useLocation();

  if (!isAuthenticated) {
    return (
      <header
        css={css`
          padding: 30px 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
        `}
      >
        <Link to="/">
          <img
            css={css`
              cursor: pointer;
              max-width: 134px;
              max-height: 40px;
              vertical-align: middle;
            `}
            src={logo}
            alt={`Themi Logo`}
          />
        </Link>
        <div
          css={css`
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          `}
        >
          <div>
            {location.pathname !== "/login" ? (
              <Button
                css={css`
                  color: #fff;
                `}
                onClick={() => {
                  history.push("/login");
                }}
              >
                Login
              </Button>
            ) : (
              <Button
                css={css`
                  color: #fff;
                `}
                onClick={() => {
                  history.push("/register");
                }}
              >
                Sign up
              </Button>
            )}
          </div>
          <LanguageSelector
            css={css`
              margin-top: 8px;
            `}
          />
        </div>
      </header>
    );
  }

  return (
    <header
      css={css`
        margin-top: 44px;
        margin-bottom: 30px;
        height: 70px;
        padding: 0 30px;
        border-radius: 40px;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: center;
        background-color: #ffffff;
      `}
    >
      <Link to="/">
        <img
          css={css`
            cursor: pointer;
            max-width: 134px;
            max-height: 40px;
            vertical-align: middle;
          `}
          src={logo}
          alt={`Themi Logo`}
        />
      </Link>
    </header>
  );
}
