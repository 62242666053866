import React from "react";
import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { serverErrorHandler } from "../helpers/serverErrorHandler";
import { createVideo } from "../VideoEditor/actions/createVideo";
import { Button } from "../VideoEditor/components/Button";
import { customToast } from "./customToast";
import { Loader } from "./Loader";
import { sendEvent } from "../helpers/tracking";
import { generateDefaultEmptyScene } from "../VideoEditor/helpers/video";

export function CreateThemiButton() {
  const { t } = useTranslation();
  const [submitting, setSubmitting] = React.useState(false);
  const history = useHistory();
  const name = t("scene.title");
  const emptyContent = generateDefaultEmptyScene(name, 0, t);

  return (
    <Button
      onClick={async function () {
        setSubmitting(true);

        try {
          const response = await createVideo({
            name: "New Themi",
            initial_content: {
              scenes: [emptyContent.scene],
              elements: emptyContent.elements,
            },
          });

          sendEvent("Create Themi", {
            themiId: response.data.uuid,
          });

          history.push(`/video-editor/${response.data.uuid}`);
        } catch (error: any) {
          customToast.error(
            t("status.error", {
              error: serverErrorHandler(error),
            })
          );
        } finally {
          setSubmitting(false);
        }
      }}
      css={css`
        color: #fff;
        padding: 7px 28px;
        font-size: 20px;
      `}
    >
      {submitting ? <Loader /> : `+ ` + t("pagelayout.newThemi-button")}
    </Button>
  );
}
