import {
  VideoElementOpenQuestion,
  VideoElementState,
} from "../../../VideoEditor/types/Video";
import { css } from "styled-components/macro";
import { calculateProportionalSize } from "../../helpers/renderer";
import { usePlayer } from "../../contexts/PlayerContext";

export function ElementOpenQuestionRenderer(props: {
  element: VideoElementOpenQuestion;
  state: VideoElementState;
}) {
  const { canvasSize } = usePlayer();

  return (
    <div
      css={css`
        width: 100%;
        height: 100%;
        border-radius: ${calculateProportionalSize(10, canvasSize.width)}px;
        background: #ffffff;
        padding: ${calculateProportionalSize(20, canvasSize.width)}px;
      `}
    >
      Coming soon
    </div>
  );
}
