import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNetwork } from "../helpers/useNetwork";
import {
  Video,
  VideoElement,
  VideoScene,
  VideoPublishStatus,
  ReorderingScene,
} from "../types/Video";
import { liveQuery } from "dexie";
import { db, VideoStateUpdateDraft } from "../db";
import { commitLocalChange, localAPI } from "../API";
import { compileVideo, getReverseAction } from "../helpers/video";
import last from "lodash/last";
import first from "lodash/first";
import { getVideo } from "../actions/getVideo";
import { commitChange } from "../actions/commitChange";
import { useAuth } from "../../contexts/UserContext";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

export type StorageAPIMeta = {
  userId?: number;
  updateGroupId?: string | null;
  fake?: boolean;
};

function moveItems(video: Video, from: ReorderingScene, to: ReorderingScene) {
  const items: VideoScene[] = JSON.parse(
    JSON.stringify(video.schema.schema.scenes)
  );

  let newItems = items;

  const movedItem = items.find((item) => item.id === from.id);
  const toItem = items.find((item) => item.id === to.id);
  const movedItemIsActuallyMoving = movedItem?.order !== toItem?.order;

  if (movedItem && toItem && movedItemIsActuallyMoving) {
    const direction = toItem.order > movedItem.order ? "DOWN" : "UP";
    if (direction === "DOWN") {
      newItems = newItems.map((item) => {
        if (movedItem.order === item.order) {
          return { ...item, order: toItem.order };
        }
        if (item.order <= toItem.order && item.order > movedItem.order) {
          const newOrder = item.order - 1;
          return { ...item, order: newOrder };
        }

        return item;
      });
    } else {
      newItems = newItems.map((item) => {
        if (movedItem.order === item.order) {
          return { ...item, order: toItem.order };
        }
        if (item.order < movedItem.order && item.order >= toItem.order) {
          const newOrder = item.order + 1;
          return { ...item, order: newOrder };
        }

        return item;
      });
    }
  }

  return newItems
    .sort((a, b) => a.order - b.order)
    .map((item, i) => ({
      ...item,
      order: i,
    }));
}

const StorageContext = React.createContext<{
  video: Video | null;
  undo: () => Promise<void>;
  redo: () => Promise<void>;
  hasUndo: boolean;
  hasRedo: boolean;
  api: {
    createScene: (meta?: StorageAPIMeta) => Promise<void>;
    deleteScene: (elementID: string, meta?: StorageAPIMeta) => Promise<void>;
    reorderScene: (
      from: ReorderingScene,
      to: ReorderingScene,
      meta?: StorageAPIMeta
    ) => Promise<void>;
    createSceneWithContent: (
      scene: Pick<VideoScene, "name" | "order" | "start_time">,
      elements: VideoElement[],
      meta?: StorageAPIMeta
    ) => Promise<VideoScene | null>;
    createElement: (
      element: VideoElement,
      meta?: StorageAPIMeta
    ) => Promise<void>;
    createElements: (
      elements: VideoElement[],
      meta?: StorageAPIMeta
    ) => Promise<void>;
    updateElement: (
      elementID: string,
      element: Partial<VideoElement>,
      meta?: StorageAPIMeta
    ) => Promise<void>;
    updateScene: (
      elementID: string,
      element: Partial<VideoScene>,
      meta?: StorageAPIMeta
    ) => Promise<void>;
    deleteElement: (elementID: string, meta?: StorageAPIMeta) => Promise<void>;
    deleteElements: (
      elements: VideoElement[],
      meta?: StorageAPIMeta
    ) => Promise<void>;
    bringToFront: (elementID: string, meta?: StorageAPIMeta) => Promise<void>;
    bringToBack: (elementID: string, meta?: StorageAPIMeta) => Promise<void>;
    getLastEvent: () => Promise<string | number | null>;
    updateVideoPublishStatus: (
      newStatus: VideoPublishStatus
    ) => Promise<number | null>;
  };
}>({
  video: null,
  hasRedo: false,
  hasUndo: false,
  undo: () => Promise.resolve(),
  redo: () => Promise.resolve(),
  api: {
    createScene: () => Promise.resolve(),
    createSceneWithContent: () => Promise.resolve(null),
    deleteScene: () => Promise.resolve(),
    reorderScene: () => Promise.resolve(),
    createElement: () => Promise.resolve(),
    createElements: () => Promise.resolve(),
    updateElement: () => Promise.resolve(),
    updateScene: () => Promise.resolve(),
    deleteElement: () => Promise.resolve(),
    deleteElements: () => Promise.resolve(),
    bringToFront: () => Promise.resolve(),
    bringToBack: () => Promise.resolve(),
    getLastEvent: () => Promise.resolve(null),
    updateVideoPublishStatus: () => Promise.resolve(null),
  },
});

export function StorageProvider(props: {
  videoId: string;
  children: (video: Video) => React.ReactNode;
}) {
  const [video, setVideo] = useState<Video | null>(null);
  const [undoStack, setUndoStack] = useState<VideoStateUpdateDraft[]>([]);
  const [redoStack, setRedoStack] = useState<VideoStateUpdateDraft[]>([]);
  const { online } = useNetwork();
  const history = useHistory();
  const { userprofile } = useAuth();
  const { t } = useTranslation();

  const getLatestVideo = useCallback(async () => {
    const updates = await db.video_state_updates
      .where("video_id")
      .equals(props.videoId)
      .toArray();
    const video = await localAPI.getVideo(props.videoId);

    if (video) {
      return compileVideo(video, updates);
    }

    return null;
  }, [props.videoId]);

  // Get initial state
  useEffect(() => {
    if (!online) {
      localAPI.getVideo(props.videoId).then((video) => {
        if (video) {
          setVideo(video);
        } else {
          getVideo(props.videoId)
            .then((video) => {
              if (video) {
                setVideo(video);
              }
            })
            .catch(() => {
              history.push("/?no_access=true");
            });
        }
      });
    } else {
      getVideo(props.videoId)
        .then((video) => {
          if (video) {
            setVideo(video);

            localAPI.saveVideo(props.videoId, video);
          }
        })
        .catch(() => {
          history.push("/?no_access=true");
        });
    }
  }, [props.videoId, online, history]);

  // Compile state
  useEffect(() => {
    const videoUpdatesObservable = liveQuery(() =>
      db.video_state_updates.where("video_id").equals(props.videoId).toArray()
    );

    const subscription = videoUpdatesObservable.subscribe(async (updates) => {
      const video = await localAPI.getVideo(props.videoId);

      if (video) {
        const newVideo = compileVideo(video, updates);

        setVideo(newVideo);
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [props.videoId]);

  // Sync with remote
  useEffect(() => {
    const videoUpdatesObservable = liveQuery(() =>
      db.video_state_updates
        .where({
          video_id: props.videoId,
        })
        .filter((update) => update.remote_id === null)
        .sortBy("timestamp")
    );

    const subscription = videoUpdatesObservable.subscribe(async (updates) => {
      updates.forEach(async (update) => {
        if (!update.id) return;
        if (update.fake) return;

        const changeEvent = {
          videoId: update.video_id,
          elementId: update.element_id,
          content: update.content,
          action: update.action,
          meta: {
            userId: update.user_id,
          },
          eventId: update.event_id,
        };

        const remoteChange = await commitChange(changeEvent);

        if (remoteChange) {
          await db.video_state_updates.update(update.id, {
            remote_id: remoteChange.data.id,
          });
        }
      });
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [props.videoId]);

  const undo = useCallback(async () => {
    const video = await getLatestVideo();
    const update = last(undoStack);

    if (!update || !video) return;

    const reverseChange = getReverseAction(video, update);

    if (reverseChange) {
      setRedoStack((stack) => [reverseChange, ...stack]);
      setUndoStack((stack) => stack.slice(0, -1));

      await commitLocalChange({
        videoId: update.video_id,
        elementId: update.element_id,
        content: update.content,
        action: update.action,
        meta: {
          userId: update.user_id,
          updateGroupId: update.update_group_id,
        },
      });
    }
  }, [undoStack, getLatestVideo]);

  const redo = useCallback(async () => {
    const video = await getLatestVideo();
    const update = first(redoStack);

    if (!update || !video) return;

    const reverseChange = getReverseAction(video, update);

    if (reverseChange) {
      setUndoStack((stack) => [...stack, reverseChange]);
      setRedoStack((stack) => stack.slice(1));

      await commitLocalChange({
        videoId: update.video_id,
        elementId: update.element_id,
        content: update.content,
        action: update.action,
        meta: {
          userId: update.user_id,
          updateGroupId: update.update_group_id,
        },
      });
    }
  }, [redoStack, getLatestVideo]);

  const createScene = useCallback(
    async (meta?: StorageAPIMeta) => {
      const video = await getLatestVideo();

      if (!video || !userprofile) return;

      const { change } = await localAPI.createScene(
        video.uuid,
        t("scene.title"),
        {
          userId: userprofile.id,
          ...meta,
        }
      );
      const reverseChange = getReverseAction(video, change);

      if (reverseChange) {
        setUndoStack((stack) => [...stack, reverseChange]);
      }

      setRedoStack([]);
    },
    [userprofile, t, getLatestVideo]
  );

  const createSceneWithContent = useCallback(
    async (
      scene: Pick<VideoScene, "name" | "order" | "start_time">,
      elements: VideoElement[],
      meta?: StorageAPIMeta
    ) => {
      const video = await getLatestVideo();

      if (!video || !userprofile) return null;

      const { change, newScene } = await localAPI.createSceneWithContent(
        video.uuid,
        scene,
        elements,
        {
          userId: userprofile.id,
          ...meta,
        }
      );
      const reverseChange = getReverseAction(video, change);

      if (reverseChange) {
        setUndoStack((stack) => [...stack, reverseChange]);
      }

      setRedoStack([]);

      return newScene;
    },
    [userprofile, getLatestVideo]
  );

  const createElement = useCallback(
    async (element: VideoElement, meta?: StorageAPIMeta) => {
      const video = await getLatestVideo();

      if (!video || !userprofile) return;

      const { change } = await localAPI.createElement(video.uuid, element, {
        userId: userprofile.id,
        ...meta,
      });

      if (!meta?.fake) {
        const reverseChange = getReverseAction(video, change);

        if (reverseChange) {
          setUndoStack((stack) => [...stack, reverseChange]);
        }

        setRedoStack([]);
      }
    },
    [userprofile, getLatestVideo]
  );

  const createElements = useCallback(
    async (elements: VideoElement[], meta?: StorageAPIMeta) => {
      const video = await getLatestVideo();

      if (!video || !userprofile) return;

      const { change } = await localAPI.createElements(video.uuid, elements, {
        userId: userprofile.id,
        ...meta,
      });
      const reverseChange = getReverseAction(video, change);

      if (reverseChange) {
        setUndoStack((stack) => [...stack, reverseChange]);
      }

      setRedoStack([]);
    },
    [userprofile, getLatestVideo]
  );

  const updateElement = useCallback(
    async (
      elementId: string,
      data: Partial<VideoElement>,
      meta?: StorageAPIMeta
    ) => {
      const video = await getLatestVideo();

      if (!video || !userprofile) return;

      const element = video.schema.schema.elements.find(
        (element) => element.id === elementId
      );

      const newData = {
        ...element,
        ...data,
      } as VideoElement;

      const { change } = await localAPI.updateElement(
        video.uuid,
        elementId,
        newData,
        {
          userId: userprofile.id,
          ...meta,
        }
      );

      if (!meta?.fake) {
        const reverseChange = getReverseAction(video, change);

        if (reverseChange) {
          setUndoStack((stack) => [...stack, reverseChange]);
        }

        setRedoStack([]);
      }
    },
    [userprofile, getLatestVideo]
  );

  const updateScene = useCallback(
    async (
      elementId: string,
      data: Partial<VideoScene>,
      meta?: StorageAPIMeta
    ) => {
      const video = await getLatestVideo();

      if (!video || !userprofile) return;

      const scene = video.schema.schema.scenes.find(
        (element) => element.id === elementId
      );

      const newData = {
        ...scene,
        ...data,
      } as VideoScene;

      const { change } = await localAPI.updateScene(
        video.uuid,
        elementId,
        newData,
        {
          userId: userprofile.id,
          ...meta,
        }
      );
      const reverseChange = getReverseAction(video, change);

      if (reverseChange) {
        setUndoStack((stack) => [...stack, reverseChange]);
      }

      setRedoStack([]);
    },
    [userprofile, getLatestVideo]
  );

  const deleteElement = useCallback(
    async (elementId: string, meta?: StorageAPIMeta) => {
      const video = await getLatestVideo();

      if (!video || !userprofile) return;

      const { change } = await localAPI.deleteElement(video.uuid, elementId, {
        userId: userprofile.id,
        ...meta,
      });
      if (!meta?.fake) {
        const reverseChange = getReverseAction(video, change);

        if (reverseChange) {
          setUndoStack((stack) => [...stack, reverseChange]);
        }

        setRedoStack([]);
      }
    },
    [userprofile, getLatestVideo]
  );

  const deleteElements = useCallback(
    async (elements: VideoElement[], meta?: StorageAPIMeta) => {
      const video = await getLatestVideo();

      if (!video || !userprofile) return;

      const { change } = await localAPI.deleteElements(video.uuid, elements, {
        userId: userprofile.id,
        ...meta,
      });
      const reverseChange = getReverseAction(video, change);

      if (reverseChange) {
        setUndoStack((stack) => [...stack, reverseChange]);
      }

      setRedoStack([]);
    },
    [userprofile, getLatestVideo]
  );

  const bringToFront = useCallback(
    async (elementId: string, meta?: StorageAPIMeta) => {
      const video = await getLatestVideo();

      if (!video || !userprofile) return;

      const element = video.schema.schema.elements.find(
        (element) => element.id === elementId
      );

      if (!element) return;

      const sameSceneElements = video.schema.schema.elements.filter(
        (el) => el.scene_id === element.scene_id
      );

      const newOrder =
        Math.max(...sameSceneElements.map((e, i) => e.order || i)) + 1;

      const { change } = await localAPI.updateElement(
        video.uuid,
        elementId,
        {
          ...element,
          order: newOrder,
        },
        {
          userId: userprofile.id,
          ...meta,
        }
      );

      const reverseChange = getReverseAction(video, change);

      if (reverseChange) {
        setUndoStack((stack) => [...stack, reverseChange]);
      }

      setRedoStack([]);
    },
    [userprofile, getLatestVideo]
  );
  const bringToBack = useCallback(
    async (elementId: string, meta?: StorageAPIMeta) => {
      const video = await getLatestVideo();

      if (!video || !userprofile) return;

      const element = video.schema.schema.elements.find(
        (element) => element.id === elementId
      );

      if (!element) return;

      const sameSceneElements = video.schema.schema.elements.filter(
        (el) => el.scene_id === element.scene_id
      );

      const newOrder =
        Math.min(...sameSceneElements.map((e, i) => e.order || i)) - 1;

      const { change } = await localAPI.updateElement(
        video.uuid,
        elementId,
        {
          ...element,
          order: newOrder,
        },
        {
          userId: userprofile.id,
          ...meta,
        }
      );

      const reverseChange = getReverseAction(video, change);

      if (reverseChange) {
        setUndoStack((stack) => [...stack, reverseChange]);
      }

      setRedoStack([]);
    },
    [userprofile, getLatestVideo]
  );

  const deleteScene = useCallback(
    async (elementId: string, meta?: StorageAPIMeta) => {
      const video = await getLatestVideo();

      if (!video || !userprofile) return;

      const { change } = await localAPI.deleteScene(video.uuid, elementId, {
        userId: userprofile.id,
        ...meta,
      });
      const reverseChange = getReverseAction(video, change);

      if (reverseChange) {
        setUndoStack((stack) => [...stack, reverseChange]);
      }

      setRedoStack([]);
    },
    [userprofile, getLatestVideo]
  );

  const reorderScene = useCallback(
    async (
      from: ReorderingScene,
      to: ReorderingScene,
      meta?: StorageAPIMeta
    ) => {
      const video = await getLatestVideo();

      if (!video || !userprofile) return;

      const { change } = await localAPI.reorderScene(
        video.uuid,
        moveItems(video, from, to),
        {
          userId: userprofile.id,
          ...meta,
        },
        from.id
      );

      const reverseChange = getReverseAction(video, change);

      if (reverseChange) {
        setUndoStack((stack) => [...stack, reverseChange]);
      }

      setRedoStack([]);
    },
    [userprofile, getLatestVideo]
  );

  const getLastEvent = useCallback(async () => {
    const video = await getLatestVideo();

    if (!video) return null;

    const videoUpdate = await localAPI.getLastEvent(video.uuid);

    if (videoUpdate?.remote_id) return videoUpdate?.remote_id;

    if (video.schema.last_change?.id) return video.schema.last_change.id;

    return null;
  }, [getLatestVideo]);

  const updateVideoPublishStatus = useCallback(
    async (newStatus: VideoPublishStatus) => {
      const video = await getLatestVideo();

      if (!video) return null;

      const videoUpdate = await localAPI.updateVideoPublishStatus(
        video.uuid,
        newStatus
      );

      setVideo((video) => {
        if (video)
          return {
            ...video,
            publish_status: newStatus,
          };

        return video;
      });

      return videoUpdate;
    },
    [getLatestVideo]
  );

  const api = useMemo(
    () => ({
      createScene,
      createSceneWithContent,
      updateScene,
      deleteScene,
      reorderScene,
      createElement,
      createElements,
      updateElement,
      deleteElement,
      deleteElements,
      bringToFront,
      bringToBack,
      getLastEvent,
      updateVideoPublishStatus,
    }),
    [
      createScene,
      createSceneWithContent,
      updateScene,
      deleteScene,
      reorderScene,
      createElement,
      createElements,
      updateElement,
      deleteElement,
      deleteElements,
      bringToFront,
      bringToBack,
      getLastEvent,
      updateVideoPublishStatus,
    ]
  );

  return (
    <StorageContext.Provider
      value={{
        video,
        undo,
        redo,
        hasUndo: undoStack.length > 0,
        hasRedo: redoStack.length > 0,
        api,
      }}
    >
      {video && props.children(video)}
    </StorageContext.Provider>
  );
}

export function useStorage() {
  const context = React.useContext(StorageContext);

  if (context === undefined) {
    throw new Error("useStorage must be used within a StorageProvider");
  }

  return context;
}
