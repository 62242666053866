import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "../components/Modal";
import { Button } from "../components/Button";

import { ReactComponent as ErrorIcon } from "../VideoEditor/assets/icons/Error.svg";

export function ModalAiError(props: { isOpen: boolean; onClose: () => void }) {
  const { t } = useTranslation();

  return (
    <Modal
      modalIsOpen={props.isOpen}
      onClose={props.onClose}
      contentLabel={t("ai.modal.error.title")}
      contentStyles={{ maxWidth: "416px", padding: "20px 28px 21px 29px" }}
    >
      <ModalHeader
        css={css`
          font-size: 20px;
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <ErrorIcon width={22} height={22} />
          <span
            css={css`
              margin-left: 17px;
            `}
          >
            {t("ai.modal.error.title")}
          </span>
        </div>
      </ModalHeader>
      <ModalBody
        css={css`
          padding: 3px 0;
          font-size: 15px;
          font-weight: 300;
          margin-left: 39px;
        `}
      >
        {t("ai.modal.error.description")}
      </ModalBody>
      <ModalFooter
        css={css`
          justify-content: center;
        `}
      >
        <Button
          onClick={props.onClose}
          css={css`
            background-color: transparent;
            color: #8e8e8e;
            border: 1px solid #8e8e8e;
            padding: 4px 30px;
          `}
          hoverStyles={"none"}
        >
          {t("actions.close")}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
