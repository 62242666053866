import React, { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { useDebounce } from "use-debounce";

import { clipboardCopy } from "../../helpers/clipboardCopy";
import { customToast } from "../../components/customToast";

import { InputType } from "../../components/InputElement";
import { QueryInput } from "../../components/QueryInput";
import { UserMetaPreview } from "../../components/UserMetaPreview";
import { ModalDelete } from "../../modals/ModalDelete";
import { Button } from "../../components/Button";
import { DisableUI } from "../../VideoEditor/components/DisableUI";
import { LifetimeTooltip } from "../../components/Tooltip";
import { ActionButton } from "../../components/ActionButton";

import { UserMeta } from "../../types/UserProfile";
import {
  Collaborator,
  CollaboratorRole,
} from "../../VideoEditor/types/Collaborator";
import { VideoPublishStatus } from "../../VideoEditor/types/Video";

import { theme } from "../../themes/variables";

import { useNewFetch } from "../../useAPI";
import { useStorage } from "../../VideoEditor/contexts/StorageContext";
import { getUsersBySearch } from "../../VideoEditor/actions/getUsersBySearch";
import { getCollaborators } from "../../VideoEditor/actions/getCollaborators";
import { deleteCollaborator } from "../../VideoEditor/actions/deleteCollaborator";
import { sendInvitesToViewers } from "../../VideoEditor/actions/sendInvitesToViewers";
import { publishVideo } from "../../VideoEditor/actions/publishVideo";
import { canEdit } from "../../VideoEditor/helpers/collaborator";
import { serverErrorHandler } from "../../helpers/serverErrorHandler";
import { pageLoading } from "../../helpers/pageLoading";

import { ReactComponent as SearchIcon } from "../../assets/icons/Search.svg";
import { ReactComponent as DeleteIcon } from "../../VideoEditor/assets/icons/Delete.svg";
import { ReactComponent as CheckboxIcon } from "../../VideoEditor/assets/icons/Checkbox.svg";
import { ReactComponent as CopyIcon } from "../../VideoEditor/assets/icons/Copy.svg";
import { sendEvent } from "../../helpers/tracking";
import { calculateVideoDuration } from "../../VideoEditor/helpers/video";
import { useAuth } from "../../contexts/UserContext";

export function Viewers(props: { firstTimePublished: boolean }) {
  const { t } = useTranslation();
  const { userprofile } = useAuth();
  const {
    video,
    api: { getLastEvent, updateVideoPublishStatus },
  } = useStorage();

  const [query, setQuery] = useState<string>("");
  const [tooltip, setTooltip] = useState(false);
  const [viewerToRemove, setViewerToRemove] = useState<UserMeta | null>(null);
  const [potentialViewers, setPotentialViewers] = useState<UserMeta[]>([]);
  const [debouncedQuery] = useDebounce(query, 500);

  const {
    data: viewers,
    error: viewersError,
    refetch: refetchViewers,
  } = useNewFetch(getCollaborators, {
    videoId: video!.uuid,
    role: "ALL",
  });

  const addPotentialViewer = useCallback(
    (user: UserMeta) => {
      setPotentialViewers((users) => {
        let usersIds = users.map((u) => u.id);
        if (viewers) {
          usersIds = usersIds.concat(viewers.map((v) => v.user.id));
        }

        if (usersIds.includes(user.id)) {
          return users;
        }

        return [...users, user];
      });
    },
    [viewers]
  );

  const removePotentialViewer = useCallback((user: UserMeta) => {
    setPotentialViewers((users) => users.filter((u) => u.id !== user.id));
  }, []);

  const removeViewer = useCallback((viewer: Collaborator) => {
    if (viewer.role !== CollaboratorRole.OWNER) setViewerToRemove(viewer.user);
  }, []);

  const onClipboardCopy = useCallback(async () => {
    const protocol = window.location.protocol;
    const host = window.location.host;

    await clipboardCopy(`${protocol}//${host}/video/${video?.uuid}`);
    setTooltip(true);
  }, [video?.uuid]);

  const onSendInvites = useCallback(
    async (callback: Promise<void>) => {
      if (video?.uuid) {
        try {
          await sendInvitesToViewers({
            videoId: video.uuid,
            userIds: potentialViewers.map((v) => v.id),
          });
          await callback;
          await refetchViewers();
          setPotentialViewers([]);
        } catch (error) {
          customToast.error(
            t("status.error", {
              error: serverErrorHandler(error),
            })
          );
        }
      }
    },
    [video?.uuid, potentialViewers, t, refetchViewers]
  );

  const onPublish = useCallback(
    async (callback: Promise<void>) => {
      if (video?.uuid) {
        try {
          const lastEventId = await getLastEvent();
          if (!lastEventId) {
            customToast.error(
              t("status.error", {
                error: serverErrorHandler(t("publish.share.error")),
              })
            );

            return;
          }
          await publishVideo(video.uuid, lastEventId);
          await callback;
          await updateVideoPublishStatus(VideoPublishStatus.PUBLISHED);

          sendEvent("Publish Themi", {
            themiId: video.uuid,
            publisher: userprofile?.id,
            numberOfSequences: video.schema.schema.scenes.length,
            duration: calculateVideoDuration(video.schema.schema) / 1000,
            features: video.schema.schema.elements.reduce(
              (features, element) => {
                if (!features.includes(element.type)) {
                  features.push(element.type);
                }
                return features;
              },
              [] as string[]
            ),
          });
        } catch (error) {
          customToast.error(
            t("status.error", {
              error: serverErrorHandler(error),
            })
          );
        }
      }
    },
    [getLastEvent, t, updateVideoPublishStatus, userprofile, video]
  );

  if (!viewers || viewersError) {
    return pageLoading(viewersError);
  }

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
      `}
    >
      <p
        css={css`
          font-size: 20px;
          line-height: 120%;
          font-weight: 500;
        `}
      >
        {t("publish.viewers.published")}
      </p>

      <p
        css={css`
          font-weight: 400;
          font-size: 16px;
          line-height: 151%;
          padding-top: 9px;
          color: #8e8e8e;
        `}
      >
        {!props.firstTimePublished
          ? t("publish.viewers.description")
          : t("publish.viewers.share")}
      </p>

      {video?.publish_status === VideoPublishStatus.PUBLISHED &&
        !props.firstTimePublished && (
          <div
            css={css`
              margin-top: 25px;
            `}
          >
            <ActionButton onClick={onPublish}>
              {t("publish.viewers.update")}
            </ActionButton>
          </div>
        )}

      <div
        css={css`
          margin-top: 47px;
          max-width: 315px;
        `}
      >
        <QueryInput
          getFunction={getUsersBySearch}
          onChange={addPotentialViewer}
          options={{
            limit: 3,
            offset: 0,
            internalArgs: {
              query: debouncedQuery.trim(),
            },
          }}
          fetchOptions={{
            enabled: debouncedQuery.trim().length >= 3,
          }}
          inputProps={{
            value: query,
            onChange: (e) => setQuery(e.target.value),
            label: t("publish.viewers.viewers"),
            placeholder: t("publish.viewers.search_description"),
            type: InputType.TEXT,
            prepend: <SearchIcon color={"#8E8E8E"} />,
          }}
          css={css`
            height: 37px;
            .focused {
              svg {
                color: ${theme.colors.primary};
              }
            }
          `}
        >
          {(user) => {
            return (
              <UserMetaPreview
                user={user}
                dense
                css={css`
                  border: 0.5px solid #8e8e8e;
                  border-radius: 7px;
                  padding: 4px 7px;
                  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
                  margin-top: 2px;
                  cursor: pointer;
                `}
              />
            );
          }}
        </QueryInput>
      </div>

      <div
        css={css`
          max-height: 250px;
          min-height: 200px;
          overflow-y: scroll;
          margin-top: 10px;
          /* width */
          ::-webkit-scrollbar {
            width: 9px;
            border-radius: 5px;
          }

          /* Track */
          ::-webkit-scrollbar-track {
            background: transparent;
            border-radius: 5px;
          }

          /* Handle */
          ::-webkit-scrollbar-thumb {
            background: #cdcdcd;
            border-radius: 5px;
          }

          /* Handle on hover */
          ::-webkit-scrollbar-thumb:hover {
            background: #555;
          }
        `}
      >
        {potentialViewers.map((viewer) => {
          return (
            <div
              key={viewer.id}
              css={css`
                display: flex;
                align-items: center;
                margin-top: 12px;
                cursor: pointer;
              `}
            >
              <div
                css={css`
                  margin-right: 18px;
                `}
                onClick={() => removePotentialViewer(viewer)}
              >
                <CheckboxIcon />
              </div>

              <UserMetaPreview user={viewer} />
            </div>
          );
        })}

        {viewers.map((viewer) => {
          return (
            <div
              key={viewer.user.id}
              css={css`
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 12px;
                ${viewer.role !== CollaboratorRole.OWNER &&
                css`
                  cursor: pointer;
                `}
              `}
            >
              <UserMetaPreview user={viewer.user} />

              {viewer.role !== CollaboratorRole.OWNER && (
                <div onClick={() => removeViewer(viewer)}>
                  <DeleteIcon />
                </div>
              )}
            </div>
          );
        })}
      </div>

      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-top: 17px;
          border-top: 1px solid #f2f2f2;
        `}
      >
        <LifetimeTooltip
          isActive={tooltip}
          text={t("publish.viewers.link_copied")}
          position="center"
          onDeactivate={() => setTooltip(false)}
          lifeTime={3000}
        >
          <Button
            icon={<CopyIcon color={"#8E8E8E"} width={21} height={21} />}
            hoverStyles="none"
            background={theme.colors.white}
            border={`1px solid #8E8E8E`}
            color="#8E8E8E"
            onClick={onClipboardCopy}
          >
            {t("publish.viewers.copy")}
          </Button>
        </LifetimeTooltip>

        <LifetimeTooltip
          activateOnClick
          text={t("tooltip.access")}
          lifeTime={5000}
          position="center"
          show={!canEdit(video?.current_user_role)}
          verticalPosition="top"
        >
          <DisableUI show={!canEdit(video?.current_user_role)}>
            <ActionButton
              onClick={onSendInvites}
              disabled={potentialViewers.length === 0}
            >
              {t("publish.viewers.send_invites")}
            </ActionButton>
          </DisableUI>
        </LifetimeTooltip>
      </div>

      <ModalDelete
        isOpen={!!viewerToRemove?.id}
        onClose={() => setViewerToRemove(null)}
        onDelete={async () => {
          try {
            await deleteCollaborator({
              videoId: video?.uuid!,
              userId: viewerToRemove?.id!,
            });
            await refetchViewers();
          } catch (error) {
            customToast.error(
              t("status.error", {
                error: serverErrorHandler(error),
              })
            );
          } finally {
            setViewerToRemove(null);
          }
        }}
        title={t("publish.viewers.delete")}
        description={t("publish.viewers.delete_confirm")}
      />
    </div>
  );
}
