import { css } from "styled-components/macro";
export function Dots(props: {
  onDotClick: (index: number) => void;
  imageSlider: { image: string; title: string; description: string }[];
  className?: string;
  currentIndex: number;
}) {
  return (
    <div className={props.className}>
      {props.imageSlider &&
        props.imageSlider.map((_, index: number) => (
          <div
            key={index}
            onClick={() => props.onDotClick(index)}
            css={css`
              width: 8px;
              height: 8px;
              border-radius: 50%;
              background-color: #fae7d9;
              margin-left: 7px;
              background-color: ${props.currentIndex >= index
                ? "#E95B2E"
                : "#fae7d9"};
            `}
          ></div>
        ))}
    </div>
  );
}
