import {
  VideoElementUserTagging,
  VideoElementState,
  VideoElementUserTag,
} from "../../types/Video";
import { css } from "styled-components/macro";
import { useRef, useState } from "react";
import { useEditor } from "../../contexts/EditorContext";
import { useStorage } from "../../contexts/StorageContext";
import { cloneDeep } from "lodash";
import {
  calculateProportionalSize,
  getReverseAdjustedState,
} from "../../helpers/renderer";
import { UserTagForm } from "../../forms/UserTagForm";

import { v4 as uuid } from "uuid";
import { ElementEditorPortal } from "../../portals/ElementEditorPortal";
import { ElementUserTagPortal } from "../../portals/ElementUserTagPortal";
import { Avatar } from "../../components/Avatar";
import { UserTagCardArea } from "../../../components/UserTagCardArea";

export function ElementUserTaggingRenderer(props: {
  element: VideoElementUserTagging;
  state: VideoElementState;
}) {
  const [hoveredId, setHoveredId] = useState<string | null>(null);
  const targetRef = useRef<HTMLDivElement | null | any>(null);

  //const wrapperRef = useRef<HTMLDivElement | null>(null);
  const { api } = useStorage();
  const {
    setActiveElementId,
    canvasSize,
    activeElementId,
    editingElement,
    setEditingElement,
  } = useEditor();

  const tags = props.element.tags || [];
  const editValues = tags.find(
    (t) => editingElement && t.id === editingElement.meta?.tagId
  );

  const tagSize = calculateProportionalSize(30, canvasSize.width);
  const margin = calculateProportionalSize(5, canvasSize.width);
  const borderSize = calculateProportionalSize(2, canvasSize.width);
  const itemSize = tagSize - borderSize * 2;

  function calculateElementWidth(tags: VideoElementUserTag[]) {
    return (tags.length + 1) * tagSize + tags.length * margin + 10;
  }

  return (
    <div
      css={css`
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        flex-flow: row wrap;
      `}
    >
      {tags.map((tag, index) => {
        const PX_10 = calculateProportionalSize(10, canvasSize.width);

        return (
          <div
            css={css`
              position: relative;
              margin-right: ${margin}px;
              cursor: pointer;

              ${!activeElementId &&
              css`
                &:hover {
                  z-index: 300;

                  .remove-tag {
                    opacity: 1;
                    visibility: visible;
                  }
                  .tag-card {
                    opacity: 1;
                    visibility: visible;
                    z-index: ${hoveredId ? 500 : 0};
                  }
                }
              `}
            `}
            style={{
              transform: `rotate(${0 - props.state.rotation}deg)`,
            }}
            key={index}
            onClick={() => {
              setEditingElement({
                id: props.element.id,
                meta: {
                  tagId: tag.id,
                },
              });
            }}
            onMouseOver={() => setHoveredId(tag.id)}
            onMouseEnter={() => setHoveredId(tag.id)}
            onMouseLeave={() => setHoveredId(null)}
          >
            <Avatar
              size={itemSize}
              name={tag.name}
              url={tag.avatar?.image}
              border
            />
            <button
              className="remove-tag"
              css={css`
                position: absolute;
                width: ${PX_10}px;
                height: ${PX_10}px;
                right: -${calculateProportionalSize(8, canvasSize.width)}px;
                top: 0;
                border-radius: 50%;
                background: #e95b2e;
                border: none;
                cursor: pointer;
                font-size: ${calculateProportionalSize(20, canvasSize.width)}px;
                transform: rotate(45deg);
                color: #fff;
                font-size: ${PX_10}px;
                line-height: ${PX_10}px;
                opacity: 0;
                visibility: hidden;
              `}
              onClick={() => {
                const element = cloneDeep(props.element);

                element.tags = element.tags.filter(
                  (tag, tagIndex) => tagIndex !== index
                );

                element.states = [
                  getReverseAdjustedState(
                    {
                      ...props.state,
                      width: calculateElementWidth(element.tags),
                    },
                    canvasSize.width
                  ),
                ];

                api.updateElement(props.element.id, element);
              }}
            >
              +
            </button>

            {hoveredId === tag.id && (
              <div
                css={css`
                  position: absolute;
                  width: 190px;
                  bottom: 100%;
                  left: 50%;
                  transform: translate(-50%, -10px);
                  opacity: 0;
                  visibility: hidden;
                  z-index: 1000;
                `}
                className="tag-card"
                ref={targetRef}
              >
                <ElementUserTagPortal>
                  <UserTagCardArea tag={tag} text={targetRef} />
                </ElementUserTagPortal>
              </div>
            )}
          </div>
        );
      })}
      <button
        css={css`
          display: inline-block;
          width: ${itemSize}px;
          height: ${itemSize}px;
          border-radius: 50%;
          padding: 0;
          border: none;
          background: #fff;
          box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
          cursor: pointer;
          box-sizing: border-box;
          text-align: center;
          font-size: ${calculateProportionalSize(20, canvasSize.width)}px;
          color: #cdcdcd;
          font-family: Arial;
        `}
        onClick={(e) => {
          e.stopPropagation();
          setActiveElementId(null);
          setEditingElement({
            id: props.element.id,
          });
        }}
      >
        +
      </button>
      {editingElement && editingElement.id === props.element.id && (
        <ElementEditorPortal
          onClose={() => {
            setEditingElement(null);
          }}
        >
          <UserTagForm
            initialValues={editValues ? editValues : null}
            onSubmit={async (values) => {
              let element = cloneDeep(props.element);

              element.tags = element.tags || [];

              if (editValues) {
                element.tags = element.tags.map((tag) => {
                  if (tag.id === editValues.id) {
                    return {
                      ...tag,
                      ...values,
                    };
                  }

                  return tag;
                });
              } else {
                element.tags.push({
                  ...values,
                  id: uuid(),
                });
              }

              element.states = [
                getReverseAdjustedState(
                  {
                    ...props.state,
                    width: calculateElementWidth(element.tags),
                  },
                  canvasSize.width
                ),
              ];

              setActiveElementId(element.id);
              setEditingElement(null);

              await api.updateElement(element.id, element);
            }}
          />
        </ElementEditorPortal>
      )}
    </div>
  );
}
