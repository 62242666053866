import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { css } from "styled-components/macro";
import { uploadFile } from "../../actions/file";
import { File as FileType } from "../../types/File";
import { Loader } from "./Loader";

export type DroppedImage = {
  preview: string;
  file: File;
};

export function DropFile(props: {
  initialMessage?: React.ReactNode | React.ReactNode[];
  loadingMessage?: React.ReactNode | React.ReactNode[];
  className?: string;
  onUpload: (file: FileType) => void;
}) {
  const [uploading, setUploading] = useState(false);

  const { initialMessage, className } = props;

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];

      if (file) {
        setUploading(true);

        const response = await uploadFile({
          file,
          title: file.name,
        });

        props.onUpload(response);

        setUploading(false);
      }
    },
    [props]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div
      css={css`
        border-radius: 9px;
        background-color: #f2f2f2;
        padding: 9px;
      `}
    >
      <div
        {...getRootProps()}
        css={css`
          height: 106px;
          width: 100%;
          border: 1px dashed ${isDragActive ? "#FF812F" : "#000000"};
          background: transparent;
          border-radius: 6px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          ${isDragActive ? `background: #f5cdb3;` : ""}
        `}
        className={className}
      >
        <input {...getInputProps()} accept=".jpg,.png,.svg,.jpeg" />
        {uploading ? (
          <div>
            <Loader />
          </div>
        ) : (
          <div>{initialMessage}</div>
        )}
      </div>
    </div>
  );
}
