import React, { useEffect, useState } from "react";
import set from "lodash/set";
import { cloneDeep } from "lodash";
import {
  VideoElement,
  VideoElementDrawConfig,
  VideoElementShapeConfig,
} from "../types/Video";
import { useStorage } from "./StorageContext";

export type Nav =
  | "record"
  | "media"
  | "shape"
  | "text"
  | "draw"
  | "resources"
  | "upload"
  | "gif"
  | "ai"
  | null;

export enum NavType {
  Dropdown = "dropdown",
  Select = "select",
}

export type NavItemBlank = {
  type: NavType;
  key: Nav;
};

export type SettingsMode = "canvas" | "transitions";
export type EditorConfig = {
  draw: VideoElementDrawConfig;
  shape: VideoElementShapeConfig;
};

export type CanvasSize = {
  width: number;
  height: number;
};

export type EditingElement = {
  id: string;
  meta?: { [key: string]: any };
};

export const navItems: NavItemBlank[] = [
  {
    key: "ai",
    type: NavType.Select,
  },
  {
    key: "record",
    type: NavType.Select,
  },
  {
    key: "media",
    type: NavType.Select,
  },
  {
    key: "text",
    type: NavType.Dropdown,
  },
  {
    key: "shape",
    type: NavType.Select,
  },
  {
    key: "draw",
    type: NavType.Select,
  },
  {
    key: "resources",
    type: NavType.Select,
  },
  {
    key: "upload",
    type: NavType.Select,
  },
];

type Cord = {
  x: number;
  y: number;
};

export type DraggingPosition = {
  elementId: string;
  boundaries: Cord[];
};

const defaultConfig: EditorConfig = {
  draw: {
    mode: "draw",
    lineWidth: 10,
    color: "#000",
    opacity: 100,
  },
  shape: {
    fillColor: "#fff",
    fillOpacity: 100,
    strokeColor: "#000",
    strokeLineCap: "round",
    strokeOpacity: 100,
    strokeSize: 1,
  },
};

const EditorContext = React.createContext<{
  activeElementId: string | null;
  setActiveElementId: (elementId: string | null) => void;
  activeElementsId: string[];
  setActiveElementsId: (elementsId: string[]) => void;
  editingElement: EditingElement | null;
  setEditingElement: (element: EditingElement | null) => void;
  activeSceneId: string | null;
  setActiveSceneId: (sceneId: string | null) => void;
  activeNavType: NavType | null;
  activeNav: Nav;
  setActiveNav: (nav: Nav) => void;
  drawEnabled: boolean;
  setDrawEnabled: (enabled: boolean) => void;
  drawConfig: VideoElementDrawConfig;
  setDrawConfig: (key: string, value: any) => void;
  shapeConfig: VideoElementShapeConfig;
  setShapeConfig: (key: string, value: any) => void;
  settingsMode: SettingsMode;
  setSettingsMode: (value: SettingsMode) => void;
  settingsOpen: boolean;
  currentElement: VideoElement | null;
  toggleSettings: () => void;
  openSettings: () => void;
  closeSettings: () => void;
  showProductTour: boolean;
  hideProductTour: () => void;
  wrapperRef: React.RefObject<HTMLDivElement>;
  canvasSize: CanvasSize;
  setCanvasSize: (size: CanvasSize) => void;
  interactivityEditing: boolean;
  setInteractivityEditing: (value: boolean) => void;
  draggingPosition: DraggingPosition | null;
  setDraggingPosition: (position: DraggingPosition | null) => void;
  recordModalVisible: boolean;
  setRecordModalVisible: (value: boolean) => void;
}>({
  activeElementId: null,
  setActiveElementId: () => {},
  activeElementsId: [],
  setActiveElementsId: () => {},
  editingElement: null,
  setEditingElement: () => {},
  activeSceneId: null,
  setActiveSceneId: () => {},
  drawEnabled: false,
  setDrawEnabled: () => {},
  activeNavType: null,
  activeNav: null,
  setActiveNav: () => {},
  drawConfig: defaultConfig.draw,
  setDrawConfig: () => {},
  shapeConfig: defaultConfig.shape,
  setShapeConfig: () => {},
  settingsMode: "canvas",
  setSettingsMode: () => {},
  settingsOpen: false,
  toggleSettings: () => {},
  openSettings: () => {},
  closeSettings: () => {},
  showProductTour: true,
  hideProductTour: () => {},
  currentElement: null,
  wrapperRef: React.createRef(),
  canvasSize: {
    width: 0,
    height: 0,
  },
  setCanvasSize: () => {},
  interactivityEditing: false,
  setInteractivityEditing: () => {},
  draggingPosition: null,
  setDraggingPosition: () => {},
  recordModalVisible: false,
  setRecordModalVisible: () => {},
});

export function EditorProvider(props: { children: React.ReactNode }) {
  const [activeNavType, setActiveNavType] = React.useState<NavType | null>(
    null
  );
  const [activeNav, setActiveNav] = React.useState<Nav>(null);
  const [showProductTour, setShowProductTour] = React.useState(true);
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const { video, api } = useStorage();
  const [activeElementId, setActiveElementId] = React.useState<string | null>(
    null
  );
  const [activeElementsId, setActiveElementsId] = React.useState<string[]>([]);
  const [editingElement, setEditingElement] =
    React.useState<EditingElement | null>(null);
  const [configValues, setConfig] = React.useState<EditorConfig>(defaultConfig);
  const [settingsOpen, setSettingsOpen] = React.useState(false);
  const [activeSceneId, setActiveSceneId] = React.useState<string | null>(null);
  const [drawEnabled, setDrawEnabled] = React.useState(false);
  const [interactivityEditing, setInteractivityEditing] = React.useState(false);
  const [draggingPosition, setDraggingPosition] =
    React.useState<DraggingPosition | null>({
      elementId: "",
      boundaries: [],
    });
  const [canvasSize, setCanvasSize] = React.useState({
    width: 0,
    height: 0,
  });
  const [settingsMode, setSettingsMode] =
    React.useState<SettingsMode>("canvas");
  const [recordModalVisible, setRecordModalVisible] = useState(false);

  const currentElement = React.useMemo(() => {
    if (!activeElementId || !video) return null;

    const element = video.schema.schema.elements.find(
      (element) => element.id === activeElementId
    );

    return element || null;
  }, [activeElementId, video]);

  useEffect(() => {
    if (activeNav) {
      const item = navItems.find((item) => item.key === activeNav);
      if (item) setActiveNavType(item?.type);
      else setActiveNavType(null);
    }
  }, [activeNav]);

  function getConfigValue(
    types: VideoElement["type"][],
    lookup: keyof EditorConfig
  ) {
    return currentElement && types.includes(currentElement.type)
      ? currentElement.config
      : configValues[lookup];
  }

  function getConfigSetterFunction(type: string) {
    return (key: string, value: any) => {
      if (currentElement) {
        const newConfig = currentElement.config
          ? cloneDeep(currentElement.config)
          : {};

        api.updateElement(currentElement.id, {
          config: set(newConfig, key, value) as any,
        });
      } else {
        setConfig((config) => {
          const newConfig = cloneDeep(config);

          return set(newConfig, `${type}.${key}`, value);
        });
      }
    };
  }

  return (
    <EditorContext.Provider
      value={{
        editingElement,
        setEditingElement,
        activeElementId,
        setActiveElementId,
        activeElementsId,
        wrapperRef,
        setActiveElementsId,
        activeNavType,
        activeNav,
        setActiveNav,
        activeSceneId,
        setActiveSceneId,
        drawEnabled,
        setDrawEnabled,
        currentElement,
        showProductTour,
        hideProductTour: () => setShowProductTour(false),
        drawConfig: getConfigValue(
          ["drawing"],
          "draw"
        ) as VideoElementDrawConfig,
        setDrawConfig: getConfigSetterFunction("draw"),
        shapeConfig: getConfigValue(
          [
            "rectangle",
            "ellipse",
            "star",
            "polygon",
            "arrow-1",
            "arrow-2",
            "arrow-3",
          ],
          "shape"
        ) as VideoElementShapeConfig,
        settingsMode,
        setSettingsMode,
        setShapeConfig: getConfigSetterFunction("shape"),

        settingsOpen,
        toggleSettings: () => setSettingsOpen((open) => !open),
        openSettings: () => setSettingsOpen(true),
        closeSettings: () => setSettingsOpen(false),

        canvasSize,
        setCanvasSize,
        interactivityEditing,
        setInteractivityEditing,
        draggingPosition,
        setDraggingPosition,
        recordModalVisible,
        setRecordModalVisible,
      }}
    >
      {props.children}
    </EditorContext.Provider>
  );
}

export function useEditor() {
  const context = React.useContext(EditorContext);
  if (context === undefined) {
    throw new Error("useEditor must be used within a EditorProvider");
  }
  return context;
}
