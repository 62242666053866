import React, { useEffect, useRef } from "react";
import { VideoScene } from "../types/Video";
import hexToRgba from "hex-to-rgba";
import { isEqual } from "lodash";
import { getSvgPathFromStroke } from "../helpers/draw";
import getStroke from "perfect-freehand";
import { allowedReactions } from "../renderers/elements/ElementReactionRenderer";
import { normalizeOrder } from "../contexts/PlaybackContext";
import { convertToRichText } from "../helpers/video";
import { calculateReverseRelativeSize } from "../helpers/renderer";

export const canvasWidth = 707;
export const canvasHeight = (canvasWidth * 9) / 16;

function getProportionalSize(value: number, currentWidth: number) {
  const ratio = currentWidth / canvasWidth;

  return Number(Number(value * ratio).toFixed(2));
}

function html_to_xml(
  html: string,
  meta: {
    width: number;
    height: number;
    sceneWidth: number;
  }
) {
  html = html.replaceAll(
    new RegExp("font-size:.*?(.*?)cqw;", "g"),
    (match1, match2) => {
      const amount = +match2;
      const normalizedFontSize = calculateReverseRelativeSize(amount);

      return match1.replace(
        `${amount}cqw`,
        `${String(getProportionalSize(normalizedFontSize, meta.sceneWidth))}px`
      );
    }
  );

  const doc = document.implementation.createHTMLDocument("canvas");
  doc.body.style.cssText = `
  width: ${getProportionalSize(meta.width, meta.sceneWidth)}px;
  height: ${getProportionalSize(meta.height, meta.sceneWidth)}px;
  font-size: ${getProportionalSize(16, meta.sceneWidth)}px;
  line-height: 1.6;
  word-spacing: 0em;
  letter-spacing: 0em;
  `;
  const style = document.createElement("style");
  style.setAttribute("type", "text/css");
  style.innerHTML = `
  li {
    padding: 0px;
  }
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  `;

  doc.body.appendChild(style);
  doc.write(html);

  if (doc.documentElement.namespaceURI)
    doc.documentElement.setAttribute("xmlns", doc.documentElement.namespaceURI);

  html = new XMLSerializer().serializeToString(doc.body);
  return html;
}

const ScenePreviewRenderer = (props: {
  scene: VideoScene;
  width?: number;
  className?: string;
}) => {
  const ref = useRef<HTMLCanvasElement>(null);
  const aspectRatio = canvasWidth / canvasHeight;
  const sceneWidth = props.width || 300;
  const sceneHeight = sceneWidth / aspectRatio;

  useEffect(() => {
    const canvas = ref.current;

    if (!canvas) return;

    const ctx = canvas.getContext("2d");

    if (!ctx) return;

    const elements = normalizeOrder(props.scene.elements).sort((a, b) => {
      return a.order - b.order;
    });

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    ctx.fillStyle = props.scene.backgroundColor;
    ctx.fillRect(0, 0, sceneWidth, sceneHeight);

    function roundedImage(
      x: number,
      y: number,
      width: number,
      height: number,
      radius: number
    ) {
      if (!ctx) return;

      ctx.beginPath();
      ctx.moveTo(x + radius, y);
      ctx.lineTo(x + width - radius, y);
      ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
      ctx.lineTo(x + width, y + height - radius);
      ctx.quadraticCurveTo(
        x + width,
        y + height,
        x + width - radius,
        y + height
      );
      ctx.lineTo(x + radius, y + height);
      ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
      ctx.lineTo(x, y + radius);
      ctx.quadraticCurveTo(x, y, x + radius, y);
      ctx.closePath();
    }

    elements.forEach((element) => {
      const { rotation, left, top, width, height } = element.states[0];

      if (element.type === "text") {
        const text = convertToRichText(element).text;
        const data =
          "data:image/svg+xml;charset=utf-8," +
          '<svg xmlns="http://www.w3.org/2000/svg" width="' +
          getProportionalSize(width, sceneWidth) +
          '" height="' +
          getProportionalSize(height, sceneWidth) +
          '">' +
          '<foreignObject width="100%" height="100%">' +
          html_to_xml(text, {
            width,
            height,
            sceneWidth,
          }) +
          "</foreignObject>" +
          "</svg>";

        const img = new Image();
        img.onload = function () {
          ctx.save();

          const translateX = left + width / 2;
          const translateY = top + height / 2;
          ctx.translate(
            getProportionalSize(translateX, sceneWidth),
            getProportionalSize(translateY, sceneWidth)
          );
          ctx.rotate((rotation * Math.PI) / 180);
          ctx.translate(
            0 - getProportionalSize(translateX, sceneWidth),
            0 - getProportionalSize(translateY, sceneWidth)
          );
          ctx.drawImage(
            img,
            getProportionalSize(left, sceneWidth),
            getProportionalSize(top, sceneWidth)
          );
          ctx.restore();
        };
        img.src = data;
      }

      if (element.type === "image") {
        const image = new Image();

        image.src = element.image.image;

        image.onload = () => {
          ctx.drawImage(
            image,
            getProportionalSize(left, sceneWidth),
            getProportionalSize(top, sceneWidth),
            getProportionalSize(width, sceneWidth),
            getProportionalSize(height, sceneWidth)
          );
        };
      }

      if (element.type === "gif") {
        const image = new Image();

        image.src = element.image.src.url;

        image.onload = () => {
          ctx.drawImage(
            image,
            getProportionalSize(left, sceneWidth),
            getProportionalSize(top, sceneWidth),
            getProportionalSize(width, sceneWidth),
            getProportionalSize(height, sceneWidth)
          );
        };
      }

      if (element.type === "video") {
        const video = document.createElement("video");

        video.src = element.file.file;
        // this is important
        video.autoplay = true;
        video.muted = true;

        video.onloadeddata = () => {
          const radius =
            Math.max(
              getProportionalSize(width, sceneWidth),
              getProportionalSize(height, sceneWidth)
            ) / 2;

          ctx.save();
          roundedImage(
            getProportionalSize(left, sceneWidth),
            getProportionalSize(top, sceneWidth),
            getProportionalSize(width, sceneWidth),
            getProportionalSize(height, sceneWidth),
            radius
          );
          ctx.clip();
          ctx.drawImage(
            video,
            getProportionalSize(left, sceneWidth),
            getProportionalSize(top, sceneWidth),
            getProportionalSize(width, sceneWidth),
            getProportionalSize(height, sceneWidth)
          );
          ctx.restore();
          video.pause();
        };
      }

      if (element.type === "polygon") {
        const { config } = element;

        ctx.beginPath();
        ctx.moveTo(
          getProportionalSize(left + width / 2, sceneWidth),
          getProportionalSize(top, sceneWidth)
        );
        ctx.lineTo(
          getProportionalSize(left, sceneWidth),
          getProportionalSize(top + height, sceneWidth)
        );
        ctx.lineTo(
          getProportionalSize(left + width, sceneWidth),
          getProportionalSize(top + height, sceneWidth)
        );
        ctx.closePath();

        ctx.fillStyle = hexToRgba(config.fillColor, config.fillOpacity / 100);
        ctx.fill();
        ctx.lineWidth = config.strokeSize / 2;
        ctx.strokeStyle = hexToRgba(
          config.strokeColor,
          config.strokeOpacity / 100
        );
        ctx.stroke();
      }
      if (element.type === "star") {
        const { config } = element;

        ctx.beginPath();
        ctx.moveTo(
          getProportionalSize(left, sceneWidth),
          getProportionalSize(top + height * 0.38, sceneWidth)
        );
        ctx.lineTo(
          getProportionalSize(left + width * 0.38, sceneWidth),
          getProportionalSize(top + height * 0.38, sceneWidth)
        );
        ctx.lineTo(
          getProportionalSize(left + width * 0.5, sceneWidth),
          getProportionalSize(top, sceneWidth)
        );
        ctx.lineTo(
          getProportionalSize(left + width * 0.62, sceneWidth),
          getProportionalSize(top + height * 0.38, sceneWidth)
        );
        ctx.lineTo(
          getProportionalSize(left + width, sceneWidth),
          getProportionalSize(top + height * 0.38, sceneWidth)
        );
        ctx.lineTo(
          getProportionalSize(left + width * 0.68, sceneWidth),
          getProportionalSize(top + height * 0.61, sceneWidth)
        );
        ctx.lineTo(
          getProportionalSize(left + width * 0.81, sceneWidth),
          getProportionalSize(top + height, sceneWidth)
        );
        ctx.lineTo(
          getProportionalSize(left + width * 0.5, sceneWidth),
          getProportionalSize(top + height * 0.79, sceneWidth)
        );
        ctx.lineTo(
          getProportionalSize(left + width * 0.19, sceneWidth),
          getProportionalSize(top + height, sceneWidth)
        );
        ctx.lineTo(
          getProportionalSize(left + width * 0.32, sceneWidth),
          getProportionalSize(top + height * 0.61, sceneWidth)
        );

        ctx.closePath();

        ctx.fillStyle = hexToRgba(config.fillColor, config.fillOpacity / 100);
        ctx.fill();
        ctx.lineWidth = config.strokeSize / 2;
        ctx.strokeStyle = hexToRgba(
          config.strokeColor,
          config.strokeOpacity / 100
        );
        ctx.stroke();
      }

      if (element.type === "rectangle") {
        const { config } = element;

        ctx.beginPath();
        ctx.rect(
          getProportionalSize(left, sceneWidth),
          getProportionalSize(top, sceneWidth),
          getProportionalSize(width, sceneWidth),
          getProportionalSize(height, sceneWidth)
        );
        ctx.closePath();

        ctx.fillStyle = hexToRgba(config.fillColor, config.fillOpacity / 100);
        ctx.fill();
        ctx.lineWidth = config.strokeSize / 2;
        ctx.strokeStyle = hexToRgba(
          config.strokeColor,
          config.strokeOpacity / 100
        );

        ctx.stroke();
      }

      if (element.type === "poll") {
        ctx.beginPath();
        ctx.rect(
          getProportionalSize(left, sceneWidth),
          getProportionalSize(top, sceneWidth),
          getProportionalSize(width, sceneWidth),
          getProportionalSize(height, sceneWidth)
        );
        ctx.closePath();

        ctx.fillStyle = hexToRgba("#ffffff", 1);
        ctx.fill();
      }

      if (element.type === "open-question") {
        ctx.beginPath();
        ctx.rect(
          getProportionalSize(left, sceneWidth),
          getProportionalSize(top, sceneWidth),
          getProportionalSize(width, sceneWidth),
          getProportionalSize(height, sceneWidth)
        );
        ctx.closePath();

        ctx.fillStyle = hexToRgba("#ffffff", 1);
        ctx.fill();
      }

      if (element.type === "reaction") {
        const { config } = element;

        ctx.beginPath();
        ctx.rect(
          getProportionalSize(left, sceneWidth),
          getProportionalSize(top, sceneWidth),
          getProportionalSize(width, sceneWidth),
          getProportionalSize(height, sceneWidth)
        );
        ctx.closePath();

        ctx.fillStyle = hexToRgba("#ffffff", 1);
        ctx.fill();

        const reactions = config ? config.reactions : [];

        reactions.forEach((reactionName, index) => {
          const reaction = allowedReactions.find(
            (reaction) => reaction.name === reactionName
          );

          if (reaction) {
            ctx.fillText(
              reaction.icon,
              getProportionalSize(left + 20 + index * 60, sceneWidth),
              getProportionalSize(top + 50, sceneWidth)
            );
          }
        });
      }

      if (element.type === "ellipse") {
        const { config } = element;

        ctx.beginPath();
        ctx.ellipse(
          getProportionalSize(left + width / 2, sceneWidth),
          getProportionalSize(top + height / 2, sceneWidth),
          getProportionalSize(width / 2, sceneWidth),
          getProportionalSize(height / 2, sceneWidth),
          rotation,
          0,
          2 * Math.PI
        );
        ctx.closePath();

        ctx.fillStyle = hexToRgba(config.fillColor, config.fillOpacity / 100);
        ctx.fill();
        ctx.lineWidth = config.strokeSize / 2;
        ctx.strokeStyle = hexToRgba(
          config.strokeColor,
          config.strokeOpacity / 100
        );
        ctx.stroke();
      }

      if (element.type === "audio") {
        ctx.beginPath();
        ctx.ellipse(
          getProportionalSize(left + width / 2, sceneWidth),
          getProportionalSize(top + width / 2, sceneWidth),
          getProportionalSize(width / 2, sceneWidth),
          getProportionalSize(width / 2, sceneWidth),
          rotation,
          0,
          2 * Math.PI
        );
        ctx.closePath();

        ctx.fillStyle = hexToRgba("#ffffff", 0.6);
        ctx.fill();
        ctx.lineWidth = 2;
        ctx.strokeStyle = hexToRgba("#ffffff", 1);
        ctx.stroke();
      }

      if (
        element.type === "arrow-1" ||
        element.type === "arrow-2" ||
        element.type === "arrow-3"
      ) {
        const { config } = element;

        ctx.beginPath();
        ctx.moveTo(
          getProportionalSize(left, sceneWidth),
          getProportionalSize(top, sceneWidth)
        );
        ctx.lineTo(
          getProportionalSize(left + width, sceneWidth),
          getProportionalSize(top, sceneWidth)
        );
        ctx.closePath();

        ctx.lineWidth = config.strokeSize / 2;
        ctx.strokeStyle = hexToRgba(
          config.strokeColor,
          config.strokeOpacity / 100
        );
        ctx.stroke();
      }

      if (element.type === "drawing") {
        const { config, drawing } = element;

        ctx.lineWidth = config.lineWidth;
        ctx.strokeStyle = hexToRgba(config.color, config.opacity / 100);

        ctx.save();
        ctx.scale((0.32 * width) / canvasWidth, (0.32 * height) / canvasHeight);
        ctx.translate(
          getProportionalSize(left, sceneWidth) * 10,
          getProportionalSize(top, sceneWidth) * 10
        );

        drawing.marks.forEach((mark) => {
          const path = new Path2D(
            getSvgPathFromStroke(
              getStroke(mark.points, {
                size: config.lineWidth,
                thinning: 0,
                smoothing: 0.5,
                streamline: 0.5,
              })
            )
          );

          ctx.stroke(path);
        });

        ctx.restore();
      }
    });
  }, [props, sceneWidth, sceneHeight]);

  return (
    <canvas
      width={sceneWidth}
      height={sceneHeight}
      ref={ref}
      className={props.className}
      style={{
        width: "100%",
        display: "block",
      }}
    />
  );
};

export const ScenePreview = React.memo(
  ScenePreviewRenderer,
  (oldProps, newProps) => {
    const is = isEqual(oldProps, newProps);

    return is;
  }
);
