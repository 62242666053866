import React from "react";
import { css } from "styled-components/macro";

import { Avatar } from "../VideoEditor/components/Avatar";

import { UserMeta } from "../types/UserProfile";

export type UserMetaPreviewProps = {
  user: UserMeta;
  dense?: boolean;
  onClick?: (user: UserMeta) => void;
  className?: string;
};

export function UserMetaPreview(props: UserMetaPreviewProps) {
  const { user, dense, onClick = () => {}, className } = props;

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
      `}
      onClick={() => onClick(user)}
      className={className}
    >
      <Avatar
        key={user.id}
        url={user.avatar?.image}
        border
        className="avatar"
        size={dense ? 26 : 43}
        css={css`
          ${!dense && "border-width: 2px;"}
        `}
        name={user.first_name + " " + user.last_name}
      />

      <div
        css={css`
          display: flex;
          align-items: left;
          flex-direction: column;
          margin-left: ${dense ? "7px" : "12px"};
          white-space: nowrap;
          overflow: hidden;
        `}
      >
        <span
          css={css`
            font-weight: ${dense ? 400 : 500};
            font-size: 16px;
            line-height: 22px;
          `}
        >
          {user.first_name} {user.last_name}
        </span>

        <span
          css={css`
            font-weight: 300;
            font-size: ${dense ? "14px" : "16px"};
            overflow: hidden;
            text-overflow: ellipsis;
            ${!dense &&
            css`
              color: #8e8e8e;
            `}
            line-height: 19px;
          `}
        >
          {user.email}
        </span>
      </div>
    </div>
  );
}
