import Joyride, { Step, STATUS } from "react-joyride";
import { useEditor } from "../contexts/EditorContext";
import { TooltipBody } from "./TooltipBody";
import { useHistory } from "react-router-dom";
import { useStorage } from "../contexts/StorageContext";
import { useTranslation } from "react-i18next";
import { sendEvent } from "../../helpers/tracking";

export function WalkThrough() {
  const { openSettings, closeSettings, showProductTour } = useEditor();
  const history = useHistory();
  const { video } = useStorage();
  const { t } = useTranslation();
  const tourSteps: Step[] = [
    {
      target: "#themis-timeline",
      title: t("tooltip_timeline.title"),
      content: (
        <p>
          {t("tooltip_timeline.description.first")}
          <br /> {t("tooltip_timeline.description.second")}
        </p>
      ),
      styles: {
        options: {
          arrowColor: "#FAE7D9",
        },
      },
      floaterProps: {
        styles: {
          floaterWithAnimation: {
            transition: "opacity 0.9s ease 0.5s",
          },
          floater: {
            filter: "drop-shadow(rgba(0, 0, 0, 0.2) 0px 0px 6px)",
          },
        },
      },
      disableBeacon: true,
    },
    {
      target: "#themis-canvasSettings",
      title: t("tooltip_canvasSettings.title"),
      styles: {
        options: {
          arrowColor: "#fff",
        },
      },
      floaterProps: {
        styles: {
          floaterWithAnimation: {
            transition: "opacity 0.9s ease 0.5s",
          },
          floater: {
            filter: "drop-shadow(rgba(0, 0, 0, 0.2) 0px 0px 6px)",
          },
        },
      },
      placement: "left",
      content: t("tooltip_canvasSettings.description"),
      disableBeacon: true,
    },
    {
      target: "#themis-collaborators",
      title: t("tooltip_collaboration.title"),
      styles: {
        options: {
          arrowColor: "#fff",
        },
      },
      floaterProps: {
        styles: {
          floaterWithAnimation: {
            transition: "opacity 0.9s ease 0.5s",
          },
          floater: {
            filter: "drop-shadow(rgba(0, 0, 0, 0.2) 0px 0px 6px)",
          },
        },
      },
      content: t("tooltip_collaboration.description"),

      disableBeacon: true,
    },

    {
      target: "#themis-publish",
      title: t("tooltip_publish.title"),
      content: (
        <div>
          <p>
            {t("tooltip_publish.description")}
            <strong> {t("tooltip_publish.direct-link")} </strong>{" "}
            {t("tooltip_publish.or")}
            <strong> {t("tooltip_publish.export-file")}</strong>
          </p>
        </div>
      ),
      disableBeacon: true,
      floaterProps: {
        styles: {
          floaterWithAnimation: {
            transition: "opacity 0.9s ease 0.5s",
          },
          floater: {
            filter: "drop-shadow(rgba(0, 0, 0, 0.2) 0px 0px 6px)",
          },
        },
      },
      styles: {
        tooltipTitle: {
          paddingRight: "10px",
        },
      },
    },
    {
      target: "#themis-sceneEditor",
      title: t("tooltip_screenEditor.title"),
      content: t("tooltip_screenEditor.description"),
      disableBeacon: true,
      placement: "center",
      floaterProps: {
        styles: {
          floater: {
            filter: "drop-shadow(rgba(0, 0, 0, 0) 0px 0px 0px)",
          },
        },
      },
    },
  ];

  return (
    <>
      <Joyride
        run={showProductTour}
        steps={tourSteps}
        continuous
        showSkipButton={false}
        showProgress={true}
        disableOverlay={true}
        tooltipComponent={TooltipBody}
        callback={(data) => {
          const finishedStatuses: string[] = [STATUS.FINISHED];
          if (finishedStatuses.includes(data.status)) {
            sendEvent("Product Tour Completed");

            history.push({ pathname: `/video-editor/${video?.uuid}` });
          }
          if (data.action === "update") {
            if (data.index === 1) {
              openSettings();
            } else {
              closeSettings();
            }
          }
        }}
      />
    </>
  );
}
