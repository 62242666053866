import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useParams } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { css } from "styled-components/macro";
import { customToast } from "../../components/customToast";
import { Button } from "../../VideoEditor/components/Button";
import { InputType } from "../../components/InputComponent";
import { InputField } from "../fields/InputField";
import { Column, Spacing, Row } from "../../helpers/layout";
import { setPassword } from "../../actions/auth/setPassword";

import { serverErrorHandler } from "../../helpers/serverErrorHandler";

interface FormValues {
  new_password: string;
  confirm_password: string;
}

export function SetPasswordForm() {
  const params = useParams<{
    uuid: string;
  }>();

  const history = useHistory();
  const { t } = useTranslation();

  return (
    <div>
      <Formik<FormValues>
        initialValues={{
          new_password: "",
          confirm_password: "",
        }}
        validationSchema={Yup.object().shape({
          new_password: Yup.string().min(8).required(),
          confirm_password: Yup.string()
            .oneOf([Yup.ref("new_password"), null], t("auth.pwd-not-match"))
            .required(),
        })}
        onSubmit={async (values: FormValues, { setSubmitting }) => {
          try {
            setSubmitting(true);

            await setPassword(values.new_password, params.uuid);

            customToast.success(t("status.success"));

            history.push("/login");
          } catch (error) {
            customToast.error(
              t("status.error", {
                error: serverErrorHandler(error),
              })
            );
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, isValid }) => (
          <Form noValidate>
            <Column gutter={Spacing.m}>
              <InputField
                name="new_password"
                type={InputType.PASSWORD}
                placeholder={t("auth.new-password")}
                css={css`
                  padding: 0 40px;
                `}
              />

              <InputField
                name="confirm_password"
                type={InputType.PASSWORD}
                placeholder={t("auth.confirm-new-password")}
                css={css`
                  padding: 0 40px;
                `}
              />

              <Row
                justify="flex-end"
                align="center"
                css={css`
                  margin-top: 40px;
                `}
              >
                <Button
                  isSubmitting={isSubmitting}
                  disabled={!isValid}
                  type="submit"
                  css={css`
                    margin: 0 auto;
                    color: #fff;
                  `}
                >
                  {t("auth.save-password")}
                </Button>
              </Row>
            </Column>
            <Link
              css={css`
                display: flex;
                justify-content: center;
                font-size: 16px;
                font-weight: 500;
                margin-top: 20px;
                cursor: pointer;
              `}
              to="/login"
            >
              {t("auth.back-to-login")}
            </Link>
          </Form>
        )}
      </Formik>
    </div>
  );
}
