import { authorised } from "../../request";

type Args = {
  videoId: string;
  userId: number;
};

export async function deleteCollaborator(args: Args) {
  const API = authorised();

  return API.request({
    method: "DELETE",
    url: `/api/studio/themis/${args.videoId}/collaborators/${args.userId}/`,
  });
}
