import { useEffect, useState } from "react";
import { getWaitlistPosition } from "../actions/auth/waitlistPosition";
import { Footer } from "../components/Landing/Footer";
import { Header } from "../components/Landing/Header";
import { createGlobalStyle } from "styled-components";
import { Wrapper } from "../components/Landing/Wrapper";
import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { FeedbackWidget } from "../components/FeedbackWidget";

const GlobalStyle = createGlobalStyle`
  body {
    background: #f9efe0;
  }
`;

export function WaitlistPosition() {
  const [position, setPosition] = useState(0);
  const { t } = useTranslation();
  const refQueryParam = new URLSearchParams(window.location.search).get("ref");

  useEffect(() => {
    if (!refQueryParam) return;

    getWaitlistPosition(refQueryParam).then((data) => {
      setPosition(data.data.order);
    });
  }, [refQueryParam]);

  const invite_link = `https://${window.location.hostname}/?ref=${refQueryParam}`;

  return (
    <div>
      <GlobalStyle />
      <Header />
      <Wrapper>
        <div
          css={css`
            padding: 100px 0;
            text-align: center;
          `}
        >
          <h2
            css={css`
              margin-bottom: 20px;
            `}
          >
            {t("waitlist.position.title", { value: position })}
          </h2>
          <p>{t("waitlist.position.description.firstpart")}</p>
          <p>{t("waitlist.position.description.secondpart")}</p>
          <div
            css={css`
              display: inline-block;
              margin-top: 40px;
              background: #fff;
              padding: 10px 20px;
              border-radius: 5px;
            `}
            onClick={(e) => {
              // select the text
              const range = document.createRange();
              range.selectNode(e.currentTarget);
              const selection = window.getSelection();

              if (selection) {
                selection.removeAllRanges();
                selection.addRange(range);
              }
            }}
          >
            {invite_link}
          </div>
        </div>
      </Wrapper>
      <Footer />
      <FeedbackWidget />
    </div>
  );
}
