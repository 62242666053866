import React, { useRef, useState, useCallback, ChangeEvent } from "react";
import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";

import { AcceptedMimeTypes } from "../../types/File";

import { Button } from "./Button";
import { CircularProgressBar } from "../../components/CircularProgressBar";
import { customToast } from "../../components/customToast";

import { ReactComponent as DownloadIcon } from "../assets/icons/Download.svg";

import { statusPercent, useAi } from "../contexts/AiContext";
import { serverErrorHandler } from "../../helpers/serverErrorHandler";

import { theme } from "../../themes/variables";
import { UploadStatus } from "../actions/getAiFileStatus";

const supportedFormats = [
  AcceptedMimeTypes.PDF,
  AcceptedMimeTypes.DOC,
  AcceptedMimeTypes.DOCX,
  AcceptedMimeTypes.PPT,
  AcceptedMimeTypes.PPTX,
  AcceptedMimeTypes.JPG,
  AcceptedMimeTypes.JPEG,
  AcceptedMimeTypes.TXT,
  AcceptedMimeTypes.PNG,
];

const statusMessage: {
  [key in UploadStatus]: string;
} = {
  [UploadStatus.ERROR]: "ml.status.error",
  [UploadStatus.UPLOADING]: "ml.status.uploading",
  [UploadStatus.PARSING]: "ml.status.parsing",
  [UploadStatus.SUMMARIZING]: "ml.status.summarizing",
  [UploadStatus.GENERATING_LAYOUT]: "ml.status.generating-layout",
  [UploadStatus.COMPLETED]: "ml.status.completed",
};

export function AiSidebar() {
  const { t } = useTranslation();
  const { status, uploadFiles } = useAi();
  const [loading, setLoading] = useState(false);

  const uploadRef = useRef<HTMLInputElement>(null);

  const onUploadBtnClick = useCallback(() => {
    if (uploadRef.current) {
      uploadRef.current.click();
    }
  }, []);

  const onUpload = useCallback(
    async (files: File[]) => {
      setLoading(true);
      try {
        await uploadFiles(files);
      } catch (e: any) {
        customToast.error(
          t("status.error", {
            error: serverErrorHandler(e),
          })
        );
      } finally {
        setLoading(false);
      }
    },
    [t, uploadFiles]
  );

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const files = acceptedFiles;

      if (files) {
        onUpload(files);
      }
    },
    [onUpload]
  );

  const onFileUpload = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        onUpload(Array.from(e.target.files));
      }
    },
    [onUpload]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div>
      <div
        css={css`
          font-size: 20px;
          line-height: 22px;
          font-weight: 500;
        `}
      >
        {t("ai.summarize")}
      </div>

      <div
        css={css`
          margin-top: 11px;
          margin-bottom: 30px;
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
        `}
      >
        {t("ai.description")}
      </div>

      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 20px;
        `}
      >
        <div
          {...getRootProps()}
          css={css`
            ${isDragActive &&
            css`
              display: flex;
              align-items: center;
              justify-content: center;
              height: 106px;
              width: 100%;
              border: 1px dashed ${theme.colors.primary};
              background: #f5cdb3;
              background: transparent;
              border-radius: 6px;
              cursor: pointer;
              ${isDragActive ? `background: #f5cdb3;` : ""}
            `}
          `}
        >
          <input
            {...getInputProps()}
            ref={uploadRef}
            onChange={onFileUpload}
            multiple
            accept={Object.values(supportedFormats).join(",")}
          />
          {!isDragActive && (
            <Button
              icon={<DownloadIcon width={13} height={17} />}
              css={css`
                padding: 9px 20px;
                color: ${theme.colors.white};
                background: ${theme.colors.black};
              `}
              onClick={onUploadBtnClick}
              isSubmitting={loading}
            >
              {t("ai.upload-file")}
            </Button>
          )}
        </div>
      </div>
      {status.map((job) => (
        <div
          key={job.file_id}
          css={css`
            width: calc(100% + 42px);
            margin-left: -21px;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 17px 21px;
            gap: 12px;
            color: ${theme.colors.white};
            background: linear-gradient(
              94.89deg,
              #a47aff 35.78%,
              #c3a9fc 99.32%
            );
            border-radius: 8px;
            margin-bottom: 20px;
          `}
        >
          <div
            css={css`
              flex: 1 0 10%;
              min-width: 0;
            `}
          >
            <div
              css={css`
                font-weight: 500;
                font-size: 16px;
                line-height: 22px;
              `}
            >
              {t(statusMessage[job.status])}
            </div>
            <div
              css={css`
                font-weight: 300;
                font-size: 14px;
                line-height: 22px;
                word-wrap: break-word;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
              `}
            >
              {job.filename}
            </div>
          </div>
          <div
            css={css`
              width: 52px;
              height: 52px;
              flex: 0 0 auto;
            `}
          >
            <CircularProgressBar
              percent={statusPercent[job.status]}
              size={52}
              strokeWidth={3}
              color={theme.colors.white}
            >
              {statusPercent[job.status]}%
            </CircularProgressBar>
          </div>
        </div>
      ))}
    </div>
  );
}
